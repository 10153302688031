import { axios } from "../../config/axios";
import { endpoint } from "../../config/constants";
import { formatDate } from "../../utils/formatDate";

export const getSlaughtertaskList = async () => {
	

	const token = JSON.parse(localStorage.getItem("token"));
	const response = await axios.get(endpoint.GET_SLAUGHTER_TASK_LIST, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});

	if (!response.data) {
		throw new Error("No data found");
	}

	
	return response?.data;
};



export const getSlaughter = async (pageNumber = 1, limit = 10) => {
	const token = JSON.parse(localStorage.getItem("token"));
	const response = await axios.get(endpoint.GET_SLAUGHTERS+ `&pageNumber=${pageNumber}&pageSize=${limit}`, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});

	if (!response.data) {
		throw new Error("No data found");
	}

	const transformedData = response?.data?.data?.data.map((data) => {
	   
		const tobeDeleted = ["password"];
		tobeDeleted.forEach((key) => delete data[key]);
			return {
				...data,
				created_at: formatDate(data.created_at),
			};
		});
	
		return {data:transformedData};
};








// updateOrder

// export const updateOrder = async (id, status) => {
// 	const token = JSON.parse(localStorage.getItem("token"));
// 	const response = await axios.put(
// 		endpoint.UPDATE_ORDER_STATUS + `${id}`,
// 		{
// 			order_status: status,
// 		},
// 		{
// 			headers: {
// 				Authorization: `Bearer ${token}`,
// 			},
// 		}
// 	);
// 	if (!response.data) {
// 		throw new Error("No data found");
// 	}
// 	return response.data;
// };
