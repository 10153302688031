import React, { useState } from "react";

const TransTab = ({
  className = "",
  tabsName,
  tabNumberCounting,
  onTabChange,
  searchbar,
}) => {
  const [activeTab, setActiveTab] = useState(tabsName[0]);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    onTabChange(tab); // Notify parent component about tab change
  };

  return (
    <div className={`flex flex-col md:flex-row md:items-center justify-between ${className}`}>
      <div className="flex border-pacific-blue">
        {tabsName?.map((tab) => {
          const tabInfo = tabNumberCounting.find((item) => item.name === tab);
          const countValue = tabInfo ? tabInfo.countValue : 0;
          return (
            <button
              key={tab}
              onClick={() => handleTabChange(tab)}
              className={`flex-1 py-3 px-1 md:px-4 flex flex-nowrap ${
                activeTab === tab
                  ? "border-b-2 border-pacific-blue text-pacific-blue"
                  : "text-gray-500"
              } hover:bg-gray-100`}
            >
              {tab}
              <span
                className={`ml-2 py-0.5 px-1 text-xs text-center ${
                  activeTab === tab
                    ? "bg-pacific-blue text-white"
                    : "text-gray-500 bg-[#E4E4E7] hidden md:inline-block"
                }`}
              >
                {countValue}
              </span>
            </button>
          );
        })}
      </div>
       {searchbar && <div> {searchbar}</div>}
    </div>
  );
};

export default TransTab;
