import React from 'react'
import cattle1 from "../../../assets/images/cattle.png"
import {
    dateIcon,
    locationIcon,
    timeIcon,
    totalTaskIcon,
  } from "../../../assets/Table/TabListIcon";
  import {
    correctIcon,
    rejectIcon,
    rightArrowIcon,
    reportNowIcon,
  } from "../../../assets/Table/TableActionIcon";

function DetailPage({changeDetailPage, activeTab}) {
  return (
    <div>
    <button className="px-4 py-5 text-[#00ADB9]" onClick={()=>changeDetailPage()}>Return</button>
   
    <div className={`w-full rounded-md overflow-hidden ${activeTab === "Rejected"? "border-[#D9D9D9]  ":" border-[#00ADB9] "}  border-[2px]`}>
    <div className={`w-full px-4 py-5  ${activeTab === "Rejected"? " text-[#525252] bg-[#D9D9D9]":"  bg-[#CFF0F2]"}  flex justify-between`}>
        <h1>ORD-20240514-987654</h1>
        <p className="text-red-500">New</p>
    </div>

    <div className="flex text-[#808080] text-[12px] px-4 py-5 justify-between flex-wrap">

     <div className="flex">
        <div className="p-2">
        {dateIcon}
        </div>
       <div className="flex flex-col">
         <p>Date of Qurban</p>
         <p>11/07/2024</p>
       </div>
     </div>

     <div className="flex">
        <div className="p-2">
        {locationIcon}
        </div>
       <div className="flex flex-col">
         <p>Loaction</p>
         <p>Masjid Kampung Klang Gate</p>
       </div>
     </div>

     <div className="flex">
        <div className="p-2">
        {timeIcon}
        </div>
       <div className="flex flex-col">
         <p>Time</p>
         <p>09:00 AM - 04:30 PM</p>
       </div>
     </div>
   
    </div>

    

<div className="relative overflow-x-auto shadow-md sm:rounded-lg">
   <table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
       <thead className={`text-xs  ${activeTab === "Rejected"? "text-[#D9D9D9] ": " text-[#00ADB9]"} uppercase bg-gray-50 `}>
           <tr>
               <th scope="col" className="px-6 py-3">
                   Ruminant ID
               </th>
               <th scope="col" className="px-6 py-3">
                   Farm
               </th>
               <th scope="col" className="px-6 py-3">
                   Participants
               </th>
               {activeTab=="Ongoing" ?
               <th scope="col" className="px-6 py-3">
               proof
               </th>
                 :
                 activeTab=="Completed" ?
                ( <th scope="col" className="px-6 py-3">
                    proof
                    </th>)
                 :null
              }
           </tr>
       </thead>
       <tbody>
           <tr className="bg-white border-b  hover:bg-gray-50 ">
               <th scope="row" className="flex items-center px-6 py-4 font-medium text-gray-900 whitespace-nowrap ">
                 <img src={cattle1}  className="pr-2"/>
                 
                  MY-RUM-2023-123456
               </th>
               <td className="px-6 py-4">
                   3 People
               </td>
               <td className="px-6 py-4">
               3 People
               </td>
               {activeTab==="Ongoing" ?
               (<td className="px-6 py-4 text-white">
               <button className='btn bg-[#9747FF] w-[100px] py-2 rounded-md mr-2'>Before</button>
               <button className='btn bg-[#CCCCCC] w-[100px] py-2 rounded-md '>After</button>
               </td>)
               :
               activeTab==="Completed" ?
              ( <td className="px-6 py-4 text-[#00ADB9] e">
               <button className='btn border border-[#00ADB9] w-[100px] py-2 rounded-md mr-2'>Uploaded</button>
               <button className='btn border border-[#00ADB9] w-[100px] py-2 rounded-md '>Uploaded</button>
               </td>):

activeTab=="Rejected" ?
( <td className="px-6 py-4 text-[#00ADB9] e">
 <button className={`btn text-white bg-[#D9D9D9] w-[100px] py-2 rounded-md mr-2`}>Uploaded</button>
 <button className='btn text-white bg-[#D9D9D9] w-[100px] py-2 rounded-md '>Uploaded</button>
 </td>)
               :null
            }
           </tr>
           <tr className="bg-white border-b  hover:bg-gray-50 ">
           <th scope="row" className="flex items-center px-6 py-4 font-medium text-gray-900 whitespace-nowrap ">
           <img src={cattle1}  className="pr-2"/> MY-RUM-2023-123456
               </th>
               <td className="px-6 py-4">
                   2 people
               </td>
               <td className="px-6 py-4">
               3 People
               </td>
               {activeTab=="Ongoing" ?
               (<td className="px-6 py-4 text-white">
               <button className='btn bg-[#9747FF] w-[100px] py-2 rounded-md mr-2'>Before</button>
               <button className='btn bg-[#CCCCCC] w-[100px] py-2 rounded-md '>After</button>
               </td>)
               :
               activeTab=="Completed" ?
              ( <td className="px-6 py-4 text-[#00ADB9] e">
               <button className='btn border border-[#00ADB9] w-[100px] py-2 rounded-md mr-2'>Uploaded</button>
               <button className='btn border border-[#00ADB9] w-[100px] py-2 rounded-md '>Uploaded</button>
               </td>):
               activeTab=="Rejected" ?
               ( <td className="px-6 py-4 text-[#00ADB9] e">
                <button className={`btn text-white bg-[#D9D9D9] w-[100px] py-2 rounded-md mr-2`}>Uploaded</button>
                <button className='btn text-white bg-[#D9D9D9] w-[100px] py-2 rounded-md '>Uploaded</button>
                </td>)
               :null
            }
           </tr>
          
       </tbody>
   </table>
</div>

    </div>

    <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-5 mb-5 border border-[#D9D9D9]">
   <table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
       <thead className={`text-xs uppercase ${activeTab === "Rejected"? "text-[#525252] bg-[#D9D9D9]": "text-xs text-[#00ADB9] bg-[#CFF0F2]"} `}>
           <tr>
               <th scope="col" className="px-6 py-3">
                  Name
               </th>
               <th scope="col" className="px-6 py-3">
                   IC Number
               </th>
               <th scope="col" className="px-6 py-3">
                  Portion
               </th>
               <th scope="col" className="px-6 py-3">
               Ruminant ID
               </th>

              
           </tr>
       </thead>
       <tbody>
           <tr className="bg-white border-b  hover:bg-gray-50 ">
               <th scope="row" className="flex items-center px-6 py-4 font-medium text-gray-900 whitespace-nowrap ">
               Ahmad Ali Khan
               </th>
               <td className="px-6 py-4">
               850624-14-5678
               </td>
               <td className="px-6 py-4">
               2 Portion
               </td>
               <td className="px-6 py-4">
               MY-RUM-2023-123456
               </td>
           </tr>
           <tr className="bg-white border-b  hover:bg-gray-50 ">
               <th scope="row" className="flex items-center px-6 py-4 font-medium text-gray-900 whitespace-nowrap ">
               Ahmad Ali Khan
               </th>
               <td className="px-6 py-4">
               850624-14-5678
               </td>
               <td className="px-6 py-4">
               2 Portion
               </td>
               <td className="px-6 py-4">
               MY-RUM-2023-123456
               </td>
           </tr>
           <tr className="bg-white border-b  hover:bg-gray-50 ">
               <th scope="row" className="flex items-center px-6 py-4 font-medium text-gray-900 whitespace-nowrap ">
               Ahmad Ali Khan
               </th>
               <td className="px-6 py-4">
               850624-14-5678
               </td>
               <td className="px-6 py-4">
               2 Portion
               </td>
               <td className="px-6 py-4">
               MY-RUM-2023-123456
               </td>
           </tr>
           <tr className="bg-white border-b  hover:bg-gray-50 ">
               <th scope="row" className="flex items-center px-6 py-4 font-medium text-gray-900 whitespace-nowrap ">
               Ahmad Ali Khan
               </th>
               <td className="px-6 py-4">
               850624-14-5678
               </td>
               <td className="px-6 py-4">
               2 Portion
               </td>
               <td className="px-6 py-4">
               MY-RUM-2023-123456
               </td>
           </tr>
           <tr className="bg-white border-b  hover:bg-gray-50 ">
               <th scope="row" className="flex items-center px-6 py-4 font-medium text-gray-900 whitespace-nowrap ">
               Ahmad Ali Khan
               </th>
               <td className="px-6 py-4">
               850624-14-5678
               </td>
               <td className="px-6 py-4">
               2 Portion
               </td>
               <td className="px-6 py-4">
               MY-RUM-2023-123456
               </td>
           </tr>
          
       </tbody>
   </table>


</div>


{activeTab === "New" ? (
  <div className="flex w-full mb-[200px] md:mb-[100px]">
    <div
      className="flex w-[50%] px-4 justify-center bg-[#FFF5F2] items-center mx-1 font-medium py-1 border cursor-pointer border-[#E42726] text-[#E42726] rounded-md"
    >
      <span className="flex relative w-6 h-6 justify-center items-center mx-1 mr-2 ml-0 my-0 rounded-full">
        {rejectIcon}
      </span>
      <div className="font-normal leading-none">Reject</div>
    </div>
    <div
      className="flex w-[50%] bg-[#58C083] justify-center items-center mx-1 font-medium py-1 px-4 border cursor-pointer border-[#58C083] text-white rounded-md"
    >
      <span className="flex relative w-6 h-6 justify-center items-center mx-1 mr-2 ml-0 my-0 rounded-full">
        {correctIcon}
      </span>
      <div className="font-normal leading-none">Accept</div>
    </div>
  </div>
) :


activeTab === "Ongoing" ||  activeTab === "Rejected"? (<>
    <div className='rounded-md overflow-hidden shadow-md sm:rounded-lg mt-5 mb-5 border border-[#D9D9D9]'>
        <div className={` ${activeTab === "Rejected"? "text-[#525252] bg-[#D9D9D9]": "text-[#00ADB9] uppercase bg-[#CFF0F2]"} p-3`}> <h1>Remarks</h1></div>

<div>
    

<textarea id="message" rows="6" className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg  " placeholder="Write Somthing here..."></textarea>

</div>
    </div>
  <div className="flex w-full mb-[200px] md:mb-[100px] text-white">
    <div
      className="flex w-[50%] px-4 justify-center py-3 bg-[#CCCCCC] items-center mx-1 font-medium  border cursor-pointer  rounded-md"
    >
      <div className="font-normal leading-none">Save</div>
    </div>
    <div
      className="flex w-[50%] bg-[#CCCCCC] justify-center items-center mx-1 font-medium py-3 px-4 border cursor-pointer   rounded-md"
    >
      <div className="font-normal leading-none">Submit</div>
    </div>
  </div></>
) :

activeTab === "Completed" ? (<>
    <div className='rounded-md overflow-hidden shadow-md sm:rounded-lg mt-5 mb-5 border border-[#D9D9D9]'>
        <div className='text-[#00ADB9] uppercase bg-[#CFF0F2] p-3'> <h1>Remarks</h1></div>

<div>
    

<textarea id="message" rows="6" className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg  " placeholder="Write Somthing here..."></textarea>

</div>
    </div>
  <div className="flex w-full mb-[200px] md:mb-[100px] text-[#00ADB9]">
    <div
      className="flex w-[100%] px-4 justify-center py-3  items-center mx-1 font-medium  border border-[#00ADB9] cursor-pointer  rounded-md"
    >
      <div className="font-normal leading-none">Completed</div>
    </div>
   
  </div></>
) 
 



: null}





   </div>
  )
}

export default DetailPage
