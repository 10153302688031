import React, { useEffect, useState } from "react";
import HeaderSection from "../../../../components/HeaderSection";
import DataTable from "../../../../components/Table/DataTable";
import CreatePackageDetail from "./CreatePackageDetail";
import { useGetPkgDetails } from "../hooks/useGetPkgDetails";
import { Loader } from "../../../../components/Loader";
import Search from "../../../../components/Search";
import Button from "../../../../components/Button/Button";
import { Dialog } from "@headlessui/react";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { axios } from "../../../../config/axios";
import { useGetPackagesHook } from "../../Packages/hooks/useGetPackagesHook";
import { useGetAnimal } from "../../AnimalTypes/hooks/useGetAnimal";
import { formatDate, formatDateWithoutTime } from "../../../../utils/formatDate";
import { getCalenderDetail } from "../../../../services/settings";
import { FaUser } from "react-icons/fa";
import { config } from "../../../../config/config";

const DisplayPackageDetails = () => {
  const { PackageDetail, isError, isLoading, refetch } = useGetPkgDetails();
  let [edititem, setEditItem] = useState({});
  let [calenderList, setCalenderList] = useState({});
  let [editModal, setEditModal] = useState(false);
  const [editloading, setEditLoading] = useState(false);
  let [createModal, setCreateModal] = useState(false);
  const [calendarVisible, setCalendarVisible] = useState(false);


  const {
    packages
  } = useGetPackagesHook();
  const { animals } = useGetAnimal();
  console.log("packages");
  console.log(packages);



  const {
    register: registerEditPkgDetail,
    handleSubmit: handlePkgDetail,
    formState: { errors: errorsEditpkgDetail },
    setValue: setValueEditPkgDetail,
    watch: watchEditCustomer,
    reset: resetEditPkgDetail,
  } = useForm();




  const handleEditPkgDetail = async (data) => {
    setEditLoading(true);

    // Create a new FormData object
    const formData = new FormData();
    console.log(data)
    // Append your data to the FormData object
    formData.append('package_id', parseInt(data.package_id) || 0);
    formData.append('calendar_id', parseInt(data.calendar_id) || 0);
    formData.append('status', data.status === 'true');
    formData.append('animal_id', parseInt(data.animal_id) || 0);
    formData.append('actual_price', parseInt(data.actual_price) || 0);
    formData.append('price_per_unit', parseInt(data.price_per_unit) || 0);
    // formData.append('closing_date', parseInt(data.closing_date) || 0);
    formData.append('description', data.description || '');
    formData.append('name', data?.name || '');
    formData.append('stock_label', data.stock_label || '');
    formData.append('per_portion_label', data.per_portion_label || '');

    // Append the image file to the FormData object
    if (data.sub_pkg_image && data.sub_pkg_image[0]) {
      formData.append('sub_pkg_image', data.sub_pkg_image[0]);
    }

    const token = JSON.parse(localStorage.getItem("token"));

    try {
      const response = await axios.put(
        `packages/package-details/${edititem.id}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      console.log(response);
      setEditModal(false);
      refetch();
      toast.success("Pkg Detail Updated");
      setEditLoading(false);
    } catch (error) {
      toast.error(error?.response?.data?.error || "Something went wrong");
      console.error("Error:", error);
      setEditLoading(false);
    }
    console.log("Edit item", data);
  };


  useEffect(() => {
    if (editModal) {
       
      // if (edititem?.calendar_event?.id !== undefined) {
      //   setCalendarVisible(false);
      // }
      resetEditPkgDetail({
        package_id: edititem?.package_id || "",
        status: edititem?.enabled ? "true" : "false" || "",
        name: edititem?.name || "",
        calendar_id: edititem?.calendar_event?.id || "",
        animal_id: edititem?.ruminant?.id || "",
        description: edititem?.description || "",
        actual_price: edititem?.actual_price || "",
        price_per_unit: edititem?.price_per_unit || "",
        image: edititem?.image || "",
        per_portion_label: edititem?.per_portion_label || "",
        stock_label: edititem?.stock_label || "",

      });
    }
  }, [editModal, resetEditPkgDetail, edititem]);



  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getCalenderDetail();
        setCalenderList(result);
      } catch (error) {
        console.error("Error fetching calendar details:", error);
      }
    };

    fetchData();
  }, []);


  const {
    register: CreatePkgDetail,
    handleSubmit: handleCreatePkgDetail,
    formState: { errors: errorsCreatePkgDetail },
    setValue: setValueCreatetPkgDetail,
    watch: watchEditPkgDetail,

  } = useForm();







  const handleCreatePkgDetails = async (data) => {
    setEditLoading(true);
     
    // Create a new FormData object
    const formData = new FormData();

    // Append your data to the FormData object
    formData.append('package_id', parseInt(data.package_id) || 0);
    console.log("parseInt(data.calendar_id)");
    if (data.calendar_id !== "") {

      formData.append('calendar_id', parseInt(data.calendar_id) || 0);
    }
    console.log(parseInt(data.calendar_id));
    formData.append('animal_id', parseInt(data.animal_id) || 0);
    formData.append('actual_price', parseInt(data.actual_price) || 0);
    formData.append('price_per_unit', parseInt(data.price_per_unit) || 0);
    formData.append('description', data.description || '');
    formData.append('name', data.name || '');
    formData.append('stock_label', data.stock_label || '');
    formData.append('per_portion_label', data.per_portion_label || '');

    // Append the image file to the FormData object
    if (data.sub_pkg_image && data.sub_pkg_image[0]) {
      formData.append('sub_pkg_image', data.sub_pkg_image[0]);
    }

    const token = JSON.parse(localStorage.getItem("token"));

    try {
      const response = await axios.post(
        `packages/package-details`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      console.log("pkg-detail", response);
      setCreateModal(false);
      refetch();
      toast.success("Pkg Detail Created");
      setEditLoading(false);
    } catch (error) {
      toast.error(error?.response?.data?.error || "Something went wrong");
      console.error("Error:", error);
      setEditLoading(false);
    }
    console.log("Edit item", data);
  };


  if (isError) {
    return <div className="">There was some Error, Please try again</div>;
  }

  if (isLoading) {
    return (
      <div className="flex space-x-2">
        <Loader />
        <div className="">Loading...</div>
      </div>
    );
  }

  return (<>
    <div className='flex justify-between mb-2'>

      <button className="font-bold" onClick={() => console.log(PackageDetail)}>
        Qurban/Aqiqah List
      </button>

      <Button type="button" onClick={() => setCreateModal(true)}>
        Add Package Detail
      </Button>


    </div>

<div className="overflow-auto">
    <table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
      <thead className="text-xs text-pacific-blue uppercase bg-[#CFF0F2] ">
        <tr>
          <th scope="col" className="px-6 py-3 text-base font-medium">
            <div className="flex gap-1 items-center">ID</div>
          </th>

          <th scope="col" className="px-6 py-3 text-base font-medium">
            <div className="flex gap-1 items-center">Name</div>
          </th>
          <th scope="col" className="px-6 py-3 text-base font-medium">
            <div className="flex gap-1 items-center">Image</div>
          </th>
          {/* <th scope="col" className="px-6 py-3 text-base font-medium">
              <div className="flex gap-1 items-center">Sub Pkg Type</div>
            </th> */}

          <th scope="col" className="px-6 py-3 text-base font-medium">
            <div className="flex gap-1 items-center">Master Pkg Type</div>
          </th>


          <th scope="col" className="px-6 py-3 text-base font-medium">
            <div className="flex gap-1 items-center">Description</div>
          </th>
          <th scope="col" className="px-6 py-3 text-base font-medium">
            <div className="flex gap-1 items-center">Price/Unit</div>
          </th>
          <th scope="col" className="px-6 py-3 text-base font-medium">
            <div className="flex gap-1 items-center">Actual Price</div>
          </th>
          <th scope="col" className="px-6 py-3 text-base font-medium">
            <div className="flex gap-1 items-center">Avaible Stock</div>
          </th>

          <th scope="col" className="px-6 py-3 text-base font-medium">
            <div className="flex gap-1 items-center">Rumminant Name</div>
          </th>

          <th scope="col" className="px-6 py-3 text-base font-medium">
            <div className="flex gap-1 items-center">Portions</div>
          </th>

          <th scope="col" className="px-6 py-3 text-base font-medium">
            <div className="flex gap-1 items-center">per portion label</div>
          </th>

          <th scope="col" className="px-6 py-3 text-base font-medium">
            <div className="flex gap-1 items-center">stock label</div>
          </th>


          <th scope="col" className="px-6 py-3 text-base font-medium">
            <div className="flex gap-1 items-center">Created Date </div>
          </th>

          <th scope="col" className="px-6 py-3 text-base font-medium">
            <div className="flex gap-1 items-center">Updated Date </div>
          </th>

          <th scope="col" className="px-6 py-3 text-base font-medium ">
            <div className="flex gap-1 items-center">Enabaled</div>
          </th>
          <th scope="col" className="px-2 py-3 text-base font-medium">
            Action
          </th>
        </tr>
      </thead>
      <tbody>
        {PackageDetail?.map((e) => {
          if (e?.packageData?.pkg_type == "WAQF") {
            return null
          }

          return (
            <tr className="bg-white border-b" key={e?.id}>
              <td className="px-6 py-4 text-sm font-medium">{e?.id}</td>
              <td className="px-6 py-4 text-sm font-medium">{e?.extraData?.name}</td>
              <td className="px-6 py-4 text-sm font-medium">
              {(e?.image !== undefined && e?.image !== "" && e?.image !== "undefined" && e?.image !== null && e?.image !== "null") ? (
                      <img src={`${e?.image}`} className="w-14" />
                    ) : (
                      <FaUser className="w-8 h-10" />
                    )}
                
              </td>
              {/* <td className="px-6 py-4 text-sm font-medium">{e?.sub_pkg_type}</td> */}
              <td className="px-6 py-4 text-sm font-medium">
                {e?.packageData?.pkg_type} {e?.extraData?.packageData?.country?.name}
              </td>

              <td className="px-6 py-4 text-sm font-medium">{e?.description}</td>
              <td className="px-6 py-4 text-sm font-medium">
                {Number(e?.price_per_unit).toFixed(2)}

              </td>

              <td className="px-6 py-4 text-sm font-medium">
                {Number(e?.actual_price).toFixed(2)}

              </td>
              <td className="px-6 py-4 text-sm font-medium">
                {e?.available_stock}
              </td>

              <td className="px-6 py-4 text-sm font-medium">
                {e?.ruminant_name}
              </td>

              <td className="px-6 py-4 text-sm font-medium">
                {e?.portions}
              </td>
              <td className="px-6 py-4 text-sm font-medium">
                {e?.per_portion_label}
              </td>
              <td className="px-6 py-4 text-sm font-medium">
                {e?.stock_label}
              </td>

              <td className="px-6 py-4 text-sm font-medium">
                {formatDate(e?.extraData?.created_at)}
              </td>

              <td className="px-6 py-4 text-sm font-medium">
                {formatDate(e?.extraData?.created_at)}

              </td>




              <td className="px-6 py-4 text-sm font-medium">
                {e?.enabled ? <Button className="w-24 ">Active </Button> : <Button className="bg-red-500 hover:bg-red-500 w-24" disabled>In-Active </Button>}
              </td>
              <td className="px-6 py-4 text-sm font-medium text-pacific-blue">
                <Button className="px-8"
                  onClick={() => {
                    setEditItem(e);
                     
                    const selectedPackage = packages.find((pkg) => pkg.id === parseInt(e?.package?.id));
                    const result=selectedPackage?.pkg_type?.toLowerCase() === "qurban";
                    setCalendarVisible(result);
                    setEditModal(true);
                  }}
                >
                  Edit
                </Button>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
    </div>

    {/* Create Modal */}
    {createModal && (
      <Dialog
        open={createModal}
        onClose={() => setCreateModal(false)}
        className="fixed inset-0 z-50 overflow-y-auto p-4 duration-500 "
      >
        <div className="flex items-center justify-center min-h-screen">
          <Dialog.Overlay className="fixed inset-0 bg-black/30" />

          <Dialog.Panel className="relative max-w-[600px] w-full mx-auto bg-white p-4 rounded">
            <Dialog.Title className="text-lg font-medium mb-6 border-b-2 flex items-center justify-between">
              <span className="" onClick={() => console.log(packages)}>Create Package Detail</span>
              <div
                className="flex items-center justify-center "
                onClick={() => {
                  setCreateModal(false);
                }}
              >
                <h1
                  onClick={() => setCreateModal(false)}
                  className="bg-gray-200 px-2 rounded-md mb-1 hover:text-red-600 cursor-pointer"
                >
                  x
                </h1>
              </div>
            </Dialog.Title>
            <form
              onSubmit={handleCreatePkgDetail(handleCreatePkgDetails)}
              encType="multipart/form-data"
            >
              <div className="grid grid-cols-1  sm:grid-cols-2 gap-4">

                <div className="mt-2">
                  <label className="block text-sm font-medium text-gray-700">
                    Name
                  </label>
                  <input
                    type="text"
                    {...CreatePkgDetail("name", { required: true })}
                    className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                  />
                  {errorsCreatePkgDetail.name && (
                    <span className="text-red-clr">This field is required</span>
                  )}
                </div>



                <div className="">
                  <label htmlFor="package_id" className="block text-sm font-medium text-gray-700">
                    Master Package
                  </label>
                  <select
                    name='package_id'
                    id='package_id'

                    {...CreatePkgDetail("package_id", { required: true })}
                    onChange={(e) => {
                      const selectedPackageId = e.target.value;
                      const selectedPackage = packages.find((pkg) => pkg.id === parseInt(selectedPackageId));
                      setCalendarVisible(selectedPackage?.pkg_type?.toLowerCase() === "qurban");
                      setValueCreatetPkgDetail("package_id", e.target.value)
                    }
                    }
                    className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                  >
                    <option value="">Select Master Package </option>
                    {packages?.map((e, index) => {
                      if (e.pkg_type === "WAQF" || !e?.is_active ) return null;
                      return (<>
                        <option value={e.id} key={index}>{e?.name}</option>


                      </>)
                    })}
                  </select>
                  {errorsCreatePkgDetail.package_id && (
                    <span className="text-red-clr">This field is required</span>
                  )}
                </div>



                <div className="">
                  <label className="block text-sm font-medium text-gray-700">
                    Ruminant Type
                  </label>
                  <select
                    name='animal_id'
                    id='animal_id'
                    onChange={(e) => setValueCreatetPkgDetail("animal_id", e.target.value)}
                    {...CreatePkgDetail("animal_id", { required: true })}
                    className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                  >
                    <option value="">Select Ruminant</option>
                    {animals?.map((e, index) => {
                      return (<>
                        <option value={e.id} key={index}>{e?.name}</option>

                      </>)
                    })}
                  </select>
                  {errorsCreatePkgDetail.animal_id && (
                    <span className="text-red-clr">This field is required</span>
                  )}


                </div>


                {calendarVisible && (
                  <div className="">
                    <label className="block text-sm font-medium text-gray-700">
                      Calender
                    </label>
                    <select
                      name='calendar_id'
                      id='calendar_id'
                      onChange={(e) => setValueCreatetPkgDetail("calendar_id", e.target.value)}
                      {...CreatePkgDetail("calendar_id", { required: true })}
                      className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                    >
                      <option value="">Select Calender</option>
                      {calenderList?.data?.data?.map((e, index) => {
                        return (<>
                          <option value={e.id} key={index}>{e?.title}</option>

                        </>)
                      })}
                    </select>
                    {errorsCreatePkgDetail.calendar_id && (
                      <span className="text-red-clr">This field is required</span>
                    )}


                  </div>
                )}




                <div className="mt-2">
                  <label className="block text-sm font-medium text-gray-700">
                    Description
                  </label>
                  <input
                    type="text"
                    onChange={(e) => setValueCreatetPkgDetail("description", e.target.value)}
                    {...CreatePkgDetail("description", { required: true })}
                    className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                  />
                  {errorsCreatePkgDetail.description && (
                    <span className="text-red-clr">This field is required</span>
                  )}
                </div>




                <div className="mt-2">
                  <label className="block text-sm font-medium text-gray-700">
                    Actual Price
                  </label>
                  <input
                    type="number"
                    {...CreatePkgDetail("actual_price", { required: true })}
                    className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                  />
                  {errorsCreatePkgDetail.actual_price && (
                    <span className="text-red-clr">This field is required</span>
                  )}
                </div>

                <div className="mt-2">
                  <label className="block text-sm font-medium text-gray-700">
                    Price per Unit
                  </label>
                  <input
                    type="number"
                    {...CreatePkgDetail("price_per_unit", { required: true })}
                    className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                  />
                  {errorsCreatePkgDetail.price_per_unit && (
                    <span className="text-red-clr">This field is required</span>
                  )}
                </div>

                <div className="mt-2">
                  <label className="block text-sm font-medium text-gray-700">
                    Per portion label
                  </label>
                  <input
                    type="text"
                    {...CreatePkgDetail("per_portion_label", { required: true })}
                    className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                  />
                  {errorsCreatePkgDetail.price_per_unit && (
                    <span className="text-red-clr">This field is required</span>
                  )}
                </div>

                <div className="mt-2">
                  <label className="block text-sm font-medium text-gray-700">
                    Stock label
                  </label>
                  <input
                    type="text"
                    {...CreatePkgDetail("stock_label", { required: true })}
                    className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                  />
                  {errorsCreatePkgDetail.price_per_unit && (
                    <span className="text-red-clr">This field is required</span>
                  )}
                </div>


                <div className="">
                  <label className="block text-sm font-medium text-gray-700">
                    Image
                  </label>
                  <input
                    type="file"
                    {...CreatePkgDetail("sub_pkg_image", { required: true })}
                    className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                  />
                  {errorsCreatePkgDetail.sub_pkg_image && (
                    <span className="text-red-clr">This field is required</span>
                  )}
                </div>

              </div>

              <div className="flex justify-end mt-10">
                {editloading ? (
                  <Button type="button">
                    <Loader />
                  </Button>
                ) : (
                  <Button type="submit">Create Package Deatil</Button>
                )}
              </div>
            </form>
          </Dialog.Panel>
        </div>
      </Dialog>
    )}




    {/* Edit Modal */}
    {editModal && (
      <Dialog
        open={editModal}
        onClose={() => setEditModal(false)}
        className="fixed inset-0 z-50 overflow-y-auto p-4 duration-500 "
      >
        <div className="flex items-center justify-center min-h-screen">
          <Dialog.Overlay className="fixed inset-0 bg-black/30" />

          <Dialog.Panel className="relative max-w-[600px] w-full mx-auto bg-white p-4 rounded">
            <Dialog.Title className="text-lg font-medium mb-6 border-b-2 flex items-center justify-between">
              <span className="" onClick={() => console.log(edititem)}>Update Package Detail</span>
              <div
                className="flex items-center justify-center "
                onClick={() => {
                  setEditModal(false);
                }}
              >
                <h1
                  onClick={() => setEditLoading(false)}
                  className="bg-gray-200 px-2 rounded-md mb-1 hover:text-red-600 cursor-pointer"
                >
                  x
                </h1>
              </div>
            </Dialog.Title>
            <form
              onSubmit={handlePkgDetail(handleEditPkgDetail)}
              encType="multipart/form-data"
            >
              <div className="grid grid-cols-1  sm:grid-cols-2 gap-4">
                {(edititem?.image !== undefined && edititem?.image !== "" && edititem?.image !== "undefined" && edititem?.image !== null && edititem?.image !== "null") ? (
                      <img src={`${edititem?.image}`} className="w-14" />
                    ) : (
                      <FaUser className="w-8 h-10" />
                    )}




                <div className="mt-2">
                  <label className="block text-sm font-medium text-gray-700">
                    Name
                  </label>
                  <input
                    type="text"
                    onChange={(e) => setValueEditPkgDetail("name", e.target.value)}
                    {...registerEditPkgDetail("name", { required: true })}
                    className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                  />
                  {errorsEditpkgDetail.name && (
                    <span className="text-red-clr">This field is required</span>
                  )}
                </div>


                <div className="">
                  <label htmlFor="package_id" className="block text-sm font-medium text-gray-700">
                    Master Package
                  </label>
                  <select
                    name='package_id'
                    id='package_id'
                    {...registerEditPkgDetail("package_id", { required: true })}

                    onChange={(e) => {
                      const selectedPackageId = e.target.value;
                      const selectedPackage = packages.find((pkg) => pkg.id === parseInt(selectedPackageId));
                      setCalendarVisible(selectedPackage?.pkg_type?.toLowerCase() === "qurban");
                      setValueEditPkgDetail("package_id", e.target.value)
                    }}
                    className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                  >
                    <option value="">Select Master Package </option>
                    {packages?.map((e, index) => {
                      if (e.pkg_type === "WAQF" ||!e?.is_active ) return null;
                      return (<>
                        <option value={e?.id} key={index}>{e?.name}</option>

                      </>)
                    })}
                  </select>
                  {errorsEditpkgDetail.package_id && (
                    <span className="text-red-clr">This field is required</span>
                  )}
                </div>

                {calendarVisible && (
                  <div className="">
                    <label htmlFor="calendar_id" className="block text-sm font-medium text-gray-700">
                      Calender
                    </label>
                    <select
                      name='calendar_id'
                      id='calendar_id'
                      onChange={(e) => setValueEditPkgDetail("calendar_id", e.target.value)}
                      {...registerEditPkgDetail("calendar_id", { required: true })}
                      className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                    >
                      <option value="">Select Calender</option>
                      {calenderList?.data?.data?.map((e, index) => {
                        return (<>
                          <option value={e.id} key={index}>{e?.title}</option>

                        </>)
                      })}
                    </select>
                    {errorsEditpkgDetail.calendar_id && (
                      <span className="text-red-clr">This field is required</span>
                    )}
                  </div>
                )}


                <div className="">
                  <label className="block text-sm font-medium text-gray-700">
                    Ruminant Type
                  </label>
                  <select
                    name='animal_id'
                    id='animal_id'
                    onChange={(e) => setValueEditPkgDetail("animal_id", e.target.value)}
                    {...registerEditPkgDetail("animal_id", { required: true })}
                    className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                  >
                    <option value="">Select Ruminant</option>
                    {animals?.map((e, index) => {
                      return (<>
                        <option value={e.id} key={index}>{e?.name}</option>

                      </>)
                    })}
                  </select>
                  {errorsEditpkgDetail.animal_id && (
                    <span className="text-red-clr">This field is required</span>
                  )}


                </div>




                <div className="">
                  <label className="block text-sm font-medium text-gray-700">
                    Status
                  </label>
                  <select
                    name='status'
                    id='status'
                    onChange={(e) => setValueEditPkgDetail("status", e.target.value)}
                    {...registerEditPkgDetail("status", { required: true })}
                    className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                  >
                    <option value="">Select Status</option>
                    <option value="true">Active</option>
                    <option value="false">In-Active</option>



                  </select>
                  {errorsEditpkgDetail.status && (
                    <span className="text-red-clr">This field is required</span>
                  )}


                </div>



                <div className="mt-2">
                  <label className="block text-sm font-medium text-gray-700">
                    Description
                  </label>
                  <input
                    type="text"
                    onChange={(e) => setValueEditPkgDetail("description", e.target.value)}
                    {...registerEditPkgDetail("description", { required: true })}
                    className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                  />
                  {errorsEditpkgDetail.description && (
                    <span className="text-red-clr">This field is required</span>
                  )}
                </div>




                <div className="mt-2">
                  <label className="block text-sm font-medium text-gray-700">
                    Actual Price
                  </label>
                  <input
                    type="number"
                    {...registerEditPkgDetail("actual_price", { required: true })}
                    className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                  />
                  {errorsEditpkgDetail.actual_price && (
                    <span className="text-red-clr">This field is required</span>
                  )}
                </div>

                <div className="mt-2">
                  <label className="block text-sm font-medium text-gray-700">
                    Price per Unit
                  </label>
                  <input
                    type="number"
                    {...registerEditPkgDetail("price_per_unit", { required: true })}
                    className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                  />
                  {errorsEditpkgDetail.price_per_unit && (
                    <span className="text-red-clr">This field is required</span>
                  )}
                </div>


                <div className="mt-2">
                  <label className="block text-sm font-medium text-gray-700">
                    Per portion label
                  </label>
                  <input
                    type="text"
                    {...registerEditPkgDetail("per_portion_label", { required: true })}
                    className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                  />
                  {errorsEditpkgDetail.actual_price && (
                    <span className="text-red-clr">This field is required</span>
                  )}
                </div>

                <div className="mt-2">
                  <label className="block text-sm font-medium text-gray-700">
                    Stock label

                  </label>
                  <input
                    type="text"
                    {...registerEditPkgDetail("stock_label", { required: true })}
                    className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                  />
                  {errorsEditpkgDetail.price_per_unit && (
                    <span className="text-red-clr">This field is required</span>
                  )}
                </div>

                <div className="">
                  <label className="block text-sm font-medium text-gray-700">
                    Image
                  </label>
                  <input
                    type="file"
                    {...registerEditPkgDetail("sub_pkg_image")}
                    className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                  />
                  {errorsEditpkgDetail.sub_pkg_image && (
                    <span className="text-red-clr">This field is required</span>
                  )}
                </div>

              </div>

              <div className="flex justify-end mt-10">
                {editloading ? (
                  <Button type="button">
                    <Loader />
                  </Button>
                ) : (
                  <Button type="submit">Update Package Deatil</Button>
                )}
              </div>
            </form>
          </Dialog.Panel>
        </div>
      </Dialog>
    )}













    {/* <div>
      <HeaderSection
        title={"Packages Details"}
        btnTitle={"Create Package Detail"}
        setIsOpen={setIsOpen}
      />
      <div className="flex items-center justify-end mb-3">
        <Search />
      </div>
      <DataTable
        data={PackageDetail || []}
        canEdit={true}
        updatefn={updatePackage}
      />
      <CreatePackageDetail isOpen={isOpen} setIsOpen={setIsOpen} />
      {showEdit && (
        <CreatePackageDetail
          isOpen={showEdit}
          setIsOpen={setShowEdit}
          updateId={updateId}
        />
      )}
    </div> */}

  </>
  );
};

export default DisplayPackageDetails;
