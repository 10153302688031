import React, { useState, useRef, useEffect } from "react";
import ProfileVerifi from "../../../assets/images/ProfileVerification/ProfileVerifi.png";
import Button from "../../../components/Button/Button";
import ProgressBar from "./ProgressBar";
import FirstStep from "./firstStep";
import SecondStep from "./secondeStep";
import ThirdStep from "./thirdStep";



const ProfileVerification = () => {

  
  const [stepStart, setStepstart] = useState(false);
  const [step, setStep] = useState(1);

  const handleStepschange=()=>{
    setStep((prev) => Math.min(prev + 1, 3))
  }
  

  // Steps
 
  const renderContent = () => {
    switch (step) {
      case 1:
        return (
          <FirstStep handleStepschange={handleStepschange}/>
        );
      case 2:
        return <div>

          <SecondStep handleStepschange={handleStepschange}/>
        </div>;
      case 3:
        return <div>
          <ThirdStep />
        </div>;
      default:
        return null;
    }
  };

  return (
    <>
      {!stepStart ? (
        <div className="flex flex-col justify-center min-h-screen px-3 mb-24">
          <div className="flex flex-col items-center ">
            <img
              src={ProfileVerifi}
              className="h-40 mb-7"
              alt="Profile Verification"
            />
            <div className="md:text-3xl text-lg font-bold text-dark-teal mt-4">
              Verify your identity
            </div>
            <div className="md:text-lg text-xs text-[#848484] md:w-96 w-80 text-center mt-4 mb-10 mx-3">
              In order to get to know our customers better, we request all
              customers to verify their identity.
            </div>
            <Button
              className="md:px-44 px-32 mt-6"
              onClick={() => setStepstart(true)}
            >
              Start
            </Button>
          </div>
        </div>
      ) : (
        <div className="mb-24">
          <ProgressBar step={step} />
          <div className="flex flex-col items-center min-h-screen p-4">
            <div className="mt-4 text-2xl">{renderContent()}</div>
          </div>
        </div>
      )}
    </>
  );
};

export default ProfileVerification;
