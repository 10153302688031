import React , {useEffect ,useState} from "react";
import { useParams } from "react-router-dom";
import { useGetSupplierDetails } from "../hooks/useGetSupplier";
import ReusableInput from "../../../components/ResuableInputField";
import { formatDate } from "../../../utils/formatDate";
import Button from "../../../components/Button/Button";
import { useUpdateSupplierStatus } from "../hooks/useUpdateSupplierStatus";
import axios from 'axios';
import { config } from '../../../config/config';

const SupplierDetail = () => {
	const [feedlots, setFeedlots] = useState([]);
	// const [isLoading, setIsLoading] = useState(false);
	
	const { id } = useParams();
	const fetchFeedlots = async () => {
		// setIsLoading(true);
		
		const token = JSON.parse(localStorage.getItem("token"));  // Retrieve token from localStorage
		try {
			const response = await axios.get(`${config.backendURL}users/${id}`, {
				headers: {
					Authorization: `Bearer ${token}`  // Set the Authorization header with the token
				}
			});
			console.log("response.data.data");
			console.log(response.data.data);
			setFeedlots(response.data.data); // Adjust this path based on the actual API response structure
		} catch (error) {
			console.error('Failed to fetch feedlots:', error);
		}
		// setIsLoading(false);
	};
	useEffect(() => {

        fetchFeedlots();
    }, [id]);

	const {
		data: supplierDetails,
		isError,
		error,
		isLoading,
	} = useGetSupplierDetails(id);
	const { onSubmit, is_loading } = useUpdateSupplierStatus();

	const handleButtonClick = async () => {
		await onSubmit(id);
		setTimeout(() => {
			fetchFeedlots();
		  }, 3000); // 1-second delay
	  };
	// console.log(supplierDetails);

	if (isError) {
		return (
			<div className="">
				There was some Error, Please try again
				{error}
			</div>
		);
	}

	if (isLoading) {
		return <div className="">Loading, Please wait!</div>;
	}


	

	return (
		<>
	
		<div className="w-full  sm:w-[600px] md:w-[800px] lg:w-[900px] ">
			<h1 className="text-lg font-bold " onClick={()=>console.log(config.resourceUrl,feedlots?.supplierFeedlots[0].image)}>Supplier Details</h1>
			<div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3  gap-4  border-2 p-3">
				<ReusableInput
					type={"text"}
					label={"Full Name"}
					value={supplierDetails?.data?.fullname}
					disable={true}
				/>
				<ReusableInput
					type={"text"}
					label={"Email"}
					value={supplierDetails.data.email}
					disable={true}
				/>{" "}
				<ReusableInput
					type={"text"}
					label={"ID"}
					value={supplierDetails.data.id}
					disable={true}
				/>
				<ReusableInput
					type={"text"}
					label={"IC Number"}
					value={supplierDetails.data.ic_number}
					disable={true}
				/>
				<ReusableInput
					type={"text"}
					label={"Phone Number"}
					value={supplierDetails.data.phone_no}
					disable={true}
				/>
				<ReusableInput
					type={"text"}
					label={"Active"}
					value={supplierDetails.data.is_active}
					disable={true}
				/>
				<ReusableInput
					type={"text"}
					label={"Role"}
					value={supplierDetails.data.role}
					disable={true}
				/>
				<ReusableInput
					type={"text"}
					label={"Address"}
					value={supplierDetails.data.address || ""}
					disable={true}
				/>{" "}
				<ReusableInput
					type={"text"}
					label={"Region"}
					value={supplierDetails?.data?.region?.name || ""}
					disable={true}
				/>{" "}
				<ReusableInput
					type={"text"}
					label={"State"}
					value={supplierDetails?.data?.state?.name || ""}
					disable={true}
				/>
				<ReusableInput
					type={"text"}
					label={"Created At"}
					value={formatDate(supplierDetails?.data?.created_at) || ""}
					disable={true}
				/>
				<ReusableInput
					type={"text"}
					label={"Status"}
					value={supplierDetails?.data?.is_active? "Active":"In-Active" || ""}
					disable={true}
				/>
			</div>

			<div className="mt-4 w-full max-w-[200px]">
				<Button
					type="button"
					// onClick={() => {
						// onSubmit(id);
						onClick={handleButtonClick}
					// }}>
					>
					{is_loading ? (
						"Please wait..."
					) : (
						<>
							{supplierDetails.data.is_active
								? "Disapprove Supplier"
								: "Approve Supplier"}
						</>
					)}
				</Button>
			</div>

			{/* feedlot table  */}

		</div>
			<table className="w-full text-sm text-left rtl:text-right text-gray-500 mt-5">
      <thead className="text-xs text-pacific-blue uppercase bg-[#CFF0F2]">
        <tr>
          <th scope="col" className="px-6 py-3 text-base font-medium">Feedlot Id</th>
          <th scope="col" className="px-6 py-3 text-base font-medium">Name</th>
          {/* <th scope="col" className="px-6 py-3 text-base font-medium">Email</th> */}
          <th scope="col" className="px-6 py-3 text-base font-medium">Image</th>
          {/* <th scope="col" className="px-6 py-3 text-base font-medium">Phone Number</th> */}
          <th scope="col" className="px-6 py-3 text-base font-medium">Feedlot Type</th>
		  {/* <th scope="col" className="px-6 py-3 text-base font-medium">IC NUMBER</th> */}
          {/* <th scope="col" className="px-6 py-3 text-base font-medium">PIC</th> */}
		  <th scope="col" className="px-6 py-3 text-base font-medium">CAPACITY</th>
		  <th scope="col" className="px-6 py-3 text-base font-medium">STATUS</th>
        </tr>
      </thead>
      <tbody>
        {feedlots?.feedlots?.map(feedlot => (
          <tr className="bg-white border-b" key={feedlot?.id}>
            <td className="px-6 py-4 text-sm font-medium">{feedlot?.id}</td>
            <td className="px-6 py-4 text-sm font-medium">{feedlot?.fullname}</td>
            {/* <td className="px-6 py-4 text-sm font-medium">{feedlot?.email} </td> */}
            <td className="px-6 py-4 text-sm font-medium">
              <img src={`${config.backendBaseURL}uploads/${feedlot?.image}`} alt="Feedlot Image"  style={{ width: '80px', height: '80px' }} />
            </td>
            {/* <td className="px-6 py-4 text-sm font-medium">{feedlot?.phone_no}</td> */}
            <td className="px-6 py-4 text-sm font-medium">{feedlot?.feedlot_type}</td>
			{/* <td className="px-6 py-4 text-sm font-medium">{feedlot?.ic_number}</td>
			<td className="px-6 py-4 text-sm font-medium">{feedlot?.pic}</td> */}
			<td className="px-6 py-4 text-sm font-medium">{feedlot?.capacity}</td>
            {/* <td className="px-6 py-4 text-sm font-medium">{feedlot?.is_active}</td> */}
			<td className="px-6 py-4 text-sm font-medium">
                  {feedlot?.is_active? <Button className="w-24">Active </Button> : <Button className="bg-red-500 hover:bg-red-500 w-24" disabled>In-Active </Button>}
                  </td>

          </tr>
        ))}
      </tbody>
    </table>
	</>
	);
};

export default SupplierDetail;
