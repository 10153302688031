import { axios } from "../../config/axios";

export const getOrganizationList = async () => {
  const token = JSON.parse(localStorage.getItem("token"));

  const response = await axios.get("organization", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.data) {
    throw new Error("No data found");
  }


  return response.data;
};



export const createOrganization = async (data) => {
    const token = JSON.parse(localStorage.getItem("token"));
    
    const response = await axios.post("organization", data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  
    if (!response.data) {
      throw new Error("No data found");
    }
  
    return response;
  };


  export const getOrganizationTypeList = async () => {
    const token = JSON.parse(localStorage.getItem("token"));
  
    const response = await axios.get("organization/org-types", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  
    if (!response.data) {
      throw new Error("No data found");
    }
  
  
    return response.data;
  };
  export const createOrganizationType = async (data) => {
    const token = JSON.parse(localStorage.getItem("token"));
    
    const response = await axios.post("organization/org-types", data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  
    if (!response.data) {
      throw new Error("No data found");
    }
  
    return response;
  };
