import React from 'react'
import Sales from './componenet/sales'
import Stock from './componenet/Stock'

const AdminDashboard = () => {
  return (
    <>
    <Sales />
    <Stock />
    {/* <div>index</div> */}
    </>
  )
}

export default AdminDashboard