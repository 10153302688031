/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import DataTable from "../../../components/Table/DataTable";
import AddCattle from "./AddCattle";
import HeaderSection from "../../../components/HeaderSection";
import { useFetchSuppliersList, useGetCattles } from "../hooks/useGetCattles";
import { useParams } from "react-router-dom";
import ReusableInput from "../../../components/ResuableInputField";
import {
  animal_gender,
  animal_type,
  package_type,
} from "../../../config/constants";
import Button from "../../../components/Button/Button";
import { Loader } from "../../../components/Loader";
import { notifyNoDataFound } from "../../../utils/toasts";
import { useNavigate } from "react-router-dom";
import Search from "../../../components/Search";
import CattleList from "./CattleList";
import { useGetPackagesHook } from "../../settings/Packages/hooks/useGetPackagesHook";

export const CattlerWrapper = () => {
  let [isOpen, setIsOpen] = useState(false);
  let { page = 1 } = useParams();
  const navigate = useNavigate();
  page = Number(page);
  if (page < 1) {
    page = 1;
  }
  const [pageNumber, setPageNumber] = useState(1);
  const [limit, setLimit] = useState(10);
  const {
    packages
  } = useGetPackagesHook();

  const user = JSON.parse(localStorage.getItem("user"));

  const initialOptionState = {
    animal_type: "",
    animal_gender: "",
    package_type: "",
    supplier_id: user?.role === "SUPPLIER" ? user?.id : "",
  };
  const [option, setOption] = useState(initialOptionState);
  const [queryOption, setQueryOption] = useState(initialOptionState);
  // const [queryOption, setQueryOption] = useState({});
  const onclick_handler = (selectedRow) => {
		navigate(`/cattle/detail/${selectedRow.id}`);
	};

  const onSearch = () => {
    setQueryOption(option);
    console.log(option)

  };

  const { cattleDataMain, isError, isLoading } = useGetCattles(
    pageNumber,
    limit,
    queryOption
  );
  
  const { suppliresList } = useFetchSuppliersList();


  // if (cattleDataMain?.data?.length === 0) {
  //    
  //   notifyNoDataFound();
  // }
  if (isError) {
    return <div className="">There was some Error, Please try again</div>;
  }
  if (isLoading) {
    return (
      <div className="flex space-x-2">
        <Loader />
        <div className="">Loading...</div>
      </div>
    );
  }
  return (
    <div className="">
      <HeaderSection
        title={"Ruminant"}
        btnTitle={"Add Ruminant"}
        setIsOpen={setIsOpen}
      />
      <div className="flex flex-col md:flex-row md:items-end md:space-x-2">
        {/* <div className="w-full md:w-[200px] my-2">
          <ReusableInput
            label={"Ruminant Type"}
            type={"select"}
            options={animal_type}
            value={option.animal_type}
            onChange={(e) =>
              setOption({ ...option, animal_type: e.target.value })
            }
          />
        </div> */}
        <div className="w-ful md:w-[200px] my-2">
          <ReusableInput
            label={"Ruminant Gender"}
            type={"select"}
            options={animal_gender}
            value={option.animal_gender}
            onChange={(e) =>
              setOption({ ...option, animal_gender: e.target.value })
            }
          />
        </div>

        <div className="w-ful md:w-[200px] my-2">
        <div className={`mt-4 }`}>
      <label className={`block text-sm font-medium text-gray-700 `}>
        Package Type
      </label>
     
        <select
          
         
          onChange={(e) =>
            setOption({ ...option, package_type: e.target.value })
          }
          className={`mt-1 p-2 w-full border border-gray-300 rounded-md`}
        
        >
          <option value={""}>Please Select</option>
          <option key="QURBAN"> Qurban</option>
          <option key="AQIQAH" >Aqiqah</option>
          {/* {packages?.map((option) => (
            <option key={option.pkg_type} value={option.pkg_type}>
              {option.name}
            </option>
          ))} */}
        </select>
      
    </div>
          
        </div>
        {user.role !== "SUPPLIER" && (
        <div className="w-ful w-full md:w-[200px] my-2">
          <ReusableInput
            label={"Supplier"}
            type={"select"}
            options={
              suppliresList?.data &&
              suppliresList?.data?.data.map((sup) => ({
                value: sup.id,
                label: sup.fullname,
              }))
            }
            value={option.supplier_id}
            onChange={(e) =>
              setOption({ ...option, supplier_id: e.target.value })
            }
          />
        </div>
        )}
        <div className="md:w-[100px]">
          <Button onClick={onSearch} title={"Search"} className="my-2 mt-6">
            Search
          </Button>
        </div>
        {/* <div className="md:min-w-[300px] flex flex-1">
          <Search />
        </div> */}
      </div>
      <div className="flex space-x-2"></div>

      <CattleList dataList={cattleDataMain?.data || []}
       page={pageNumber}
        setPageNumber={setPageNumber}
        detailPage={onclick_handler}/>
     
      <AddCattle isOpen={isOpen} setIsOpen={setIsOpen} />
    </div>
  );
};
