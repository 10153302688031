import React, { useEffect, useState } from "react";
import Button from "../../../components/Button/Button";
import { Dialog } from "@headlessui/react";
import {
  useFetchFeddlotList,
  useFetchSuppliersList,
} from "../hooks/useGetCattles";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { axios } from "../../../config/axios";
import { Loader } from "../../../components/Loader";
import { useGetPackagesHook } from "../../settings/Packages/hooks/useGetPackagesHook";
import { useGetPkgDetails } from "../../settings/PackagesDetail/hooks/useGetPkgDetails";
import { calculateAge, formatDate } from "../../../utils/formatDate";
import format from "date-fns/format";

function CattleList({ dataList, page, setPageNumber, detailPage }) {
  let [editModal, setEditModal] = useState(false);
  const [editloading, setEditLoading] = useState(false);
  const [feedlotList, setFeedlotList] = useState([]);


  let [edititem, setEditItem] = useState({});
  const { suppliresList } = useFetchSuppliersList();
  // const { feedlotList } = useFetchFeddlotList();
  // const { packages } = useGetPackagesHook();
  const { PackageDetail } = useGetPkgDetails();


  const {
    register: registerEditRuminant,
    handleSubmit: handleEditRuminants,
    formState: { errors: errorsEditRuminant },
    setValue: setValueEditRuminant,
    watch: watchEditRuminant,
    reset: resetEditRuminant,
  } = useForm();

  useEffect(() => {
    if (editModal) {
      const formattedDob = edititem?.extraData?.dob
        ? format(new Date(edititem.extraData.dob), "yyyy-MM-dd")
        : "";

      const selectedSupplier = suppliresList?.data?.data.find(
        (ele) => ele.id === parseInt(edititem?.extraData?.supplier?.id)
      );

      const feedlots = selectedSupplier?.feedlots || [];
      setFeedlotList(feedlots)

      resetEditRuminant({
        package_id: edititem?.extraData?.package?.id || "",
        dob: formattedDob,
        tag_id: edititem?.extraData?.tag_id || "",
        supplier_id: edititem?.extraData?.supplier?.id || "",
        weight: edititem?.weight || "",
        animal_gender: edititem?.extraData?.animal_gender || "",
        feedlot_id: edititem?.extraData?.feedlot?.id,
      });
    }
  }, [editModal, resetEditRuminant, edititem]);
  const user = JSON.parse(localStorage.getItem("user"));

  const handleEditRuminant = async (data) => {
    console.log(data);
    setEditLoading(true);

    const token = JSON.parse(localStorage.getItem("token"));
    const formData = new FormData();

    // Ensure supplier_id and feedlot_id are numbers
    data.supplier_id = user?.role === "SUPPLIER" ? user.id : (parseInt(data?.supplier_id) || 0);
    // data.supplier_id = parseInt(data.supplier_id) || 0;
    data.feedlot_id = parseInt(data.feedlot_id) || 0;
    data.package_id = parseInt(data.package_id) || 0;
    // data.age = parseInt(data.age) || 0;
    data.dob = data.dob || "";
    data.tag_id = data.tag_id || "";
    data.weight = parseFloat(data.weight);

    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        if (key === 'animal_image') {
          if (data[key] && data[key].length > 0) {
            formData.append(key, data[key][0]);
          }
        } else {
          formData.append(key, data[key]);
        }
      }
    }

    try {
      const response = await axios.put(
        `/animal-inventory/animals/${edititem.id}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response);
      setEditModal(false);
      toast.success("Ruminant Updated");
    } catch (error) {
      toast.error(error?.response?.data?.error || "Something went wrong");
      console.error("Error:", error);
    } finally {
      setEditLoading(false);
    }
    console.log("Edit item", data);
  };
  return (
    <div>
      <button className="font-bold" onClick={() => console.log(dataList)}>
        Ruminant List
      </button>
      <table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
        <thead className="text-xs text-pacific-blue uppercase bg-[#CFF0F2] ">
          <tr>
            <th scope="col" className="px-6 py-3 text-base font-medium ">
              <div className="flex gap-1 items-center">Id</div>
            </th>
            <th scope="col" className="px-6 py-3 text-base font-medium">
              <div className="flex gap-1 items-center">Image</div>
            </th>
            <th scope="col" className="px-6 py-3 text-base font-medium">
              <div className="flex gap-1 items-center">Available Stock</div>
            </th>
            <th scope="col" className="px-6 py-3 text-base font-medium">
              <div className="flex gap-1 items-center">Ruminant Id</div>
            </th>
            <th scope="col" className="px-6 py-3 text-base font-medium">
              <div className="flex gap-1 items-center">Supplier Name</div>
            </th>
            <th scope="col" className="px-6 py-3 text-base font-medium">
              <div className="flex gap-1 items-center">Sub Package Name</div>
            </th>
            <th scope="col" className="px-6 py-3 text-base font-medium">
              <div className="flex gap-1 items-center">Portions</div>
            </th>

            <th scope="col" className="px-6 py-3 text-base font-medium">
              <div className="flex gap-1 items-center">Age</div>
            </th>


            <th scope="col" className="px-6 py-3 text-base font-medium">
              <div className="flex gap-1 items-center">Tag ID</div>
            </th>

            <th scope="col" className="px-6 py-3 text-base font-medium">
              <div className="flex gap-1 items-center">Weight</div>
            </th>
            <th scope="col" className="px-6 py-3 text-base font-medium">
              <div className="flex gap-1 items-center">Country</div>
            </th>
            <th scope="col" className="px-6 py-3 text-base font-medium">
              <div className="flex gap-1 items-center">State</div>
            </th>
            <th scope="col" className="px-6 py-3 text-base font-medium">
              <div className="flex gap-1 items-center">Region</div>
            </th>
            <th scope="col" className="px-6 py-3 text-base font-medium">
              <div className="flex gap-1 items-center">Available</div>
            </th>
            <th scope="col" className="px-2 py-3 text-base font-medium">
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          {dataList?.map((e) => {
            return (
              <tr className="bg-white border-b" key={e?.id}>
                <th scope="row" className="px-6 py-4 text-sm font-medium">
                  {e?.id}
                </th>
                <td className="px-6 py-4 text-sm font-medium">
                  <img src={e.image} className="h-10 w-10 rounded-full" />
                </td>
                <td className="px-6 py-4 text-sm font-medium">
                  {e?.available_stock}
                </td>
                <td className="px-6 py-4 text-sm font-medium">
                  {e?.ruminent_id}
                </td>
                <td className="px-6 py-4 text-sm font-medium">
                  {e?.extraData?.supplier?.fullname}
                </td>

                <td className="px-6 py-4 text-sm font-medium">
                  {e?.extraData?.package?.name}
                </td>
                <td className="px-6 py-4 text-sm font-medium">{e?.portions}</td>


                <td className="px-6 py-4 text-sm font-medium">{calculateAge(e?.extraData?.dob)




                }</td>
                <td className="px-6 py-4 text-sm font-medium">{e?.extraData?.tag_id}</td>
                <td className="px-6 py-4 text-sm font-medium">{e?.weight}/KG</td>
                <td className="px-6 py-4 text-sm font-medium">{e?.country}</td>
                <td className="px-6 py-4 text-sm font-medium">{e?.state}</td>
                <td className="px-6 py-4 text-sm font-medium">{e?.region}</td>

                <td className="px-6 py-4 text-sm font-medium">
                  {e?.is_available ? (
                    <Button className="w-24 bg-green-600">Available </Button>
                  ) : (
                    <Button
                      className="bg-red-500 hover:bg-red-500 w-24"
                      disabled
                    >
                      Not Available{" "}
                    </Button>
                  )}
                </td>
                <td className=" px-6 py-4 text-sm font-medium text-pacific-blue">
                  <div className="flex">
                  <Button
                    className="px-8"
                    onClick={() => {
                      setEditItem(e);
                      setEditModal(true);
                    }}
                  >
                    Edit
                  </Button>

                  <Button
                    className="px-8 ml-2"
                    onClick={() => {
                      detailPage(e);
                    }}
                  >
                    Detail
                  </Button>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      <div className="flex justify-center mt-4">
        <Button
          onClick={() => {
            if (page != 1) setPageNumber(page - 1);
          }}
        >
          Previous
        </Button>
        <Button className="ml-3" onClick={() => setPageNumber(page + 1)}>
          Next
        </Button>
      </div>

      {/* Edit Modal */}
      {editModal && (
        <Dialog
          open={editModal}
          onClose={() => setEditModal(false)}
          className="fixed inset-0 z-50 overflow-y-auto p-4 duration-500 "
        >
          <div className="flex items-center justify-center min-h-screen">
            <Dialog.Overlay className="fixed inset-0 bg-black/30" />

            <Dialog.Panel className="relative max-w-[600px] w-full mx-auto bg-white p-4 rounded">
              <Dialog.Title className="text-lg font-medium mb-6 border-b-2 flex items-center justify-between">
                <span onClick={() => {
                  //  setEditLoading(false)
                  console.log(suppliresList)
                }} className="">Update Ruminant Profile</span>
                <div
                  className="flex items-center justify-center "
                  onClick={() => {
                    setEditModal(false);
                  }}
                >
                  <h1

                    className="bg-gray-200 px-2 rounded-md mb-1 hover:text-red-600 cursor-pointer"
                  >
                    x
                  </h1>
                </div>
              </Dialog.Title>
              <form
                onSubmit={handleEditRuminants(handleEditRuminant)}
                encType="multipart/form-data"
              >
                <div className="grid grid-cols-1  sm:grid-cols-2 gap-4">
                  {user?.role !== "SUPPLIER" && (
                    <div className="">
                      <label
                        htmlFor="supplier"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Select Supplier
                      </label>
                      <select
                        id="supplier"
                        className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                        {...registerEditRuminant("supplier_id", {
                          required: true,
                        })}
                        name="supplier_id"

                        onChange={(e) => {
                          setValueEditRuminant("supplier_id", e.target.value)
                          const selectedSupplier = suppliresList?.data?.data.find(
                            (ele) => ele.id === parseInt(e.target.value)
                          );
                          console.log(selectedSupplier)
                          const feedlots = selectedSupplier?.feedlots || [];
                          setFeedlotList(feedlots)
                        }}
                      >
                        <option value="">
                          Select the Supplier
                        </option>
                        {suppliresList?.data?.data.map((supplier, index) => (
                          <option key={index} value={supplier.id}>
                            {supplier?.fullname}
                          </option>
                        ))}
                      </select>
                      {errorsEditRuminant.supplier_id && (
                        <span className="text-red-clr">
                          This field is required
                        </span>
                      )}
                    </div>
                  )}

                  <div className="">
                    <label
                      htmlFor="feedlot_id"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Select Feedlot {edititem?.extraData?.feedlot?.id}
                    </label>
                    <select
                      id="feedlot_id"
                      className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                      name="feedlot_id"
                      onChange={(e) =>
                        setValueEditRuminant("feedlot_id", e.target.value)
                      }
                      {...registerEditRuminant("feedlot_id", { required: true })}
                    >
                      <option value="">Select the feedlot</option>
                      {user?.role === "SUPPLIER" ? (
                        user?.feedlots?.map((feedlot, index) => (
                          <option key={index} value={feedlot.id}>
                            {feedlot?.fullname}
                          </option>
                        ))
                      ) : (
                        feedlotList?.map((feedlot, index) => (
                          <option key={index} value={feedlot.id}>
                            {feedlot?.fullname}
                          </option>
                        ))
                      )}

                    </select>
                    {errorsEditRuminant.feedlot_id && (
                      <span className="text-red-clr">
                        This field is required
                      </span>
                    )}
                  </div>


                  <div className="">
                    <label
                      htmlFor="package_id"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Sub Package Name
                    </label>
                    <select
                      id="package_id"
                      className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                      {...registerEditRuminant("package_id", {
                        required: true,
                      })}
                      name="package_id"
                      onChange={(e) =>
                        setValueEditRuminant("package_id", e.target.value)
                      }
                    >
                      <option value="">Select the Package</option>

                      {/* {packages.map((pkg, index) => (
                        <option key={index} value={pkg.id}>
                          {pkg?.pkg_type}
                        </option>
                      ))} */}

                      {PackageDetail?.filter(pkg => pkg?.package?.pkg_type !== "WAQF" && pkg.enabled).map((pkg, index) => (
                        <option key={index} value={pkg?.id}>
                          {pkg?.name}
                        </option>
                      ))}
                    </select>
                    {errorsEditRuminant.package_id && (
                      <span className="text-red-clr">
                        This field is required
                      </span>
                    )}


                  </div>

                  <div className="">
                    <label htmlFor="dob" className="block text-sm font-medium text-gray-700">
                      Date of birth
                    </label>
                    <input
                      id="dob"
                      type="date"
                      {...registerEditRuminant("dob", { required: true })}
                      className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                      min="0"
                    />
                    {errorsEditRuminant.dob && (
                      <span className="text-red-clr">
                        This field is required
                      </span>
                    )}
                  </div>

                  <div className="">
                  <label htmlFor="tag_id" className="block text-sm font-medium text-gray-700">
                  Tag ID                 </label>
                  <input
                    id="tag_id"
                    type="text"
                    {...registerEditRuminant("tag_id", { required: true })}
                    className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                    min="0"
                  />
                  {errorsEditRuminant.tag_id && (
                    <span className="text-red-clr">
                      This field is required
                    </span>
                  )}
                </div>

                  <div className="mb-4">
                    <label htmlFor="weight" className="block mb-2 font-medium text-gray-700">
                      Weight (KG)
                    </label>
                    <input
                      id="weight"
                      type="number"
                      {...registerEditRuminant("weight", {
                        required: "Weight is required",
                      })}
                      className={`mt-1 p-1 w-full border border-gray-300 rounded-md                 
                    `}
                      min="0"
                    />
                    {errorsEditRuminant.weight && (
                      <p className="text-red-500 text-sm mt-1">
                        {errorsEditRuminant.weight.message}
                      </p>
                    )}
                  </div>



                  <div>
                    <label htmlFor="animal_gender" className="block mb-2 font-medium text-gray-700">
                      Gender
                    </label>
                    <select
                      id="animal_gender"
                      className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                      {...registerEditRuminant("animal_gender", {
                        required: true,
                      })}
                      onChange={(e) => setValueEditRuminant("animal_gender", e.target.value)}
                    >
                      <option value="">Select the Gender</option>
                      <option value="MALE">MALE</option>
                      <option value="FEMALE">FEMALE</option>
                    </select>
                    {errorsEditRuminant.animal_gender && (
                      <span className="text-red-clr">This field is required</span>
                    )}
                  </div>
                  <div className="">
                    <label htmlFor="animal_image" className="block mb-2 font-medium text-gray-700">
                      Image
                    </label>
                    <input
                      id="animal_image"
                      type="file"
                      {...registerEditRuminant("animal_image")}
                      className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                    />
                    {errorsEditRuminant.animal_image && (
                      <span className="text-red-clr">
                        This field is required
                      </span>
                    )}
                  </div>
                </div>

                <div className="flex justify-end mt-10">
                  {editloading ? (
                    <Button type="button">
                      <Loader />
                    </Button>
                  ) : (
                    <Button type="submit">Update Ruminant</Button>
                  )}
                </div>
              </form>
            </Dialog.Panel>
          </div>
        </Dialog>
      )}
    </div>
  );
}

export default CattleList;
