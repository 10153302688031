import React, { useState, useEffect } from "react";
import { useFetchAnalyticsSales } from "../hooks/useGetAnalytics";
import HeaderSection from "../../../components/HeaderSection";
import DataTable from "../../../components/Table/DataTable";
import ResuableInputField from "../../../components/ResuableInputField";
import Search from "../../../components/Search";
import Button from "../../../components/Button/Button";
import SaleTable from "./SaleTable";
import PaymentHistory from "./PaymentHistory";

const AnalyticsSales = () => { 





  const endDateDefault = new Date().toISOString().split("T")[0];
  const startDateDefault = new Date(Date.now() - 30 * 24 * 60 * 60 * 1000)
    .toISOString()
    .split("T")[0];

  const [date, setDate] = useState({
    startDate: startDateDefault,
    endDate: endDateDefault,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDate((pre) => ({
      ...pre,
      [name]: value,
    }));
  };

  const { sales, isError, isLoading, refetch } = useFetchAnalyticsSales(
    date?.startDate,
    date?.endDate
  );

  useEffect(() => {
    refetch();
  }, [date?.startDate, date?.endDate, refetch]);

  if (isError) {
    return <h1 className="">An error occurred</h1>;
  }

  if (isLoading) {
    return <h1 className="">Please wait, fetching data</h1>;
  }

  return (
    <>
  
    <div>
      <HeaderSection title={"Sales"} />
      <div className="grid grid-cols-12 gap-4 mb-4">
        <div className="col-span-12 sm:col-span-3">
          <ResuableInputField
            className="focus:border-pacific-blue focus:outline-none"
            type={"date"}
            label={"Start Date"}
            name="startDate"
            onChange={handleChange}
            value={date?.startDate}
          />
        </div>
        <div className="col-span-12 sm:col-span-3">
          <ResuableInputField
            className="focus:border-pacific-blue focus:outline-none"
            type={"date"}
            label={"End Date"}
            name="endDate"
            onChange={handleChange}
            value={date?.endDate}
          />
        </div>
        <div className="col-span-12 sm:col-span-6 flex items-end justify-end">
          <Search />
        </div>
      </div>

      <SaleTable sales={sales}/>
    </div>

    </>
  );
};

export default AnalyticsSales;
