import React, { useEffect, useState } from 'react';
import TransactionTable from './TransactionTable'; // Ensure this is the correct path to your TransactionTable component
import { axios } from '../../../config/axios';

const AdminTransaction = () => {
  const [transactionData, setTransactionData] = useState(null);
  const token = JSON.parse(localStorage.getItem('token')); // Assume the token is stored in localStorage
console.log(token);
  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const response = await axios.get('animal-inventory/get-transactions', {
          headers: {
            Authorization: `Bearer ${token}`, // Set the Authorization header with the token
          },
        });
        setTransactionData(response.data); // Adjust according to the actual data structure
      } catch (error) {
        console.error('Failed to fetch transaction data:', error);
      }
    };

    fetchTransactions();
  }, [token]);

  return (
    <div>
      <h1 className="text-2xl font-bold mb-4">Transaction List</h1>
      {transactionData ? (
        <TransactionTable transactions={transactionData} />
      ) : (
        <p>Loading transactions...</p>
      )}
    </div>
  );
};

export default AdminTransaction;
