import { axios } from "../../config/axios";
import { endpoint } from "../../config/constants";

export const GetTransaction = async () => {

    const url1 = `${endpoint.GET_TRANSCTION}get-reporter-payable`;
    const url2 = `${endpoint.GET_TRANSCTION}get-slaughter-payable`;


    const token = JSON.parse(localStorage.getItem("token"));
    const user = JSON.parse(localStorage.getItem("user"));

    const url = user.role === "SLAUGHTER" ? url2 : url1;

    const response = await axios.get(url, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });


    if (!response.data) {
        throw new Error("No data found");
    }

    return response.data;
};
