import React from "react";
import { Route, Routes } from "react-router-dom";
import Sidebar from "../components/Sidebar";
import Customers from "./Customers";
import Suppliers from "./Suppliers";
import Orders from "./Orders";
import Refund from "./Refund";
import Feedlot from "./Feedlot";
import Cattle from "./Cattle";
import RoleCheck from "../HOC/RoleCheck";
import DisplayOrder from "./DisplayOrder";
import { NotFound } from "./NotFound";
import ProfilePage from "../features/profilePage/components";
import SupplierDetail from "../features/supplier/components/SupplierDetail";
import FeedlotDetails from "../features/feedlot/components/FeedlotDetails";
import Packages from "../features/settings/Packages";
import PackagesDetails from "../features/settings/PackagesDetail";
import Countires from "../features/countries";
import DisplayStates from "../features/countries/components/DisplayStates";
import DisplayRegion from "../features/countries/components/DisplayRegion";
import States from "../features/countries/components/States";
import Regions from "../features/countries/components/Regions";
import AdminDashboard from "../features/dashboard/Admin";
import UserProfile from "../features/profile";
import CattleDetail from "../features/cattle/components/CattleDetail";
import AnalyticsOrders from "../features/analytics/components/Orders";
import AdminTransaction from "../features/analytics/components/Transaction";
import AnalyticsSales from "../features/analytics/components/Sales";
import AnalyticsStock from "../features/analytics/components/Stock";
import Tasks from "../features/tasks/components";
import Executor from "../features/executor/components";
import DashboardMenu from "../features/dashboard/component";
import SupportCenter from "../features/support-center/components";
import Faqs from "../features/support-center/components/Faqs";
import FaqsQuestions from "../features/support-center/components/FaqsQuestions";
import Policies from "../features/support-center/components/Policies";
import ChatUs from "../features/support-center/components/ChatUs";
import ProfileVerification from "../features/ProfileVerification/components/ProfileVerification";
import Transaction from "../features/transaction/Transaction";
import Analytics from "../features/transaction/Analytics";
import RequestReceived from "../features/transaction/RequestReceived";
import TotalEarned from "../features/transaction/TotalEarned";
import Paid from "../features/transaction/Paid";
import ViewHistory from "../features/transaction/ViewHistory";
import Payment from "../features/transaction/Payment";
import History from "../features/transaction/History";
import SlaughterMain from "../features/task new/components";
import SadakahDetail from "../features/settings/SadakahDetail";
import CalenderPage from "../features/calender";
import Reporter from "./Reporter";
import Slaughter from "../features/task new/components/task";
import SlaughterList from "./Slaughter";

import ClaimList from "../features/claimList/ClaimList";
import ExecuterPage from "../features/executerprice";
import FeedlotPage from "../features/feedlotTest";
import PaymentHistory from "../features/analytics/components/PaymentHistory";
import OrganizationPage from "../features/organization";
import OrganizationTypePage from "../features/organization type";
import AnimalTypes from "../features/settings/AnimalTypes/components/DisplayAnimalTypes";
import AnimalTypesTest from "../features/settings/AnimalTypes/components/AnimalTypes";
import CertificatePage from "../features/Certificate";
const Dashboard = () => {
  return (
    <Sidebar>
      <Routes>
        <Route
          element={
            <RoleCheck allowedRoles={["ADMIN", "SLAUGHTER", "REPORTER", "SUPPLIER"]} /> } >
          <Route path="/profile" element={<UserProfile />} />
          {/* <Route path="/settings/animal-types" element={<AnimalTypes />} /> */}
          <Route path="/payment/history/:page" element={<PaymentHistory />} />
          {/* Below route for Coder Executor */}
          <Route path="/profilepage" element={<ProfilePage />} />
          <Route path="/support-center" element={<SupportCenter />} />
          <Route path="/support-center/faqs" element={<Faqs />} />
          <Route path="/support-center/questions" element={<FaqsQuestions />} />
          <Route path="/support-center/policies" element={<Policies />} />
          <Route path="/support-center/chatus" element={<ChatUs />} />
          <Route path="/ProfileVerification" element={<ProfileVerification />} />
          <Route path="/analytics" element={<Analytics />} />
          <Route path="/requestreceived" element={<RequestReceived />} />
          <Route path="/TotalEarned" element={<TotalEarned />} />
          <Route path="/paid" element={<Paid />} />
          <Route path="/viewhistory" element={<ViewHistory />} />
          <Route path="/payment" element={<Payment />} />
          <Route path="/history" element={<History />} />
        </Route>

        <Route element={<RoleCheck allowedRoles={["ADMIN"]} />}>
          <Route path="/customers" element={<Customers />} />
          <Route path="/customers/:page" element={<Customers />} />
          <Route path="/reporter" element={<Reporter />} />
          <Route path="/reporter/:page" element={<Reporter />} />
          <Route path="/slaughter" element={<SlaughterList />} />
          <Route path="/slaughter/:page" element={<SlaughterList />} />
          <Route path="/suppliers" element={<Suppliers />} />
          <Route path="/suppliers/:page" element={<Suppliers />} />
          <Route path="/supplier/detail/:id" element={<SupplierDetail />} />
          <Route path="/claimlist" element={<ClaimList />} />
          <Route path="/AdminDashboard" element={<AdminDashboard />} />
          <Route path="/orders" element={<Orders />} />
          <Route path="/orders/:id" element={<DisplayOrder />} />
          <Route path="/Refund" element={<Refund />} />
          <Route path="/settings/sadakah-details" element={<SadakahDetail />} />
          <Route path="/settings/package-details" element={<PackagesDetails />} />
          <Route path="/settings/animal-types" element={<AnimalTypesTest />} />
          <Route path="/settings/packages" element={<Packages />} />
          <Route path="/countries" element={<Countires />} />
          <Route path="/countries/states" element={<States />} />
          <Route path="countries/regions" element={<Regions />} />
          <Route path="/:country_id/state" element={<DisplayStates />} />
          <Route path="/:countrie/:state/region" element={<DisplayRegion />} />
          <Route path="/analytics/transaction" element={<AdminTransaction />} />
          <Route path="/analytics/sales" element={<AnalyticsSales />} />
          <Route path="/analytics/stock" element={<AnalyticsStock />} />
          <Route path="/calenderpage" element={<CalenderPage />} />
          <Route path="/setpriceexecuter" element={<ExecuterPage />} />
          <Route path="/organization" element={<OrganizationPage />} />
          <Route path="/organizationtype" element={<OrganizationTypePage />} />
          <Route path="/certificate" element={<CertificatePage />} />
          {/* <Route path="/analytics/orders" element={<AnalyticsOrders />} /> */}
        </Route>

        <Route element={<RoleCheck allowedRoles={["ADMIN", "SUPPLIER"]} />}>
          <Route path="/feedlot" element={<FeedlotPage />} />
          <Route path="/feedlot/:page" element={<FeedlotPage />} />

          {/* <Route path="/feedlot" element={<Feedlot />} /> */}
          {/* <Route path="/feedlot/:page" element={<Feedlot />} /> */}
          {/* <Route path="/feedlot/detail/:id" element={<FeedlotDetails />} /> */}
        </Route>

        <Route element={<RoleCheck allowedRoles={["SLAUGHTER"]} />}>
          <Route path="/tasks" element={<SlaughterMain />} />
          {/* <Route path="/orders" element={<Orders />} />
          <Route path="/orders/:id" element={<DisplayOrder />} /> */}
          {/* <Route path="/feedlot" element={<Feedlot />} />
          <Route path="/feedlot/:page" element={<Feedlot />} />
          <Route path="/feedlot/detail/:id" element={<FeedlotDetails />} /> */}
        </Route>

        <Route element={<RoleCheck allowedRoles={[ "REPORTER"]} />}>
          <Route path="/tasksreporter" element={<Executor />} />
          {/* <Route path="/orders" element={<Orders />} />
          <Route path="/orders/:id" element={<DisplayOrder />} /> */}
          {/* <Route path="/feedlot" element={<Feedlot />} />
          <Route path="/feedlot/:page" element={<Feedlot />} />
          <Route path="/feedlot/detail/:id" element={<FeedlotDetails />} /> */}
        </Route>

        <Route element={<RoleCheck allowedRoles={["SLAUGHTER", "REPORTER"]} />}>
        <Route path="/DashboardMenu" element={<DashboardMenu />} />
          <Route path="/transaction" element={<Transaction />} />
        </Route>
        <Route
          element={
            <RoleCheck allowedRoles={["ADMIN", "SUPPLIER", "FEEDLOT"]} />
          }
        >
          <Route path="/cattle" element={<Cattle />} />
          <Route path="/cattle/:page" element={<Cattle />} />
          <Route path="/cattle/detail/:page" element={<CattleDetail />} />
        </Route>

        <Route path="*" element={<NotFound />} />
      </Routes>
    </Sidebar>
  );
};

export default Dashboard;
