import React from 'react'
import Search from '../../../components/Search'
import { Link  } from 'react-router-dom'

const Faqs = () => {
    let arr=[
        {
            h:'How do i update my billing information?',
            desc:'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore'
        },
        {
            h:'How do i update my billing information?',
            desc:'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore'
        },
        {
            h:'How do i update my billing information?',
            desc:'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore'
        },
        {
            h:'How do i update my billing information?',
            desc:'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore'
        },
        {
            h:'How do i update my billing information?',
            desc:'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore'
        },
        {
            h:'How do i update my billing information?',
            desc:'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore'
        },
    ]
  return (
    <div>
     <div className='px-2 md:px-20 mb-[200px]'>
      <div className='md:w-[32vw] space-y-2 sm-full'>
          <h1 className='text-pacific-blue font-bold text-[20px] md:text-[32px]'>Hello, how can we help?</h1>
          <p className='text-[#A4A4A4] pb-2'>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore</p> 
               <Search className='w-[16.4rem] md:w-[23rem]'/>
      </div>
      <div className='grid grid-cols-1 md:grid-cols-2 md:w-[50vw] gap-8 md:gap-6 leading-7 my-6 md:my-12'>
        {
          arr.map((item)=>(
            <div>
               <Link to='/support-center/questions'><h1 className='font-bold text-[14px]'>{item.h}</h1></Link>
                <p className='text-[#A4A4A4] text-[14px]'>{item.desc}</p>
            </div>
          ))
        }
      </div>
      </div>
    </div>
  )
}

export default Faqs
