import React from "react";
import DisplayPackages from "./components/DisplayPackages";

const PackagesWrapper = () => {
	return (
		<div>
			<DisplayPackages />
		</div>
	);
};

export default PackagesWrapper;
