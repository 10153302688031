
import React, { useState, useRef, useEffect } from "react";
import FrameCamera from "../../../assets/images/ProfileVerification/FrameCamera.png";
import Circle from "../../../assets/images/ProfileVerification/circle.png";
import { FaPlusCircle } from "react-icons/fa";
import { RiArrowGoBackFill } from "react-icons/ri";

function SecondStep({handleStepschange}) {
    const [isCameraOn, setIsCameraOn] = useState(false);
    
    const [capturedImage, setCapturedImage] = useState("");
    const [selectedFile, setSelectedFile] = useState(null);
    const videoRef = useRef(null);
    const canvasRef = useRef(null);
    const fileInputRef = useRef(null);



    useEffect(() => {
        if (isCameraOn) {
          navigator.mediaDevices
            .getUserMedia({ video: true })
            .then((stream) => {
              videoRef.current.srcObject = stream;
              videoRef.current.play();
            })
            .catch((err) => {
              console.error("Error accessing the camera: ", err);
            });
        }
      }, [isCameraOn]);
    
      const captureImage = () => {
        const canvas = canvasRef.current;
        const video = videoRef.current;
        const context = canvas.getContext("2d");
    
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        context.drawImage(video, 0, 0, canvas.width, canvas.height);
        const dataUrl = canvas.toDataURL("image/png");
    
        // Convert data URL to Blob
        fetch(dataUrl)
          .then((res) => res.blob())
          .then((blob) => {
            // Create a File object from the Blob
            const file = new File([blob], "captured_image.png", { type: "image/png" });
            setSelectedFile(file);
          });
    
        setCapturedImage(dataUrl);

  // Turn off the camera after capturing the image
  const stream = video.srcObject;
  const tracks = stream.getTracks();

  tracks.forEach((track) => track.stop()); // Stop all video tracks
        setIsCameraOn(false); // Turn off the camera after capturing the image
      };


    const startCamera = () => {
        setIsCameraOn(true);
        setCapturedImage("");
      };
    
      const handleIconClick = () => {
        fileInputRef.current.click();
      };
    
      const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
          setSelectedFile(file);
          setCapturedImage(URL.createObjectURL(file));
        }
      };
    
      const handleReplaceImage = () => {
        setSelectedFile(null); // Clear the selected file
        setCapturedImage(""); // Clear the captured image
        fileInputRef.current.click(); // Trigger file input click to select a new image
      };
  return (
    <>    <div className="flex flex-col justify-center items-center md:w-[500px]">
            <div className="md:text-lg text-xs my-8 text-[#848484] text-center">
            Snap a quick selfie for identity verification. Ensure your face is well-lit, visible and fits the square provided. Don’t worry, we’ll keep it secure.
            </div>
            <div className="relative w-full ">
              {!isCameraOn && !capturedImage && (
                <img
                  onClick={startCamera}
                  src={FrameCamera}
                  alt="FrameCamera"
                  className="cursor-pointer"
                />
              )}
              {isCameraOn && (
                <video
                  ref={videoRef}
                  className="w-full h-auto bg-gray-300"
                  autoPlay
                  playsInline
                ></video>
              )}
              {capturedImage && (
                <img
                  src={capturedImage}
                  alt="Captured"
                  className="w-full h-auto"
                />
              )}
              <canvas ref={canvasRef} className="hidden"></canvas>

              {isCameraOn && (
                <button
                  className="mt-4 px-4 py-2 rounded absolute bottom-0 right-0"
                  onClick={captureImage}
                >
                  <img src={Circle} alt="Capture" />
                </button>
              )}
            </div>
            <div className="my-8 text-[#848484] text-2xl">or</div>
            <div className="flex gap-3">
              {!selectedFile ? (
                <div className="w-8 h-8 border-2 border-pacific-blue flex justify-center items-center rounded-lg">
                <FaPlusCircle
                  className="text-pacific-blue text-lg cursor-pointer"
                  onClick={() => {
                    setIsCameraOn(false);
                    setCapturedImage("");
                    handleIconClick();
                  }}
                />
              </div>
            ) : (
              <div className="flex items-center">
                <button onClick={() => {
                    setIsCameraOn(false);
                    setCapturedImage("");
                    handleIconClick();
                  }} className="border border-gray-400 px-3  rounded-md text-[16px]">
                  {selectedFile.name}
                </button>
              </div>
              )}

              <input
                type="file"
                id="fileInput"
                ref={fileInputRef}
                className="hidden"
                accept="image/*"
                onChange={handleFileChange}
              />

              <div
                className={`w-8 h-8 border-2 flex justify-center items-center cursor-pointer rounded-lg ${
                  capturedImage !== ""
                    ? "border-pacific-blue"
                    : "border-[#B6B6B6]"
                }`}
                onClick={isCameraOn ? null : startCamera}
              >
                <RiArrowGoBackFill
                  className={`text-lg ${
                    capturedImage !== ""
                      ? "text-pacific-blue"
                      : "text-[#B6B6B6]"
                  }`}
                />
              </div>
            </div>
          </div>

          <div className="mt-10">
            {(capturedImage=="" && selectedFile==null)?
              <button
                className="px-4 w-full md:text-xl text-sm  md:py-2 py-1 bg-gray-400 text-white rounded"
                onClick={() =>console.log("sa")}
              >
                Submit
              </button>
              :
              <button
              className="px-4  md:text-xl text-sm w-full md:py-2 py-1 bg-blue-600 text-white rounded"
              onClick={() =>{handleStepschange()}}
            >
              Submit
            </button>
}
            </div>
          </>

  )
}

export default SecondStep