import React from 'react'
import { IoIosArrowForward } from "react-icons/io";
import { IoIosArrowDown } from "react-icons/io";
import { IoArrowDownCircleOutline } from "react-icons/io5";


const Payment = () => {
  return (
    <div className="relative overflow-x-auto mb-[200px]">
    <table className="w-full text-sm text-left rtl:text-right text-gray-500">
        <thead className="text-xs text-pacific-blue uppercase bg-[#CFF0F2] ">
            <tr>
                <th scope="col" className="px-2 py-3">
                <input id="link-checkbox" type="checkbox" value="" className="w-5 h-5 text-blue-600 bg-gray-100 border-gray-300 rounded-2xl"/>
                </th>
                <th scope="col" className="px-6 py-3 text-base font-medium">
<div className='flex gap-1 items-center'> 
                Invoice ID 

                <IoIosArrowDown/>
</div>
                </th>
                <th scope="col" className="px-6 py-3 text-base font-medium">
                <div className='flex gap-1 items-center'> 
                Date

                <IoIosArrowDown/>
</div>
                </th>
                <th scope="col" className="px-6 py-3 text-base font-medium">
                <div className='flex gap-1 items-center'> 

                Bank
                <IoIosArrowDown/>
</div>

                </th>
                <th scope="col" className="px-6 py-3 text-base font-medium">
                Amount
                </th>

                <th scope="col" className="px-6 py-3 text-base font-medium">
                Status
                </th>

                <th scope="col" className="px-6 py-3 text-base font-medium">
                Action
                </th>
                <th scope="col" className="px-2 py-3 text-base font-medium">
                   
                </th>
            </tr>
        </thead>
        <tbody>
            <tr className="bg-white border-b ">
                <th scope="row" className="px-2 py-4">
                <input id="link-checkbox" type="checkbox" value="" className="w-5 h-5 text-blue-600 bg-gray-100 border-gray-300 rounded-2xl"/>
                  
                </th>
                <th scope="row" className="px-6 py-4 text-sm font-medium">
                118399472AA
                </th>
                <td className="px-6 py-4 text-sm font-medium">
                25/06/2024
                </td>
                <td className="px-6 py-4 text-sm font-medium">
                BIMB
                </td>

                <td className="px-6 py-4">
                <div className="flex items-end" >
                <p className="text-[10px] font-medium">RM </p>
                <p className="text-sm font-medium">2,400.00</p>
              </div>

                </td>

                <td className="px-6 py-4 text-[#09CB3F] text-sm font-medium">
                    Receive
                </td>

                <td className="px-6 py-4 text-sm font-medium">
                  <div className='flex gap-1 w-28 pl-2 text-electric-purple border border-electric-purple rounded-2xl'>
<IoArrowDownCircleOutline className='text-lg'/>
                Download
                  </div>
                </td>

                <th scope="row" className="px-2 py-4">
                  <IoIosArrowForward/>
                </th>
            </tr>
           
        </tbody>
    </table>
</div>
  )
}

export default Payment