import React, { useState, useEffect } from "react";
import {
  useFetchCountires,
  useFetchCountryStateRegions,
  useFetchCountryStates,
} from "../hooks/useFetchCountries";
import HeaderSection from "../../../components/HeaderSection";
import DataTable from "../../../components/Table/DataTable";
import { updateRegionStatus } from "../../../services/countries";
import { notify } from "../../../utils/toasts";
import Search from "../../../components/Search";
import CreateOrUpdateRegion from "./CreateOrUpdateRegion";
import Button from "../../../components/Button/Button";
import { Dialog } from "@headlessui/react";
import { useForm } from "react-hook-form";
import jsonData from "../../../assets/jsonfile/countrydata.json";
import { axios } from "../../../config/axios";
import { toast } from "react-toastify";

const Regions = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [selectedRegion, setSelectedRegion] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [jsonselectedCountry, setjsonSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const { countries } = useFetchCountires();
  const { states } = useFetchCountryStates(selectedCountry);
  const [jsonRegionList, setjsonRegionList] = useState([]);
  const { regions, isError, isLoading, refetch } =
    useFetchCountryStateRegions(selectedState);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset
  } = useForm();

  useEffect(() => {
    if (selectedRegion) {
      setValue("name", selectedRegion.name);
      setValue("state_id", selectedRegion.state.id);
      setValue("status", selectedRegion.enabled);
    }
  }, [selectedRegion, setValue]);

  const handleCreateRegion = async (data) => {
    const token = JSON.parse(localStorage.getItem("token"));

    try {
      await axios.post(
        "/users/add-regions",
        {
          name: data.name,
          state_id: Number(data.state_id),
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setIsOpen(false);
      toast.success("Region Created");
    } catch (error) {
      toast.error(error?.response?.data?.error || "Something went wrong");
    }
  };

  const handleUpdateRegion = async (data) => {
    const token = JSON.parse(localStorage.getItem("token"));

    try {
      await axios.put(
        `/users/put/${selectedRegion.id}/regions`,
        {
          status: data.status,
          state_id: Number(data.state_id),
          name: data.name,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setIsOpen(false);
      toast.success("Region Updated");
    } catch (error) {
      toast.error(error?.response?.data?.error || "Something went wrong");
    }
  };

  const handleSubmitForm = (data) => {
    if (isUpdating) {
      handleUpdateRegion(data);
    } else {
      handleCreateRegion(data);
    }
  };
  const openCreateDialog = () => {
    // Reset the form values and selected region
    reset({
      name: "",
      state_id: "",
      status: true, // Default status value
    });
    setSelectedRegion(null);
    setIsUpdating(false);
    setIsOpen(true);
  };
  if (isError) {
    return <h1 className="">An error occured</h1>;
  }

  if (isLoading) {
    return <h1 className="">Please wait, fetching data</h1>;
  }
  return (
    <div>
      <div>
        <div className="flex flex-col justify-betweenr mb-3">
          <div className="flex flex-col sm:flex-row md:space-x-2">
            <div className="md:w-[300px] ">
              <label
                htmlFor="country_id"
                className="block text-sm font-medium text-gray-700"
              >
                Select Country
              </label>

              <select
                className="mt-1 p-2 w-full border border-gray-300 rounded-md"
                onChange={(e) => setSelectedCountry(e.target.value)}
                value={selectedCountry}
              >
                <option value={""}>Select Option</option>
                {countries?.data?.map((option) => (
                  <option key={option.id} value={option.id}>
                    {option.name}
                  </option>
                ))}
              </select>
            </div>

            <div className="md:w-[300px] ">
              <label
                htmlFor="country_id"
                className="block text-sm font-medium text-gray-700"
              >
                Select State
              </label>

              <select
                className="mt-1 p-2 w-full border border-gray-300 rounded-md"
                onChange={(e) => setSelectedState(e.target.value)}
                value={selectedState}
              >
                <option value={""}>Select Option</option>
                {states?.data?.map((option) => (
                  <option key={option.id} value={option.id}>
                    {option.name}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="flex justify-between mb-2">
            <button
              className="font-bold"
              onClick={() => console.log(countries)}
            >
              Region List
            </button>

            <Button
              type="button"
              onClick={openCreateDialog}
            >
              Add Region
            </Button>
          </div>
        </div>

        <table className="w-full text-sm overflow-x-auto text-left rtl:text-right text-gray-500 ">
          <thead className="text-xs text-pacific-blue uppercase bg-[#CFF0F2] ">
            <tr>
              <th scope="col" className="px-6 py-3 text-base font-medium ">
                <div className="flex gap-1 items-center">Id</div>
              </th>

              <th scope="col" className="px-6 py-3 text-base font-medium">
                <div className="flex gap-1 items-center">State</div>
              </th>

              <th scope="col" className="px-6 py-3 text-base font-medium">
                <div className="flex gap-1 items-center">Region</div>
              </th>
              <th scope="col" className="px-6 py-3 text-base font-medium">
                <div className="flex gap-1 items-center">Status</div>
              </th>
              <th scope="col" className="px-2 py-3 text-base font-medium">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {regions?.data?.map((e) => {
              return (
                <tr className="bg-white border-b " key={e?.id}>
                  <th scope="row" className="px-6 py-4 text-sm font-medium">
                    {e?.id}
                  </th>

                  <td className="px-6 py-4 text-sm font-medium">
                    {e?.state?.name}
                  </td>
                  <td className="px-6 py-4 text-sm font-medium">{e?.name}</td>
                  <td
                    className={`px-6 py-4 text-sm font-medium ${
                      e?.enabled ? "text-green-500" : "text-red-500"
                    }`}
                  >
                    {e?.enabled ? "Active" : "In-Active"}
                  </td>
                  <td className="px-6 py-4 text-sm font-medium text-pacific-blue">
                    <Button
                      className="px-8"
                      onClick={() => {
                        setSelectedRegion(e);
                        setIsOpen(true);
                        setIsUpdating(true);
                      }}
                    >
                      Edit
                    </Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>

        {/* Add Country */}
        {isOpen && (
          <Dialog
            open={isOpen}
            onClose={() => setIsOpen(false)}
            className="fixed inset-0 z-50 overflow-y-auto p-4 duration-500 "
          >
            <div className="flex items-center justify-center min-h-screen">
              <Dialog.Overlay className="fixed inset-0 bg-black/30" />
              <Dialog.Panel className="relative max-w-[600px] w-full mx-auto bg-white p-4 rounded">
                <Dialog.Title className="text-lg font-medium mb-6 border-b-2 flex items-center justify-between">
                  <span className="">
                    {isUpdating ? "Update Region" : "Create Region"}
                  </span>
                  <div
                    className="flex items-center justify-center "
                    onClick={() => setIsOpen(false)}
                  >
                    <h1
                      onClick={() => setIsOpen(false)}
                      className="bg-gray-200 px-2 rounded-md mb-1 hover:text-red-600 cursor-pointer"
                    >
                      x
                    </h1>
                  </div>
                </Dialog.Title>
                <form onSubmit={handleSubmit(handleSubmitForm)}>
                  <div className="grid grid-cols-1  sm:grid-cols-2 gap-4">
                  
                  {!isUpdating && (
                    <div>
                      <label
                        htmlFor="country_id"
                        className="block mt-1 font-medium text-gray-700"
                      >
                        Country
                      </label>
                      <select
                        type="select"
                        id="country_id"
                        name="country_id"
                        {...register("country_id", {
                          required: "Name is required",
                        })}
                        className="mt-1 p-2 w-full border border-gray-300 rounded-md"
                        onChange={(e) => {
                          setSelectedCountry(e.target.value);
                          const selectedStateName =
                            e.target.options[e.target.selectedIndex].text;
                          setjsonSelectedCountry(selectedStateName);
                        }}
                      >
                        <option value={""}>Select Option</option>
                        {countries?.data?.map((option) => (
                          <option
                            key={option.id}
                            value={option.id}
                            name={option.name}
                          >
                            {option.name}
                          </option>
                        ))}
                      </select>
                      {errors.country_id && <p className="text-red-500 text-sm">{errors.country_id.message}</p>}
                    </div>
                  )}

                    <div>
                      <label
                        htmlFor="state_id"
                        className="block mt-1 font-medium text-gray-700"
                      >
                        State
                      </label>
                      <select
                        type="select"
                        id="state_id"
                        name="state_id"
                        {...register("state_id", {
                          required: "state is required",
                        })}
                        className="mt-1 p-2 w-full border border-gray-300 rounded-md"
                        onChange={(e) => {
                          const selectedStateName =
                            e.target.options[e.target.selectedIndex].text;
                          console.log(selectedStateName);
                          // const countryData = jsonData.find(
                          //   (country) => country.country === jsonselectedCountry
                          // );

                          // const selectedState = countryData?.states?.find(
                          //   (state) => state.name === selectedStateName
                          // );
                          // console.log(selectedState);
                          // if (selectedState) {
                          //   setjsonRegionList(selectedState?.regions);
                          // } else {
                          //   setjsonRegionList([]);
                          // }
                        }}
                      >
                        <option value={""}>Select Option</option>
                        {states?.data?.map((option) => (
                          <option key={option.id} value={option.id}>
                            {option.name}
                          </option>
                        ))}
                      </select>
                      {errors.state_id && <p className="text-red-500 text-sm">{errors.state_id.message}</p>}
                    </div>
                    

                    <div>
                      <label
                        htmlFor="name"
                        className="block mt-1 font-medium text-gray-700"
                      >
                        Region
                      </label>
                      <input
                        type="text"
                        id="name"
                        name="name"
                        {...register("name", {
                          required: "Name is required",
                        })}
                        className="mt-1 p-2 w-full border border-gray-300 rounded-md"
                        placeholder="Enter the region name"
                      />
       {errors.name && <p className="text-red-500 text-sm">{errors.name.message}</p>}
                      {/* <select
                        type="select"
                        id="name"
                        name="name"
                        {...registerAddRegion("name", {
                          required: "Name is required",
                        })}
                        className="mt-1 p-2 w-full border border-gray-300 rounded-md"
                       
                      >
                        <option value={""}>Select Option</option>
                        {jsonRegionList?.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
                      </select> */}
                    </div>

                    {isUpdating && (
                      <div className="col-span-2">
                        <label
                          htmlFor="status"
                          className="block mt-1 font-medium text-gray-700"
                        >
                          Status
                        </label>
                        <select
                          id="status"
                          {...register("status", {
                            required: "Status is required",
                          })}
                          className="mt-1 p-2 w-full border border-gray-300 rounded-md"
                        >
                          <option value={true}>Active</option>
                          <option value={false}>Inactive</option>
                        </select>
                        {errors.status && (
                          <p className="text-red-500 text-sm">
                            {errors.status.message}
                          </p>
                        )}
                      </div>
                    )}
                  </div>
                  <div className="flex justify-end mt-10">
                    <Button
                      type="button"
                      className="mr-2 bg-danger"
                      onClick={() => setIsOpen(false)}
                    >
                      Cancel
                    </Button>
                    <Button type="submit" color="primary">
                      {isUpdating ? "Update" : "Create"}
                    </Button>
                  </div>
                </form>
              </Dialog.Panel>
            </div>
          </Dialog>
        )}
      </div>
    </div>
  );
};

export default Regions;
