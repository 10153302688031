import React, { useEffect, useState } from "react";
import { Loader } from "../../components/Loader";
import { Dialog } from "@headlessui/react";
import Button from "../../components/Button/Button";
import { useGetAnimal } from "../settings/AnimalTypes/hooks/useGetAnimal";
import { toast } from "react-toastify";
import { axios } from "../../config/axios";
import {
  createOrganizationType,
  getOrganizationTypeList,
} from "../../services/organization";
import { formatDate, formatDateWithoutTime } from "../../utils/formatDate";
import { config } from "../../config/config";

function CertificatePage() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  let [isOpen, setIsOpen] = useState(false);
  let [editModal, setEditModal] = useState(false);
  const [id, setId] = useState("");
  let [edititem, setEditItem] = useState({
  title :"",
   subtitle    :"",
   portion_text:"",
   description :"",
   date:"",
   summary     :"",
   left_position:"",
   right_position:"",
   left_name   :"",        
   right_name  :"",
  //  left_image  :"",   
  //  right_image :"",
   is_active:false
   });

  const [event, setEvent] = useState({
     title :"",
    subtitle    :"",
    portion_text:"",
    description :"",
    date:"",
    summary     :"",
    left_position:"",
    right_position:"",
    left_name   :"",        
    right_name  :"",
    // left_image  :"",   
    // right_image :"",
    is_active:false
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEvent((prevEvent) => ({
      ...prevEvent,
      [name]: value,
    }));
  };
  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setEvent((prevEvent) => ({
      ...prevEvent,
      [name]: files[0],
    }));
  };


  
  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditItem((prevEvent) => ({
      ...prevEvent,
      [name]: value,
    }));
  };
  // const handleEditFileChange = (e) => {
  //   const { name, files } = e.target;
  //   setEditItem((prevEvent) => ({
  //     ...prevEvent,
  //     [name]: files[0],
  //   }));
  // };


  const formatInputDate = (dateString) => {
    if (!dateString) return '';

    // Extract the date portion from the ISO string
    const date = new Date(dateString.split("T")[0]);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    // Format to 'YYYY-MM-DD'
    console.log(`${year}-${month}-${day}`)
    return `${year}-${month}-${day}`;
  };


  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();

    for (const key in event) {
        if (
          (key === 'left_image' && event[key]) ||
          (key === 'right_image' && event[key])
        ) {
          formData.append(key, event[key]); // Directly append the image file object
        } else if (key !== 'image') {
          formData.append(key, event[key]);
        }
    }

    for (let pair of formData.entries()) {
      console.log(pair[0] + ': ' + pair[1]);
    }

    try {
      const token = JSON.parse(localStorage.getItem('token'));

      const response = await axios.post('animal-inventory/certificates', formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });

      console.log(response);
      if (response?.status === 200) {
        toast.success(response?.data?.data || 'Success!');
      } else {
        toast.error(response?.data?.message || 'An error occurred');
      }

      fetchData();
      setIsOpen(false);
    } catch (error) {
      console.error(error);
      const errorMessage =
        error?.response?.data?.message || 'An unexpected error occurred. Please try again.';
      toast.error(errorMessage);
    }
  }

  const handleEditSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();

    for (const key in edititem) {
        if (
          (key === 'left_image' && edititem[key]) ||
          (key === 'right_image' && edititem[key])
        ) {
          formData.append(key, edititem[key]); // Directly append the image file object
        } else if (key !== 'image') {
          formData.append(key, edititem[key]);
        }
    }

    for (let pair of formData.entries()) {
      console.log(pair[0] + ': ' + pair[1]);
    }

    try {
      const token = JSON.parse(localStorage.getItem('token'));

      const response = await axios.patch(`animal-inventory/certificates/${id}`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });

      console.log(response);
      if (response?.status === 200) {
        toast.success(response?.data?.data || 'Success!');
      } else {
        toast.error(response?.data?.message || 'An error occurred');
      }

      fetchData();
      toast.success("Update Successfull");

      setEditModal(false);
    } catch (error) {
      console.error(error);
      const errorMessage =
        error?.response?.data?.message || 'An unexpected error occurred. Please try again.';
      toast.error(errorMessage);
    }
  }






  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true); 

    try {
      const token = JSON.parse(localStorage.getItem('token'));

      const response = await axios.get('animal-inventory/certificates', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      
console.log(response)
      if (!response.data) {
        throw new Error('No data found');
      }

      setData(response?.data?.data); 
      setLoading(false);
    } catch (error) {
      // setLoading(false);
      console.error('Error fetching data:', error);
      const errorMessage = error.response?.data?.message || 'An unexpected error occurred. Please try again.';
      toast.error(errorMessage); 
    } finally {
      setLoading(false);
    }
  };


  const generateCertificate = async () => {
    try {
      // Retrieve token from local storage
      const token = JSON.parse(localStorage.getItem('token'));
  
      // Make the POST request
      const response = await axios.post(
        `{${config?.backendBaseURL}api/animal-inventory/generate-certificates}`,
        {}, // You can pass the required data here, if needed
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'accept': '*/*',
          },
        }
      );
  
      // Log the response and show success message
      console.log(response);
      toast.success(response?.data?.data);
  
    } catch (error) {
      // Handle error and show error message
      console.error(error?.response?.data?.error);
      toast.error(error?.response?.data?.error);
    } finally {
      // Set loading to false
      setLoading(false);
    }
  };
  



  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <div className="relative overflow-x-auto mb-[200px]">
        <div className="flex justify-between  m-4">

          <button className="font-bold" onClick={() => console.log(data)}>
            Certificate List
          </button>

<div className="flex">          <Button type="button"  onClick={generateCertificate}>
           Generate  Certificate 
          </Button>

          <Button className="ml-3" type="button"  onClick={() => {
   
   setIsOpen(true);
 }}>
           Add Certificate
         </Button>
         </div>

        </div>
        <table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
          <thead className="text-xs text-pacific-blue uppercase bg-[#CFF0F2] ">
            <tr>
              <th scope="col" className="px-6 py-3 text-base font-medium">
                <div className="flex gap-1 items-center">Id</div>
              </th>
              <th scope="col" className="px-6 py-3 text-base font-medium">
                <div className="flex gap-1 items-center">  Title  </div>
              </th>

              <th scope="col" className="px-6 py-3 text-base font-medium">
                <div className="flex gap-1 items-center">Subtitle</div>
              </th>

              <th scope="col" className="px-6 py-3 text-base font-medium">
                <div className="flex gap-1 items-center">Portion Text</div>
              </th>

              <th scope="col" className="px-6 py-3 text-base font-medium">
                <div className="flex gap-1 items-center">Description</div>
              </th>

              <th scope="col" className="px-6 py-3 text-base font-medium ">
                <div className="flex gap-1 items-center text-center">Date</div>
              </th>

              <th scope="col" className="px-6 py-3 text-base font-medium ">
                <div className="flex gap-1 items-center text-center">Summary</div>
              </th>

              <th scope="col" className="px-6 py-3 text-base font-medium ">
                <div className="flex gap-1 items-center text-center">Left Position</div>
              </th>

              <th scope="col" className="px-6 py-3 text-base font-medium ">
                <div className="flex gap-1 items-center text-center">Right Position</div>
              </th>

              <th scope="col" className="px-6 py-3 text-base font-medium ">
                <div className="flex gap-1 items-center text-center">Left Name</div>
              </th>

              <th scope="col" className="px-6 py-3 text-base font-medium ">
                <div className="flex gap-1 items-center text-center">Right Name</div>
              </th>

              {/* <th scope="col" className="px-6 py-3 text-base font-medium ">
                <div className="flex gap-1 items-center text-center">Left Image</div>
              </th>

              <th scope="col" className="px-6 py-3 text-base font-medium ">
                <div className="flex gap-1 items-center text-center">Right Image</div> 
              </th>*/}

              <th scope="col" className="px-6 py-3 text-base font-medium ">
                <div className="flex gap-1 items-center text-center">Status</div>
              </th>

              <th scope="col" className="px-6 py-3 text-base font-medium ">
              </th>

            </tr>
          </thead>
          <tbody>
            {data?.map((e) => {
              return (
                <tr className="bg-white border-b ">
                  <th scope="row" className="px-6 py-4 text-sm font-medium">
                    {e?.id}
                  </th>
                  <td className="px-6 py-4 text-sm font-medium">{e?.title}</td>

                  <td className="px-6 py-4 text-sm font-medium">
                    {e?.subtitle}
                  </td>

                  <td className="px-6 py-4 text-sm font-medium">
                    {e?.portion_text}
                  </td>

                  <td className="px-6 py-4 text-sm font-medium">
                    {e?.description}
                  </td>

                  <td className="px-6 py-4 text-sm font-medium">
                    {formatDateWithoutTime(e?.date)}
                  </td>

                  <td className="px-6 py-4 text-sm font-medium">
                    {e?.summary}
                  </td>

                  <td className="px-6 py-4 text-sm font-medium">
                    {e?.left_position}
                  </td>

                  <td className="px-6 py-4 text-sm font-medium">
                    {e?.right_position}
                  </td>

                  <td className="px-6 py-4 text-sm font-medium">
                    {e?.left_name}
                  </td>

                  <td className="px-6 py-4 text-sm font-medium">
                    {e?.right_name}
                  </td>

                  {/* <td className="px-6 py-4 text-sm font-medium">
                  {e.left_image == null ? (
                      ""
                    ) : (
                      <img
                        src={`${config.resourceUrl}${e?.left_image}`}
                        height={50}
                        width={50}
                        alt="left image"
                      />
                    )}
                  </td>

                  <td className="px-6 py-4 text-sm font-medium">
                
                  
                  {e?.right_image == null ? (
                    ""
                  ) : (
                    <img
                      src={`${config?.resourceUrl}${e?.right_image}`}
                      height={50}
                      width={50}
                      alt="left image"
                    />
                  )}
                  
                 

                  </td> */}

                  <td className="px-6 py-4 text-sm font-medium">
                    {e?.is_active ? (
                      <Button className="w-24 ">Active </Button>
                    ) : (
                      <Button
                        className="bg-red-500 hover:bg-red-500 w-24"
                        disabled
                      >
                        In-Active{" "}
                      </Button>
                    )}
                  </td>

                  <td className="px-6 py-4 text-sm font-medium text-pacific-blue">
                    <Button
                      onClick={() => {
                        setId(e?.id)
                        const formatDate = (dateString) => {
                          const [day, month, year] = dateString.split('-');
                          const fullYear = `20${year}`; 
                          return `${fullYear}-${month}-${day}`;
                        };
                        setEditItem({
                          title :e?.title,
                          subtitle    :e?.subtitle,
                          portion_text:e?.portion_text,
                          description :e?.description,
                          date:formatDate(e?.date),
                          summary     :e?.summary,
                          location     :e?.location,
                          left_position:e?.left_position,
                          right_position:e?.right_position,
                          left_name:e?.left_name,        
                          right_name  :e?.right_name,
                          is_active:e?.is_active
                        });
                        setEditModal(true);
                      }}
                    >
                      Edit
                    </Button>

                  
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>

        {isOpen && (
          <Dialog
            open={isOpen}
            onClose={() => setIsOpen(true)}
            className="fixed inset-0 z-50 overflow-y-auto p-4 duration-500 "
          >
            <div className="flex items-center justify-center min-h-screen">
              <Dialog.Overlay className="fixed inset-0 bg-black/30" />

              <Dialog.Panel className="relative max-w-[600px] w-full mx-auto bg-white p-4 rounded">
                <Dialog.Title className="text-lg font-medium mb-6 border-b-2 flex items-center justify-between">
                  <span className="">Create Certificate</span>
                  <div
                    className="flex items-center justify-center "
                    onClick={() => setIsOpen(false)}
                  >
                    <h1 className="bg-gray-200 px-2 rounded-md mb-1 hover:text-red-600 ">
                      x
                    </h1>
                  </div>
                </Dialog.Title>

                <form
                  onSubmit={handleSubmit}
                  className=" max-h-[60vh] overflow-auto"
                >
                  <div className="grid grid-cols-1  sm:grid-cols-1 gap-4">
                    <div className="">
                      <label
                        htmlFor="title"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Cerificate Title
                      </label>
                      <input
                        type="text"
                        id="title"
                        name="title"
                        value={event?.title}
                        className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                        onChange={handleChange}
                        required
                      />
                    </div>

                    <div className="">
                      <label
                        htmlFor="subtitle"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Sub Title
                      </label>
                      <input
                        type="text"
                        id="subtitle"
                        name="subtitle"
                        value={event?.subtitle}
                        className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                        onChange={handleChange}
                        required
                      />
                    </div>

                    <div className="">
                      <label
                        htmlFor="portion_text"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Portion Text
                      </label>
                      <input
                        type="text"
                        id="portion_text"
                        name="portion_text"
                        value={event?.portion_text}
                        className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                        onChange={handleChange}
                        required
                      />
                    </div>

                    <div className="">
                      <label
                        htmlFor="description"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Description
                      </label>
                      <input
                        type="text"
                        id="description"
                        name="description"
                        value={event?.description}
                        className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                        onChange={handleChange}
                        required
                      />
                    </div>

                    <div className="">
                      <label
                        htmlFor="date"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Date
                      </label>
                      <input
                        type="date"
                        id="date"
                        name="date"
                        value={event?.date}
                        className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                        onChange={handleChange}
                        required
                      />
                    </div>

                    <div className="">
                      <label
                        htmlFor="location"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Location
                      </label>
                      <input
                        type="text"
                        id="location"
                        name="location"
                        value={event?.location}
                        className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                        onChange={handleChange}
                        required
                      />
                    </div>

                    <div className="">
                      <label
                        htmlFor="summary"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Summary
                      </label>
                      <input
                        type="text"
                        id="summary"
                        name="summary"
                        value={event?.summary}
                        className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                        onChange={handleChange}
                        required
                      />
                    </div>



                    <div className="">
                      <label
                        htmlFor="left_name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Left Name
                      </label>
                      <input
                        type="text"
                        id="left_name"
                        name="left_name"
                        value={event?.left_name}
                        className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                        onChange={handleChange}
                        required
                      />
                    </div>

                    <div className="">
                      <label
                        htmlFor="left_position"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Left Position
                      </label>
                      <input
                        type="text"
                        id="left_position"
                        name="left_position"
                        value={event?.left_position}
                        className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                        onChange={handleChange}
                        required
                      />
                    </div>

                    <div className="">
                      <label
                        htmlFor="right_name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Right Name
                      </label>
                      <input
                        type="text"
                        id="right_name"
                        name="right_name"
                        value={event?.right_name}
                        className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                        onChange={handleChange}
                        required
                      />
                    </div>

                    <div className="">
                      <label
                        htmlFor="right_position"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Right Position
                      </label>
                      <input
                        type="text"
                        id="right_position"
                        name="right_position"
                        value={event?.right_position}
                        className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                        onChange={handleChange}
                        required
                      />
                    </div>

                    

                    

                    {/* <div className="">
                      <label
                        htmlFor="left_image"
                        className="block text-sm font-medium text-gray-700"
                      >
                       Left Image
                      </label>
                      <input
                        type="file"
                        id="left_image"
                        name="left_image"
                        className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                        onChange={handleFileChange}
                        required
                      />
                    </div>

                    <div className="">
                      <label
                        htmlFor="right_image"
                        className="block text-sm font-medium text-gray-700"
                      >
                       Right Image
                      </label>
                      <input
                        type="file"
                        id="right_image"
                        name="right_image"
                        className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                        onChange={handleFileChange}
                        required
                      />
                    </div> */}

                   

                    
                  </div>

                  <div className="flex justify-end mt-10">
                    <Button type="submit">Submit</Button>
                  </div>
                </form>
              </Dialog.Panel>
            </div>
          </Dialog>
        )}

        {editModal && (
          <Dialog
            open={editModal}
            onClose={() => setEditModal(true)}
            className="fixed inset-0 z-50 overflow-y-auto p-4 duration-500 "
          >
            <div className="flex items-center justify-center min-h-screen">
              <Dialog.Overlay className="fixed inset-0 bg-black/30" />

              <Dialog.Panel className="relative max-w-[600px] w-full mx-auto bg-white p-4 rounded">
                <Dialog.Title className="text-lg font-medium mb-6 border-b-2 flex items-center justify-between">
                  <span className="" onClick={()=>console.log(edititem)}>Update Certificate</span>
                  <div
                    className="flex items-center justify-center "
                    onClick={() => {
                      setEditModal(false);
                    }}
                  >
                    <h1 className="bg-gray-200 px-2 rounded-md mb-1 hover:text-red-600 ">
                      x
                    </h1>
                  </div>
                </Dialog.Title>

                <form
                  onSubmit={handleEditSubmit}
                >
               

                        <div className="grid grid-cols-1  sm:grid-cols-2 gap-4">
                    <div className="">
                      <label
                        htmlFor="title"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Cerificate Type
                      </label>
                      <input
                        type="text"
                        id="title"
                        name="title"
                        value={edititem.title}
                        className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                        onChange={handleEditChange}

                        required
                      />
                    </div>

                    <div className="">
                      <label
                        htmlFor="subtitle"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Sub Title
                      </label>
                      <input
                        type="text"
                        id="subtitle"
                        name="subtitle"
                        value={edititem.subtitle}
                        className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                        onChange={handleEditChange}

                        required
                      />
                    </div>

                    <div className="">
                      <label
                        htmlFor="portion_text"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Portion Text
                      </label>
                      <input
                        type="text"
                        id="portion_text"
                        name="portion_text"
                        value={edititem.portion_text}
                        className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                        onChange={handleEditChange}

                        required
                      />
                    </div>

                    <div className="">
                      <label
                        htmlFor="description"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Description
                      </label>
                      <input
                        type="text"
                        id="description"
                        name="description"
                        value={edititem.description}
                        className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                        onChange={handleEditChange}

                        required
                      />
                    </div>

                    <div className="">
                      <label
                        htmlFor="date"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Date
                      </label>
                      <input
                        type="date"
                        id="date"
                        name="date"
                        value={formatInputDate(edititem?.date)}

                        className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                        onChange={handleEditChange}

                        required
                      />
                    </div>

                    <div className="">
                      <label
                        htmlFor="summary"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Summary
                      </label>
                      <input
                        type="text"
                        id="summary"
                        name="summary"
                        value={edititem.summary}
                        className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                        onChange={handleEditChange}

                        required
                      />
                    </div>

                    <div className="">
                      <label
                        htmlFor="location"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Location
                      </label>
                      <input
                        type="text"
                        id="location"
                        name="location"
                        value={edititem.location}
                        className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                        onChange={handleEditChange}

                        required
                      />
                    </div>

                    <div className="">
                      <label
                        htmlFor="left_position"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Left Position
                      </label>
                      <input
                        type="text"
                        id="left_position"
                        name="left_position"
                        value={edititem.left_position}
                        className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                        onChange={handleEditChange}
                        required
                      />
                    </div>

                    <div className="">
                      <label
                        htmlFor="right_position"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Right Position
                      </label>
                      <input
                        type="text"
                        id="right_position"
                        name="right_position"
                        value={edititem.right_position}
                        className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                        onChange={handleEditChange}
                        required
                      />
                    </div>

                    <div className="">
                      <label
                        htmlFor="left_name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Left Name
                      </label>
                      <input
                        type="text"
                        id="left_name"
                        name="left_name"
                        value={edititem.left_name}
                        className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                        onChange={handleEditChange}
                        required
                      />
                    </div>

                    <div className="">
                      <label
                        htmlFor="right_name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Right Name
                      </label>
                      <input
                        type="text"
                        id="right_name"
                        name="right_name"
                        value={edititem.right_name}
                        className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                        onChange={handleEditChange}
                        required
                      />
                    </div>

                    {/* <div className="">
                      <label
                        htmlFor="left_image"
                        className="block text-sm font-medium text-gray-700"
                      >
                       Left Image
                      </label>
                      <input
                        type="file"
                        id="left_image"
                        name="left_image"
                        className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                        onChange={handleEditFileChange}
                        required
                      />
                    </div>

                    <div className="">
                      <label
                        htmlFor="right_image"
                        className="block text-sm font-medium text-gray-700"
                      >
                       Right Image
                      </label>
                      <input
                        type="file"
                        id="right_image"
                        name="right_image"
                        className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                        onChange={handleEditFileChange}
                        required
                      />
                    </div> */}


                    <div className="">
                    <label className="block text-sm font-medium text-gray-700">
                    STATUS
                    </label>
                    <select
                      id="is_active"
                      name="is_active"
                      value={edititem?.is_active}
                      className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                      onChange={handleEditChange}
                     
                    >
                      <option value="">Select Status</option>
                      <option value="true">Active</option>
                      <option value="false">Inactive</option>
                    </select>
                  </div>
                   

                    
                  </div>

                  <div className="flex justify-end mt-10">
                    <Button type="submit">Update Certificate</Button>
                  </div>
                </form>
              </Dialog.Panel>
            </div>
          </Dialog>
        )}
      </div>
    </>
  );
}

export default CertificatePage;
