import React, { useState, useEffect } from "react";
import {
  FaUsers,
  FaTruck,
  FaWarehouse,
  FaCut,
  FaPen,
  FaDollarSign,
} from "react-icons/fa";
import { GiSheep } from "react-icons/gi";
import { motion } from "framer-motion";
import { Line } from "react-chartjs-2";
import "chart.js/auto";
import { Tooltip } from "react-tooltip";
import ResuableInputField from "../../../../components/ResuableInputField";
import { useGetAnimal } from "../../../settings/AnimalTypes/hooks/useGetAnimal";
import { useGetPackagesHook } from "../../../settings/Packages/hooks/useGetPackagesHook";
import { axios } from "../../../../config/axios";

const Sales = () => {
  const {
    packages,
    isError: pkgError,
    isLoading: pkgLoading,
  } = useGetPackagesHook();
  const { animals, isError, isLoading } = useGetAnimal();

  const endDateDefault = new Date().toISOString().split("T")[0];
  const startDateDefault = new Date(Date.now() - 30 * 24 * 60 * 60 * 1000)
    .toISOString()
    .split("T")[0];

  
  const [formData, setFormData] = useState({
    animal_type_id: '',
    package_type_id: '',
    startDate: startDateDefault,
    endDate: endDateDefault,
    view_all:false
  });
  const [salesData, setSalesData] = useState(null);

  const handleChange = async (e) => {
    const { name, value } = e.target;
    const updatedFormData = {
      ...formData,
      [name]: value,
    };

    setFormData(updatedFormData);

    // Fetch sales data based on any field change
    await fetchSalesData(updatedFormData);
  };

  const fetchSalesData = async (params) => {
    const token = JSON.parse(localStorage.getItem("token"));  // Retrieve token from localStorage
     // Include view_all parameter manually if it is false
     const filteredParams = Object.fromEntries(Object.entries(params).filter(([key, value]) => value || key === 'view_all'));


    try {
      const response = await axios.get('reports/sales', {
        params: filteredParams,
        headers: {
          Authorization: `Bearer ${token}`,  // Set the Authorization header with the token
        },
      });
      setSalesData(response.data.data);
    } catch (error) {
      console.error('Failed to fetch sales data:', error);
    }
  };

  useEffect(() => {
    // Fetch initial sales data
    fetchSalesData(formData);
  }, []);

  

  const cardVariants = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: { opacity: 1, scale: 1, transition: { duration: 0.5 } },
  };

  const chartData = {
    labels: ["January", "February", "March", "April", "May", "June", "July"],
    datasets: [
      {
        label: "Sales",
        data: [65, 59, 80, 81, 56, 55, 40],
        fill: false,
        backgroundColor: "rgba(75,192,192,0.4)",
        borderColor: "rgba(75,192,192,1)",
      },
    ],
  };


  return (
    <div className=" bg-gray-100 p-6">
      <div className="max-w-7xl mx-auto">
        <h1 className="text-4xl font-bold mb-6 text-gray-700">Dashboard</h1>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-6">
          <motion.div
            variants={cardVariants}
            initial="hidden"
            animate="visible"
            className="bg-white shadow-lg rounded-lg p-6 flex items-center hover:shadow-2xl transition-shadow duration-300 relative"
          >
            <FaUsers className="text-indigo-600 text-4xl mr-4" />
            <div>
              <h2 className="text-xl font-semibold mb-1 text-indigo-600">
                Customers
              </h2>
              <p className="text-3xl font-bold text-gray-700">
              {salesData?.users?.customers || 0}
              </p>
            </div>
            <div
              data-tooltip-id="customers-tooltip"
              data-tooltip-content="Total number of customers"
              className="absolute top-2 right-2 text-gray-400 cursor-pointer"
            >
              i
            </div>
            <Tooltip id="customers-tooltip" />
          </motion.div>
          <motion.div
            variants={cardVariants}
            initial="hidden"
            animate="visible"
            className="bg-white shadow-lg rounded-lg p-6 flex items-center hover:shadow-2xl transition-shadow duration-300 relative"
          >
            <FaTruck className="text-indigo-600 text-4xl mr-4" />
            <div>
              <h2 className="text-xl font-semibold mb-1 text-indigo-600">
                Suppliers
              </h2>
              <p className="text-3xl font-bold text-gray-700">
              {salesData?.users?.suppliers || 0}
              </p>
            </div>
            <div
              data-tooltip-id="suppliers-tooltip"
              data-tooltip-content="Total number of suppliers"
              className="absolute top-2 right-2 text-gray-400 cursor-pointer"
            >
              i
            </div>
            <Tooltip id="suppliers-tooltip" />
          </motion.div>
          <motion.div
            variants={cardVariants}
            initial="hidden"
            animate="visible"
            className="bg-white shadow-lg rounded-lg p-6 flex items-center hover:shadow-2xl transition-shadow duration-300 relative"
          >
            <FaWarehouse className="text-indigo-600 text-4xl mr-4" />
            <div>
              <h2 className="text-xl font-semibold mb-1 text-indigo-600">
                Feedlots
              </h2>
              <p className="text-3xl font-bold text-gray-700">
              {salesData?.users?.feedlots || 0}
              </p>
            </div>
            <div
              data-tooltip-id="feedlots-tooltip"
              data-tooltip-content="Total number of feedlots"
              className="absolute top-2 right-2 text-gray-400 cursor-pointer"
            >
              i
            </div>
            <Tooltip id="feedlots-tooltip" />
          </motion.div>
          <motion.div
            variants={cardVariants}
            initial="hidden"
            animate="visible"
            className="bg-white shadow-lg rounded-lg p-6 flex items-center hover:shadow-2xl transition-shadow duration-300 relative"
          >
            <FaCut className="text-indigo-600 text-4xl mr-4" />
            <div>
              <h2 className="text-xl font-semibold mb-1 text-indigo-600">
                Slaughters
              </h2>
              <p className="text-3xl font-bold text-gray-700">
              {salesData?.users?.slaughters || 0}
              </p>
            </div>
            <div
              data-tooltip-id="slaughters-tooltip"
              data-tooltip-content="Total number of slaughters"
              className="absolute top-2 right-2 text-gray-400 cursor-pointer"
            >
              i
            </div>
            <Tooltip id="slaughters-tooltip" />
          </motion.div>
          <motion.div
            variants={cardVariants}
            initial="hidden"
            animate="visible"
            className="bg-white shadow-lg rounded-lg p-6 flex items-center hover:shadow-2xl transition-shadow duration-300 relative"
          >
            <FaPen className="text-indigo-600 text-4xl mr-4" />
            <div>
              <h2 className="text-xl font-semibold mb-1 text-indigo-600">
                Reporters
              </h2>
              <p className="text-3xl font-bold text-gray-700">
              {salesData?.users?.reporters || 0}
              </p>
            </div>
            <div
              data-tooltip-id="reporters-tooltip"
              data-tooltip-content="Total number of reporters"
              className="absolute top-2 right-2 text-gray-400 cursor-pointer"
            >
              i
            </div>
            <Tooltip id="reporters-tooltip" />
          </motion.div>
          <motion.div
            variants={cardVariants}
            initial="hidden"
            animate="visible"
            className="bg-white shadow-lg rounded-lg p-6 flex items-center hover:shadow-2xl transition-shadow duration-300 relative"
          >
            <FaUsers className="text-indigo-600 text-4xl mr-4" />
            <div>
              <h2 className="text-xl font-semibold mb-1 text-indigo-600">
                Total Users
              </h2>
              <p className="text-3xl font-bold text-gray-700">
              {salesData?.users?.total || 0}
              </p>
            </div>
            <div
              data-tooltip-id="total-users-tooltip"
              data-tooltip-content="Total number of users"
              className="absolute top-2 right-2 text-gray-400 cursor-pointer"
            >
              i
            </div>
            <Tooltip id="total-users-tooltip" />
          </motion.div>
        </div>

        <div className="grid grid-cols-12 gap-4 mb-4">
          <div className="col-span-12 sm:col-span-3">
            <ResuableInputField
              className="focus:border-pacific-blue focus:outline-none"
              type={"date"}
              label={"Start Date"}
              name="startDate"
              onChange={handleChange}
              value={formData?.startDate}
            />
          </div>
          <div className="col-span-12 sm:col-span-3">
            <ResuableInputField
              className="focus:border-pacific-blue focus:outline-none"
              type={"date"}
              label={"End Date"}
              name="endDate"
              onChange={handleChange}
              value={formData?.endDate}
            />
          </div>
         <div className="col-span-12 sm:col-span-3">
          <ResuableInputField
            className="focus:border-pacific-blue focus:outline-none"
            type={"select"}
            label={"Animal Type"}
            name="animal_type_id"
            onChange={handleChange}
            value={formData.animal_type_id}
            options={
              animals?.map((pkg) => ({
                value: pkg.id,
                label: pkg.name,
              })) || []
            }
          />
        </div>
        <div className="col-span-12 sm:col-span-3">
          <ResuableInputField
            className="focus:border-pacific-blue focus:outline-none"
            type={"select"}
            label={"Package Type"}
            name="package_type_id"
            onChange={handleChange}
            value={formData.package_type_id}
            options={
              packages?.map((pkg) => ({
                value: pkg.id,
                label: pkg.name,
              })) || []
            }
          />
        </div>
          {/* <div className="col-span-12 sm:col-span-6 flex items-end justify-end">
          <Search />
        </div> */}
        </div> 

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
          <motion.div
            variants={cardVariants}
            initial="hidden"
            animate="visible"
            className="bg-white shadow-lg rounded-lg p-6 flex items-center hover:shadow-2xl transition-shadow duration-300 relative"
          >
            {/* <FaDollarSign className="text-green-600 text-4xl mr-4" /> */}
            <span className="text-green-600 text-4xl mr-4 font-bold">RM</span>

            <div>
              <h2 className="text-xl font-semibold mb-1 text-green-600">
                Total Sales
              </h2>
              <p className="text-3xl font-bold text-gray-700">
              {salesData?.totalSales || 0}
              </p>
            </div>
            <div
              data-tooltip-id="total-sales-tooltip"
              data-tooltip-content="Total sales amount"
              className="absolute top-2 right-2 text-gray-400 cursor-pointer"
            >
              i
            </div>
            <Tooltip id="total-sales-tooltip" />
          </motion.div>
          <motion.div
            variants={cardVariants}
            initial="hidden"
            animate="visible"
            className="bg-white shadow-lg rounded-lg p-6 flex items-center hover:shadow-2xl transition-shadow duration-300 relative"
          >
            {/* <GiSheep className="text-green-600 text-4xl mr-4" /> */}
            <span className="text-green-600 text-4xl mr-4 font-bold">RM</span>
            <div>
              <h2 className="text-xl font-semibold mb-1 text-green-600">
                Total Qurban Sales
              </h2>
              <p className="text-3xl font-bold text-gray-700">
              {salesData?.totalQurbanSales || 0}
              </p>
            </div>
            <div
              data-tooltip-id="total-qurban-sales-tooltip"
              data-tooltip-content="Total Qurban sales amount"
              className="absolute top-2 right-2 text-gray-400 cursor-pointer"
            >
              i
            </div>
            <Tooltip id="total-qurban-sales-tooltip" />
          </motion.div>
          <motion.div
            variants={cardVariants}
            initial="hidden"
            animate="visible"
            className="bg-white shadow-lg rounded-lg p-6 flex items-center hover:shadow-2xl transition-shadow duration-300 relative"
          >
            {/* <GiSheep className="text-green-600 text-4xl mr-4" /> */}
            <span className="text-green-600 text-4xl mr-4 font-bold">RM</span>
            <div>
              <h2 className="text-xl font-semibold mb-1 text-green-600">
                Total Aqiqah Sales
              </h2>
              <p className="text-3xl font-bold text-gray-700">
              {salesData?.totalAqiqahSales || 0}
              </p>
            </div>
            <div
              data-tooltip-id="total-aqiqah-sales-tooltip"
              data-tooltip-content="Total Aqiqah sales amount"
              className="absolute top-2 right-2 text-gray-400 cursor-pointer"
            >
              i
            </div>
            <Tooltip id="total-aqiqah-sales-tooltip" />
          </motion.div>
          <motion.div
            variants={cardVariants}
            initial="hidden"
            animate="visible"
            className="bg-white shadow-lg rounded-lg p-6 flex items-center hover:shadow-2xl transition-shadow duration-300 relative"
          >
            {/* <FaDollarSign className="text-green-600 text-4xl mr-4" /> */}
            <span className="text-green-600 text-4xl mr-4 font-bold">RM</span>

            <div>
              <h2 className="text-xl font-semibold mb-1 text-green-600">
                Total Sadaqah Sales
              </h2>
              <p className="text-3xl font-bold text-gray-700">
              {salesData?.totalWaqfSales || 0}
              </p>
            </div>
            <div
              data-tooltip-id="total-Sadaqah-sales-tooltip"
              data-tooltip-content="Total Sadaqah sales amount"
              className="absolute top-2 right-2 text-gray-400 cursor-pointer"
            >
              i
            </div>
            <Tooltip id="total-Sadaqah-sales-tooltip" />
          </motion.div>
        </div>

      

        <div className="mt-5">
          <table className="w-full text-sm overflow-x-auto text-left rtl:text-right text-gray-500 ">
            <thead className="text-xs text-pacific-blue uppercase bg-[#CFF0F2] ">
              <tr>
                <th scope="col" className="px-6 py-3 text-base font-medium ">
                  <div className="flex gap-1 items-center">Order Id</div>
                </th>
                <th scope="col" className="px-6 py-3 text-base font-medium ">
                  <div className="flex gap-1 items-center">Customer Name</div>
                </th>
                <th scope="col" className="px-6 py-3 text-base font-medium">
                  <div className="flex gap-1 items-center">Email</div>
                </th>
                {/* <th scope="col" className="px-6 py-3 text-base font-medium">
                  <div className="flex gap-1 items-center">Ruminant Type</div>
                </th> */}

                <th scope="col" className="px-6 py-3 text-base font-medium">
                  <div className="flex gap-1 items-center">Ic Number</div>
                </th>

                <th scope="col" className="px-6 py-3 text-base font-medium">
                  <div className="flex gap-1 items-center">Payment Status</div>
                </th>

                <th scope="col" className="px-6 py-3 text-base font-medium">
                  <div className="flex gap-1 items-center">Package Name</div>
                </th>

                <th scope="col" className="px-6 py-3 text-base font-medium">
                  <div className="flex gap-1 items-center">Price</div>
                </th>

                {/* <th scope="col" className="px-6 py-3 text-base font-medium">
                  <div className="flex gap-1 items-center">Price</div>
                </th>

                <th scope="col" className="px-6 py-3 text-base font-medium">
                  <div className="flex gap-1 items-center">Price/Unit</div>
                </th>

                <th scope="col" className="px-6 py-3 text-base font-medium">
                  <div className="flex gap-1 items-center">Actual Price</div>
                </th>

                <th scope="col" className="px-6 py-3 text-base font-medium">
                  <div className="flex gap-1 items-center">Status</div>
                </th> */}
              </tr>
            </thead>
            <tbody>
            {salesData?.orders?.map((order) => (
                <tr className="bg-white border-b " key={order.id}>
                  <th scope="row" className="px-6 py-4 text-sm font-medium">
                    {order.ref_id}
                  </th>
                  <td className="px-6 py-4 text-sm font-medium">
                    {order.customer.fullname}
                  </td>
                  <td className="px-6 py-4 text-sm font-medium">
                    {order.customer.email}
                  </td>
                  <td className="px-6 py-4 text-sm font-medium">
                    {order.customer.ic_number}
                  </td>
                  <td className="px-6 py-4 text-sm font-medium">
                    {order?.payment_status}
                  </td>
                  <td className="px-6 py-4 text-sm font-medium">
                    {order.package.name}
                  </td>
                  <td className="px-6 py-4 text-sm font-medium">
                    {order.price ? "RM" : ""} {(Number(order.price) || 0).toFixed(2)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {/* <div className="bg-white shadow-lg rounded-lg p-6 mt-6">
          <h2 className="text-xl font-semibold mb-4 text-gray-700">
            Sales Overview
          </h2>
          <Line data={chartData} />
        </div> */}
      </div>
    </div>
  );
};

export default Sales;
