import { useQuery } from "@tanstack/react-query";
import { api_keys } from "../../../../config/constants";
import { getAllAnimals } from "../../../../services/settings";

export const useGetAnimal = () => {
	const {
		data: animals,
		isError,
		isLoading,
		refetch,
	} = useQuery({
		queryKey: [api_keys.GET_ANIMALS ],
		queryFn: () => getAllAnimals(),
	});

	return {
		animals,
		isError,
		isLoading,
		refetch,
	};
};

