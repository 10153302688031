import React from "react";

export const NotFound = () => {
  return (
    <div>
      <div className="flex justify-center items-center h-[80vh]">
        <div className="text-2xl font-bold text-gray-500">404 | Not Found</div>
      </div>
    </div>
  );
};
