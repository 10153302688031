import {
	orderIcon,
	packageIcon,
	profileIcon,
	usersIcon,
	rightArrowIcon,
} from "../assets/icons";

export const roleRoutes = {
	ADMIN: [
		{
			id: 0,
			path: "/AdminDashboard",
			pathname: "Dashboard",
			icon: profileIcon,
		},
		{
			id: 1,
			path: "/profile",
			pathname: "Profile",
			icon: profileIcon,
		},

		{
			id: 2,
			path: "",
			pathname: "User Management",
			icon: usersIcon,
			arrowIcon: rightArrowIcon,
			childMenu: [
				{
					id: 0,
					path: "/customers",
					pathname: "Customers",
					icon: usersIcon,
				},
				{
					id: 1,
					path: "/suppliers",
					pathname: "Suppliers",
					icon: usersIcon,
				},
				
				{
					id: 5,
					path: "/reporter",
					pathname: "Reporter",
					icon: usersIcon,
				},
				{
					id: 6,
					path: "/slaughter",
					pathname: "Slaughter",
					icon: usersIcon,
				},
			]}
			,

			{
				id: 3,
				path: "/feedlot",
				pathname: "Feedlot",
				icon: usersIcon,
			},
			{
				id: 4,
				path: "/claimlist",
				pathname: "ClaimList",
				icon: usersIcon,
			},
		
		{
			id: 5,
			path: "/orders",
			pathname: "Orders",
			icon: orderIcon,
		},
		
		{
			id: 5,
			path: "/Refund",
			pathname: "Refund",
			icon: orderIcon,
		},
		
		{
			id: 6,
			path: "/cattle",
			pathname: "Ruminant",
			icon: usersIcon,
		},

		{
			id: 7,
			path: "/settings",
			pathname: "Package Management",
			icon: usersIcon,
			arrowIcon: rightArrowIcon,
			childMenu: [
				{
					id: 0,
					path: "/settings/packages",
					pathname: "Master Packages",
					icon: packageIcon,
				},
				{
					id: 1,
					path: "/settings/animal-types",
					pathname: "Ruminant Types",
					icon: usersIcon,
				},
				{
					id: 2,
					path: "/settings/package-details",
					pathname: "Qurban/Aqiqah",
					icon: usersIcon,
				},
				{
					id: 3,
					path: "/settings/sadakah-details",
					pathname: "Sadakah Packages",
					icon: usersIcon,
				},
			],
		},
		{
			id: 8,
			path: "/countries",
			pathname: "Countries",
			icon: usersIcon,
			arrowIcon: rightArrowIcon,
			childMenu: [
				{
					id: 6,
					path: "/countries",
					pathname: "countries",
					icon: usersIcon,
				},
				{
					id: 7,
					path: "/countries/states",
					pathname: "States",
					icon: usersIcon,
				},
				{
					id: 8,
					path: "countries/regions",
					pathname: "Regions",
					icon: usersIcon,
				},
			],
		},
		// {
		// 	id: 8,
		// 	path: "/transaction",
		// 	pathname: "Transaction",
		// 	icon: usersIcon,
		// 	arrowIcon: rightArrowIcon, 
		// },
		{
			id: 9,
			path: "/analytics",
			pathname: "Analytics",
			icon: usersIcon,
			arrowIcon: rightArrowIcon,
			childMenu: [
				// {
				// 	id: 1,
				// 	path: "/analytics/orders",
				// 	pathname: "Transaction",
				// 	icon: usersIcon,
				// },
				{
					id: 1,
					path: "/analytics/transaction",
					pathname: "Transaction",
					icon: usersIcon,
				},
				{
					id: 2,
					path: "/analytics/sales",
					pathname: "Sales",
					icon: usersIcon,
				},
				{
					id: 3,
					path: "analytics/stock",
					pathname: "Stock",
					icon: usersIcon,
				},
			],
		},
		// {
		// 	id: 10,
		// 	path: "/tasks",
		// 	pathname: "Tasks",
		// 	icon: usersIcon,
		// 	arrowIcon: rightArrowIcon, 
		// },
		// {
		// 	id: 11,
		// 	path: "/support-center",
		// 	pathname: "Support Center",
		// 	icon: usersIcon,
		// 	arrowIcon: rightArrowIcon, 
		// },
		// {
		// 	id: 12,
		// 	path: "/DashboardMenu",
		// 	pathname: "Dashboard",
		// 	icon: usersIcon,
		// 	arrowIcon: rightArrowIcon, 
		// },
		// {
		// 	id: 13,
		// 	path: "/reporter",
		// 	pathname: "Reporter",
		// 	icon: usersIcon,
		// 	arrowIcon: rightArrowIcon, 
		// },
		{
			id: 14,
			path: "",
			pathname: "Setting",
			icon: usersIcon,
			arrowIcon: rightArrowIcon,
			childMenu: [
				{
					id: 1,
					path: "/calenderpage",
					pathname: "Qurban Calender",
					icon: usersIcon,
				},

				{
					id: 2,
					path: "/setpriceexecuter",
					pathname: "Executer Price",
					icon: usersIcon,
				},

				{
					id: 3,
					path: "/organization",
					pathname: "Organization",
					icon: usersIcon,
				},
				{
					id: 3,
					path: "/organizationtype",
					pathname: "Organization Type",
					icon: usersIcon,
				},

				{
					id: 3,
					path: "/certificate",
					pathname: "Certificate",
					icon: usersIcon,
				},

				
			]
		},
		
	],
	SUPPLIER: [
		{
			id: 13,
			path: "/feedlot",
			pathname: "Feedlot",
			icon: usersIcon,
		},

		{
			id: 14,
			path: "/cattle",
			pathname: "Ruminant",
			icon: usersIcon,
		},
	],
	FEEDLOT: [
		{
			id: 15,
			path: "/cattle",
			pathname: "Ruminant",
			icon: usersIcon,
		},
	],
	SLAUGHTER:[
		{
			id: 1,
			path: "/DashboardMenu",
			pathname: "Dashboard",
			icon: usersIcon,
			arrowIcon: rightArrowIcon, 
		},
		{
			id: 2,
			path: "/tasks",
			pathname: "Tasks",
			icon: usersIcon,
			arrowIcon: rightArrowIcon, 
		},
		{
			id: 3,
			path: "/transaction",
			pathname: "Transaction",
			icon: usersIcon,
			arrowIcon: rightArrowIcon, 
		},
		{
			id: 4,
			path: "/support-center",
			pathname: "Support Center",
			icon: usersIcon,
			arrowIcon: rightArrowIcon, 
		},
	],
	REPORTER:[
		{
			id: 1,
			path: "/DashboardMenu",
			pathname: "Dashboard",
			icon: usersIcon,
			arrowIcon: rightArrowIcon, 
		},
		{
			id: 2,
			path: "/tasksreporter",
			pathname: "Tasks",
			icon: usersIcon,
			arrowIcon: rightArrowIcon, 
		},
		{
			id: 3,
			path: "/transaction",
			pathname: "Transaction",
			icon: usersIcon,
			arrowIcon: rightArrowIcon, 
		},
		{
			id: 4,
			path: "/support-center",
			pathname: "Support Center",
			icon: usersIcon,
			arrowIcon: rightArrowIcon, 
		},
	]
};
