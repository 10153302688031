
import { useQuery } from "@tanstack/react-query";
import { api_keys } from "../../../config/constants";
import { getAnalyticsOrders, getAnalyticsSales, getAnalyticsStock } from "../../../services/analytics";

// getAnalyticsStock
export const useFetchAnalyticsStock = () => {
  const {
    data: stock,
    isError,
    isLoading,
  } = useQuery({
    queryKey: [api_keys.GET_ANALYTICS_STOCK],
    queryFn: () => getAnalyticsStock(),
  });
  return {
    stock,
    isError,
    isLoading,
  };
};

// getAnalyticsOrders
export const useFetchAnalyticsOrders = (startDate, endDate) => {
  const {
    data: orders,
    isError,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: [api_keys.GET_ANALYTICS_ORDERS,  startDate, endDate],
    queryFn: () => { 
      return getAnalyticsOrders(startDate, endDate);
    },
  });
  return {
    orders,
    isError,
    isLoading,
    refetch,
  };
}; 

// getAnalyticsSales
export const useFetchAnalyticsSales = (startDate, endDate) => {
  const {
    data: sales,
    isError,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: [api_keys.GET_ANALYTICS_SALES,  startDate, endDate],
    queryFn: () => { 
      return getAnalyticsSales(startDate, endDate);
    },
  });
  return {
    sales,
    isError,
    isLoading,
    refetch,
  };
}; 
