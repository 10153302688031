import { axios } from "../../config/axios";
import { config } from "../../config/config";
import { endpoint } from "../../config/constants";
import { formatDate } from "../../utils/formatDate";

export const getAllRuminantPrice = async () => {
  const token = JSON.parse(localStorage.getItem("token"));

  const response = await axios.get(endpoint.GET_EXECUTER_SET_PRICE, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.data) {
    throw new Error("No data found");
  }

  // Uncomment and use if data transformation is needed
  // const transformedData = response.data.data.map((data) => {
  //   const tobeDeleted = ["updated_at"];
  //   tobeDeleted.forEach((key) => delete data[key]);
  //   return {
  //     ...data,
  //     created_at: formatDate(data.created_at),
  //   };
  // });

  return response.data;
};

export const createRuminantPrice = async (data) => {
  const token = JSON.parse(localStorage.getItem("token"));
  
  const response = await axios.post(endpoint.GET_EXECUTER_SET_PRICE, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.data) {
    throw new Error("No data found");
  }

  return response;
};

// Uncomment and use if needed
// export const updatePkgDetails = async (id, data) => {
//   const token = JSON.parse(localStorage.getItem("token"));
//   const response = await axios.put(endpoint.GET_PKG_DETAILS + `/${id}`, data, {
//     headers: {
//       Authorization: `Bearer ${token}`,
//     },
//   });
//   if (!response.data) {
//     throw new Error("No data found");
//   }
//   return response.data;
// };
