import React from 'react'
import Button from '../../components/Button/Button'
import MoneyverseBusinessAnalytics from "../../assets/images/RequestReceived/Moneyverse Business Analytics.png"
import { Link } from 'react-router-dom'
import { formatDateWithoutTime } from '../../utils/formatDate'
import { axios } from '../../config/axios'
import { toast } from 'react-toastify'




const TotalEarned = ({changeStep,itemData}) => {
  const user = JSON.parse(localStorage.getItem("user"));



  const ApplyClaim = async (data) => {
    try {
      const token = JSON.parse(localStorage.getItem("token"));
      if (!token) {
        toast.error("No token found");
        return;
      }
  
      const response = await axios.post(
        "executor/claim-payment", // Corrected URL
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json', // Ensure Content-Type is set
          },
        }
      );
  
      if (!response?.data) {
        toast.error("Claim Failed");
      } else {

        toast.success("Apply for claim Successful");
        changeStep(0)

      }
    } catch (error) {
      console.error(error);
      toast.error("An error occurred");
    }
  };
  
  

  return (
    <>   
      <div className="flex flex-col items-center min-h-screen px-3 md:mt-24 mt-10 mb-[200px] md:mb-56">
          <div className="flex flex-col items-center border border-quill-grey rounded-xl bg-white md:w-[503px] w-full p-4 ">
            <img
              src={MoneyverseBusinessAnalytics}
              className="h-40 mb-7"
              alt="Profile Verification"
            />
            <span className="md:text-lg text-base text-dark-teal  font-medium">Claim Amount</span>
              <div className="flex my-2 items-end text-pacific-blue">
                <p className="text-xs md:text-2xl font-medium">RM </p>
                <p className="text-xl md:text-4xl font-semibold">{(itemData?.executor_payable?.amount_payable)*(itemData?.reporter_order?.order?.total_portions)}</p>
              </div>
            {/* <span className=" text-xs text-star-dust mb-5 mt-1 font-medium">{formatDateWithoutTime(itemData?.reporter_order?.created_at)}</span> */}



            <div className='bg-[#EBEBEB] rounded-lg w-[95%] p-4'> 
              <div className='flex w-full justify-between pb-3'>
                <p className='md:text-sm text-xs font-medium text-[#525252]'>Type of task</p>
                <p className='md:text-sm text-xs font-medium text-pacific-blue'>{itemData?.order?.package?.name}</p>
              </div>


            


              <div className='flex w-full justify-between pb-3'>
                <p className='md:text-sm text-xs font-medium text-[#525252]'>Recipient</p>
                <p className='md:text-sm text-xs font-medium text-pacific-blue'>{user?.fullname}</p>
              </div>

              <div className='flex w-full justify-between pb-3'>
                <p className='md:text-sm text-xs font-medium text-[#525252]'>IC Number </p>
                <p className='md:text-sm text-xs font-medium text-pacific-blue'>{user?.ic_number}</p>
              </div>

               <div className='flex w-full justify-between '>
                <p className='md:text-sm text-xs font-medium text-[#525252]'>Phone Number</p>
                <p className='md:text-sm text-xs font-medium text-pacific-blue'>{user?.phone_no}</p>
              </div>
            </div>


            <div className='w-full flex md:flex-row flex-col-reverse md:gap-5 '>

          



            <Button
              className="w-full px-32 mt-4"
              onClick={()=>
              {
                const data={  executor_payable_id: itemData?.executor_payable?.id,
                  task_id: itemData?.id }

                  console.log(data)
                ApplyClaim(data)
              }}
            >
             Claim Now
            </Button>

</div>
          </div>
        </div>
  </>
  )
}

export default TotalEarned