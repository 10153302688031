import { useQuery } from "@tanstack/react-query";
import { api_keys } from "../../../../config/constants";
import {
  getAllPackagesDetails,
} from "../../../../services/settings";

export const useGetPkgDetails = () => {
  const {
    data: PackageDetail,
    isError,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: [api_keys.GET_PKG_DETAILS],
    queryFn: () => getAllPackagesDetails(),
  });
  return {
    PackageDetail,
    isError,
    isLoading,
    refetch,
  };
};
