// getFeedlotDetails
import { useQuery } from "@tanstack/react-query";
import { api_keys } from "../../../config/constants";
import {
  getAllCountries,
  getAllCountryStates,
  getAllStateRegions,
  updateRegionStatus,
  updateStateStatusApi,
} from "../../../services/countries";

// getAllCountries
export const useFetchCountires = () => {
  const {
    data: countries,
    isError,
    isLoading,
    refetch
  } = useQuery({
    queryKey: [api_keys.GET_COUNTRIES],
    queryFn: () => getAllCountries(),
  });
  return {
    countries,
    isError,
    isLoading,
    refetch
  };
};

export const useFetchCountryStates = (id) => {
  const {
    data: states,
    isError,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: [api_keys.GET_COUNTRY_STATES, id],
    queryFn: () => {
     
      return getAllCountryStates(id);
    },
  });
  return {
    states,
    isError,
    isLoading,
    refetch,
  };
};
export const useFetchCountryStateRegions = (id) => {
  const {
    data: regions,
    isError,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: [api_keys.GET_STATE_REGIONS, id],
    queryFn: () => {
 
      return getAllStateRegions(id);
    },
  });
  return {
    regions,
    isError,
    isLoading,
    refetch,
  };
};

export const updateRegionStaus = async (id, status) => {
  const response = await updateRegionStatus(id, status);
  return response;
};

export const updateStateStatus = async (id, status) => {
  const response = await updateStateStatusApi(id, status);
  return response;
};
