import { axios } from "../../config/axios";
import { endpoint } from "../../config/constants";
import { formatDate } from "../../utils/formatDate";

export const getAllReporters = async (pageNumber = 1, limit = 10) => {
	const token = JSON.parse(localStorage.getItem("token"));

	const response = await axios.get(
		endpoint.GET_REPORTERS + `&pageNumber=${pageNumber}&pageSize=${limit}`,
		{
			headers: {
				Authorization: `Bearer ${token}`,
			},
		}
	);
	if (!response.data) {
		throw new Error("No data found");
	}
	
	const transformedData = response?.data?.data?.data.map((data) => {
	    data.country = data?.country?.name || "N/A"
    data.state = data?.state?.name || "N/A"
    data.region = data?.region?.name || "N/A"
    const tobeDeleted = ["address", "password","supplier_id","role" ];
    tobeDeleted.forEach((key) => delete data[key]);
		return {
			...data,
			created_at: formatDate(data.created_at),
			updated_at: formatDate(data.updated_at),
		};
	});
	console.log("transformedData");
	console.log(transformedData);
	const { data, ...rest } = response?.data?.data?.data;
	return {
		data: transformedData,
		...rest,
	};
};



export const getExecutor = async () => {
	

	const token = JSON.parse(localStorage.getItem("token"));
	const response = await axios.get(endpoint.Get_EXECUTOR, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});

	if (!response.data) {
		throw new Error("No data found");
	}

	return response.data;
};


export const AsignOrderToReporter = async (data) => {
	const token = JSON.parse(localStorage.getItem("token"));

	const response = await axios.post(endpoint.Get_EXECUTOR, data, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
	if (!response.data) {
		// throw new Error("No data found");
		console.log(response)

		return(response)
	}
	else
	return response.data;
};


// export const getFeedlotDetails = async (id) => {
// 	const token = JSON.parse(localStorage.getItem("token"));
// 	const response = await axios.get(endpoint.GET_USER_DETAILS + `${id}`, {
// 		headers: {
// 			Authorization: `Bearer ${token}`,
// 		},
// 	});
// 	if (!response.data) {
// 		throw new Error("No data found");
// 	}
// 	return response.data;
// };
