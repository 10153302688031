import { useQuery } from "@tanstack/react-query";
import { api_keys } from "../../../config/constants";
import { getSlaughter } from "../../../services/slaughter";


const useSlaughter = ({pageNumber, limit}) => {
    const {
        data: slaughter,
        isError,
        isLoading,
        refetch
      } = useQuery({
        queryKey: [api_keys.GET_USERS, pageNumber, limit],
        queryFn: () => getSlaughter(pageNumber, limit),
      });


      
      return {
        slaughter,
        isError,
        isLoading,
        refetch
      };
 
}

export default useSlaughter