import React, { useState, useEffect } from "react";
import {
  updateStateStatus,
  useFetchCountires,
  useFetchCountryStates,
} from "../hooks/useFetchCountries";
import HeaderSection from "../../../components/HeaderSection";
import DataTable from "../../../components/Table/DataTable";
import { notify } from "../../../utils/toasts";
import Search from "../../../components/Search";
import CreateOrUpdateState from "./CreateOrUpdateState";
import { useFetchStates, useUpdateState } from "../hooks/useCreateOrUpdateState";
import Button from "../../../components/Button/Button";
import { Dialog } from "@headlessui/react";
import { useForm } from "react-hook-form";
import jsonData from "../../../assets/jsonfile/countrydata.json";
import { axios } from "../../../config/axios";
import { toast } from "react-toastify";

const States = () => {
  let [isOpen, setIsOpen] = useState(false);
  const [selectedState, setSelectedState] = useState(null);
  const { countries } = useFetchCountires();
  const [selectedCountry, setSelectedCountry] = useState("");
  const [stateCodeReadable,setstateCodeReadable]=useState("")
  const [jsonStateList, SetjsonStateList] = useState([]);

  const { states, isError, isLoading, refetch } =
    useFetchCountryStates(selectedCountry);

  const {
    register: registerAddState,
    handleSubmit: handleAddState,
    formState: { errors: errorsAddState },
    setValue,
    reset, // Import reset method to clear form values
  } = useForm();

  const [selectedCode, setSelectedCode] = useState("");

  const handleStateChange = (event) => {
    const selectedState = event.target.value;
    const foundCode = CodeList.find((item) => item.State === selectedState);
    const codeValue = foundCode ? foundCode.Code : "";
    setSelectedCode(codeValue);
    setValue("code", codeValue);
  };


  const CodeList = [
    {
      "Code": "MY-00",
      "State": "All States"
    },
    {
      "Code": "MY-01",
      "State": "Johor"
    },
    {
      "Code": "MY-02",
      "State": "Kedah"
    },
    {
      "Code": "MY-03",
      "State": "Kelantan"
    },
    {
      "Code": "MY-04",
      "State": "Melaka"
    },
    {
      "Code": "MY-05",
      "State": "Negeri Sembilan"
    },
    {
      "Code": "MY-06",
      "State": "Pahang"
    },
    {
      "Code": "MY-07",
      "State": "Penang"
    },
    {
      "Code": "MY-08",
      "State": "Perak"
    },
    {
      "Code": "MY-09",
      "State": "Perlis"
    },
    {
      "Code": "MY-10",
      "State": "Selangor"
    },
    {
      "Code": "MY-11",
      "State": "Terengganu"
    },
    {
      "Code": "MY-12",
      "State": "Sabah"
    },
    {
      "Code": "MY-13",
      "State": "Sarawak"
    },
    {
      "Code": "MY-14",
      "State": "Kuala Lumpur"
    },
    {
      "Code": "MY-15",
      "State": "Labuan"
    },
    {
      "Code": "MY-16",
      "State": "Putrajaya"
    },
    {
      "Code": "MY-17",
      "State": "Not Applicable"
    }
  ]


  const handleCreateStateFunction = async (data) => {
debugger;

    const modifiedData = {
      ...data,
      country_id: Number(data.country_id)  // Convert country_id to number
    };
    console.log(modifiedData);


    const token = JSON.parse(localStorage.getItem("token"));

    try {
      if (selectedState) {
        // Update existing state
        const response = await axios.put(
          `/users/put/${selectedState.id}/states`,
          {
            status: data.status,
            country_id: Number(data.country_id),
            name: data.name,
            code: data.code
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        toast.success("State Updated");
      } else {
        // Create new state
        const response = await axios.post(
          `/users/add-states`,
          {
            country_id: Number(data.country_id),
            name: data.name,
            code: data.code
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        toast.success("State Created");
      }
      setIsOpen(false);
      refetch();
    } catch (error) {
      toast.error(error?.response?.data?.error || "Something went wrong");
      console.error("Error:", error);
    }

    // try {
    //   const response = await axios.post(
    //     `/users/add-states`,
    //     modifiedData,
    //     {
    //       headers: {
    //         Authorization: `Bearer ${token}`,
    //       },
    //     }
    //   );
    //   console.log(response);
    //   setIsOpen(false);

    //   toast.success("State Created");

    // } catch (error) {
    //   toast.error(error?.response?.data?.error || "Something went wrong");
    //   console.error("Error:", error);

    // }
    console.log("add item", data);
  };




  useEffect(() => {
    if (isOpen === false) {
      setSelectedState(null);
      refetch(); // Refetch states when modal is opened and a country is selected
    }
  }, [isOpen, refetch]);

  useEffect(() => {
    
    if (selectedState) {
      // Populate form with selected state data for updating
      setValue("country_id", selectedState.country_id);
      setValue("name", selectedState.name);
      setValue("code", selectedState.code);
      setValue("status", selectedState.enabled ? "true" : "false"); 
    }
  }, [selectedState, setValue]);

  const openUpdateDialog = (state) => {
    console.log(state);
    setSelectedState(state);
    setIsOpen(true);
  };

  if (isError) {
    return <h1 className="">An error occurred</h1>;
  }

  if (isLoading) {
    return <h1 className="">Please wait, fetching data</h1>;
  }

  return (
    <div>
      <div>

        <div className="flex flex-col md:flex-row md:items-end justify-between mb-3">
          <div className="md:w-[300px]">
            <label
              htmlFor="country_id"
              className="block text-sm font-medium text-gray-700"
            >
              Select Country
            </label>

            <select
              className="mt-1 p-2 w-full border border-gray-300 rounded-md"
              onChange={(e) => {
                setSelectedCountry(e.target.value)
                refetch()
                // setCountryName( e.target.options[selectedOptionIndex].text)
              }}
              value={selectedCountry}
            >
              <option value="">Select Option</option>
              {countries?.data?.map((option) => (
                <option key={option.id} value={option.id} name={option.name}>
                  {option.name}
                </option>
              ))}
            </select>
          </div>




        </div>

        <div className='flex justify-between mb-2'>
          <button className='font-bold' onClick={() => console.log(countries)}>
            State List
          </button>

          <Button type="button" onClick={() => {
            console.log(states?.data)
            setSelectedState(null); // Reset selected state
            reset(); // Clear form fields
            setIsOpen(true)
          }}>
            Add State
          </Button>
        </div>

        <table className="w-full text-sm overflow-x-auto text-left rtl:text-right text-gray-500 ">
          <thead className="text-xs text-pacific-blue uppercase bg-[#CFF0F2] ">
            <tr >
              <th scope="col" className="px-6 py-3 text-base font-medium ">
                <div className='flex gap-1 items-center'>
                  Id
                </div>
              </th>
              <th scope="col" className="px-6 py-3 text-base font-medium">
                <div className='flex gap-1 items-center'>
                  Country
                </div>
              </th>
              <th scope="col" className="px-6 py-3 text-base font-medium">
                <div className='flex gap-1 items-center'>
                  State
                </div>
              </th>
              <th scope="col" className="px-6 py-3 text-base font-medium">
                <div className='flex gap-1 items-center'>
                  Code
                </div>
              </th>
              <th scope="col" className="px-6 py-3 text-base font-medium">
                <div className='flex gap-1 items-center'>
                  Status
                </div>
              </th>
              <th scope="col" className="px-2 py-3 text-base font-medium">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {states?.data?.map((e) => {
              return (
                <tr className="bg-white border-b " key={e?.id}>
                  <th scope="row" className="px-6 py-4 text-sm font-medium">
                    {e?.id}
                  </th>
                  <td className="px-6 py-4 text-sm font-medium">
                    {e?.country?.name}
                  </td>
                  <td className="px-6 py-4 text-sm font-medium">
                    {e?.name}
                  </td>
                  <td className={`px-6 py-4 text-sm font-medium`}>
                    {e?.code}
                  </td>
                  <td className={`px-6 py-4 text-sm font-medium ${e?.enabled ? "text-green-500" : "text-red-500"}`}>
                    {e?.enabled ? "Active" : "In-Active"}
                  </td>
                  <td className="px-6 py-4 text-sm font-medium text-pacific-blue">
                    <Button className="px-8"
                      onClick={() => {
                        openUpdateDialog(e)
                        // setEditItem(e)
                        // isEditModal(true)
                        // console.log(edititem)
                      }}>
                      Edit
                    </Button>
                    
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>

        {/* Add state */}
        {isOpen &&
          <Dialog
            open={isOpen}
            onClose={() => setIsOpen(false)}
            className="fixed inset-0 z-50 overflow-y-auto p-4 duration-500 "
          >
            <div className="flex items-center justify-center min-h-screen">
              <Dialog.Overlay className="fixed inset-0 bg-black/30" />
              <Dialog.Panel className="relative max-w-[600px] w-full mx-auto bg-white p-4 rounded">
                <Dialog.Title className="text-lg font-medium mb-6 border-b-2 flex items-center justify-between">
                  <span className="">
                  {selectedState ? "Update State" : "Create State"}
                  </span>
                  <div
                    className="flex items-center justify-center "
                    onClick={() => setIsOpen(false)}
                  >
                    <h1 onClick={() => setIsOpen(false)} className="bg-gray-200 px-2 rounded-md mb-1 hover:text-red-600 cursor-pointer">
                      x
                    </h1>
                  </div>
                </Dialog.Title>
                <form onSubmit={handleAddState(handleCreateStateFunction)}>
                  <div className="grid grid-cols-1  sm:grid-cols-2 gap-4">
                    <div>
                      <label htmlFor="country_id"
                        className="block mt-1 font-medium text-gray-700">Country</label>
                      <select
                        type="select"
                        id="country_id"
                        name="country_id"
                        {...registerAddState("country_id", {
                          required: "Name is required",
                        })}
                        className="mt-1 p-2 w-full border border-gray-300 rounded-md"
                        onChange={(e) => {

                          const selectedOption = e.target.options[e.target.selectedIndex];
                          const countryName = selectedOption.innerText;
                          setstateCodeReadable(countryName)
                          // const countryData = Array.isArray(jsonData) ? jsonData.find((c) => c.country === countryName) : null;
                          // if (countryData) {
                          //   console.log(countryData.states)
                          //   SetjsonStateList(countryData.states);
                          // } else {
                          //   SetjsonStateList([]);
                          //   console.log(countryData)

                          // }
                        }}
                      >
                        <option value={""}>Select Option</option>
                        {countries?.data?.map((option) => (
                          <option key={option.id} value={option.id} name={option.name}>
                            {option.name}
                          </option>
                        ))}
                      </select>
                    </div>



                    <div>
                      <label htmlFor="name"
                        className="block mt-1 font-medium text-gray-700">State  {selectedCountry}</label>
                        <input
  type="text"
  id="name"
  name="name"
  {...registerAddState("name", {
    required: "Name is required",
  })}
  className="mt-1 p-2 w-full border border-gray-300 rounded-md"
  placeholder="Enter the state name"
  onChange={(e) => handleStateChange(e)}  // Handle state change
/>
{errorsAddState.name && (
                        <span className="text-red-clr">This field is required</span>
                      )}
                      {/* <select
                        type="select"
                        id="name"
                        name="name"
                        {...registerAddState("name", {
                          required: "Name is required",
                        })}
                        className="mt-1 p-2 w-full border border-gray-300 rounded-md"
                        onChange={(e) => handleStateChange(e)}
                      >
                        <option value={""}>Select Option</option>
                        {jsonStateList?.map((option) => (
                          <option key={option?.name} value={option?.name} name={option.name}>
                            {option.name}
                          </option>
                        ))}
                      </select> */}
                    </div>




                   {stateCodeReadable==="Malaysia"?
                    <div>
                      <label htmlFor="code" className="block mt-1 font-medium text-gray-700">
                        State Code {stateCodeReadable}
                      </label>
                      <input
                        type="text"
                        id="code"
                        value={selectedCode}
                        name="code"
                        {...registerAddState("code", {
                          required: "State code is required",
                        })}
                        readOnly 
                        className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                      />
                      {errorsAddState.code && (
                        <span className="text-red-clr">This field is required</span>
                      )}
                    </div>
                    :
                    <div>
                    <label htmlFor="code" className="block mt-1 font-medium text-gray-700">
                      State Code 
                    </label>
                    <input
                      type="text"
                      id="code"
                      name="code"
                      {...registerAddState("code", {
                        required: "State code is required",
                      })}
                      
                      className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                    />
                    {errorsAddState.code && (
                      <span className="text-red-clr">This field is required</span>
                    )}
                  </div>}


                  {selectedState && (
  <div className="mb-4">
    <label htmlFor="status" className="block text-sm font-medium text-gray-700">
      Status
    </label>
    <select
            id="status"
            {...registerAddState("status")}
            className="mt-1 p-2 w-full border border-gray-300 rounded-md"
            defaultValue={selectedState.status ? "true" : "false"} // Ensure status is a string
          >
            <option value="true">Active</option>
            <option value="false">Inactive</option>
          </select>
  </div>
)}





                  </div>
                  <div className="flex justify-end mt-10">
                    <Button type="submit">{selectedState ? "Update" : "Create"}</Button>
                  </div>
                </form>
              </Dialog.Panel>
            </div>
          </Dialog>}








        {/* <DataTable
          data={states.data || []}
          canUpdateStatus={true}
          updatefn={openUpdateDialog}
          canEdit={true}
        /> */}
        {/* <CreateOrUpdateState
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                stateDetails={selectedState}
                // refetch={refetch}
            /> */}
      </div>
    </div>
  );
};

export default States;
