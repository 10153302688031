import { axios } from "../../config/axios";
import { endpoint } from "../../config/constants";
import { formatDate } from "../../utils/formatDate";

export const getAllUsers = async (pageNumber = 1, limit = 10) => {
  const token = JSON.parse(localStorage.getItem("token"));

  const response = await axios.get(
    endpoint.GET_USERS + `&pageNumber=${pageNumber}&pageSize=${limit}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  if (!response.data) {
    throw new Error("No data found");
  }

  return {
    data: response?.data?.data
  };
};
