import React from "react";
import { Link } from "react-router-dom";
const ThankYou = () => {
	return (
		<div className="min-h-screen flex sm:items-center justify-center bg-gray-100 p-4 ">
			<div className="w-full sm:w-[700px] bg-white p-8 rounded-md shadow-md h-full">
				<div className="flex flex-col items-center justify-center mb-4 text-xl">
					<h1 className="">DigiQurbani</h1>
				</div>

				<div className="flex  items-center justify-center mb-4 text-md">
					Thank you for signing up Your request has been submitted successfully{" "}
					<br />
					Once your account is approved, you will receive an email with further
					instructions.
				</div>

				<div className="mt-4 text-sm ">
					<Link
						to="/login"
						className="flex text-black items-center justify-center w-full">
						Already have an account?{" "}
						<span className="text-blue-500 underline">Sign in</span>
					</Link>
				</div>
			</div>
		</div>
	);
};

export default ThankYou;
