export const animalData = [
	{
		id: 9,
		is_available: true,
		supplier_id: 16,
		animal_type: "GOAT",
		package_type: "QURBAN",
		animal_gender: "MALE",
		portions: 1,
		age: "3",
		price: "1000",
		created_at: "2024-03-31T04:55:50.082Z",
		updated_at: "2024-03-31T04:55:50.082Z",
	},
	{
		id: 10,
		is_available: true,
		supplier_id: 16,
		animal_type: "BUFFALO",
		package_type: "QURBAN",
		animal_gender: "MALE",
		portions: 0,
		age: "3",
		price: "700",
		created_at: "2024-03-31T04:56:11.794Z",
		updated_at: "2024-04-20T17:29:30.215Z",
	},
	{
		id: 8,
		is_available: true,
		supplier_id: 16,
		animal_type: "CATTLE",
		package_type: "QURBAN",
		animal_gender: "MALE",
		portions: 4,
		age: "3",
		price: "700",
		created_at: "2024-03-31T04:55:31.416Z",
		updated_at: "2024-04-21T14:55:24.342Z",
	},
];
export const feedlotData = [
	{
		id: 0,
		name: "John",
		mobile: "03070146989",
		icNumber: "31231231",
		capacity: 10,
		address: "Islamabad Pakistan",
		state: "Islamabad",
		district: "Islamabad",
	},
	{
		id: 1,
		name: "John",
		mobile: "03070146989",
		icNumber: "31231231",
		capacity: 10,
		address: "Islamabad Pakistan",
		state: "Islamabad",
		district: "Islamabad",
	},
	{
		id: 2,
		name: "John",
		mobile: "03070146989",
		icNumber: "31231231",
		capacity: 10,
		address: "Islamabad Pakistan",
		state: "Islamabad",
		district: "Islamabad",
	},
	{
		id: 3,
		name: "John",
		mobile: "03070146989",
		icNumber: "31231231",
		capacity: 10,
		address: "Islamabad Pakistan",
		state: "Islamabad",
		district: "Islamabad",
	},
	{
		id: 4,
		name: "John",
		mobile: "03070146989",
		icNumber: "31231231",
		capacity: 10,
		address: "Islamabad Pakistan",
		state: "Islamabad",
		district: "Islamabad",
	},
	{
		id: 5,
		name: "John",
		mobile: "03070146989",
		icNumber: "31231231",
		capacity: 10,
		address: "Islamabad Pakistan",
		state: "Islamabad",
		district: "Islamabad",
	},
];
export const rolePaths = {
	ADMIN: "/AdminDashboard",
	SUPPLIER: "/feedlot",
	FEEDLOT: "/cattle",
	SLAUGHTER: "/DashboardMenu",
	REPORTER:"/DashboardMenu"
};
