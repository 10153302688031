import React from "react";

const Button = ({
	type = "button",
	className = "",
	disabled = false,
	onClick = () => {},
	children,
}) => {
	return (
		<button
			type={type}
			className={` text-white py-2 px-4 rounded-md  focus:outline-none bg-pacific-blue hover:bg-pacific-blue hover:opacity-75 ${className}`}
			onClick={onClick}
			disabled={disabled}>
			{children}
		</button>
	);
};

export default Button;
