import React, { useState, useEffect } from "react";
import Button from "../../../../components/Button/Button";
import { Dialog } from "@headlessui/react";
import { useCreatePackage } from "../hooks/useCreatePackage";
import ReusableInput from "../../../../components/ResuableInputField";
import { useFetchCountires } from "../../../countries/hooks/useFetchCountries";
import { toast } from "react-toastify";
import { config } from "../../../../config/config";
import { FaUser } from "react-icons/fa";

const CreateNewPackage = ({ isOpen, setIsOpen, updateId = null }) => {
  const [isImageValid, setIsImageValid] = useState(true);
  const { countries, isError, isLoading } = useFetchCountires();
  const [formData, setFormData] = useState({
    country_id: "",
    name:"",
    pkg_type: "",
    pkg_price: "",
    name:"",
    // pkg_quota: "",
    short_des: "",
    // pkg_info: "",
    pkg_info_link: "",
    is_active:"",
    installment_text:"",
    text:"",
    pkg_image: null, // State to hold the file object
  });

  // Update formData when updateId changes
  useEffect(() => {
    if (updateId) {
      console.log(updateId);
      setFormData({
        country_id: updateId.country_id || "",
        name: updateId?.name || "",
        pkg_type: updateId.pkg_type || "",
        pkg_price: updateId.pkg_price || "",
        // pkg_quota: updateId.pkg_quota || "",
        short_des: updateId.short_des || "",
        // pkg_info: updateId.pkg_info || "",
        pkg_info_link: updateId.pkg_info_link || "",
        is_active: updateId.is_active?"true":"false" || "",
        installment_text: updateId.installment_text || "",
        text: updateId.text || "",
        pkg_image: updateId.pkg_image, // Reset file input when updating
      });
      setIsImageValid(true); // Reset image validity when updating

    }
  }, [updateId]);

  const { onSubmit } = useCreatePackage(setIsOpen, updateId);

  // const handleInputChange = (e) => {
  //   const { name, value, files } = e.target;
  //   if (files && files.length > 0) {
  //     const file = files[0];
  //     // const acceptedTypes = ["image/jpeg", "image/png", "image/gif", "image/bmp", "image/svg+xml"];
      
  //     // if (!acceptedTypes.includes(file.type)) {
  //     //   toast.error("Only .jpg, .jpeg, .png, .gif, .bmp, .svg files are allowed");
  //     //   return;
  //     // }

  //     if (file.size > 1048576) { // 1MB = 1048576 bytes
  //       toast.error("File size should not exceed 1MB");
  //       return;
  //     }

  //     const img = new Image();
  //     img.onload = () => {
  //       if (img.height > img.width) {
  //         toast.error("Image height should not be greater than the width");
  //       } else {
  //         setFormData((prevFormData) => ({
  //           ...prevFormData,
  //           pkg_image: file,
  //         }));
  //       }
  //     };
  //     img.src = URL.createObjectURL(file);
  //   } else {
  //     setFormData((prevFormData) => ({
  //       ...prevFormData,
  //       [name]: value,
  //     }));
  //   }
  // };

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    if (files && files.length > 0) {
      const file = files[0];
      const acceptedTypes = ["image/jpeg", "image/png", "image/gif", "image/bmp", "image/svg+xml"];
  
      if (!acceptedTypes.includes(file.type)) {
        toast.error("Only .jpg, .jpeg, .png, .gif, .bmp, .svg files are allowed");
        setIsImageValid(false);
        return;
      }
  
      if (file.size > 1048576) { // 1MB = 1048576 bytes
        toast.error("File size should not exceed 1MB");
        setIsImageValid(false);
        return;
      }
  
      const img = new Image();
      img.onload = () => {
        if (img.height > img.width) {
          toast.error("Image height should not be greater than the width");
          setIsImageValid(false);
        } else {
          setIsImageValid(true);
          setFormData((prevFormData) => ({
            ...prevFormData,
            pkg_image: file,
          }));
        }
      };
      img.src = URL.createObjectURL(file);
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };
  
  if (isError) {
    return <div className="">There was some Error, Please try again</div>;
  }

  if (isLoading) {
    return (
      <div className="flex space-x-2">
        <div className="">Loading...</div>
      </div>
    );
  }

  return (
    <Dialog
      open={isOpen}
      onClose={() => setIsOpen(true)}
      className="fixed inset-0 z-50 overflow-y-auto p-4 duration-500"
    >
      <div className="flex items-center justify-center min-h-screen">
        <Dialog.Overlay className="fixed inset-0 bg-black/30" />

        <Dialog.Panel className="relative max-w-[600px] w-full mx-auto bg-white p-4 rounded">
          <Dialog.Title className="text-lg font-medium mb-6 border-b-2 flex items-center justify-between">
            <span className="" onClick={()=>console.log(updateId)}>
              {updateId ? "Update Master Package" : "Create New Master Package"}
            </span>
            <div
              className="flex items-center justify-center"
              onClick={() => setIsOpen(false)}
            >
              <h1 className="bg-gray-200 px-2 rounded-md mb-1 hover:text-red-600">
                x
              </h1>
            </div>
          </Dialog.Title>
          <form
            onSubmit={(e) => {
              e.preventDefault();
               console.log(formData)
               console.log("formData")

              const formDataWithFile = new FormData();
              // Object.keys(formData).forEach((key) => {
              //   formDataWithFile.append(key, formData[key]);
              // });


              Object.keys(formData).forEach((key) => {
                if (key === 'is_active') {
                  // Convert is_active to a boolean
                  formDataWithFile.append(key, formData[key] === 'true' ? true : false);
                } else {
                  formDataWithFile.append(key, formData[key]);
                }
              });
             
              formDataWithFile.forEach((value, key) => {
                console.log(`${key}: ${value}`);
              });
              onSubmit(formDataWithFile);
            }}
          >



            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            {(formData.pkg_image !== undefined && formData.pkg_image !== "" && formData.pkg_image !== "undefined" && formData.pkg_image !== null) ? (
                      <img src={`${config?.backendBaseURL}uploads/${formData.pkg_image}`} className="w-14" />
                    ) : (
                      <FaUser className="w-8 h-10" />
                    )}

            {/* {formData?.pkg_image ? (
               <div className="">
  <img src={`${config.resourceUrl}${formData.pkg_image}`} alt="Package " className="h-40" />
  </div>
) : null} */}

            <div className="">
                <label
                  htmlFor="name"
                  className="block text-sm font-medium text-gray-700"
                >
                  Package name
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  onChange={handleInputChange}
                  value={formData.name}
                  required
                  className={`mt-1 p-1 w-full border border-gray-300 rounded-md`}
                />
              </div>
              
              <div className={formData?.pkg_image ? "mt-4" : ""}>
                <label
                  htmlFor="pkg_image"
                  className="block text-sm font-medium text-gray-700"
                >
                  Package Image
                </label>
                <input
                  type="file"
                  id="pkg_image"
                  name="pkg_image"
                  onChange={handleInputChange}
                  // required={!updateId}
                    accept=".jpg,.jpeg,.png,.gif,.bmp,.svg"
                  className={`mt-1 p-1 w-full border border-gray-300 rounded-md`}
                />
              </div>

             
              <ReusableInput
                type="select"
                id="country_id"
                name="country_id"
                label={"Country"}
                value={formData.country_id}
                placeholder="Select Country"
                options={
                  countries?.data.map((country) => ({
                    value: country.id,
                    label: country.name,
                  })) || []
                }
                onChange={handleInputChange}
                required
                className="mt-1 p-1 w-full border border-gray-300 rounded-md"
              />

<ReusableInput
                type="select"
                id="pkg_type"
                name="pkg_type"
                label={"Package Type"}
                value={formData.pkg_type}
                placeholder="Select Package Type"
                options={[
                  { value: "QURBAN", label: "QURBAN" },
                  { value: "AQIQAH", label: "AQIQAH" },
                  { value: "WAQF", label: "SADAQAH" },
                ]}
                onChange={handleInputChange}
                className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                required

              />

              
{updateId ? (
  <ReusableInput
    type="number"
    id="pkg_price"
    name="pkg_price"
    value={formData.pkg_price}
    label={"Starting from value"}
    placeholder="Enter value"
    onChange={handleInputChange}
    className="mt-1 p-1 w-full border border-gray-300 rounded-md"
    required
  />
) : (
  <ReusableInput
    type="number"
    id="pkg_price"
    name="pkg_price"
    value={formData.pkg_price}
    label={"Starting from value"}
    placeholder="Enter value"
    onChange={handleInputChange}
    className="mt-1 p-1 w-full border border-gray-300 rounded-md"
    required
  />
)}
              <ReusableInput
                type="text"
                id="text"
                name="text"
                value={formData.text}
                label={" Starting From Label"}
                placeholder="Enter Starting From Label"
                onChange={handleInputChange}
                className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                required

              />
               <ReusableInput
                type="text"
                id="installment_text"
                name="installment_text"
                value={formData.installment_text}
                label={"Installment Label"}
                placeholder="Enter Installment Label"
                onChange={handleInputChange}
                className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                required

              />
              {/* <ReusableInput
                type="number"
                id="pkg_quota"
                name="pkg_quota"
                label={"Package Quota"}
                value={formData.pkg_quota}
                placeholder="Enter Package Quota"
                onChange={handleInputChange}
                className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                required

              /> */}
              <ReusableInput
                type="text"
                id="short_des"
                name="short_des"
                value={formData.short_des}
                label={"Short description"}
                placeholder="Enter short description"
                onChange={handleInputChange}
                className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                required

              />
              {/* <ReusableInput
                type="text"
                id="pkg_info"
                name="pkg_info"
                value={formData.pkg_info}
                label={"Package Info"}
                placeholder="Enter Package Info"
                onChange={handleInputChange}
                className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                required

              /> */}
              <ReusableInput
                type="text"
                id="pkg_info_link"
                name="pkg_info_link"
                value={formData.pkg_info_link}
                label={"Package Info Link"}
                placeholder="Enter Package Info Link"
                onChange={handleInputChange}
                className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                required

              />



                  <ReusableInput
                type="select"
                id="is_active"
                name="is_active"
                label={"Status"}
                value={formData?.is_active}
                placeholder="Select Status"
                options={[
                  { value: "true", label: "Active" },
                  { value: "false", label: "Inactive" },
                ]}
                onChange={handleInputChange}
                className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                required

              />

            </div>

            <div className="flex justify-end mt-10">
              <Button type="submit" >
                {updateId ? "Update Package" : "Create Package"}
              </Button>

            </div>
          </form>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
};

export default CreateNewPackage;
