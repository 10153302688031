import React, { useEffect, useState } from "react";
import HeaderSection from "../../../components/HeaderSection";
import { useFetchCountires } from "../hooks/useFetchCountries";
import DataTable from "../../../components/Table/DataTable";
import { useNavigate } from "react-router-dom";
import Search from "../../../components/Search";
import Button from "../../../components/Button/Button";
import { config } from "../../../config/config";
import { Dialog } from "@headlessui/react";
import { Loader } from "../../../components/Loader";
import jsonData from "../../../assets/jsonfile/countrydata.json";
import { axios } from "../../../config/axios";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { FaUser } from "react-icons/fa";


const DisplayCountries = () => {
  const { countries, isError, isLoading, refetch } = useFetchCountires();
  const navigate = useNavigate();
  let [isOpen, setIsOpen] = useState(false);
  let [editModal, isEditModal] = useState(false);
  let [loading, setLoading] = useState(false);
  let [edititem, setEditItem] = useState({});








  const {
    register: registerAddCountry,
    handleSubmit: handleAddCountry,
    formState: { errors: errorsAddCountry },
    reset,
  } = useForm();

  const handleAddCountryClick = () => {
    setIsOpen(true);  // Logic to open modal or form
    reset({ name: "" });  // Clear the input field 'name'
  };

  const {
    register: registerUpdateCountry,
    handleSubmit: handleUpdateCountry,
    formState: { errors: errorsUpdateCountry },
    reset: resetUpdateCountry,
  } = useForm();

  useEffect(() => {
    if (editModal) {
      resetUpdateCountry({
        name: edititem?.name || "",
        image: null,
      });
    }
  }, [editModal, resetUpdateCountry, edititem]);

  const handleCreateCountryFunction = async (data) => {
    setLoading(true);
   


    const token = JSON.parse(localStorage.getItem("token"));
    const formData = new FormData();

    // Ensure supplier_id, feedlot_id, package_id, age, and weight are numbers
    data.name = data.name;
debugger;

    // Iterate through data object keys
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        if (key === 'image' && data[key] && data[key].length > 0) {
          formData.append(key, data[key][0]);
        } else if (key !== 'image') {
          formData.append(key, data[key]);
        }
      }
    }

  

    try {
      const response = await axios.post(
        `/users/add-countries`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response);
      refetch()
      setIsOpen(false);
      setLoading(false)
      toast.success("Country Created");

    } catch (error) {
      toast.error(error?.response?.data?.error || "Something went wrong");
      console.error("Error:", error);
      setLoading(false)
    }
    console.log("add item", data);
  };


  const handleUpdateCountryFunction = async (data) => {
    setLoading(true);
    console.log(data);


    const token = JSON.parse(localStorage.getItem("token"));
    const formData = new FormData();

    data.name = data.name;


    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        if (key === 'image' && data[key] && data[key].length > 0) {
          // If image is an object, assume it's a File object
          formData.append(key, data[key][0]);
        } else if (key !== 'image') {
          formData.append(key, data[key]);
        }
      }
    }
    

    // Log formData for debugging
    for (let [key, value] of formData.entries()) {
      console.log(`${key}: ${value}`);
    }

    try {
      const response = await axios.put(
        `/users/edit-country/${edititem?.id}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response);
      isEditModal(false);
      setLoading(false)
      refetch()
      toast.success("Country Updated");

    } catch (error) {
      toast.error(error?.response?.data?.error || "Something went wrong");
      console.error("Error:", error);
      setLoading(false)
    }
    console.log("Edit item", data);
  };




  if (isError) {
    return <h1 className="">An error occured</h1>;
  }

  if (isLoading) {
    return <h1 className="">Please wait, fetching data</h1>;
  }
  const onclick_handler = (selectedRow) => {
    navigate(`/${selectedRow.id}/state`);
  };



  return (
    <div>





      <div className='flex justify-between mb-2'>
        <button className='font-bold' onClick={() => console.log(countries)}>
          Country List
        </button>

        <Button type="button"  onClick={handleAddCountryClick}>
          Add Country
        </Button>

      </div>

      <table className="w-full text-sm overflow-x-auto text-left rtl:text-right text-gray-500 ">

        <thead className="text-xs text-pacific-blue uppercase bg-[#CFF0F2] ">
          <tr >

            <th scope="col" className="px-6 py-3 text-base font-medium ">
              <div className='flex gap-1 items-center'>
                Id
              </div>
            </th>
            <th scope="col" className="px-6 py-3 text-base font-medium">
              <div className='flex gap-1 items-center'>

                image
              </div>

            </th>
            <th scope="col" className="px-6 py-3 text-base font-medium">
              <div className='flex gap-1 items-center'>
                Name

              </div>
            </th>




            <th scope="col" className="px-2 py-3 text-base font-medium">
              Action
            </th>
          </tr>
        </thead>
        <tbody>


          {countries?.data?.map((e) => {
            return (
              <tr className="bg-white border-b " key={e?.id}>

                <th scope="row" className="px-6 py-4 text-sm font-medium">
                  {e?.id}
                </th>
                <td className="px-6 py-4 text-sm font-medium">
                {(e?.image !== undefined && e?.image !== "" && e?.image !== "undefined" && e?.image !== null && e?.image !== "null") ? (
                      <img src={`${config?.backendBaseURL}uploads/${e?.image}`} className="h-12 w-12 rounded-full" />
                    ) : (
                      <FaUser className="w-8 h-10" />
                    )}
                 

                </td>
                <td className="px-6 py-4 text-sm font-medium">
                  {e?.name}
                </td>





                <td className="px-6 py-4 text-sm font-medium text-pacific-blue">
                  <Button className="px-8"
                    onClick={() => {

                      setEditItem(e)
                      isEditModal(true)
                      // console.log(edititem)
                    }}>
                    Edit
                  </Button>
                </td>



              </tr>
            )
          })}


        </tbody>
      </table>



      {/* Add Country */}
      {isOpen &&
        <Dialog
          open={isOpen}
          onClose={() => setIsOpen(true)}
          className="fixed inset-0 z-50 overflow-y-auto p-4 duration-500 "
        >
          <div className="flex items-center justify-center min-h-screen">
            <Dialog.Overlay className="fixed inset-0 bg-black/30" />

            <Dialog.Panel className="relative max-w-[600px] w-full mx-auto bg-white p-4 rounded">
              <Dialog.Title className="text-lg font-medium mb-6 border-b-2 flex items-center justify-between">
                <span className="">
                  Create Country
                </span>
                <div
                  className="flex items-center justify-center "
                  onClick={() => setIsOpen(false)}
                >
                  <h1 onClick={() => setIsOpen(false)} className="bg-gray-200 px-2 rounded-md mb-1 hover:text-red-600 cursor-pointer">
                    x
                  </h1>
                </div>
              </Dialog.Title>

              <form onSubmit={handleAddCountry(handleCreateCountryFunction)}>
                <div className="grid grid-cols-1  sm:grid-cols-2 gap-4">





                  <div className="">
                    <label
                      htmlFor="name"
                      className="block mt-1   font-medium text-gray-700"
                    >
                      Country Name
                    </label>

                    <input
                          type="text"
                          className="mt-1 p-2 w-full border border-gray-300 rounded-md"
                          name="name"
                          id="name"
                          placeholder="Enter a country"
                          {...registerAddCountry("name", {
                            required: "Country is required",
                          })}
                        />

                    {/* <select
                      className="mt-1 p-2 w-full border border-gray-300 rounded-md"


                      name='name'
                      id='name'
                      {...registerAddCountry("name", {
                        required: "Country is required",
                      })}
                    >
                      <option value="">
                        Select the Country
                      </option>
                      {jsonData?.map((country, index) => (
                        <option key={index} value={country.country}>{country.country}</option>
                      ))}

                    </select> */}

                    {/* <input
                      name='name'
                      id='name'
                      {...registerAddCountry("name", {
                        required: "Country is required",
                      })}
                      className="mt-2 p-1 w-full border border-gray-300 rounded-md"
                    /> */}
                    <p className="text-red-500 text-sm">
                      {errorsAddCountry?.name?.message}
                    </p>
                  </div>


                  <div className="">
                    <label htmlFor="image" className="block mb-2 font-medium text-gray-700">
                      Image
                    </label>
                    <input
                      id="image"
                      type="file"
                      {...registerAddCountry("image")}
                      className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                    />
                    {errorsAddCountry.image && (
                      <span className="text-red-clr">
                        This field is required
                      </span>
                    )}
                  </div>




                </div>

                <div className="flex justify-end mt-10">

                  <Button type="submit">Create Country</Button>

                </div>
              </form>
            </Dialog.Panel>
          </div>
        </Dialog>}


      {/* edit Country */}
      {editModal &&
        <Dialog
          open={editModal}
          onClose={() => isEditModal(true)}
          className="fixed inset-0 z-50 overflow-y-auto p-4 duration-500 "
        >
          <div className="flex items-center justify-center min-h-screen">
            <Dialog.Overlay className="fixed inset-0 bg-black/30" />

            <Dialog.Panel className="relative max-w-[600px] w-full mx-auto bg-white p-4 rounded">
              <Dialog.Title className="text-lg font-medium mb-6 border-b-2 flex items-center justify-between">
                <span className="">
                  Update Country
                </span>
                <div
                  className="flex items-center justify-center "
                  onClick={() => setIsOpen(false)}
                >
                  <h1 onClick={() => isEditModal(false)} className="bg-gray-200 px-2 rounded-md mb-1 hover:text-red-600 cursor-pointer">
                    x
                  </h1>
                </div>
              </Dialog.Title>

              <form onSubmit={handleUpdateCountry(handleUpdateCountryFunction)}>
                <div className="grid grid-cols-1  sm:grid-cols-2 gap-4">





                  <div className="">
                    <label
                      htmlFor="name"
                      className="block mt-1   font-medium text-gray-700"
                    >
                      Country Name
                    </label>


                    <input
  type="text"
  className="mt-1 p-2 w-full border border-gray-300 rounded-md"
  name="name"
  id="name"
  placeholder="Enter the country name"
  {...registerUpdateCountry("name", {
    required: "Country is required",
  })}
/>

                    {/* <select
                      className="mt-1 p-2 w-full border border-gray-300 rounded-md"


                      name='name'
                      id='name'
                      {...registerUpdateCountry("name", {
                        required: "Country is required",
                      })}
                    >
                      <option value="">
                        Select the Country
                      </option>
                      {jsonData?.map((country, index) => (
                        <option key={index} value={country.country}>{country.country}</option>
                      ))}

                    </select> */}


                   
                    <p className="text-red-500 text-sm">
                      {errorsUpdateCountry?.name?.message}
                    </p>
                  </div>


                  <div className="">
                    <label htmlFor="image" className="block mb-2 font-medium text-gray-700">
                      Image
                    </label>
                    <input
                      id="image"
                      type="file"
                      {...registerUpdateCountry("image")}
                      className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                    />
                    {errorsUpdateCountry.image && (
                      <span className="text-red-clr">
                        This field is required
                      </span>
                    )}
                  </div>




                </div>

                <div className="flex justify-end mt-10">

                  <Button type="submit">Update Country</Button>

                </div>
              </form>
            </Dialog.Panel>
          </div>
        </Dialog>}




      {/* <DataTable data={countries.data || []} onClick={onclick_handler}   /> */}
    </div>
  );
};

export default DisplayCountries;
