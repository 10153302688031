import { axios } from "../../config/axios";
import { config } from "../../config/config";
import { endpoint } from "../../config/constants";
import { formatDate } from "../../utils/formatDate";

export const getAllFeedlotList = async (pageNumber = 1, pageSize = 10) => {
  const token = JSON.parse(localStorage.getItem("token"));

  const response = await axios.get(`${endpoint.GET_FEEDLOTS_NEW}?pageNumber=${pageNumber}&pageSize=${pageSize}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.data) {
    throw new Error("No data found");
  }

  return response.data;
};

// export const createRuminantPrice = async (data) => {
//   const token = JSON.parse(localStorage.getItem("token"));
  
//   const response = await axios.post(endpoint.GET_EXECUTER_SET_PRICE, data, {
//     headers: {
//       Authorization: `Bearer ${token}`,
//     },
//   });

//   if (!response.data) {
//     throw new Error("No data found");
//   }

//   return response;
// };
