import { useQuery } from "@tanstack/react-query";

const useFetchData = (queryKey, queryFn) => {
	const { data, isError, isLoading } = useQuery({
		queryKey: queryKey,
		queryFn: queryFn,
	});

	return { data, isError, isLoading };
};

export default useFetchData;
