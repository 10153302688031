import { axios } from "../../config/axios";
import { endpoint } from "../../config/constants";

export const getAllCountries = async () => {
  const token = JSON.parse(localStorage.getItem("token"));
  const response = await axios.get(endpoint.GET_COUNTRIES, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  if (!response.data) {
    throw new Error("No data found");
  }
  return response.data;
};
export const getAllCountryStates = async (country_id) => {
  console.log("sakdhash"," ", country_id, "d" )
  const token = JSON.parse(localStorage.getItem("token"));
  const url = country_id 
  ? endpoint.GET_COUNTRY_STATES.replace(":country_id", country_id) 
  : "users/get/,/states";
  
console.log(url);
  const response = await axios.get(
    url,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  if (!response.data) {
    throw new Error("No data found");
  }

  return response.data;
};
export const getAllStateRegions = async (state_id) => {
  const token = JSON.parse(localStorage.getItem("token"));

  const url = state_id 
  ? endpoint.GET_STATE_REGIONS.replace(":state_id", state_id) 
  : "users/get/,/regions";

  const response = await axios.get(
   url,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  if (!response.data) {
    throw new Error("No data found");
  }

  return response.data;
};

export const updateRegionStatus = async (id, status) => {
  const token = JSON.parse(localStorage.getItem("token"));
  const response = await axios.put(
    endpoint.UPDATE_REGION_STATUS.replace(":id", id),
    { status },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  if (!response.data) {
    throw new Error("No data found");
  }
  return response.data;
};

export const updateStateStatusApi = async (id, status) => {
  const token = JSON.parse(localStorage.getItem("token"));
  const response = await axios.put(
    endpoint.UPDATE_STATE_STATUS.replace(":id", id),
    { status },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  if (!response.data) {
    throw new Error("No data found");
  }
  return response.data;
};

export const createState = async (stateData) => {
  const token = JSON.parse(localStorage.getItem("token"));
  const response = await axios.post(endpoint.CREATE_STATE, stateData, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  if (!response.data) {
    throw new Error("Failed to create state");
  }
  return response.data;
};

export const updateState = async (id, stateData) => {
  
  const token = JSON.parse(localStorage.getItem("token"));
  const response = await axios.put(
    endpoint.UPDATE_STATE.replace(":id", id), 
    stateData,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  if (!response.data) {
    throw new Error("Failed to update state");
  }
  return response.data;
};

export const createRegion = async (regionData) => {
  
  const token = JSON.parse(localStorage.getItem("token"));
  const response = await axios.post(endpoint.CREATE_REGION, regionData, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  if (!response.data) {
    throw new Error("Failed to create region");
  }
  return response.data;
};

export const updateRegion = async (id, regionData) => {
  
  const token = JSON.parse(localStorage.getItem("token"));
  const response = await axios.put(
    endpoint.UPDATE_REGION.replace(":id", id),
    regionData,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  if (!response.data) {
    throw new Error("Failed to update region");
  }
  return response.data;
};
