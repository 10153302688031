import React, { useEffect, useState } from 'react'
import { createCalenderDetail, getAllsadakahDetails, getCalenderDetail, updateCalenderDetail } from '../../services/settings';
import { Loader } from '../../components/Loader';
import HeaderSection from '../../components/HeaderSection';
import { Dialog } from '@headlessui/react';
import Button from '../../components/Button/Button';
import { formatDate, formatDateWithoutTime } from "../../utils/formatDate";
import { useFetchCountires } from '../countries/hooks/useFetchCountries';
import { toast } from 'react-toastify';

function CalenderPage() {
  const { countries } = useFetchCountires();
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  let [isOpen, setIsOpen] = useState(false);
  let [isEditOpen, setIsEditOpen] = useState(false);
  let [editItem, setEditItem] = useState({});
  const [event, setEvent] = useState({
    title: "",
    date: "",
    closing_date: "",
    remarks: "",
    is_active: "",
    country_id: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    let parsedValue = value;

    if (name === "is_active") {
      parsedValue = value === "true";
    }

    setEvent({
      ...event,
      [name]: parsedValue,
    });

  
  };

  const handleInputEditChange = (e) => {
    const { name, value } = e.target;
    let parsedValue = value;

    if (name === "is_active") {
      parsedValue = value === "true";
    }

    setEditItem({
      ...editItem,
      [name]: parsedValue,
    });
  };

  useEffect(() => {

    fetchData();
  }, []);

  const fetchData = async () => {
    let updatedData = await getCalenderDetail();
    setLoading(true)
    setData(updatedData);
  };


  if (!loading) {
    return (
      <Loader />
    )
  }
  return (
    <div className="relative overflow-x-auto mb-[200px]">

      <div className='flex justify-between mb-2'>
        <button className='font-bold' onClick={() => console.log(data)}>
          Calender
        </button>

        <Button type="button" onClick={() => setIsOpen(true)}>
          Add Calender
        </Button>


      </div>
      <table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
        <thead className="text-xs text-pacific-blue uppercase bg-[#CFF0F2] ">
          <tr>

            <th scope="col" className="px-6 py-3 text-base font-medium">
              <div className='flex gap-1 items-center'>
                Id
              </div>
            </th>
            <th scope="col" className="px-6 py-3 text-base font-medium">
              <div className='flex gap-1 items-center'>
                Date

              </div>
            </th>
            <th scope="col" className="px-2 py-3 text-base font-medium">
              Closing Date
            </th>
            <th scope="col" className="px-6 py-3 text-base font-medium">
              <div className='flex gap-1 items-center'>

                Title
              </div>

            </th>

            <th scope="col" className="px-2 py-3 text-base font-medium">
              Remarks
            </th>

            <th scope="col" className="px-2 py-3 text-base font-medium">
              Country
            </th>

            <th scope="col" className="px-2 py-3 text-base font-medium">
              Status
            </th>
            <th scope="col" className="px-2 py-3 text-base font-medium">
              Action
            </th>
          </tr>
        </thead>
        <tbody>


          {data?.data?.data.map((e) => {
            return (
              <tr className="bg-white border-b " key={e?.id}>

                <th scope="row" className="px-6 py-4 text-sm font-medium">
                  {e?.id}
                </th>
                <td className="px-6 py-4 text-sm font-medium">
                  {formatDateWithoutTime(e.date)}
                </td>
                <td className="px-6 py-4 text-sm font-medium">
                  {formatDateWithoutTime(e.closing_date)}
                </td>
                <td className="px-6 py-4 text-sm font-medium">
                  {e.title}
                </td>

                <td className="px-6 py-4 text-sm font-medium">
                  {e.remarks}
                </td>

                <td className="px-6 py-4 text-sm font-medium">
                  {e.country?.name}
                </td>
                <td className="px-6 py-4 text-sm font-medium">
                  {e?.is_active ? (
                    <Button className="w-24 ">Active </Button>
                  ) : (
                    <Button
                      className="bg-red-500 hover:bg-red-500 w-24"
                      disabled
                    >
                      In-Active{" "}
                    </Button>
                  )}
                </td>



                <td className="px-6 py-4 text-sm font-medium">
                
                    <Button
                      className=" w-24"

                      onClick={()=>{
                        setIsEditOpen(true)
                        setEditItem(e)
                      }}
                    >
                      Edit
                    </Button>
                 
                </td>




              </tr>
            )
          })}


        </tbody>
      </table>


      {isOpen &&
        <Dialog
          open={isOpen}
          onClose={() => setIsOpen(true)}
          className="fixed inset-0 z-50 overflow-y-auto p-4 duration-500 "
        >
          <div className="flex items-center justify-center min-h-screen">
            <Dialog.Overlay className="fixed inset-0 bg-black/30" />

            <Dialog.Panel className="relative max-w-[600px] w-full mx-auto bg-white p-4 rounded">
              <Dialog.Title className="text-lg font-medium mb-6 border-b-2 flex items-center justify-between">
                <span className="">
                  Add calender
                </span>
                <div
                  className="flex items-center justify-center "
                  onClick={() => setIsOpen(false)}
                >
                  <h1 className="bg-gray-200 px-2 rounded-md mb-1 hover:text-red-600 ">
                    x
                  </h1>
                </div>
              </Dialog.Title>

              <form onSubmit={async (e) => {
                e.preventDefault();
                console.log(event)
                try {
                  const result = await createCalenderDetail(event);
                 console.log(result)
                 
                    toast.success("Add calendar successfully");
                    fetchData();
                    setIsOpen(false);
                  
                  console.log(result);
                } catch (error) {
                 
                  toast.error(error?.response?.data?.error);
                  console.error(error);
                }
              }}>
                <div className="grid grid-cols-1  sm:grid-cols-2 gap-4">





                  <div className="">
                    <label
                      htmlFor="title"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Title
                    </label>
                    <input
                      type="text"
                      id="title"
                      name="title"
                      className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                      value={event.title}
                      onChange={handleInputChange}
                      required
                    />

                  </div>

                  <div className="">
                    <label
                      htmlFor="date"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Date
                    </label>
                    <input
                      type="date"
                      id="date"
                      name="date"
                      className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                      onChange={handleInputChange}
                      required
                    />

                  </div>


                  <div className="">
                    <label
                      htmlFor="closing_date"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Closing Date
                    </label>
                    <input
                      type="date"
                      id="closing_date"
                      name="closing_date"
                      className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                      onChange={handleInputChange}
                      required
                    />

                  </div>


                  <div className="">
                    <label
                      htmlFor="remarks"
                      className="block text-sm font-medium text-gray-700"
                    >
                      remarks
                    </label>
                    <input
                      type="text"
                      id="remarks"
                      name="remarks"
                      className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                      onChange={handleInputChange}
                      required
                    />

                  </div>


                  <div className="">
                    <label
                      htmlFor="is_active"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Status
                    </label>

                    <select
                      id="is_active"
                      name="is_active"
                      className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                      onChange={handleInputChange}
                      required>
                      <option value="">Select the Status</option>
                      <option value="true">Active</option>
                      <option value="false">Inative</option>


                    </select>
                  </div>

                  <div className="">
                    <label
                      htmlFor="country_id"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Country
                    </label>



                    <select
                      id="country_id"
                      name="country_id"
                      className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                      onChange={handleInputChange}
                      required>
                      <option value="">Select the Country</option>
                      {countries?.data?.map((e, index) => {
                        return (
                          <option key={index} value={e.id}>
                            {e?.name}
                          </option>
                        )
                      })}

                    </select>
                  </div>





                </div>

                <div className="flex justify-end mt-10">
                  <Button type="submit">
                    Add Calender
                  </Button>
                </div>
              </form>
            </Dialog.Panel>
          </div>
        </Dialog>}





        {isEditOpen &&
        <Dialog
          open={isEditOpen}
          onClose={() => setIsEditOpen(true)}
          className="fixed inset-0 z-50 overflow-y-auto p-4 duration-500 "
        >
          <div className="flex items-center justify-center min-h-screen">
            <Dialog.Overlay className="fixed inset-0 bg-black/30" />

            <Dialog.Panel className="relative max-w-[600px] w-full mx-auto bg-white p-4 rounded">
              <Dialog.Title className="text-lg font-medium mb-6 border-b-2 flex items-center justify-between">
                <span className="" onClick={()=>console.log(editItem)}>
                
                  Add calender
                </span>
                <div
                  className="flex items-center justify-center "
                  onClick={() => setIsEditOpen(false)}
                >
                  <h1 className="bg-gray-200 px-2 rounded-md mb-1 hover:text-red-600 ">
                    x
                  </h1>
                </div>
              </Dialog.Title>

              <form onSubmit={async (e) => {
                e.preventDefault();
                console.log(event)
                try {
                  const { id,createdAt,updatedAt,country, ...otherData } = editItem;

                  console.log("aksnd" , otherData)
                  const result = await updateCalenderDetail(id,otherData);
                 console.log(result)
                 
                    toast.success("Update calendar successfully");
                    fetchData();
                    setIsEditOpen(false);
                  
                  console.log(result);
                } catch (error) {
                 
                  toast.error(error?.response?.data?.error);
                  console.error(error);
                }
              }}>
                <div className="grid grid-cols-1  sm:grid-cols-2 gap-4">





                  <div className="">
                    <label
                      htmlFor="title"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Title
                    </label>
                    <input
                      type="text"
                      id="title"
                      name="title"
                      className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                      value={editItem?.title}
                      onChange={handleInputEditChange}
                      required
                    />

                  </div>

                  <div className="">
                    <label
                      htmlFor="date"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Date
                    </label>
                    <input
                      type="date"
                      id="date"
                      name="date"
                      value={editItem?.date?.split('T')[0]}
                      className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                      onChange={handleInputEditChange}
                      required
                    />

                  </div>


                  <div className="">
                    <label
                      htmlFor="closing_date"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Closing Date
                    </label>
                    <input
                      type="date"
                      id="closing_date"
                      name="closing_date"
                      value={editItem?.closing_date?.split('T')[0]}

                      className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                      onChange={handleInputEditChange}
                      required
                    />

                  </div>


                  <div className="">
                    <label
                      htmlFor="remarks"
                      className="block text-sm font-medium text-gray-700"
                    >
                      remarks
                    </label>
                    <input
                      type="text"
                      id="remarks"
                      name="remarks"
                      value={editItem?.remarks}
                      className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                      onChange={handleInputEditChange}
                      required
                    />

                  </div>


                  <div className="">
                    <label
                      htmlFor="is_active"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Status
                    </label>

                    <select
                      id="is_active"
                      name="is_active"
                      value={editItem?.is_active}
                      className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                      onChange={handleInputEditChange}
                      required>
                      <option value="">Select the Status</option>
                      <option value="true">Active</option>
                      <option value="false">Inative</option>

                   </select>
                  </div>

                  <div className="">
                    <label
                      htmlFor="country_id"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Country
                    </label>



                    <select
                      id="country_id"
                      name="country_id"
                      value={editItem?.country_id}
                      className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                      onChange={handleInputEditChange}
                      required>
                      <option value="">Select the Country</option>
                      {countries?.data?.map((e, index) => {
                        return (
                          <option key={index} value={e.id}>
                            {e?.name}
                          </option>
                        )
                      })}

                    </select>
                  </div>





                </div>

                <div className="flex justify-end mt-10">
                  <Button type="submit">
                    Update Calender
                  </Button>
                </div>
              </form>
            </Dialog.Panel>
          </div>
        </Dialog>}




    </div>
  )
}

export default CalenderPage
