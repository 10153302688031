import React from "react";
import { useParams } from "react-router-dom";
import { updateStateStatus, useFetchCountryStates } from "../hooks/useFetchCountries";
import HeaderSection from "../../../components/HeaderSection";
import DataTable from "../../../components/Table/DataTable";
import { notifySuccess } from "../../../utils/toasts";

const DisplayStates = () => {
//   const navigate = useNavigate();
  const { country_id } = useParams();
  const { states, isError, isLoading,refetch } = useFetchCountryStates(country_id);
  const updateStatus = (id, status) => {
    updateStateStatus(id, status)
      .then((data) => {
        notifySuccess("Status updated successfully");
		refetch();
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  // const onclick_handler = (selectedRow) => {
  //   navigate(`/${country_id}/${selectedRow.id}/region`);
  // };
  if (isError) {
    return <div className="">There was some Error, Please try again</div>;
  }

  if (isLoading) {
    return (
      <div className="flex space-x-2">
        <div className="">Loading...</div>
      </div>
    );
  }
  return (
    <div>
      <HeaderSection title={"States"} />
      {/* onClick={onclick_handler} */}
      <DataTable
        data={states.data || []}
        canUpdateStatus={true}
        updatefn={updateStatus}
      />
    </div>
  );
};

export default DisplayStates;
