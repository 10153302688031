import { useQuery } from "@tanstack/react-query";
import { api_keys } from "../../../config/constants";
import { getAllUsers } from "../../../services/admin";

export const useGetCustomers = (pageNumber, limit) => {
  
  const {
    data: customers,
    isError,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: [api_keys.GET_USERS, pageNumber, limit],
    queryFn: () => getAllUsers(pageNumber, limit),
  });
  return {
    customers,
    isError,
    isLoading,
    refetch
  };
};
