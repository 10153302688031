import { axios } from "../../config/axios";
import { endpoint } from "../../config/constants";
import { formatDate } from "../../utils/formatDate";

export const getAllSuppliers = async (pageNumber = 1, limit = 10,country_id ) => {
	const token = JSON.parse(localStorage.getItem("token"));
	const response = await axios.get(
		endpoint.GET_SUPPLIERS + `&pageNumber=${pageNumber}&pageSize=${limit}&country_id=${country_id}`,
		{
			headers: {
				Authorization: `Bearer ${token}`,
			},
		}
	);
	if (!response.data) {
		throw new Error("No data found");
	}

	return {
		data: response?.data?.data
	};
};

export const getSupplierDetails = async (id) => {
	const token = JSON.parse(localStorage.getItem("token"));
	const response = await axios.get(endpoint.GET_USER_DETAILS + `${id}`, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
	if (!response.data) {
		throw new Error("No data found");
	}
	return response.data;
};

export const updateSupplierStatus = async (id) => {
	const token = JSON.parse(localStorage.getItem("token"));
	const response = await axios.put(
		endpoint.APPROVE_USER_PROFILE + `${id}`,
		{},
		{
			headers: {
				Authorization: `Bearer ${token}`,
			},
		}
	);
	if (!response.data) {
		throw new Error("No data found");
	}
	return response;
};
