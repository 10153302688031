export const config = {
  // backendURL: "https://api.dqurban.com/api/",
  // resourceUrl: "http://13.229.136.251:3000/", //dev
  
 // backendBaseURL dev
  // frontendBaseUrl: "https://dev.dqurban.com/",   //dev
  // backendURL: "https://apidev.dqurban.com/api/",  //dev
  // resourceUrl: "https://apidev.dqurban.com/",  //dev
  // backendBaseURL:"https://apidev.dqurban.com/"  //dev
  
  // new backendURL 
  
  // backendBaseURL Prouduction
  frontendBaseUrl: "https://dqurban.com/", //prodduction
  backendURL: "https://api.dqurban.com/api/", //prodduction
  resourceUrl: "https://api.dqurban.com/", //prodduction
  backendBaseURL:"https://api.dqurban.com/" // production
};
