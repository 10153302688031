import { useEffect, useState } from "react";
import DataTable from "../../../components/Table/DataTable";
import { Loader } from "../../../components/Loader";
import { useNavigate, useParams } from "react-router-dom";
import HeaderSection from "../../../components/HeaderSection";
import { notifyNoDataFound } from "../../../utils/toasts";
import Search from "../../../components/Search";
import useSlaughter from "../hooks/useSlaughter";
import Slaughter from "../../task new/components/task";
import { config } from "../../../config/config";
import Button from "../../../components/Button/Button";
import { Dialog } from "@headlessui/react";
import { useForm } from "react-hook-form";
import { axios } from "../../../config/axios";
import { toast } from "react-toastify";
import { FaUser } from "react-icons/fa";


import {
  useFetchCountires,
  useFetchCountryStateRegions,
  useFetchCountryStates,
} from "../../countries/hooks/useFetchCountries";
import { formatDate } from "../../../utils/formatDate";

const SlaughterWrapper = () => {
  const navigate = useNavigate();
  const [editloading, setEditLoading] = useState(false);
  let [editModal, setEditModal] = useState(false);
  let [edititem, setEditItem] = useState({});
  const { countries } = useFetchCountires();
  const [mode, setMode] = useState(null);

  const {
    register: registerEditSlaughter,
    handleSubmit: handleEditSlaughters,
    formState: { errors: errorsEditSlaughter },
    setValue: setValueEditSlaughter,
    watch: watchEditSlaughter,
    reset: resetEditSlaughter,
  } = useForm();

  const handleEditSlaughter = async (data) => {
    setEditLoading(true);

    // Ensure country_id, region_id, and state_id are positive integers
    data.country_id = parseInt(data?.country_id) || 0;
    data.region_id = parseInt(data?.region_id) || 0;
    data.state_id = parseInt(data?.state_id) || 0;
    // data.password = data?.password || 0;

    const token = JSON.parse(localStorage.getItem("token"));
    const formData = new FormData();

    // Iterate through data object keys
    for (const key in data) {
      // Check if key is country_id, region_id, or state_id and the value is not null or undefined
      if (
        ["country_id", "region_id", "state_id"].includes(key) &&
        data[key] !== null &&
        data[key] !== undefined
      ) {
        // Append to formData
        formData.append(key, data[key]);
      } else if (
        data[key] !== null &&
        typeof data[key] === "object" &&
        !(data[key] instanceof File)
      ) {
        // Handle nested objects
        // for (const innerKey in data[key]) {
        //   formData.append(`${key}[${innerKey}]`, data[key][innerKey]);
        // }
        formData.append(`image`, data.image[0]);
      } else {
        // Append other fields
        formData.append(key, data[key]);
      }
    }

    try {
      const response = await axios.put(
        `/users/update/slaughter/${edititem.id}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response);
      // fetchData();
      setEditModal(false);
      refetch();
      toast.success("Slaughter Updated");
      setEditLoading(false);
    } catch (error) {
      toast.error(error?.response?.data?.error || "Something went wrong");
      console.error("Error:", error);
      setEditLoading(false);
    }
    console.log("Edit item", data);
  };

  useEffect(() => {
    if (editModal) {
      resetEditSlaughter({
        fullname: edititem?.fullname || "",
        email: edititem?.email || "",
        ic_number: edititem?.ic_number || "",
        country_id: edititem?.country?.id || "",
        region_id: edititem?.region?.id || "",
        state_id: edititem?.state_id || "",
        image: edititem?.image || "",
        phone_no: edititem?.phone_no || "",
        // password: edititem?.password || "",
        is_active: edititem?.is_active || false,
        // password: edititem?.password || null,
      });
    }

    setValueEditSlaughter("country_id", edititem?.country_id);
   
    const timeoutIdState = setTimeout(() => {
      setValueEditSlaughter("state_id", edititem?.state_id);
      resetEditSlaughter({
       
        state_id: edititem?.state_id || "",
      });
    }, 1000); // 5000 milliseconds = 5 seconds

    const timeoutIdRegion = setTimeout(() => {
      setValueEditSlaughter("region_id", edititem?.region_id);
      
      resetEditSlaughter({
       
        region_id: edititem?.region_id || "",
      });
    }, 4000); // 5000 milliseconds = 5 seconds

    // Cleanup function to clear all timeouts if necessary
    return () => {
      clearTimeout(timeoutIdState);
      clearTimeout(timeoutIdRegion);
    };

  }, [editModal, resetEditSlaughter, edititem]);



  let { page = 1 } = useParams();
  page = Number(page);
  if (page < 1) {
    page = 1;
  }
  const [pageNumber, setPageNumber] = useState(page);
  const [limit, setLimit] = useState(10);

  const { slaughter, isError, isLoading, refetch } = useSlaughter(
    pageNumber,
    limit
  );

 

  const totalPages = slaughter?.data?.totalCount
    ? Math.ceil(slaughter?.data?.totalCount / limit)
    : 0;

  const handlePageChange = (newPage) => {
    setPageNumber(newPage);
    navigate(`/slaughter/${newPage}`); // Update the route accordingly
  };

  const { states } = useFetchCountryStates(watchEditSlaughter("country_id"));
  const { regions } = useFetchCountryStateRegions(
    watchEditSlaughter("state_id")
  );

  if (isError) {
    return <div className="">There was some Error, Please try again</div>;
  }
  if (slaughter?.data.length === 0) {
    // notifyNoDataFound();
    // setPageNumber(1);
    // navigate(`/slaughter/1`);
    return(<h1>No Data Found</h1>)
  }
  if (isLoading) {
    return (
      <div className="flex space-x-2">
        <Loader />
        <div className="">Loading...</div>
      </div>
    );
  }

  return (
    <div>
      <div className="flex justify-between mb-2">
        <button className="font-bold" onClick={() => console.log(edititem)}>
          Slaughter List
        </button>
      </div>

      <table className="w-full text-sm overflow-x-auto text-left rtl:text-right text-gray-500 ">
        <thead className="text-xs text-pacific-blue uppercase bg-[#CFF0F2] ">
          <tr>
            <th scope="col" className="px-6 py-3 text-base font-medium ">
              <div className="flex gap-1 items-center">Id</div>
            </th>
            <th scope="col" className="px-6 py-3 text-base font-medium ">
              <div className="flex gap-1 items-center">Slaughter Id</div>
            </th>
            <th scope="col" className="px-6 py-3 text-base font-medium">
              <div className="flex gap-1 items-center">image</div>
            </th>
            <th scope="col" className="px-6 py-3 text-base font-medium">
              <div className="flex gap-1 items-center">Full Name</div>
            </th>
            <th scope="col" className="px-6 py-3 text-base font-medium">
              <div className="flex gap-1 items-center">IC Number</div>
            </th>

            <th scope="col" className="px-6 py-3 text-base font-medium">
              <div className="flex gap-1 items-center">Country</div>
            </th>

            <th scope="col" className="px-6 py-3 text-base font-medium">
              <div className="flex gap-1 items-center">state</div>
            </th>

            <th scope="col" className="px-6 py-3 text-base font-medium">
              <div className="flex gap-1 items-center">region</div>
            </th>

            <th scope="col" className="px-6 py-3 text-base font-medium">
              <div className="flex gap-1 items-center">Email</div>
            </th>

            <th scope="col" className="px-6 py-3 text-base font-medium">
              <div className="flex gap-1 items-center">Phone Number</div>
            </th>

            <th scope="col" className="px-6 py-3 text-base font-medium">
                <div className="flex gap-1 items-center">Created At</div>
              </th>
              <th scope="col" className="px-6 py-3 text-base font-medium">
                <div className="flex gap-1 items-center">Updated At</div>
              </th>
                
                

            <th scope="col" className="px-6 py-3 text-base font-medium">
              <div className="flex gap-1 items-center">Status</div>
            </th>

            <th scope="col" className="px-2 py-3 text-base font-medium">
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          {slaughter?.data?.map((e) => {
            return (
              <tr className="bg-white border-b " key={e?.id}>
                <th scope="row" className="px-6 py-4 text-sm font-medium">
                  {e?.id}
                </th>
                <td className="px-6 py-4 text-sm font-medium">{e?.ref_id}</td>
                <td className="px-6 py-4 text-sm font-medium">
                {(e?.image !== "undefined" && e?.image !== null) ? (
  <img src={`${config?.backendBaseURL}uploads/${e?.image}`} className="w-14" />
) : (
  <FaUser className="w-8 h-10" />
)}
                </td>
                <td className="px-6 py-4 text-sm font-medium">{e?.fullname}</td>
                <td className="px-6 py-4 text-sm font-medium">{e?.ic_number}</td>

                <td className="px-6 py-4 text-sm font-medium">
                  {e?.country?.name}
                </td>

                <td className="px-6 py-4 text-sm font-medium">{e?.state?.name}</td>

                <td className="px-6 py-4 text-sm font-medium">{e?.region?.name}</td>

                <td className="px-6 py-4 text-sm font-medium">{e?.email}</td>
                <td className="px-6 py-4 text-sm font-medium">{e?.phone_no}</td>
                <td className="px-6 py-4 text-sm font-medium">
                    {e?.created_at}
                  </td>
                  <td className="px-6 py-4 text-sm font-medium">
                    {formatDate(e?.updated_at)}
                  </td>
                <td className="px-6 py-4 text-sm font-medium">
                  {e?.is_active ? (
                    <Button className="w-24 ">Active </Button>
                  ) : (
                    <Button
                      className="bg-red-500 hover:bg-red-500 w-24"
                      disabled
                    >
                      In-Active{" "}
                    </Button>
                  )}
                </td>

                <td className="flex px-6 py-4 text-sm font-medium text-pacific-blue">
                <div className="flex">
                  <Button
                    className="px-8"
                    onClick={() => {
                      setEditItem(e);
                      setEditModal(true);
                      setMode("edit");
                    }}
                  >
                    Edit
                  </Button>
                                  
                    <Button
                      className="px-8 ml-2"
                      onClick={() => {
                        setEditItem(e);
                        setEditModal(true);
                        setMode("view");
                      }}
                    >
                      View
                    </Button>
                    </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      {/* pagination start */}
      {slaughter?.data && (
        <div className="flex justify-center items-center mt-4">
          <button
            onClick={() => handlePageChange(pageNumber - 1)}
            disabled={pageNumber === 1}
            className="px-4 py-2 bg-gray-300 rounded-md mr-2"
          >
            Previous
          </button>
          <div className="flex gap-2">
          {[...Array(totalPages > 0 ? totalPages : 0)].map((_, index) => (
              <button
                key={index + 1}
                onClick={() => handlePageChange(index + 1)}
                className={`px-4 py-2 rounded-md ${
                  pageNumber === index + 1
                    ? "bg-blue-500 text-white"
                    : "bg-gray-300"
                }`}
              >
                {index + 1}
              </button>
            ))}
          </div>
          <button
            onClick={() => handlePageChange(pageNumber + 1)}
            disabled={pageNumber === totalPages}
            className="px-4 py-2 bg-gray-300 rounded-md ml-2"
          >
            Next
          </button>
        </div>
      )}
      {/* pagination end */}

      {/* Edit Modal */}
      {editModal && (
        <Dialog
          open={editModal}
          onClose={() => setEditModal(false)}
          className="fixed inset-0 z-50 overflow-y-auto p-4 duration-500 "
        >
          <div className="flex items-center justify-center min-h-screen">
            <Dialog.Overlay className="fixed inset-0 bg-black/30" />

            <Dialog.Panel className="relative max-w-[600px] w-full mx-auto bg-white p-4 rounded">
              <Dialog.Title className="text-lg font-medium mb-6 border-b-2 flex items-center justify-between">
                {mode === "edit" ? (
                    <span className="">Update Slaughter Profile</span>
                  ) : (
                    <span className="">View Slaughter Profile</span>
                  )}
                <div
                  className="flex items-center justify-center "
                  onClick={() => {
                    setEditModal(false);
                  }}
                >
                  <h1
                    onClick={() => setEditLoading(false)}
                    className="bg-gray-200 px-2 rounded-md mb-1 hover:text-red-600 cursor-pointer"
                  >
                    x
                  </h1>
                </div>
              </Dialog.Title>
              <form
                onSubmit={handleEditSlaughters(handleEditSlaughter)}
                encType="multipart/form-data"
              >
                <div className="grid grid-cols-1  sm:grid-cols-2 gap-4">

                  <div>
                  <label className="block text-sm font-medium text-gray-700">
                      Image
                    </label>
                    {(edititem?.image !== "undefined" && edititem?.image !== null) ? (
                      <img src={`${config?.backendBaseURL}uploads/${edititem?.image}`} className="w-40" />
                    ) : (
                      <FaUser className="w-8 h-10" />
                    )}
                    </div>

                    
                  <div className="">
                    <label className="block text-sm font-medium text-gray-700">
                      Full Name
                    </label>
                    <input
                      type="text"
                      {...registerEditSlaughter("fullname", { required: true })}
                      disabled={mode === 'view'}
                      className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                    />
                    {errorsEditSlaughter.fullname && (
                      <span className="text-red-clr">
                        This field is required
                      </span>
                    )}
                  </div>

                  <div className="">
                    <label className="block text-sm font-medium text-gray-700">
                      Email
                    </label>
                    <input
                      type="email"
                      value={edititem?.email}
                      disabled
                      className="mt-1 p-1 w-full border border-gray-300 rounded-md bg-slate-300"
                    />
                    
                  </div>
                  <div className="">
                    <label className="block text-sm font-medium text-gray-700">
                      IC Number
                    </label>
                    <input
                      type="text"
                      {...registerEditSlaughter("ic_number", {
                        required: true,
                      })}
                      disabled={mode === 'view'}
                      className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                    />
                    {errorsEditSlaughter.ic_number && (
                      <span className="text-red-clr">
                        This field is required
                      </span>
                    )}
                  </div>
                  <div className="">
                    <label className="block text-sm font-medium text-gray-700">
                      Country
                    </label>
                    <select
                      name="country_id"
                      id="country_id"
                      onChange={(e) =>
                        setValueEditSlaughter("country_id", e.target.value)
                      }
                      {...registerEditSlaughter("country_id", {
                        required: true,
                      })}
                      disabled={mode === 'view'}
                      className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                    >
                      <option value="">Select Country</option>
                      {countries?.data?.map((country) => (
                        <option key={country?.id} value={country?.id}>
                          {country?.name}
                        </option>
                      ))}
                    </select>
                    {errorsEditSlaughter.country_id && (
                      <span className="text-red-clr">
                        This field is required
                      </span>
                    )}
                  </div>
                  <div className="mt-2">
                    <label className="block text-sm font-medium text-gray-700">
                      State
                    </label>
                    <select
                      name="state_id"
                      id="state_id"
                      onChange={(e) =>
                        setValueEditSlaughter("state_id", e.target.value)
                      }
                      {...registerEditSlaughter("state_id", { required: true })}
                      disabled={mode === 'view'}
                      className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                    >
                      <option value="">Select State</option>
                      {states?.data?.map((state) => (
                        <option key={state.id} value={state.id}>
                          {state.name}
                        </option>
                      ))}
                    </select>
                    {errorsEditSlaughter.state_id && (
                      <span className="text-red-clr">
                        This field is required
                      </span>
                    )}
                  </div>
                  <div className="mt-2">
                    <label className="block text-sm font-medium text-gray-700">
                      Region
                    </label>
                    <select
                      name="region_id"
                      id="region_id"
                      onChange={(e) =>
                        setValueEditSlaughter("region_id", e.target.value)
                      }
                      {...registerEditSlaughter("region_id", {
                        required: true,
                      })}
                      disabled={mode === 'view'}
                      className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                    >
                      <option value="">Select Region</option>
                      {regions?.data?.map((region) => (
                        <option key={region.id} value={region.id}>
                          {region.name}
                        </option>
                      ))}
                    </select>
                    {errorsEditSlaughter.region_id && (
                      <span className="text-red-clr">
                        This field is required
                      </span>
                    )}
                  </div>
                  <div className="mt-2">
                    <label className="block text-sm font-medium text-gray-700">
                      Phone No
                    </label>
                    <input
                      type="text"
                      {...registerEditSlaughter("phone_no", { required: true })}
                      disabled={mode === 'view'}
                      className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                    />
                    {errorsEditSlaughter.phone_no && (
                      <span className="text-red-clr">
                        This field is required
                      </span>
                    )}
                  </div>

                  {/* <div className="mt-2">
                    <label className="block text-sm font-medium text-gray-700">
                      Password
                    </label>
                    <input
                      type="password"
                      {...registerEditSlaughter("password", { required: true })}
                      className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                    />
                    {errorsEditSlaughter.password && (
                      <span className="text-red-clr">
                        This field is required
                      </span>
                    )}
                  </div> */}

                  <div className="">
                    <label className="block text-sm font-medium text-gray-700">
                      Image
                    </label>
                    <input
                      type="file"
                      {...registerEditSlaughter("image")}
                      disabled={mode === 'view'}
                      className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                    />
                  </div>
                  <div className="">
                    <label className="block text-sm font-medium text-gray-700">
                      STATUS
                    </label>
                    <select
                      {...registerEditSlaughter("is_active")}
                      disabled={mode === 'view'}
                      className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                    >
                      <option value="">Select Status</option>
                      <option value="true">Active</option>
                      <option value="false">Inactive</option>
                    </select>
                  </div>
                </div>

                {mode === "edit" && (
                <div className="flex justify-end mt-10">
                  {editloading ? (
                    <Button type="button">
                      <Loader />
                    </Button>
                  ) : (
                    <Button type="submit">Update Slaughter</Button>
                  )}
                </div>
                )}
              </form>
            </Dialog.Panel>
          </div>
        </Dialog>
      )}
    </div>
  );
};

export default SlaughterWrapper;
