import React, { useState } from 'react';
import TransTab from './TransTab';
import Search from '../../components/Search';
import Payment from './Payment';
import History from './History';

const ViewHistory = () => {
  const [activeTab, setActiveTab] = useState('Payment'); // Initial active tab

  const tabsName = ['Payment', 'History']; // Tabs to display
  const tabNumberCounting = [
    { name: 'Payment', countValue: 40 }, // Counts for each tab
    { name: 'History', countValue: 38 },
  ];

  // Function to handle tab change
  const handleTabChange = (tabName) => {
    setActiveTab(tabName);
  };

  // Render conditionally based on activeTab
  const renderTabContent = () => {
    switch (activeTab) {
      case 'Payment':
        return <Payment />;
      case 'History':
        return <History />;
      default:
        return null;
    }
  };

  return (
    <>
      {/* Upper Tab */}
      <TransTab
        tabsName={tabsName}
        tabNumberCounting={tabNumberCounting}
        onTabChange={handleTabChange} // Pass handleTabChange function
        searchbar={<Search className="md:w-[300px] my-2 md:my-0" />}
      />

      {/* Render the selected tab content */}
      {renderTabContent()}
    </>
  );
};

export default ViewHistory;
