import { axios } from "../../config/axios";
import { endpoint } from "../../config/constants";
export const getAnalyticsStock = async () => {
  const token = JSON.parse(localStorage.getItem("token"));
  const response = await axios.get(endpoint.GET_ANALYTICS_STOCK, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  if (!response.data) {
    throw new Error("No data found");
  }
  return response.data;
};

export const getAnalyticsOrders = async (startDate, endDate) => {
  const token = JSON.parse(localStorage.getItem("token"));
  const response = await axios.get(endpoint.GET_ANALYTICS_ORDERS + `?startDate=${startDate}&endDate=${endDate}&view_all=True`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  if (!response.data) {
    throw new Error("No data found");
  }
  return response.data;
};

export const getAnalyticsSales = async (startDate, endDate) => {
  const token = JSON.parse(localStorage.getItem("token"));
  const response = await axios.get(endpoint.GET_ANALYTICS_SALES + `?startDate=${startDate}&endDate=${endDate}&view_all=True`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  if (!response.data) {
    throw new Error("No data found");
  }
  return response.data;
};
