import React, { useState } from "react";
import circleBar from "../../assets/images/Transaction/circleBar.png";
import { MdKeyboardArrowRight } from "react-icons/md";

import { Link } from "react-router-dom";
import Button from "../../components/Button/Button";
import HeaderSection from "../../components/HeaderSection";
import { useTransactionDetails } from "./hooks/useGetTransaction";
import { Loader } from "../../components/Loader";
import TotalEarned from "./TotalEarned";
import { formatDateWithoutTime } from "../../utils/formatDate";

const Transaction = () => {
  const { data, isError, isLoading, error } = useTransactionDetails();
  const [step, setStep] = useState(0)
  const [itemData, setItemData] = useState({})

  const changeStep = (e) => {
    setStep(e)
  }

  if (isLoading) {
    return <Loader />
  }
  if (isError) {
    console.log(error)
  }
  console.log(data)
  return (
    <>
{step===0?
<>

          {/* Middle Div */}
          <HeaderSection title={"Transaction"} />
          <div className="md:w-full  md:mb-[100px] mb-[150px] items-center flex justify-between md:flex-col flex-col-reverse">
            <div className="md:w-full flex md:flex-row flex-col items-center py-10 px-7 md:gap-20 gap-10 md:mr-5 md:border border-gray-200 mt-4 rounded-3xl h-fit ">
              {/* Circular  */}
              <div className="relative md:w-80 w-72 md:h-80 h-72">
                <img src={circleBar} />
                <div className="absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2">
                  <div className="flex flex-col items-center">

                    <span className="md:text-lg text-base  font-medium">Total Earned</span>
                    <div className="flex my-2">
                      <p className="text-xs md:text-2xl font-semibold">RM </p>
                      <p className="text-xl md:text-3xl font-semibold"> {data?.data?.totalEarned}</p>
                    </div>

                    <div className="flex mt-2 gap-3">
                      <div className="flex items-center w-[50%]">
                        <div className="bg-pacific-blue h-4 w-4 m rounded-[50%] mr-2"></div>
                        <h1 className="text-xs" onClick={() => console.log(data)}>Qurban</h1>
                      </div>
                      <div className="flex items-center w-[50%]">
                        <div className="bg-electric-purple h-4 w-4 m rounded-[50%] mr-2"></div>
                        <h1 className="text-xs">Aqiqah</h1>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="md:w-[60%] w-full">
                <h1 className="text-star-dust text-sm font-medium">
                  Overall tasks performed
                </h1>
                {/* Progress bar 1*/}
                <div className="relative pt-1 w-full">
                  <div className="overflow-hidden h-10 mb-4 text-xs flex bg-amber-200 rounded-full w-full">
                    <div
                      style={{ width: "70%" }}
                      className="relative shadow-none py-2  flex flex-col text-center whitespace-nowrap text-white justify-center bg-pacific-blue"
                    >
                      <span className="absolute inset-0 md:text-base text-xs font-medium flex justify-center items-center">
                        {data?.data?.totalQurbanTasksPerformed} Tasks
                      </span>
                    </div>
                    <div
                      style={{ width: "30%" }}
                      className="relative shadow-none py-2 flex flex-col text-center whitespace-nowrap text-white justify-center bg-electric-purple"
                    >
                      <span className="absolute inset-0 md:text-base text-xs font-medium flex justify-center items-center">
                        {data?.data?.totalAqiqahTasksPerformed}Tasks
                      </span>
                    </div>
                  </div>
                </div>

                <h1 className="text-star-dust text-sm font-medium">
                  Tasks performed this year
                </h1>
                {/* Progress bar 2*/}
                <div className="relative pt-1">
                  <div className="overflow-hidden h-10 mb-4 text-xs flex bg-amber-200 rounded-full">
                    <div
                      style={{ width: "70%" }}
                      className="relative shadow-none py-2 flex flex-col text-center whitespace-nowrap text-white justify-center bg-pacific-blue"
                    >
                      <span className="absolute inset-0 md:text-base text-xs font-medium flex justify-center items-center">
                        {data?.data?.totalQurbanTasksPerformedThisYear}Tasks
                      </span>
                    </div>
                    <div
                      style={{ width: "30%" }}
                      className="relative shadow-none py-2 flex flex-col text-center whitespace-nowrap text-white justify-center bg-electric-purple"
                    >
                      <span className="absolute inset-0 md:text-base text-xs  font-medium flex justify-center items-center">
                        {data?.data?.totalAqiqahTasksPerformedThisYear} Tasks
                      </span>
                    </div>
                  </div>
                </div>

                <Link to="/analytics">

                  <div className="text-pacific-blue mb-4 flex items-center justify-between text-base font-medium bg-white border border-pacific-blue rounded-2xl py-3 px-2">
                    <p>View My Achievements</p>
                    <MdKeyboardArrowRight className="text-pacific-blue text-2xl" />
                  </div>
                </Link>

                <div className="flex md:flex-row flex-col mt-1 md:justify-between w-full md:gap-4">
                  <div className="text-pacific-blue mb-4 text-xs font-medium border md:w-[50%] w-full  border-pacific-blue rounded-2xl py-3 px-2">
                    <p>Latest earned</p>
                    <div className="flex">
                      <p className="md:text-2xl text-base font-semibold text-pacific-blue">
                        RM
                      </p>{" "}
                      <p className="md:text-5xl text-2xl font-semibold text-pacific-blue">
                        {" "}
                        {data?.data?.latestEarned}
                      </p>
                    </div>
                  </div>

                  <div className="text-pacific-blue mb-4 text-xs font-medium border md:w-[50%] w-full border-pacific-blue rounded-2xl py-3 px-2">
                    <p>Claimed</p>
                    <div className="flex">
                      <p className="md:text-2xl text-base font-semibold text-pacific-blue">
                        RM
                      </p>{" "}
                      <p className="md:text-5xl text-2xl font-semibold text-pacific-blue">
                        {" "}
                        {data?.data?.totalClaimed}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>{" "}


            {/* lower Div */}
            <div className="w-full">
              <h1 className="text-lg font-medium my-5" onClick={()=>console.log(data?.data?.slaughterTasks)}>Recent</h1>





              {data?.data?.slaughterTasks?.map((e, index) => {
                  if(e?.reporter_order?.order?.order_status=="ORDER_COMPLETED"){

                return (
                  <div className="text-star-dust mb-4 flex items-center justify-between text-base font-medium bg-white border border-dtext-star-dust rounded-2xl py-3 px-2">
                    <div className="flex gap-10">
                      <div className="bg-star-dust w-12 h-12 rounded-sm"></div>

                      <div>
                      <p className="text-[#0F405A] text-2xl font-medium"> Order Id</p>

                        <p className=" text-2xl font-medium">{e?.reporter_order?.order?.ref_id}</p>
                      </div>

                      <div>
                      <p className="text-[#0F405A] text-2xl font-medium"> Total Earning</p>

                        <p className=" text-2xl font-medium">{(e?.executor_payable?.amount_payable)*(e?.reporter_order?.order?.total_portions)}</p>
                      </div>

                      <div>
                      <p className="text-[#0F405A] text-2xl font-medium">Total Portion</p>

                        <p className="text-2xl font-medium">{e?.reporter_order?.order?.total_portions}</p>
                      </div>

                      <div>
                      <p className="text-[#0F405A] text-2xl font-medium">Per Portion Price</p>

                        <p className=" text-2xl font-medium">{e?.executor_payable?.amount_payable}</p>
                      </div>

                      <div>
                      <p className="text-[#0F405A] text-2xl font-medium">Active Status</p>

                        <p className={` text-2xl font-medium ${e?.executor_payable?.is_active?"text-green-500":"text-red-500"}` }>{e?.executor_payable?.is_active?"Active":"InActive"}</p>
                      </div>

                    
                     
                    </div>


                    <div className="flex items-center gap-5">

                      {
                        e?.is_claimed?
                        <Button className="bg-red-500 cursor-not-allowed hover:bg-red-600">Claimed </Button>:
                        <Button 
                      
                      onClick={() => {
                        setItemData(e)
                        setStep(1)
                      }
                      }>
                        Claim Now
                      </Button>

                      }

                      

                    </div>

                  </div>
                    
                )
              }
              })}






            </div>
          </div>
        </>
        :
        <TotalEarned itemData={itemData} changeStep={changeStep} />
      }
    </>
  );
};

export default Transaction;
