export const api_keys = {
	GET_SLAUGHTER: "executor/get-slaughter/1",
	GET_CATTLE: "get-cattles",
	CREATE_CATTLE: "create-cattle",
	GET_ORDERS: "get-orders",
	INVITE_FEEDLOT: "invite-feedlot",
	GET_FEEDLOTS: "get-feedlots",
	GET_FEEDLOTS_NEW: "users/role/feedlot?pageNumber=1&pageSize=100",
	GET_SUPPLIERS: "get-suppliers",
	GET_USERS: "get-users",
	GET_ORDER_DETAIL: "get-order",
	SIGNUP_SUPPLIER: "signup-supplier",
	GET_USER_DETAILS: "get-user-details",
	APPROVE_USER_PROFILE: "approve-user",
	GET_PACKAGES: "get-packages",
	CREATE_PACKAGE: "create-package",
	GET_ANIMALS: "get-animals",
	GET_PKG_DETAILS: "get-package-details",
	CREATE_ANIMALS: "create-animals",
	CREATE_PACKAGE_DETAILS: "create-package-details",
	GET_COUNTRIES: "get-countries",
	GET_COUNTRY_STATES: "get-country-states",
	GET_STATE_REGIONS: "get-state-regions",
	GET_USER_PROFILE: "get-user-profile",
	UPDATE_REGION_STATUS: "update-region-status",
	UPDATE_STATE_STATUS: "update-state-status",
	GET_ANALYTICS_ORDERS: "get-analytics-orders",
	GET_ANALYTICS_SALES: "get-analytics-sales",
	GET_ANALYTICS_STOCK: "get-analytics-stock",
};

export const endpoint = {
	GET_TRANSCTION:"executor/",
	GET_SLAUGHTER_TASK_LIST:"executor/slaughter-tasks",
	GET_SLAUGHTER:"executor/get-slaughter/1",
	Get_EXECUTOR:"executor/admin-assign-order",
	Get_EXECUTOR_TASK:"executor/reporter-assign-task",
	GET_CATTLE: "animal-inventory",
	CREATE_CATTLE: "animal-inventory",
	GET_ORDERS: "animal-inventory/get-orders",
	GET_ORDER_DETAILS: "animal-inventory/get-orders/",
	UPDATE_ORDER_STATUS: "animal-inventory/update-orders/",
	CANCELLED_ORDER_REFUNDED: "animal-inventory/cancelled-order-refunded/",	
	LOGIN: "auth/login",
	GET_FEEDLOTS_NEW: "users/role/feedlot",
	INVITE_FEEDLOT: "users/invite/feedlot",
	GET_FEEDLOTS: "users/?role=FEEDLOT",
	GET_SUPPLIERS: "users/?role=SUPPLIER",
	GET_REPORTERS: "users/?role=REPORTER",
	GET_SLAUGHTERS: "users/?role=SLAUGHTER",
	GET_USERS: "users/?role=USER",
	SIGNUP_SUPPLIER: "auth/signup/seller",
	GET_USER_DETAILS: "users/",
	APPROVE_USER_PROFILE: "users/approve/profile/",
	GET_PACKAGES: "packages",
	GET_ANIMALS: "packages/animal-types",
	GET_EXECUTER_SET_PRICE: "executor/admin-set-executor-payable",
	GET_PKG_DETAILS: "packages/package-details",
	GET_COUNTRIES: "users/get/countries",
	GET_COUNTRY_STATES: "users/get/:country_id/states",
	GET_STATE_REGIONS: "users/get/:state_id/regions",
	GET_USER_PROFILE: "users/get/profile",
	UPDATE_REGION_STATUS: "users/put/:id/regions",
	CREATE_REGION:"users/add-regions",
	UPDATE_REGION:"users/put/:id/regions",
	UPDATE_STATE_STATUS	: "users/put/:id/states",
	CREATE_STATE	: "users/add-states",
	UPDATE_STATE	: "users/put/:id/states",
	GET_ANALYTICS_ORDERS: "reports/orders",
	GET_ANALYTICS_SALES: "reports/sales",
	GET_ANALYTICS_STOCK: "reports/stock",

};

export const order_status = {
	ORDER_RECEIVED: "Order Received",
	ORDER_IN_PROGRESS: "Order In Progress",
	ORDER_SHIPPED: "Order Shipped",
	ORDER_COMPLETED: "Order Completed",
};

export const supplier_account_status = {
	approved: "Approved",
	pending: "Pending",
	rejected: "Rejected",
};

export const payment_status = {
	pending: "Pending",
	paid: "Paid",
};
export const USER_ROLES = {
	admin: "ADMIN",
	supplier: "SUPPLIER",
	feedlot: "FEEDLOT",
};

export const cattle_types = {
	cow: "Cow",
	goat: "Goat",
};

export const order_types = [
	{
		label: "ONGOING",
		name: "ONGOING",
		value: "ONGOING",
	},
	{
		label: "CANCELLED",
		name: "CANCELLED",
		value: "CANCELLED",
	},
	{
		label: "COMPLETED",
		name: "COMPLETED",
		value: "COMPLETED",
	}
];

export const animal_type = [
	{ label: "GOAT", value: "GOAT" },
	{ label: "BUFFALO", value: "BUFFALO" },
	{ label: "CATTLE", value: "CATTLE" },
];

export const animal_gender = [
	{ label: "MALE", value: "MALE" },
	{ label: "FEMALE", value: "FEMALE" },
];

export const package_type = [
	{ label: "AQIQAH", value: "AQIQAH" },
	{ label: "QURBANI", value: "QURBANI" },
];

export const animalTypes_tempdata = [
	{ id: 0, name: "GOAT", animalType: "AQIQAH" },
	{ id: 1, name: "GOAT", animalType: "AQIQAH" },
	{ id: 2, name: "GOAT", animalType: "AQIQAH" },
	{ id: 3, name: "GOAT", animalType: "AQIQAH" },
	{ id: 4, name: "GOAT", animalType: "AQIQAH" },
	{ id: 5, name: "GOAT", animalType: "AQIQAH" },
];

export const packages_tempData = [
	{ id: 0, name: "GOAT", packageType: "AQIQAH" },
	{ id: 1, name: "GOAT", packageType: "AQIQAH" },
	{ id: 2, name: "GOAT", packageType: "QURBAN" },
	{ id: 3, name: "GOAT", packageType: "AQIQAH" },
	{ id: 4, name: "GOAT", packageType: "QURBAN" },
	{ id: 5, name: "GOAT", packageType: "AQIQAH" },
];

export const package_details_tempData = [
	{ id: 0, name: "GOAT", packageType: "AQIQAH" },
	{ id: 1, name: "GOAT", packageType: "AQIQAH" },
	{ id: 2, name: "GOAT", packageType: "QURBAN" },
	{ id: 3, name: "GOAT", packageType: "AQIQAH" },
	{ id: 4, name: "GOAT", packageType: "QURBAN" },
	{ id: 5, name: "GOAT", packageType: "AQIQAH" },
];
