import { axios } from "../../config/axios";
import { endpoint } from "../../config/constants";

export const loginUser = async (email, password , role = null) => {
	const url = role ? `${endpoint.LOGIN}?role=${role}` : endpoint.LOGIN;
	const response = await axios.post(url, {
		email,
		password,
	});
	if (!response.data) {
		throw new Error("No data found");
	}
	return response.data;
};

// signup supplier
export const signupSupplier = async (data) => {
	const response = await axios.post(endpoint.SIGNUP_SUPPLIER, data);
	if (!response.data) {
		throw new Error("No data found");
	}
	return response.data;
};

//forgot password


