import React, { useState } from 'react';
import { IoIosArrowForward } from "react-icons/io";
import { IoIosArrowDown } from "react-icons/io";
import { IoArrowDownCircleOutline } from "react-icons/io5";
import { useGetSlaughterDataHook } from '../hooks/useGetTaskDataHook';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { toast } from 'react-toastify';
import DetailPage from './detailPage copy';
import { config } from '../../../config/config';
import { formatDate } from '../../../utils/formatDate';

const OnGoing = ({ data, refetch }) => {
  const [slaughter_task_id, Setslaughter_task_id] = useState(null)
  const [itemDetail, SetItemDetail] = useState({})

  console.log("Just Checking", data);

  const [step, SetStep] = useState(1)


  const acceptFunction = async (id) => {
    const token = JSON.parse(localStorage.getItem("token"));
    const data = {
      status: "PARTIAL_COMPLETED",
    };

    console.log(data);

    try {
      const response = await axios.patch(
        `${config.backendURL}executor/slaughter-change-task-status/${id}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      if (!response.data) {
        throw new Error("No data found");
      }
      refetch()
      console.log(response)
      toast.success(response?.data?.data);

      return response.data;
    } catch (error) {
      console.error("Error posting:", error);
      toast.error("An error occurred while rejecting.");
      throw error;
    }
  };


  return (
    <>

      {step == 1 ?
        <div className='min-h-[100vh]'>
          <div className="relative overflow-x-auto mb-[200px]">
            <table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
              <thead className="text-xs text-pacific-blue uppercase bg-[#CFF0F2] ">

                <tr>
                  <th scope="col" className="px-6 py-3 text-base font-medium">
                    <div className='flex gap-1 items-center'>
                      Order ID
                    </div>
                  </th>

                  <th scope="col" className="px-6 py-3 text-base font-medium">
                    <div className='flex gap-1 items-center'>
                      Task ID
                    </div>
                  </th>
                  <th scope="col" className="px-6 py-3 text-base font-medium">
                    <div className='flex gap-1 items-center'>
                      Task Date
                    </div>
                  </th>
                  <th scope="col" className="px-6 py-3 text-base font-medium">
                    <div className='flex gap-1 items-center'>

                      Reporter  ID

                    </div>

                  </th>
                  <th scope="col" className="px-6 py-3 text-base font-medium">
                    Payment Status
                  </th>


                  <th scope="col" className="px-6 py-3 text-base font-medium">
                    Action
                  </th>
                  <th scope="col" className="px-2 py-3 text-base font-medium">

                  </th>
                </tr>
              </thead>
              <tbody>
                {data?.map((e, index) => (
                  <tr key={index} className="bg-white border-b ">
                    <th scope="row" className="px-6 py-4 text-sm font-medium">
                      {e?.reporter_order?.order?.ref_id}
                    </th>
                    <th scope="row" className="px-6 py-4 text-sm font-medium">
                      {e?.id}
                    </th>
                    <td className="px-6 py-4 text-sm font-medium">
                      {formatDate(e?.reporter_order?.created_at)}
                    </td>
                    <td className="px-6 py-4 text-sm font-medium">
                      {e?.reporter_order?.reporter_id}
                    </td>

                    <td className="px-6 py-4 text-[#09CB3F] text-sm font-medium">
                      {e?.reporter_order?.order?.payment_status}
                    </td>



                    <td className="px-6 py-4 text-sm font-medium flex">
                      {/* {e?.task_completed_image == null ?
                        <button className="mr-2 flex gap-1 cursor-pointer px-5 pl-2 text-green-500 border border-green-500 rounded-2xl text-center py-2"
                          onClick={() => {
                          
                            console.log(e)

                            toast.error("Please upload the image before completing the task.")
                          }}
                        >
                          <IoArrowDownCircleOutline className="text-lg" />
                          <p> Partial Completed</p>
                        </button>
                        : */}
                        <button className="mr-2 flex gap-1 cursor-pointer px-5 pl-2 text-green-500 border border-green-500 rounded-2xl text-center py-2"
                          onClick={() => {
                            acceptFunction(e?.id)
                            console.log(e)
                          }}
                        >
                          <IoArrowDownCircleOutline className="text-lg" />
                          <p> Partial Completed</p>
                        </button>
                      {/* } */}

                    </td>
                    <th scope="row" className="px-2 py-4"
                      onClick={() => {
                        SetItemDetail(e)
                        SetStep(2)

                      }}>
                      <IoIosArrowForward />
                    </th>
                  </tr>
                ))}
              </tbody>

            </table>


          </div>
        </div>
        :
        <DetailPage
          changeDetailPage={() => { SetStep(1) }}
          activeTab="Ongoing"
          ItemDetail={itemDetail}
        />
      }
    </>
  )
}

export default OnGoing