import { axios } from "../../config/axios";
import { endpoint } from "../../config/constants";
import { formatDate } from "../../utils/formatDate";

export const getOrder = async (orderType) => {
	let parms = {};
	if (orderType !== "") {
		parms.order_type = orderType;
	}

	const token = JSON.parse(localStorage.getItem("token"));
	const response = await axios.get(endpoint.GET_ORDERS, {
		params: parms,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});

	if (!response.data) {
		throw new Error("No data found");
	}
	const transformedData = response?.data?.data?.map((data) => {
		const propertiesToDelete = [
			"updated_at",
			"supplier_id",
			"customer_id",
			"animal_id",
			"payment_id",
		];

		propertiesToDelete.forEach((property) => {
			if (data[property]) {
				delete data[property];
			}
		});
		return {
			...data,
			created_at: formatDate(data.created_at),

		};
	});
	return transformedData;
};






// getOrderDetails
export const getOrderDetails = async (id) => {
	const token = JSON.parse(localStorage.getItem("token"));
	const response = await axios.get(endpoint.GET_ORDER_DETAILS + `${id}`, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
	if (!response.data) {
		throw new Error("No data found");
	}

	return response.data;
};





// updateOrder

export const updateOrder = async (id, status) => {
	const token = JSON.parse(localStorage.getItem("token"));
	const response = await axios.put(
		endpoint.UPDATE_ORDER_STATUS + `${id}`,
		{
			order_status: status,
		},
		{
			headers: {
				Authorization: `Bearer ${token}`,
			},
		}
	);
	if (!response.data) {
		throw new Error("No data found");
	}
	return response.data;
};

export const refundOrder = async (id, amount) => {
	const token = JSON.parse(localStorage.getItem("token"));
	const response = await axios.put(`${endpoint.CANCELLED_ORDER_REFUNDED}${id}`, null, {
	  params: { amount },
	  headers: {
		Authorization: `Bearer ${token}`,
	  },
	});
	console.log(response.data)
   
	// if (!response.data) {
	//   throw new Error("No data found");
	// }
  
	return response.data.data;
  };