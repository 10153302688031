import React, { useState } from 'react'
import cattle1 from "../../../assets/images/cattle.png"
import {
  dateIcon,
  locationIcon,
  timeIcon,
  totalTaskIcon,
} from "../../../assets/Table/TabListIcon";
import {
  correctIcon,
  rejectIcon,
  rightArrowIcon,
  reportNowIcon,
} from "../../../assets/Table/TableActionIcon";
import { getOrderDetails } from '../../../services/orders';
import { useQuery } from '@tanstack/react-query';
import { api_keys } from '../../../config/constants';
import { config } from '../../../config/config';
import { toast, ToastContainer } from 'react-toastify';
import HeaderSection from '../../../components/HeaderSection';
import { axios } from '../../../config/axios';
import { Loader } from '../../../components/Loader';

function DetailPage({ changeDetailPage, activeTab, ItemDetail }) {

  const [selectedImage, setSelectedImage] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [selectedImage1, setSelectedImage1] = useState(null);
  const [imageFileBefore, setImageFileBefore] = useState(null);
  const [selectedImage2, setSelectedImage2] = useState(null);
  const [imageFileBefore2, setImageFileBefore2] = useState(null);
  const [selectedImage3, setSelectedImage3] = useState(null);
  const [imageFileBefore3, setImageFileBefore3] = useState(null);
  const [loading, setLoading] = useState(false);


  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setSelectedImage(imageUrl);
      setImageFile(file);
    }
  };

  const handleImageChange1 = (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setSelectedImage1(imageUrl);
      setImageFileBefore(file);
    }
  };
  const handleImageChange2 = (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setSelectedImage2(imageUrl);
      setImageFileBefore2(file);
    }
  };
  const handleImageChange3 = (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setSelectedImage3(imageUrl);
      setImageFileBefore3(file);
    }
  };


  const handleSubmit = async () => {
    console.log(ItemDetail.id)
    if (imageFile) {
      const formData = new FormData();
      formData.append('task_id', ItemDetail?.id); // Assuming ItemDetail is defined elsewhere
      formData.append('task_completed_image', imageFile);
      formData.append('image1', imageFileBefore);
      formData.append('image2', imageFileBefore2);
      formData.append('image3', imageFileBefore3);


      const token = JSON.parse(localStorage.getItem("token"));

      try {
        const response = await axios.patch(`executor/slaughter-upload-completed-task`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${token}`, // Add the token to the request headers
          },
        });
        changeDetailPage()
        setLoading(false)
      } catch (error) {
        console.error('Error uploading image:', error);
        setLoading(false)

      }
    }
  };


  const { data, isError, error, isLoading } = useQuery({
    queryKey: [api_keys.GET_ORDER_DETAIL],
    queryFn: () => getOrderDetails(ItemDetail.reporter_order?.order?.id),
  });
  if (isError) {
    return (
      <div className="">
        There was some Error, Please try again
        {error}
      </div>
    );
  }
  if (isLoading) {
    return <div className="">Please wait, your data is loading</div>;
  }

  return (
    <div>
      <button className="px-4 py-5 text-[#00ADB9]" onClick={() => changeDetailPage()}>Return</button>

      <div className={`w-full rounded-md overflow-hidden ${activeTab === "Rejected" ? "border-[#D9D9D9]  " : " border-[#00ADB9] "}  border-[2px]`}>
        <div className={`w-full px-4 py-5  ${activeTab === "Rejected" ? " text-[#525252] bg-[#D9D9D9]" : "  bg-[#CFF0F2]"}  flex justify-between`}>
          <h1 onClick={() => console.log(ItemDetail)}>{ItemDetail.reporter_order?.order?.ref_id}</h1>
          <h1 className='text-green-600'>Order Price {ItemDetail.reporter_order?.order?.price}</h1>

        </div>

        <div className="flex text-[#808080] text-[12px] px-4 py-5 justify-between flex-wrap">

          <div className="flex">
            <div className="p-2">
              {dateIcon}
            </div>
            <div className="flex flex-col">
              <p>Date of Qurban</p>
              <p>{data?.data?.created_at?.substring(0, 10)}</p>

            </div>
          </div>

          <div className="flex">
            <div className="p-2">
              {locationIcon}
            </div>
            <div className="flex flex-col">
              <p>Order Status</p>
              <p>{data?.data?.order_status}</p>
            </div>
          </div>

          <div className="flex">
            <div className="flex flex-col">
              <p > Feedlot Name</p>
              <p>{data?.data?.animalStocks[0]?.animal?.feedlot?.fullname}</p>
            </div>
          </div>

          <div className="flex">
            <div className="flex flex-col">
              <p > supplier Name</p>
              <p>{data?.data?.animalStocks[0]?.animal?.supplier?.fullname}</p>
            </div>
          </div>

          <div className="flex">
            <div className="p-2">
              {timeIcon}
            </div>
            <div className="flex flex-col">
              <p>Time</p>
              <p>{data?.data?.created_at?.substring(0, 10)}</p>

            </div>
          </div>

        </div>


{ItemDetail?.image1!=null && ItemDetail?.image2!=null && ItemDetail?.image3!=null & ItemDetail?.task_completed_image!=null &&
        <div className=' flex m-4 flex-wrap'>
        <img src={`${config?.backendBaseURL}uploads/${ItemDetail?.image1}`} className="pr-2 h-24" />
        <img src={`${config?.backendBaseURL}uploads/${ItemDetail?.image2}`} className="pr-2 h-24" />
        <img src={`${config?.backendBaseURL}uploads/${ItemDetail?.image3}`} className="pr-2 h-24" />
        <img src={`${config?.backendBaseURL}uploads/${ItemDetail?.task_completed_image}`} className="pr-2 h-24" />
          
        </div>
}

        {/* <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
            <thead className={`text-xs  ${activeTab === "Rejected" ? "text-[#D9D9D9] " : " text-[#00ADB9]"} uppercase bg-gray-50 `}>
              <tr>
                <th scope="col" className="px-6 py-3">
                  Ruminant Image
                </th>
                
                <th scope="col" className="px-6 py-3">
                  Action
                </th>
               
              </tr>
            </thead>
            <tbody>

              {activeTab == "Ongoing" ?
                <>
                  <tr className="bg-white border-b  hover:bg-gray-50 ">
                    {selectedImage1 ? (

                      <th scope="row" className="flex items-center px-6 py-4 font-medium text-gray-900 whitespace-nowrap ">
                        <img src={selectedImage1} className="mr-2 w-[40px] h-[35px] rounded-md" />

                      </th>
                    ) : (
                      <th scope="row" className="flex items-center px-6 py-4 font-medium text-gray-900 whitespace-nowrap ">
                        <div className="mr-2 w-[40px] h-[35px] rounded-md bg-gray-500" > </div>


                      </th>
                    )}
                  

                    <td className="px-6 py-4 text-white">
                      <button className='btn bg-[#9747FF]  p-2 rounded-md ' onClick={() => document.getElementById('imageUpload1').click()} >Upload Image 1</button>
                      <input type='file' id='imageUpload1' className='hidden' accept="image/*" onChange={handleImageChange1} />
                    </td>



                  </tr>



                  <tr className="bg-white border-b  hover:bg-gray-50 ">
                    {selectedImage3 ? (

                      <th scope="row" className="flex items-center px-6 py-4 font-medium text-gray-900 whitespace-nowrap ">
                        <img src={selectedImage3} className="mr-2 w-[40px] h-[35px] rounded-md" />

                      </th>
                    ) : (
                      <th scope="row" className="flex items-center px-6 py-4 font-medium text-gray-900 whitespace-nowrap ">
                        <div className="mr-2 w-[40px] h-[35px] rounded-md bg-gray-500" > </div>


                      </th>
                    )}
                   

                    <td className="px-6 py-4 text-white">
                      <button className='btn bg-[#9747FF]  p-2 rounded-md ' onClick={() => document.getElementById('imageUpload3').click()} >Upload Image 2</button>
                      <input type='file' id='imageUpload3' className='hidden' accept="image/*" onChange={handleImageChange3} />
                    </td>



                  </tr>

                  <tr className="bg-white border-b  hover:bg-gray-50 ">
                    {selectedImage2 ? (

                      <th scope="row" className="flex items-center px-6 py-4 font-medium text-gray-900 whitespace-nowrap ">
                        <img src={selectedImage2} className="mr-2 w-[40px] h-[35px] rounded-md" />

                      </th>
                    ) : (
                      <th scope="row" className="flex items-center px-6 py-4 font-medium text-gray-900 whitespace-nowrap ">
                        <div className="mr-2 w-[40px] h-[35px] rounded-md bg-gray-500" > </div>


                      </th>
                    )}
                   

                    <td className="px-6 py-4 text-white">
                      <button className='btn bg-[#9747FF] p-2 rounded-md ' onClick={() => document.getElementById('imageUpload2').click()} >Upload Image 3</button>
                      <input type='file' id='imageUpload2' className='hidden' accept="image/*" onChange={handleImageChange2} />
                    </td>



                  </tr>
                  
                  <tr className="bg-white border-b  hover:bg-gray-50 ">
                    {selectedImage ? (

                      <th scope="row" className="flex items-center px-6 py-4 font-medium text-gray-900 whitespace-nowrap ">
                        <img src={selectedImage} className="mr-2 w-[40px] h-[35px] rounded-md" />

                      </th>
                    ) : (
                      <th scope="row" className="flex items-center px-6 py-4 font-medium text-gray-900 whitespace-nowrap ">
                        <div className="mr-2 w-[40px] h-[35px] rounded-md bg-gray-500" > </div>


                      </th>
                    )}
                    

                    <td className="px-6 py-4 text-white">
                      <button className='btn bg-[#9747FF] p-2 rounded-md ' onClick={() => document.getElementById('imageUpload').click()} >Upload Image 4</button>
                      <input type='file' id='imageUpload' className='hidden' accept="image/*" onChange={handleImageChange} />
                    </td>



                  </tr>
                </>
                :

                <>
                  {ItemDetail?.reporter_order?.order?.animalStocks?.map((e) => {
                    return (
                      <tr className="bg-white border-b  hover:bg-gray-50 ">
                        <th scope="row" className="flex items-center px-6 py-4 font-medium text-gray-900 whitespace-nowrap ">
                          <img src={`${config?.backendBaseURL}${e?.animal?.animal_image}`} className="pr-2 h-12" />


                        </th>
                        <td className="px-6 py-4">


                          {e?.animal?.ruminent_id}

                        </td>
                        <td className="px-6 py-4">
                          {e?.animal?.animal_gender}
                        </td>
                        <td className="px-6 py-4">
                          {e?.animal?.package_type}

                        </td>

                        <td className="px-6 py-4">
                          {e?.portions}
                        </td>



                        {activeTab == "Completed" ?
                          (<td className="px-6 py-4 text-[#00ADB9] e">
                            <button className='btn border border-[#00ADB9] w-[100px] py-2 rounded-md mr-2'>Uploaded</button>
                            <button className='btn border border-[#00ADB9] w-[100px] py-2 rounded-md '>Uploaded</button>
                          </td>) :

                          activeTab == "Rejected" ?
                            (<td className="px-6 py-4 text-[#00ADB9] e">
                              <button className={`btn text-white bg-[#D9D9D9] w-[100px] py-2 rounded-md mr-2`}>Uploaded</button>
                              <button className='btn text-white bg-[#D9D9D9] w-[100px] py-2 rounded-md '>Uploaded</button>
                            </td>)
                            : null
                        }
                      </tr>
                    )
                  })}

                </>

              }

            </tbody>
          </table>
        </div> */}

      </div>









      {/* Principle Table */}

      <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-5 mb-5 border border-[#D9D9D9]">
        <table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
          <thead className={`text-xs uppercase ${activeTab === "Rejected" ? "text-[#525252] bg-[#D9D9D9]" : "text-xs text-[#00ADB9] bg-[#CFF0F2]"} `}>
            <tr>
              <th scope="col" className="px-6 py-3">
                Id
              </th>
              <th scope="col" className="px-6 py-3">
                Customer Type
              </th>
              <th scope="col" className="px-6 py-3">
                Name
              </th>

              <th scope="col" className="px-6 py-3">
                Phone Number
              </th>
              <th scope="col" className="px-6 py-3">
                Country
              </th>
              <th scope="col" className="px-6 py-3">
                State
              </th>
              <th scope="col" className="px-6 py-3">
                Region
              </th>
              <th scope="col" className="px-6 py-3">
                IC Number
              </th>
              <th scope="col" className="px-6 py-3">
                Address
              </th>

              <th scope="col" className="px-6 py-3">
                Portion
              </th>
            </tr>
          </thead>
          <tbody>

            <tr className="bg-white border-b  hover:bg-gray-50 ">
              <td
                className="px-6 py-4">

                {ItemDetail?.reporter_order?.order?.customer?.ref_id}
              </td>
              <td
                className="px-6 py-4">

                Principle
              </td>

              <td className="px-6 py-4">
                {ItemDetail?.reporter_order?.order?.customer?.fullname}
              </td>
              <td className="px-6 py-4">
                {ItemDetail?.reporter_order?.order?.customer?.phone_no}
              </td>

              <td className="px-6 py-4">
                {ItemDetail?.reporter_order?.order?.customer?.country?.name}
              </td>

              <td className="px-6 py-4">
                {ItemDetail?.reporter_order?.order?.customer?.state?.name}
              </td>

              <td className="px-6 py-4">
                {ItemDetail?.reporter_order?.order?.customer?.region?.name}
              </td>



              <td className="px-6 py-4">
                {ItemDetail?.reporter_order?.order?.customer?.ic_number}
              </td>
              <td className="px-6 py-4">
                {ItemDetail?.reporter_order?.order?.customer?.address}
              </td>

              <td className="px-6 py-4">
                {ItemDetail?.reporter_order?.order?.principle_portions}
              </td>

            </tr>







          </tbody>
        </table>


      </div>






      {/* Organization Table */}

      {ItemDetail?.reporter_order?.order?.OrderHistoryOrganization?.length != 0 &&
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-5 mb-5 border border-[#D9D9D9]">
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
            <thead className={`text-xs uppercase ${activeTab === "Rejected" ? "text-[#525252] bg-[#D9D9D9]" : "text-xs text-[#00ADB9] bg-[#CFF0F2]"} `}>
              <tr>
                <th scope="col" className="px-6 py-3">
                  Id
                </th>
                <th scope="col" className="px-6 py-3">
                  Customer Type
                </th>
                <th scope="col" className="px-6 py-3">
                  Name
                </th>

                <th scope="col" className="px-6 py-3">
                  Portion
                </th>
              </tr>
            </thead>
            <tbody>







              {ItemDetail?.reporter_order?.order?.OrderHistoryOrganization
                .map((organization, index) => (
                  <tr key={index} className="bg-white border-b">
                    <td className="px-6 py-4 whitespace-nowrap">{organization?.UserOrganization?.id}</td>
                    <td className="px-6 py-4 whitespace-nowrap">Organization</td>

                    <td className="px-6 py-4 whitespace-nowrap">{organization?.UserOrganization?.name}</td>


                    <td className="px-6 py-4 whitespace-nowrap">{organization?.portions}</td>

                  </tr>
                ))}

            </tbody>
          </table>


        </div>
      }











      {/* Family Table */}
      {ItemDetail?.reporter_order?.order?.familyMembersPortions.length != 0 &&
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-5 mb-5 border border-[#D9D9D9]">
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
            <thead className={`text-xs uppercase ${activeTab === "Rejected" ? "text-[#525252] bg-[#D9D9D9]" : "text-xs text-[#00ADB9] bg-[#CFF0F2]"} `}>
              <tr>
                <th scope="col" className="px-6 py-3">
                  Id
                </th>
                <th scope="col" className="px-6 py-3">
                  Customer Type
                </th>
                <th scope="col" className="px-6 py-3">
                  Name
                </th>


                <th scope="col" className="px-6 py-3">
                  IC Number
                </th>


                <th scope="col" className="px-6 py-3">
                  Portion
                </th>
              </tr>
            </thead>
            <tbody>



              {ItemDetail?.reporter_order?.order?.familyMembersPortions.map((familyMember, index) => (
                <tr key={index} className="bg-white border-b">
                  <td className="px-6 py-4 whitespace-nowrap">{familyMember?.familyMemberId}</td>
                  <td className="px-6 py-4 whitespace-nowrap">Family</td>
                  <td className="px-6 py-4 whitespace-nowrap">{familyMember?.familyMember?.fullname}</td>

                  <td className="px-6 py-4 whitespace-nowrap">{familyMember?.familyMember?.ic_number}</td>


                  <td className="px-6 py-4 whitespace-nowrap">{familyMember?.portions}</td>

                </tr>
              ))}




            </tbody>
          </table>


        </div>
      }




      {activeTab === "New" ? (
        // <div className="flex w-full mb-[200px] md:mb-[100px]">
        //   <div
        //     className="flex w-[50%] px-4 justify-center bg-[#FFF5F2] items-center mx-1 font-medium py-1 border cursor-not-allowed border-[#E42726] text-[#E42726] rounded-md"
        //   >
        //     <span className="flex relative w-6 h-6 justify-center items-center mx-1 mr-2 ml-0 my-0 rounded-full">
        //       {rejectIcon}
        //     </span>
        //     <div className="font-normal leading-none">Reject</div>
        //   </div>
        //   <div
        //     className="cursor-not-allowed flex w-[50%] bg-[#58C083] justify-center items-center mx-1 font-medium py-1 px-4 border  border-[#58C083] text-white rounded-md"
        //   >
        //     <span className="flex relative w-6 h-6 justify-center items-center mx-1 mr-2 ml-0 my-0 rounded-full">
        //       {correctIcon}
        //     </span>
        //     <div className="font-normal leading-none">Accept</div>
        //   </div>
        // </div>
        <></>
      ) :


        activeTab === "Ongoing" ? (<>
          {/* <div className='rounded-md overflow-hidden shadow-md sm:rounded-lg mt-5 mb-5 border border-[#D9D9D9]'>
            <div className={` ${activeTab === "Rejected" ? "text-[#525252] bg-[#D9D9D9]" : "text-[#00ADB9] uppercase bg-[#CFF0F2]"} p-3`}> <h1>Remarks</h1></div>

            <div>


              <textarea id="message" rows="6" className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg   " placeholder="Write Somthing here..."></textarea>

            </div>
          </div>
          <div className="flex w-full mb-[200px] md:mb-[100px] text-white">

            {selectedImage && selectedImage1 ? (
              <div className="flex w-[100%] bg-green-500 justify-center items-center mx-1 font-medium py-3 px-4 border cursor-pointer rounded-md">
                {loading ?
                  <Loader /> :
                  <button className="font-normal leading-none"
                    onClick={() => {
                      setLoading(true)
                      handleSubmit()
                      console.log(ItemDetail?.id)
                    }}

                  >Submit</button>
                }

              </div>
            ) : (
              <div className="flex w-[100%] bg-[#CCCCCC] justify-center items-center mx-1 font-medium py-3 px-4 border cursor-not-allowed rounded-md">
                <div className="font-normal leading-none">Submit</div>
              </div>
            )}
          </div> */}
        </>
        ) :

          activeTab === "Completed" ? (<>
            <div className='rounded-md overflow-hidden shadow-md sm:rounded-lg mt-5 mb-5 border border-[#D9D9D9]'>
              <div className='text-[#00ADB9] uppercase bg-[#CFF0F2] p-3'> <h1>Remarks</h1></div>

              <div>


                <textarea id="message" rows="6" className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg  " placeholder="Write Somthing here..."></textarea>

              </div>
            </div>
            <div className="flex w-full mb-[200px] md:mb-[100px] text-[#00ADB9]">
              <div
                className="flex w-[100%] px-4 justify-center py-3  items-center mx-1 font-medium  border border-[#00ADB9] cursor-pointer  rounded-md"
              >
                <div className="font-normal leading-none">Completed</div>
              </div>

            </div></>
          )




            : null}




      <ToastContainer />
    </div>
  )
}

export default DetailPage
