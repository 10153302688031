import React from 'react'
import Button from '../../components/Button/Button'
import Requestreceived from "../../assets/images/RequestReceived/Moneyverse Request Approved.png"
import { Link } from 'react-router-dom'

const RequestReceived = () => {
  return (
    <>   
      <div className="flex flex-col items-center min-h-screen px-3 md:mt-24 mt-10 mb-[200px] md:mb-56">
          <div className="flex flex-col items-center border border-quill-grey rounded-xl bg-white md:w-[503px] w-full p-4 ">
            <img
              src={Requestreceived}
              className="h-40 mb-7"
              alt="Profile Verification"
            />
            <span className="md:text-2xl text-lg">Request received!</span>
            <span className=" text-xs text-star-dust my-5 font-medium  md:px-8">Your payment is currently being processed and will be credited to your account shortly.</span>

              <div className="flex my-2 items-end text-pacific-blue">
                <p className="text-xs md:text-2xl font-medium">RM </p>
                <p className="text-xl md:text-4xl font-semibold">300.00</p>
              </div>
            <span className=" text-xs text-star-dust mb-5 mt-1 font-medium">26 July 2024 at 03:30 PM</span>



            <div className='bg-[#EBEBEB] rounded-lg w-[95%] p-4'>

              <div className='flex w-full justify-between pb-3'>
                <p className='md:text-sm text-xs font-medium text-[#525252]'>Recipient</p>
                <p className='md:text-sm text-xs font-medium text-pacific-blue'>Ahmad Ali Khan</p>
              </div>

              <div className='flex w-full justify-between pb-3'>
                <p className='md:text-sm text-xs font-medium text-[#525252]'>Bank Name </p>
                <p className='md:text-sm text-xs font-medium text-pacific-blue'>Maybank Berhad</p>
              </div>

               <div className='flex w-full justify-between '>
                <p className='md:text-sm text-xs font-medium text-[#525252]'>Account Number</p>
                <p className='md:text-sm text-xs font-medium text-pacific-blue'>1140-1234-5678</p>
              </div>
            </div>



            


            <Link to="/viewhistory" className='w-full'>


<Button
  className="w-full px-32 mt-4"
>
View History
</Button>
</Link>
</div>
        </div>
  </>
  )
}

export default RequestReceived