import React, { useState, useEffect } from 'react';
import VerifiProcess from '../../../assets/images/ProfileVerification/VerifiProcess.png'
import VerifiSuccess from '../../../assets/images/ProfileVerification/VerifiSuccess.png'
import Button from '../../../components/Button/Button';
import { Link } from 'react-router-dom';

function ThirdStep() {
  const [showProcess, setShowProcess] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowProcess(false);
    }, 4000); // 4 seconds

    return () => clearTimeout(timer); // Cleanup the timer if the component unmounts
  }, []);

  return (
    <div>
      {showProcess ?
        <div className="flex flex-col justify-center min-h-screen px-3">
        <div className="flex flex-col items-center ">
          <img
            src={VerifiProcess}
            className="h-40 md:h-80 mb-7"
            alt="Profile Verification"
          />
          <div className="md:text-3xl text-lg font-bold text-dark-teal mt-4">
          Your verification is in process.
          </div>
          <div className="md:text-lg text-xs text-[#848484] md:w-[29rem] w-80 text-center mt-4 mb-10 mx-3">
          Snap a quick selfie for identity verification. Ensure your face is well-lit, visible and fits the square provided. Don’t worry, we’ll keep it secure.
          </div>
          
        </div>
      </div> : 
       
       <div className="flex flex-col justify-center min-h-screen px-3">
        <div className="flex flex-col items-center ">
          <img
            src={VerifiSuccess}
            className="h-40 md:h-80 mb-7"
            alt="Profile Verification"
          />
          <div className="md:text-3xl text-lg font-bold text-dark-teal mt-4">
          Verification Success!
          </div>
          <div className="md:text-lg text-xs text-[#848484] md:w-[29rem] w-80 text-center mt-4 mb-10 mx-3">
          Congrats! Your identity has been verified. You’re all set to enjoy our services securely.
          </div>
<Link to="/DashboardMenu">

          <Button
              className="px-12 mt-4"
            >
             Return to dashboard
            </Button>
          
</Link>
        </div>
      </div>
       
       
       }
    </div>
  );
}

export default ThirdStep;
