import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ResuableInputField from "../../../components/ResuableInputField";
import AddCattle from "../../cattle/components/AddCattle";
import { useGetCattleById } from "../../cattle/hooks/useGetCattles";
import { axios } from "../../../config/axios";
import Button from "../../../components/Button/Button";
const PaymentHistory = ({changeStep,historyProp}) => {
  const [data,setData]=useState()
 

useEffect(()=>{
 fetchHistory()
},[])


const fetchHistory=async()=>{
  const token = JSON.parse(localStorage.getItem("token"));

  const url_path = historyProp?.role === "SLAUGHTER" 
  ? `executor/payment-history?slaughter_id=${historyProp.id}&status=PAYMENT_SUCCESS`
  : `executor/payment-history?reporter_id=${historyProp.id}&status=PAYMENT_SUCCESS`;

  const response = await axios.get(url_path, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
 
  if (!response.data) {
    throw new Error("No data found");
  }
  setData(response?.data?.data)
}



  return (
 <>
  <button className="text-gray-500 mb-4 block" onClick={() => {
    changeStep(1)
  }}>
         Return
      </button>
 


      <table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
        <thead className="text-xs text-pacific-blue uppercase bg-[#CFF0F2] ">
          <tr>
            <th scope="col" className="px-6 py-3 text-base font-medium ">
              <div className="flex gap-1 items-center">Id</div>
            </th>
            <th scope="col" className="px-6 py-3 text-base font-medium">
              <div className="flex gap-1 items-center">Full Name</div>
            </th>
            <th scope="col" className="px-6 py-3 text-base font-medium">
              <div className="flex gap-1 items-center">Email</div>
            </th>
            <th scope="col" className="px-6 py-3 text-base font-medium">
              <div className="flex gap-1 items-center">Phone No</div>
            </th>
            <th scope="col" className="px-6 py-3 text-base font-medium">
              <div className="flex gap-1 items-center">IC Number</div>
            </th>
            <th scope="col" className="px-6 py-3 text-base font-medium">
              <div className="flex gap-1 items-center">Paid Amount</div>
            </th>
            <th scope="col" className="px-6 py-3 text-base font-medium">
              <div className="flex gap-1 items-center">Date</div>
            </th>
            <th scope="col" className="px-6 py-3 text-base font-medium">
              <div className="flex gap-1 items-center">Executer ID</div>
            </th>
            
           
            <th scope="col" className="px-6 py-3 text-base font-medium">
              <div className="flex gap-1 items-center">Payment</div>
            </th>
           
          </tr>
        </thead>
        <tbody>
          {data?.payments?.map((e) => {
            return (
              <tr className="bg-white border-b" key={e?.id}>
                <th scope="row" className="px-6 py-4 text-sm font-medium">
                  {e?.id}
                </th>
                <td className="px-6 py-4 text-sm font-medium">{e?.executor?.fullname}</td>
                <td className="px-6 py-4 text-sm font-medium">{e?.executor?.email}</td>
                <td className="px-6 py-4 text-sm font-medium">{e?.executor?.phone_no}</td>
                <td className="px-6 py-4 text-sm font-medium">
                  {e?.executor?.ic_number}
                </td>
                <td className="px-6 py-4 text-sm font-medium"> {e?.paid_amount? "RM" :""}  {(Number(e?.paid_amount) || 0).toFixed(2)}
</td>
                <td className="px-6 py-4 text-sm font-medium">
                  {e?.created_at.slice(0,10)}
                </td>
                <td className="px-6 py-4 text-sm font-medium">
                  {e?.executor_id}
                </td>
              
                <td className="px-6 py-4 text-sm font-medium">
                  {e?.status? <Button className="w-24 bg-green-400">Success </Button> : <Button className="bg-red-500 hover:bg-red-500 w-24" disabled>Failed </Button>}
                  </td>
             
              </tr>
            );
          })}
        </tbody>
      </table>
 </>
  );
};

export default PaymentHistory;
