import React, { useEffect, useState } from "react";
import Button from "../../../components/Button/Button";
import { Dialog } from "@headlessui/react";
import { useFetchFeddlotList, useFetchSuppliersList } from "../hooks/useGetCattles";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { axios } from "../../../config/axios";
import { Loader } from "../../../components/Loader";
import { useGetPackagesHook } from "../../settings/Packages/hooks/useGetPackagesHook";
import { useGetPkgDetails } from "../../settings/PackagesDetail/hooks/useGetPkgDetails";


const AddCattle = ({ isOpen, setIsOpen, updateId = null }) => {
  const { PackageDetail } = useGetPkgDetails();
  let [addModal, setAddModal] = useState(false);
  const [addloading, setAddLoading] = useState(false);
  const [feedlotList, setFeedlotList] = useState([]);

  const { suppliresList } = useFetchSuppliersList();
  // const { feedlotList } = useFetchFeddlotList();
  const { packages } = useGetPackagesHook();

  const {
    register: registerAddRuminant,
    handleSubmit: handleAddRuminants,
    formState: { errors: errorsAddRuminant },
    setValue: setValueAddRuminant,
    watch: watchEditRuminant,
  } = useForm();
  const user = JSON.parse(localStorage.getItem("user"));
  const handleAddRuminant = async (data) => {
    console.log(data);
    setAddLoading(true);

    const token = JSON.parse(localStorage.getItem("token"));
  

    const formData = new FormData();

    // Ensure supplier_id, feedlot_id, package_id, age, and weight are numbers
    // data.supplier_id = parseInt(data.supplier_id) || 0;
    data.supplier_id = user?.role === "SUPPLIER" ? user.id : (parseInt(data?.supplier_id) || 0);
    data.feedlot_id = parseInt(data.feedlot_id) || 0;
    data.package_id = parseInt(data.package_id) || 0;
    data.animal_gender = data.animal_gender || "";
    data.dob = data.dob || "";
    data.tag_id = data.tag_id || "";
    data.weight = parseFloat(data.weight);
    // data.age = 1;

    // Iterate through data object keys
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        if (key === 'animal_image' && data[key]) {
          formData.append(key, data[key][0]);
        } else {
          formData.append(key, data[key]);
        }
      }
    }

    // Log formData for debugging
    for (let [key, value] of formData.entries()) {
      console.log(`${key}: ${value}`);
    }

    try {
      const response = await axios.post(
        `/animal-inventory`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response);
      setAddModal(false);
      setAddLoading(false)
      setIsOpen(false)
      toast.success("Ruminant Created");
    } catch (error) {
      toast.error(error?.response?.data?.error || "Something went wrong");
      console.error("Error:", error);
      setIsOpen(false)
    } finally {
      setAddLoading(false);
    }
    console.log("Edit item", data);
  };


  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(true)}
        className="fixed inset-0 z-50 overflow-y-auto p-4 duration-500 "
      >
        <div className="flex items-center justify-center min-h-screen">
          <Dialog.Overlay className="fixed inset-0 bg-black/30" />

          <Dialog.Panel className="relative max-w-[600px] w-full mx-auto bg-white p-4 rounded">
            <Dialog.Title className="text-lg font-medium mb-6 border-b-2 flex items-center justify-between">
              <span className="" onClick={()=>console.log(suppliresList)}>
                {updateId ? "Update Ruminant" : "Add Ruminant"}
              </span>
              <div
                className="flex items-center justify-center "
                onClick={() => setIsOpen(false)}
              >
                <h1 className="bg-gray-200 px-2 rounded-md mb-1 hover:text-red-600 cursor-pointer">
                  x
                </h1>
              </div>
            </Dialog.Title>

            <form
              onSubmit={handleAddRuminants(handleAddRuminant)}
              encType="multipart/form-data"
            >
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              {user?.role !== "SUPPLIER" && (
                <div className="">
                  <label
                    htmlFor="supplier"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Select Supplier
                  </label>
                  <select
                    id="supplier"
                    className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                    {...registerAddRuminant("supplier_id", {
                      required: true,
                    })}
                    name="supplier_id"
                    onChange={(e) => {
                      setValueAddRuminant("supplier_id", e.target.value);
                      const selectedSupplier = suppliresList?.data?.data.find(
                        (ele) => ele.id === parseInt(e.target.value)
                      );
                      
                      const feedlots = selectedSupplier?.feedlots || [];
                      setFeedlotList(feedlots)
                    }}
                  >
                    <option value="">
                      Select the Supplier
                    </option>
                    {suppliresList?.data?.data.map((supplier, index) => (
                      <option key={index} value={supplier.id}>
                        {supplier?.fullname}
                      </option>
                    ))}
                  </select>
                  {errorsAddRuminant.supplier_id && (
                    <span className="text-red-clr">
                      This field is required
                    </span>
                  )}
                </div>
              )}

                <div className="">
                  <label
                    htmlFor="feedlot_id"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Select Feedlot
                  </label>
                  <select
                    id="feedlot_id"
                    className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                    name="feedlot_id"
                    onChange={(e) =>
                      setValueAddRuminant("feedlot_id", e.target.value)
                    }
                    {...registerAddRuminant("feedlot_id", { required: true })}
                  >
                    <option value="">Select the feedlot</option>
                    {user?.role === "SUPPLIER" ? (
  user?.feedlots?.map((feedlot, index) => (
    <option key={index} value={feedlot.id}>
      {feedlot?.fullname}
    </option>
  ))
) : (
  feedlotList?.map((feedlot, index) => (
    <option key={index} value={feedlot.id}>
      {feedlot?.fullname}
    </option>
  ))
)}

                  </select>
                  {errorsAddRuminant.feedlot_id && (
                    <span className="text-red-clr">
                      This field is required
                    </span>
                  )}
                </div>

                <div className="">
                  <label
                    htmlFor="package_id"
                    className="block text-sm font-medium text-gray-700"
                    onClick={() => console.log(PackageDetail)}
                  >
                    Sub Packages
                  </label>

                  <select
                    name="package_id"
                    id="package_id"
                    onChange={(e) => setValueAddRuminant("package_id", e.target.value)}
                    {...registerAddRuminant("package_id", { required: true })}
                    className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                  >
                    <option value="">Select Package</option>
                    {PackageDetail?.filter(pkg => pkg?.package?.pkg_type != "WAQF" && pkg.enabled).map((pkg, index) => (
                      <option key={index} value={pkg?.id}>
                        {pkg?.name}
                      </option>
                    ))}
                  </select>

                  {errorsAddRuminant.package_id && (
                    <span className="text-red-clr">
                      This field is required
                    </span>
                  )}
                </div>

                <div className="">
                  <label htmlFor="dob" className="block text-sm font-medium text-gray-700">
                    Date of birth
                  </label>
                  <input
                    id="dob"
                    type="date"
                    {...registerAddRuminant("dob", { required: true })}
                    className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                    min="0"
                  />
                  {errorsAddRuminant.dob && (
                    <span className="text-red-clr">
                      This field is required
                    </span>
                  )}
                </div>
                <div className="">
                  <label htmlFor="tag_id" className="block text-sm font-medium text-gray-700">
                  Tag ID                 </label>
                  <input
                    id="tag_id"
                    type="text"
                    {...registerAddRuminant("tag_id", { required: false })}
                    className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                    min="0"
                  />
                  {errorsAddRuminant.tag_id && (
                    <span className="text-red-clr">
                      This field is required
                    </span>
                  )}
                </div>

                <div className="mb-4">
                  <label htmlFor="weight" className="block mb-2 font-medium text-gray-700">
                    Weight (kg)
                  </label>
                  <input
                    id="weight"
                    type="number"
                    step="0.01"
                    {...registerAddRuminant("weight", { required: true })}
                    className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                    min="0"
                  />
                  {errorsAddRuminant.weight && (
                    <span className="text-red-clr">
                      This field is required
                    </span>
                  )}
                </div>

                <div className="mb-4">
                  <label htmlFor="animal_gender" className="block mb-2 font-medium text-gray-700">
                    Gender
                  </label>
                  <select
                    id="animal_gender"
                    className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                    {...registerAddRuminant("animal_gender", {
                      required: true,
                    })}
                    onChange={(e) => setValueAddRuminant("animal_gender", e.target.value)}
                  >
                    <option value="">Select the Gender</option>
                    <option value="MALE">MALE</option>
                    <option value="FEMALE">FEMALE</option>
                  </select>
                  {errorsAddRuminant.animal_gender && (
                    <span className="text-red-clr">This field is required</span>
                  )}
                </div>

                <div className="">
                  <label htmlFor="animal_image" className="block mb-2 font-medium text-gray-700">
                    Image
                  </label>
                  <input
                    id="animal_image"
                    type="file"
                    {...registerAddRuminant("animal_image")}
                    className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                  />
                  {errorsAddRuminant.animal_image && (
                    <span className="text-red-clr">
                      This field is required
                    </span>
                  )}
                </div>
              </div>

              <div className="flex justify-end mt-10">

                {addloading?<Button><Loader/></Button>:
                <Button type="submit">
                  {updateId ? "Update Ruminant" : "Add Ruminant"}
                </Button>
}
              </div>
            </form>
          </Dialog.Panel>
        </div>
      </Dialog>
    </div>
  );
};

export default AddCattle;
