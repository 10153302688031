import React, { useEffect, useState } from "react";
import HeaderSection from "../../../../components/HeaderSection";
import DataTable from "../../../../components/Table/DataTable";
import CreateNewPackage from "./CreateNewPackage";
import { useGetPackagesHook } from "../hooks/useGetPackagesHook";
import { Loader } from "../../../../components/Loader";
import Search from "../../../../components/Search";
import Button from "../../../../components/Button/Button";
import { config } from "../../../../config/config";
import { formatDate, formatDateWithoutTime } from "../../../../utils/formatDate";
import { FaUser } from "react-icons/fa";

const DisplayPackages = () => {
  let [isOpen, setIsOpen] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [updateId, setUpdateId] = useState(null);
  const { packages, isError, isLoading, refetch } = useGetPackagesHook();
  const updatePackage = (id) => {
    setUpdateId(id);
    setShowEdit(true);
  };
  useEffect(() => {
    refetch();
  }, [showEdit,isOpen]);
  if (isError) {
    return <div className="">There was some Error, Please try again</div>;
  }
  if (isLoading) {
    return (
      <div className="flex space-x-2">
        <Loader />
        <div className="">Loading...</div>
      </div>
    );
  }
  console.log("packages", packages);
  return (
    <div>
      <HeaderSection
        title={"Packages"}
        btnTitle={"Create New Package"}
        setIsOpen={setIsOpen}
        
      />
      <div className="flex items-center justify-end mb-3">
        <Search />
      </div>

<div className="overflow-auto">

      <table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
          <thead className="text-xs text-pacific-blue uppercase bg-[#CFF0F2] ">
            <tr > 

              <th scope="col" className="px-6 py-3 text-base font-medium ">
                <div className='flex gap-1 items-center'
                onClick={()=>console.log(packages)}>
                  Id
                </div>
              </th>

              <th scope="col" className="px-6 py-3 text-base font-medium">
                <div className='flex gap-1 items-center'>

                 Pkg Image
                </div>

              </th>

              <th scope="col" className="px-6 py-3 text-base font-medium">
                <div className='flex gap-1 items-center'>

               Name
                </div>

              </th>

              <th scope="col" className="px-6 py-3 text-base font-medium">
                <div className='flex gap-1 items-center'>

               Package Type
                </div>

              </th>

              
              <th scope="col" className="px-6 py-3 text-base font-medium">
                <div className='flex gap-1 items-center'>
                Short Des

                </div>
              </th>
             
             

              <th scope="col" className="px-6 py-3 text-base font-medium">
                <div className='flex gap-1 items-center'>

                Country Name
                </div>

              </th>

              <th scope="col" className="px-6 py-3 text-base font-medium">
                <div className='flex gap-1 items-center'>

                Starting From Label
                </div>

              </th>

              <th scope="col" className="px-6 py-3 text-base font-medium">
                <div className='flex gap-1 items-center'>

                Installment Label
                </div>

              </th>


              <th scope="col" className="px-6 py-3 text-base font-medium">
                <div className='flex gap-1 items-center'>

                Starting from value
                </div>

              </th>

              

              {/* <th scope="col" className="px-6 py-3 text-base font-medium">
                <div className='flex gap-1 items-center'>

                Pkg Quota
                </div>

              </th> */}

              {/* <th scope="col" className="px-6 py-3 text-base font-medium">
                <div className='flex gap-1 items-center'>

                 Package Info
                </div>

              </th> */}



             

             

              <th scope="col" className="px-6 py-3 text-base font-medium">
                <div className='flex gap-1 items-center'>

                  Pkg Link
                </div>

              </th>
              <th scope="col" className="px-6 py-3 text-base font-medium">
                <div className='flex gap-1 items-center'>

                CREATED DATE
                </div>

              </th>
              <th scope="col" className="px-6 py-3 text-base font-medium">
                <div className='flex gap-1 items-center'>

                Updated Date
                </div>

              </th>

              
              <th scope="col" className="px-6 py-3 text-base font-medium">
                <div className='flex gap-1 items-center'>

                Status
                </div>

              </th>




              <th scope="col" className="px-2 py-3 text-base font-medium">
                  Action
              </th>
            </tr>
          </thead>
          <tbody>


            {packages?.map((e) => {
              return (
                <tr className="bg-white border-b " key={e?.id}>

                  <th scope="row" className="px-6 py-4 text-sm font-medium">
                    {e?.id}
                  </th>

                  <td className="px-6 py-4 text-sm font-medium">
                  {(e?.pkg_image !== undefined && e?.pkg_image !== "" && e?.pkg_image !== "undefined" && e?.pkg_image !== null && e?.pkg_image !== "null") ? (
                      <img src={`${config?.backendBaseURL}uploads/${e?.pkg_image}`} className="w-14" />
                    ) : (
                      <FaUser className="w-8 h-10" />
                    )}

                  </td>

                  <th scope="row" className="px-6 py-4 text-sm font-medium">
                    {e?.name}
                  </th>

                  <td className="px-6 py-4 text-sm font-medium">
                  {e?.pkg_type === "WAQF" ? "SADAQAH" : e?.pkg_type}
                  </td>
                
                  <td className="px-6 py-4 text-sm font-medium">
                    {e?.short_des}
                  </td>
                 

                  


                  <td className="px-6 py-4 text-sm font-medium">
                    {e?.country}
                  </td>

                  <td className="px-6 py-4 text-sm font-medium">
                    {e?.text}
                  </td>

                  <td className="px-6 py-4 text-sm font-medium">
                    {e?.installment_text}
                  </td>
                 
                  
                  <td className="px-6 py-4 text-sm font-medium ">
                    {e?.pkg_price}
                  </td>



                 

                  {/* <td className="px-6 py-4 text-sm font-medium">
                    {e?.pkg_quota}
                  </td> */}

                  {/* <td className="px-6 py-4 text-sm font-medium">
                    {e?.pkg_info}
                  </td> */}

                 

                  <td className="px-6 py-4 text-sm font-medium">
                    {e?.pkg_info_link}
                  </td>
                  <td className="px-6 py-4 text-sm font-medium">
                  {formatDate(e?.extraData?.created_at)}
                  </td>
                  <td className="px-6 py-4 text-sm font-medium">
                  {formatDate(e?.extraData?.updated_at)}
                  </td>


                  <td className="px-6 py-4 text-sm font-medium">
                  {e?.is_active? <Button className="w-24 ">Active </Button> : <Button className="bg-red-500 hover:bg-red-500 w-24" disabled>In-Active </Button>}
                  </td>


                  <td className="px-6 py-4 text-sm font-medium">
                    <Button onClick={() => {
                     updatePackage(e)
                    }}>
                      Edit
                    </Button>
                  </td>


                </tr>
              )
            })}


          </tbody>
        </table>
        </div>


   
      <CreateNewPackage isOpen={isOpen} setIsOpen={setIsOpen} />
      {showEdit && (
        <CreateNewPackage
          isOpen={showEdit}
          setIsOpen={setShowEdit}
          updateId={updateId}
        />
      )}
    </div>
  );
};

export default DisplayPackages;
