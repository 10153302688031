import React from "react";
import { Link } from "react-router-dom";

function FaqsQuestions() {
  return (
    <div className="lg:w-[58vw] space-y-5 px-2 md:px-10">
      <div>
        <Link to='/support-center/faqs'>
        <p className="flex items-center gap-1 text-[#A4A4A4]">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            className="size-3"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M15.75 19.5 8.25 12l7.5-7.5"
            />
          </svg>
          Return
        </p>
        </Link>
      </div>
      <div className="space-y-5">
         <h1 className="text-[20px] md:text-[32px] text-[#00ADB9]">How do i update my billing information?</h1>
         <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.</p>
         <ul className="list-disc pl-5">
            <li>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam re.</li>
            <li>pisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.</li>
            <li>Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</li>
         </ul>
         <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium.</p>
      </div>
    </div>
  );
}

export default FaqsQuestions;
