import { useQuery } from "@tanstack/react-query";
import { api_keys } from "../../../config/constants";
import { getAllReporters } from "../../../services/reporter/index";


const useReporter = (pageNumber, limit) => {
    const {
        data: reporter,
        isError,
        isLoading,
        refetch
      } = useQuery({
        queryKey: [api_keys.GET_USERS, pageNumber, limit],
        queryFn: () => getAllReporters(pageNumber, limit),
      });
      return {
        reporter,
        isError,
        isLoading,
        refetch
      };
}

export default useReporter