import React, { useState, useEffect } from "react";
import ResuableInputField from "../../../../components/ResuableInputField";
import {
  useFetchFeddlotList,
  useFetchSuppliersList,
} from "../../../cattle/hooks/useGetCattles";
import { useGetAnimal } from "../../../settings/AnimalTypes/hooks/useGetAnimal";
import { Bar } from "react-chartjs-2";
import { axios } from "../../../../config/axios";

const Stock = () => {
  const { suppliresList } = useFetchSuppliersList();
  const { animals, isError, isLoading } = useGetAnimal();
  console.log("suppliresList");
  console.log(suppliresList?.data?.data);

  //   const feedlotOptions = [
  //     { value: "1", label: "Feedlot 1" },
  //     { value: "2", label: "Feedlot 2" },
  //     { value: "3", label: "Feedlot 3" },
  //   ];

  //   const animalOptions = [
  //     { value: "1", label: "Animal Type 1" },
  //     { value: "2", label: "Animal Type 2" },
  //     { value: "3", label: "Animal Type 3" },
  //   ];

  const [formData, setFormData] = useState({
    supplierId: "",
    feedlotId: "",
    animalTypeId: "",
  });

  const [chartData, setChartData] = useState(null);

  const [feedlots, setFeedlots] = useState([]);

  const handleChange = async (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    if (name === "supplierId" && value) {
      // Fetch feedlots based on the selected supplierId
      await fetchFeedlots(value);
    }
  };

  const fetchFeedlots = async (supplierId) => {
    const token = JSON.parse(localStorage.getItem("token")); // Retrieve token from localStorage
    try {
      const response = await axios.get(`users/${supplierId}`, {        
        headers: {
          Authorization: `Bearer ${token}`, // Set the Authorization header with the token
        },
      });
      setFeedlots(response.data.data); // Adjust this path based on the actual API response structure
    } catch (error) {
      console.error("Failed to fetch feedlots:", error);
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      const { supplierId, feedlotId, animalTypeId } = formData;
      //   if (supplierId && feedlotId && animalTypeId) {
      try {
        const token = JSON.parse(localStorage.getItem("token"));
        const params = {};
        if (supplierId) params.supplier_id = supplierId;
        if (feedlotId) params.feedlot_id = feedlotId;
        if (animalTypeId) params.animal_type_id = animalTypeId;
        const response = await axios.get("reports/stock", {
          params,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = response.data.data;
        const labels = data.map((item) => item.animal_type_name);
        const totalStock = data.map((item) => item.totalStock);
        const availableStock = data.map((item) => item.availableStock);

        setChartData({
          labels,
          datasets: [
            {
              label: "Total Stock",
              data: totalStock,
              backgroundColor: "rgba(75,192,192,0.4)",
            },
            {
              label: "Available Stock",
              data: availableStock,
              backgroundColor: "rgba(153,102,255,0.4)",
            },
          ],
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    // };

    fetchData();
  }, [formData]);

  const supplierOptions =
    suppliresList?.data?.data?.map((supplier) => ({
      value: supplier.id,
      label: supplier.fullname,
    })) || [];

  const feedlotOptions =
    feedlots?.supplierFeedlots?.map((feedlot) => ({
      value: feedlot.id,
      label: feedlot.fullname,
    })) || [];

  return (
    <>
      <div className=" bg-gray-100 p-6">
        <div className="max-w-7xl mx-auto">
        <h1 className="text-2xl font-bold mb-6">Stocks</h1>
          <div className="grid grid-cols-12 gap-4 mb-4">
            <div className="col-span-12 sm:col-span-3">
              <ResuableInputField
                className="focus:border-pacific-blue focus:outline-none"
                type="select"
                label="Supplier ID"
                name="supplierId"
                onChange={handleChange}
                value={formData.supplierId}
                options={supplierOptions}
              />
            </div>
            <div className="col-span-12 sm:col-span-3">
              <ResuableInputField
                className="focus:border-pacific-blue focus:outline-none"
                type="select"
                label="Feedlot ID"
                name="feedlotId"
                onChange={handleChange}
                value={formData.feedlotId}
                options={feedlotOptions}
              />
            </div>
            <div className="col-span-12 sm:col-span-3">
              <ResuableInputField
                className="focus:border-pacific-blue focus:outline-none"
                type="select"
                label="Animal Type ID"
                name="animalTypeId"
                onChange={handleChange}
                value={formData.animalTypeId}
                options={
                  animals?.map((pkg) => ({
                    value: pkg.id,
                    label: pkg.name,
                  })) || []
                }
              />
            </div>
          </div>
          {chartData && (
            <div className="mt-8">
              <Bar data={chartData} />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Stock;
