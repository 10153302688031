import React from 'react'
import Button from '../../components/Button/Button'
import Paidpic from "../../assets/images/RequestReceived/Allura Celebrations.png"
import Tick from "../../assets/images/RequestReceived/tick.png"
import { CiShare2 } from "react-icons/ci";
import { Link } from 'react-router-dom'

const Paid = () => {
  return (
    <>   
      <div className="flex flex-col items-center min-h-screen px-3 md:mt-24 mt-10 mb-[200px] md:mb-56">
          <div className="flex flex-col items-center border border-quill-grey rounded-xl bg-white md:w-[503px] w-full p-4 ">
            <div className='flex w-full flex-row-reverse text-pacific-blue '>
                <div className='flex items-center gap-2 border border-pacific-blue px-6 rounded-lg'>
                <CiShare2 className='text-2xl'/> 
                <p className='text-base font-medium'>Share</p>

                </div>
                 </div>
            <img
              src={Paidpic}
              className="h-40 mb-7"
              alt="Profile Verification"
            />
            <div className='flex gap-1 items-center'>
<img src={Tick} className='w-5 h-5' />
            <span className="md:text-2xl text-lg">Paid</span>
            </div>

              <div className="flex my-2 items-end text-pacific-blue">
                <p className="text-xs md:text-2xl font-medium">RM </p>
                <p className="text-xl md:text-4xl font-semibold">300.00</p>
              </div>
            <span className=" text-xs text-star-dust mb-5 mt-1 font-medium">26 July 2024 at 03:30 PM</span>



            <div className='bg-[#EBEBEB] rounded-lg w-[95%] p-4'>

              <div className='flex w-full justify-between pb-3'>
                <p className='md:text-sm text-xs font-medium text-[#525252]'>Recipient</p>
                <p className='md:text-sm text-xs font-medium text-pacific-blue'>Ahmad Ali Khan</p>
              </div>

              <div className='flex w-full justify-between pb-3'>
                <p className='md:text-sm text-xs font-medium text-[#525252]'>Bank Name </p>
                <p className='md:text-sm text-xs font-medium text-pacific-blue'>Maybank Berhad</p>
              </div>

               <div className='flex w-full justify-between '>
                <p className='md:text-sm text-xs font-medium text-[#525252]'>Account Number</p>
                <p className='md:text-sm text-xs font-medium text-pacific-blue'>1140-1234-5678</p>
              </div>
            </div>



            


            <Link to="#" className='w-full'>


<Button
  className="w-full px-32 mt-4"
>
Download Receipt
</Button>
</Link>
</div>
        </div>
  </>
  )
}

export default Paid