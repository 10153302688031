import React, { useState } from 'react';
import TransTab from './TransTab';
import Search from '../../../components/Search';
import NewOrder from './newOrder';
const Executor = ({data,refetch}) => {
  const [activeTab, setActiveTab] = useState('NewTasks'); // Initial active tab

  const tabsName = ['NewTasks' , 'Assigned',"Slaughter Completed","Completed"]; // Tabs to display
  const tabNumberCounting = [
    { name: 'NewTasks', countValue: data.totalCount },
    { name: 'Assigned', countValue: data.totalCount },
    { name: 'Slaughter Completed', countValue: data.totalCount },
    { name: 'Completed', countValue: data.totalCount }, // Counts for each tab
    // { name: 'History', countValue: 38 },
  ];

  // Function to handle tab change
  const handleTabChange = (tabName) => {
    setActiveTab(tabName);
  };

  console.log("Just Checking123" ,data)
  // Render conditionally based on activeTab
  const renderTabContent = () => {
    switch (activeTab) {
      case 'NewTasks':
        return <NewOrder data={data} tab="new" refetch={refetch}/>;
      case 'Assigned':
        return <NewOrder data={data} tab="asign" refetch={refetch}/>;
        case 'Slaughter Completed':
        return <NewOrder data={data} tab="PARTIAL_COMPLETED" refetch={refetch}/>;
        case 'Completed':
        return <NewOrder data={data} tab="COMPLETED" refetch={refetch}/>;
      default:
        return null;
    }
  };

  return (
    <>
      {/* Upper Tab */}
      <TransTab
        tabsName={tabsName}
        tabNumberCounting={tabNumberCounting}
        onTabChange={handleTabChange}
        searchbar={<Search className="md:w-[300px] my-2 md:my-0" />}
      />
      
      {renderTabContent()}
    </>
  );
};

export default Executor;
