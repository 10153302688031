import SignIn from "./pages/SignIn";
import { Route, Routes, Navigate } from "react-router-dom";
import Signup from "./pages/Signup";
import useToken from "./hooks/useToken";
import Dashboard from "./pages/Dashboard";
import ProtectedRoute from "./HOC/ProtectedRoute";
import ThankYou from "./pages/ThankYou";
import SignInnew from "./pages/SignInnew";
import Forgot from "./pages/Forgot";
import ResetPassword from "./pages/ResetPassword";

function App() {
	const { token } = useToken();

	return (
		<Routes>
			<Route
				path="/"
				element={!token ? <SignInnew /> : <Navigate to="/AdminDashboard" />}
			/>
			<Route element={<ProtectedRoute />}>
				<Route path="/*" element={<Dashboard />} />
			</Route>
			<Route path="/login" element={<SignInnew />} />
			<Route path="/login" element={<SignInnew />} />
			<Route path="/login/:role" element={<SignInnew />} />
			<Route path="/signup" element={<Signup />} />
			<Route path="/forgot-password" element={<Forgot />} />
			<Route path="/reset-password" element={<ResetPassword />} />
			<Route path="/thankyou" element={<ThankYou />} />
		</Routes>
	);
}

export default App;
