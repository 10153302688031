import React, { useState } from 'react'
import { useGetSlaughterDataHook } from "../hooks/useGetTaskDataHook";
import { useParams } from "react-router-dom";
import Executor from './task';


function SlaughterMain() {
    
 let { page = 1 } = useParams();
 page = Number(page);
 if (page < 1) {
   page = 1;
 }
 const [pageNumber, setPageNumber] = useState(page);
 const [limit, setLimit] = useState(10);
 const { data, isError, isLoading ,refetch} = useGetSlaughterDataHook(
  pageNumber,
  limit
);

if(isLoading){
    return(<h1>
        Loading
    </h1>)
}
if(isError){
    return(<h1>
        Error
    </h1>)
}




  return (
    <div>
     <Executor data={data} refetch={refetch}/>
    </div>
  )
}

export default SlaughterMain
