import React from "react";
import { useForm } from "react-hook-form";
import Button from "../components/Button/Button";
import supplierSignupSchema from "../schema/supplierSignup";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "@tanstack/react-query";
import { signupSupplier } from "../services/auth";
import { api_keys } from "../config/constants";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import logo from "../assets/DQ Digital Qurban.png";
import {
  useFetchCountires,
  useFetchCountryStateRegions,
  useFetchCountryStates,
} from "../features/countries/hooks/useFetchCountries";
import ReusableInput from "../components/ResuableInputField";
import { notify } from "../utils/toasts";

const Signup = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm({
    resolver: zodResolver(supplierSignupSchema),
  });
  const { countries } = useFetchCountires();
  const { states } = useFetchCountryStates(watch("country_id"));
  const { regions } = useFetchCountryStateRegions(watch("state_id"));

  const mutation = useMutation({
    mutationKey: [api_keys.SIGNUP_SUPPLIER],
    mutationFn: signupSupplier,
    onSuccess: () => {
      navigate("/thankyou");
    },
    onError: (err) => {
      // console.log(err);
      notify("error", err?.response?.data?.error || "Unknow error occur");
    },
  });
  const onSubmit = (data) => {
    delete data.confirmPassword;
    mutation.mutate(data);
  };
  return (
    <div className="min-h-screen flex sm:items-center justify-center bg-gray-100 p-4 ">
      <div className="w-full sm:w-[800px] bg-white p-8 rounded-md shadow-md h-full">
        <div className="flex flex-col items-center justify-center mb-4 text-xl">
          <Link to="" className="flex h-[40px] mb-10">
            <span className="self-center text-xl font-semibold sm:text-2xl whitespace-nowrap">
              <img
                src={logo}
                alt=""
                className="h-[80px] max-w-[400px] ml-3 mt-2"
              />
            </span>
          </Link>
          
          <h1 className="text-sm text-[#9FA1AD] ">Pendaftaran Akaun Pembekal</h1>
        </div>

        <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
          <div className="grid sm:grid-cols-2 gap-4 mb-4">
            <div>
              <input
                className="w-full p-2 rounded-md border border-gray-300 focus:outline-none focus:border-blue-500"
                type="text"
                placeholder="Nama penuh*"
                {...register("fullname")}
              />
              {errors.fullname && (
                <p className="text-red-500 text-sm">
                  {errors.fullname.message}
                </p>
              )}
            </div>
            <div>
              <input
                className="w-full p-2 rounded-md border border-gray-300 focus:outline-none focus:border-blue-500"
                type="email"
                placeholder="E-Mel*"
                {...register("email")}
              />
              {errors.email && (
                <p className="text-red-500 text-sm">{errors.email.message}</p>
              )}
            </div>
            <div>
              <input
                className="w-full p-2 rounded-md border border-gray-300 focus:outline-none focus:border-blue-500"
                type="tel"
                id="MobileNumber"
                placeholder="Telefon Bimbit 601112345678*"
                {...register("phone_no")}
              />
              {errors.phone_no && (
                <p className="text-red-500 text-sm">
                  {errors.phone_no.message}
                </p>
              )}
            </div>
            <div>
              <input
                className="w-full p-2 rounded-md border border-gray-300 focus:outline-none focus:border-blue-500"
                type="text"
                placeholder="No KP / Pasport*"
                {...register("ic_number")}
              />
              {errors.ic_number && (
                <p className="text-red-500 text-sm">
                  {errors.ic_number.message}
                </p>
              )}
            </div>
            <div>
              <input
                className="w-full p-2 rounded-md border border-gray-300 focus:outline-none focus:border-blue-500"
                type="password"
                placeholder="Masukkan kata laluan baru*"
                {...register("password")}
              />
              {errors.password && (
                <p className="text-red-500 text-sm">
                  {errors.password.message}
                </p>
              )}
            </div>
            <div>
              <input
                className="w-full p-2 rounded-md border border-gray-300 focus:outline-none focus:border-blue-500"
                type="password"
                placeholder="Sahkan kata laluan*"
                {...register("confirmPassword")}
              />
              {errors.confirmPassword && (
                <p className="text-red-500 text-sm">
                  {errors.confirmPassword.message}
                </p>
              )}
            </div>
            <div>
              <ReusableInput
                label={"Negara*"}
                type={"select"}
                options={countries?.data.map((country) => ({
                  value: country.id,
                  label: country.name,
                }))}
                onChange={(e) => setValue("country_id", e.target.value)}
              />
              {errors.country_id && (
                <p className="text-red-500 text-sm">
                  {errors.country_id.message}
                </p>
              )}
            </div>
            <div>
              <ReusableInput
                label={"Negeri*"}
                type={"select"}
                options={states?.data.map((state) => ({
                  value: state.id,
                  label: state.name,
                }))}
                onChange={(e) => setValue("state_id", e.target.value)}
              />
              {errors.state_id && (
                <p className="text-red-500 text-sm">
                  {errors.state_id.message}
                </p>
              )}
            </div>
            <div>
              <ReusableInput
                label={"Daerah*"}
                type={"select"}
                options={regions?.data.map((region) => ({
                  value: region.id,
                  label: region.name,
                }))}
                onChange={(e) => setValue("region_id", e.target.value)}
              />
              {errors.region_id && (
                <p className="text-red-500 text-sm">
                  {errors.region_id.message}
                </p>
              )}
            </div>
          </div>
          <Button type="submit" className="font-semibold">
          Daftar
          </Button>
        </form>
        {/* <div className="text-sm mt-10">
					<Link to="/forgot-password" className="text-blue-500 underline">
						Forgot Password?
					</Link>
				</div> */}

        <div className="mt-4 text-sm ">
          <Link
            to="/login"
            className="flex text-black items-center justify-center w-full"
          >
           Dah ada akaun, sila?{" "}
            <span className="text-blue-500 underline">Log Masuk sini</span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Signup;
