import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import Button from "../components/Button/Button";
import { loginUser } from "../services/auth";
import { rolePaths } from "../constants";
import logo from "../assets/DQ Digital Qurban.png";
// import phoneImage from "../assets/mobile.png";
import backgroundImage from "../assets/images/login/mobile.png"; // Add the background image path here
import Gradient from "../assets/images/login/Gradient.png";
import { notify } from "../utils/toasts";
import { AiOutlineMail, AiOutlineLock } from "react-icons/ai"; // Import icons
import { useParams } from 'react-router-dom';
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { config } from "../config/config";

const SignInnew = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  let navigate = useNavigate();
  const { role } = useParams();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (event) => {
    setisLoading(true);
    localStorage.setItem("password", event.password);

    try {
       
      let response;
      if (role?.toLowerCase() === 'executor') {
        response = await loginUser(event.email, event.password, event.position);
      }
      else if (role?.toLowerCase() === 'supplier') {
        response = await loginUser(event.email, event.password, "SUPPLIER");
      }
       else {
        response = await loginUser(event.email, event.password);
      }
      // let response = await loginUser(event.email, event.password);
      if (response.success) {
        debugger;
        navigate(rolePaths[response.user.role]);
        notify("success", "Login successful");
        const s_user = JSON.stringify(response.user);
        localStorage.setItem("user", s_user);
        localStorage.setItem("token", JSON.stringify(response.token.token));
        localStorage.setItem("tokenExp", response.token.expires);
      }
    } catch (error) {
      console.log(error);
      notify("error", error?.response?.data?.message || "An error occurred");
    }
    setisLoading(false);
  };
  const getSignupLink = () => {debugger
    switch (role?.toLowerCase()) {
      case 'executer':
        return `${config?.frontendBaseUrl}Executor`;
      case 'supplier':
        return `${config?.frontendBaseUrl}Supplier`;
      default:
        return '/signup';
    }
  };

  const getSignupText = () => {
    switch (role?.toLowerCase()) {
      case 'executor':
        return 'Daftar sebagai Pelaksana';
      case 'supplier':
        return 'Daftar sebagai Pembekal';
      default:
        return 'Daftar sekarang';
    }
  };

  return (
    <div
      className="min-h-screen w-full flex flex-col sm:flex-row items-center justify-center bg-white relative"
      // style={{
      //   backgroundImage: `url(${Gradient})`,
      //   backgroundSize: "cover",
      // }}
    >
<div>
<img src={backgroundImage} className="absolute right-0 bottom-0 md:w-[45%] w-[80%] md:h-[90%] h-[75%] z-20"/>
<img src={Gradient} className="absolute right-0 bottom-0 md:w-[80%] h-screen z-0"/>

</div>
   
      <div className="flex flex-col w-full sm:w-[44%] p-8 sm:p-10  z-50">
        <div className="flex flex-col items-center mb-8">
          <Link to="" className="flex h-[30px] mb-8">
            <img src={logo} alt="Digital Qurban" className="h-full" />
          </Link>
          <h1 className="text-2xl text-[#405189] font-bold mb-2">
            {/* Bersedia untuk berbakti? */}
            Log masuk untuk meneruskan
          </h1>
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className="w-full">

        {role?.toLowerCase() === 'executor' && (
            <div className="mb-6">
              <label htmlFor="position" className="block text-sm text-black mb-2">Sila pilih jawatan</label>
              <select
                id="position"
                name="position"
                {...register("position", { required: "Please select a position" })}
                className="w-full p-3 rounded-md border border-gray-300 focus:outline-none focus:border-blue-500"
              >
                <option value="REPORTER">Pelapor</option>
                <option value="SLAUGHTER">Penyembelih</option>
              </select>
              {errors.position && (
                <p className="text-red-500 text-sm mt-1">
                  {errors.position.message}
                </p>
              )}
            </div>
          )}

          <div className="mb-4 relative">
            <label htmlFor="email" className="block text-sm text-black mb-2">
              E-mel
            </label>
            <input
              type="text"
              placeholder="Emel"
              {...register("email", {
                required: "Please enter a valid email address",
                pattern: /^\S+@\S+$/i,
              })}
              className="w-full p-3 pr-10 rounded-md border border-gray-300 focus:outline-none focus:border-blue-500"
            />
            {errors.email && (
              <p className="text-red-500 text-sm mt-1">
                {errors.email.message}
              </p>
            )}
           
            <AiOutlineMail className="absolute right-3 top-11 text-gray-400" />
          </div>
          <div className="mb-6 relative">
            <label htmlFor="password" className="block text-sm text-black mb-2">
              Kata laluan
            </label>
            <input
               type={showPassword ? "text" : "password"}
              placeholder="Masukkan kata laluan"
              {...register("password", { required: "Password is required" })}
              className="w-full p-3 pr-10 rounded-md border border-gray-300 focus:outline-none focus:border-blue-500"
            />
            {errors.password && (
              <p className="text-red-500 text-sm mt-1">
                {errors.password.message}
              </p>
            )}
              <button
          type="button"
          className="absolute right-3 top-11 text-gray-400"
          onClick={() => setShowPassword(!showPassword)}
        >
          {showPassword ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
        </button>
            {/* <AiOutlineLock className="absolute right-3 top-11 text-gray-400" /> */}
          </div>
          <div className="w-full mb-6 text-right">
            <Link to="/forgot-password" className="">
              Lupa kata laluan?
            </Link>
          </div>
          <Button type="submit" disabled={isLoading} className="w-full p-3">
            {isLoading ? "Please wait" : "Log Masuk"}
          </Button>
        </form>
        <div className="mt-6 text-sm text-center">
          {/* <Link to="/signup" className="text-black underline">
            Tidak ada akaun? <span className="font-bold ">Daftar sekarang</span> 
          </Link> */}
          {getSignupLink() !== "/signup"&&
          <a href={getSignupLink()} className="text-black underline">
            Tidak ada akaun? {navigate}<span className="font-bold ">{getSignupText()}</span>
          </a>
          }
        <div className="pt-14">© DigitalQurban2024</div>
        </div>
      </div>





      <div className="hidden sm:flex w-1/2 justify-end items-center">
        {/* <img src={phoneImage} alt="Phone" className="h-[500px] max-w-[500px]" /> */}
      </div>
    </div>
  );
};

export default SignInnew;
