import React, { useState } from 'react'
import Button from '../../../components/Button/Button'

function SaleTable({sales}) {



  return (
    <div>



<table className="w-full text-sm overflow-x-auto text-left rtl:text-right text-gray-500 ">
          
          <thead className="text-xs text-pacific-blue uppercase bg-[#CFF0F2] ">
            <tr > 

            <th scope="col" className="px-6 py-3 text-base font-medium ">
                <div className='flex gap-1 items-center'>
                  Id
                </div>
              </th>
              <th scope="col" className="px-6 py-3 text-base font-medium ">
                <div className='flex gap-1 items-center'>
                  Order Id
                </div>
              </th>
              <th scope="col" className="px-6 py-3 text-base font-medium ">
                <div className='flex gap-1 items-center'>
                  Full Name
                </div>
              </th>
              <th scope="col" className="px-6 py-3 text-base font-medium">
                <div className='flex gap-1 items-center'>

                  Email
                </div>

              </th>
              <th scope="col" className="px-6 py-3 text-base font-medium">
                <div className='flex gap-1 items-center'>
                  IC Number

                </div>
              </th>
           
             

              <th scope="col" className="px-6 py-3 text-base font-medium">
                <div className='flex gap-1 items-center'>

                  Phone No
                </div>

              </th>

             


              <th scope="col" className="px-6 py-3 text-base font-medium">
                <div className='flex gap-1 items-center'>

                  Package Name
                </div>

              </th>

              <th scope="col" className="px-6 py-3 text-base font-medium">
                <div className='flex gap-1 items-center'>

                  Sale
                </div>

              </th>

              {/* <th scope="col" className="px-6 py-3 text-base font-medium">
                <div className='flex gap-1 items-center'>

                  Price/Unit
                </div>

              </th>

              <th scope="col" className="px-6 py-3 text-base font-medium">
                <div className='flex gap-1 items-center'>

                 Actual Price
                </div>

              </th> */}



              {/* <th scope="col" className="px-6 py-3 text-base font-medium">
                <div className='flex gap-1 items-center'>

                  Status
                </div>

              </th> */}

            
              
            </tr>
          </thead>
          <tbody>
         

            {sales?.data?.orders?.map((e) => {
              return (
                <tr className="bg-white border-b " key={e?.id}>

                  <th scope="row" className="px-6 py-4 text-sm font-medium">
                    {e?.id}
                  </th>
                  <th scope="row" className="px-6 py-4 text-sm font-medium">
                    {e?.ref_id}
                  </th>
                 
                  <td className="px-6 py-4 text-sm font-medium">
                    {e?.customer?.fullname}
                  </td>
                  <td className="px-6 py-4 text-sm font-medium">
                    {e?.customer?.email}
                  </td>
                 

                  <td className="px-6 py-4 text-sm font-medium">
                    {e?.customer?.ic_number}
                  </td>


                 
                
                  <td className="px-6 py-4 text-sm font-medium">
                  {e?.customer?.phone_no}
                  </td>

                  <td className="px-6 py-4 text-sm font-medium">
                  {e?.package?.name}
                  </td>

                 

                  <td className="px-6 py-4 text-sm font-medium"> {e?.price? "RM" :""}  {(Number(e?.price) || 0).toFixed(2)}
                  </td>
                
                  {/* <td className="px-6 py-4 text-sm font-medium"> {e?.package?.price_per_unit? "RM" :""}  {(Number(e?.package?.price_per_unit) || 0).toFixed(2)}
              </td>

                 

                  <td className="px-6 py-4 text-sm font-medium"> {e?.package?.actual_price? "RM" :""}  {(Number(e?.package?.actual_price) || 0).toFixed(2)}
              </td> */}

                  {/* <td className="px-6 py-4 text-sm font-medium">
                  {e?.customer?.is_active? <Button className="w-24 ">Active </Button> : <Button className="bg-red-500 hover:bg-red-500 w-24" disabled>In-Active </Button>}
                  </td> */}
                 

                </tr>
              )
            })}


          </tbody>
          <tfoot>
              <tr className="bg-gray-200">
                <td colSpan="7" className="text-right px-6 py-4 text-base font-medium">Total Sales:</td>
                <td colSpan="2" className="px-6 py-4 text-base font-medium">
      RM {sales?.data?.orders.reduce((sum, e) => sum + (Number(e?.price) || 0), 0).toFixed(2)}
    </td>
              </tr>
            </tfoot>
        </table>
    </div>
  )
}

export default SaleTable
