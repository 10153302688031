import { axios } from "../../config/axios";
import { endpoint } from "../../config/constants";

export const getProfile = async () => {
	const token = JSON.parse(localStorage.getItem("token"));
	const response = await axios.get(endpoint.GET_USER_PROFILE, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
	if (!response.data) {
		throw new Error("No data found");
	}
	return response.data;
};
