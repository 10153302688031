import React from "react";
import { useGetProfile } from "./hooks/useFetchUserProfile";
import { Disclosure } from "@headlessui/react";
import ReusableInput from "../../components/ResuableInputField";
const UserProfile = () => {
  const { profile, isError, error, isLoading } = useGetProfile();
  if (isError) {
    return (
      <div className="">
        There was some Error, Please try again
        {error}
      </div>
    );
  }

  if (isLoading) {
    return <div className="">Loading, Please wait!</div>;
  }

  return (
    <div>
      <div className="w-full"> {/* sm:w-[600px] md:w-[800px] lg:w-[900px]  */}
        <h1 className="text-lg font-bold ">Supplier Details</h1>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4  gap-4  border-2 p-3">
          <ReusableInput
            type={"text"}
            label={"Full Name"}
            value={profile?.data?.fullname || ""}
            disable={true}
          />
          <ReusableInput
            type={"text"}
            label={"Email"}
            value={profile?.data?.email || ""}
            disable={true}
          />{" "}
          <ReusableInput
            type={"text"}
            label={"ID"}
            value={profile?.data?.id || ""}
            disable={true}
          />
          <ReusableInput
            type={"text"}
            label={"IC Number"}
            value={profile?.data?.ic_number || ""}
            disable={true}
          />
          <ReusableInput
            type={"text"}
            label={"Phone Number"}
            value={profile?.data?.phone_no || ""}
            disable={true}
          />
          <ReusableInput
            type={"text"}
            label={"Active"}
            value={profile?.data?.is_active || ""}
            disable={true}
          />
          <ReusableInput
            type={"text"}
            label={"Role"}
            value={profile?.data?.role || ""}
            disable={true}
          />
          <ReusableInput
            type={"text"}
            label={"Address"}
            value={profile?.data?.address || ""}
            disable={true}
          />{" "}
          <ReusableInput
            type={"text"}
            label={"Region"}
            value={profile?.data?.region || ""}
            disable={true}
          />{" "}
          <ReusableInput
            type={"text"}
            label={"State"}
            value={profile?.data?.state?.name || ""}
            disable={true}
          />
          <ReusableInput
            type={"text"}
            label={"State ID"}
            value={profile?.data?.state?.id || ""}
            disable={true}
          />{" "}
          <ReusableInput
            type={"text"}
            label={"Country ID"}
            value={profile?.data?.state?.country_id || ""}
            disable={true}
          />
        </div>
        <div>
          {/* <div className="w-full h-1 bg-gray-400 mt-10 mb-6"></div> */}
          {profile?.data?.FamilyMembers.map((member, index) => (
            <Disclosure key={index}>
              {({ open }) => (
                <>
                  <Disclosure.Button className="flex justify-between items-center w-full py-2 px-4 text-sm font-medium text-gray-900 bg-gray-100 rounded-md focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75">
                    <span>{member.familyMember.fullname}</span>
                    <svg
                      className={`${
                        open ? "transform rotate-180" : ""
                      } w-4 h-4`}
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 18a1 1 0 0 1-.707-.293l-8-8a1 1 0 0 1 1.414-1.414L10 15.586l6.293-6.293a1 1 0 1 1 1.414 1.414l-7.999 7.999A1 1 0 0 1 10 18z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </Disclosure.Button>
                  <Disclosure.Panel className="p-4">
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                      <div className="">
                        <label
                          htmlFor="fullname"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Full Name
                        </label>
                        <input
                          type="text"
                          disabled
                          value={member.familyMember.fullname || ""}
                          className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                        />
                      </div>{" "}
                      <div className="">
                        <label
                          htmlFor="fullname"
                          className="block text-sm font-medium text-gray-700"
                        >
                          IC Number
                        </label>
                        <input
                          type="text"
                          disabled
                          value={member.familyMember.ic_number || ""}
                          className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                        />
                      </div>
                      <div className="">
                        <label
                          htmlFor="memberid"
                          className="block text-sm font-medium text-gray-700"
                        >
                          ID
                        </label>
                        <input
                          type="text"
                          disabled
                          value={member.id || ""}
                          className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                        />
                      </div>
                      <div className="">
                        <label
                          htmlFor="orderHistory"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Order History
                        </label>
                        <input
                          type="text"
                          disabled
                          value={member.orderHistoryId || ""}
                          className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                        />
                      </div>{" "}
                      <div className="">
                        <label
                          htmlFor="portions"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Portion
                        </label>
                        <input
                          type="text"
                          disabled
                          value={member.portions || ""}
                          className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                        />
                      </div>{" "}
                      <div className="">
                        <label
                          htmlFor="portions"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Family Member ID
                        </label>
                        <input
                          type="text"
                          disabled
                          value={member.familyMemberId || ""}
                          className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                        />
                      </div>
                    </div>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
          ))}
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
