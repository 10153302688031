import React from "react";
import Search from "../../../components/Search";
import supportcenterImage from "../../../assets/images/SupportCenter/supportCenter.png";
import {
  faqsIcon,
  chatusIcon,
  policyIcon,
  arrowIcon,
} from "../../../assets/Table/SupportCenterIcon";
import { useNavigate } from "react-router-dom";

const supportCenterCard = [
  {
    icon: faqsIcon,
    title: "FAQs",
    des: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium",
    moreRead: "View FAQs",
    route: '/support-center/faqs'
  },

  {
    icon: policyIcon,
    title: "Policies",
    des: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium",
    moreRead: "View Policies",
    route: '/support-center/policies'
  },
  {
    icon: chatusIcon,
    title: "Chat with Us!",
    des: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium",
    moreRead: "Start Chat",
    route: '/support-center/chatus'
  },
];

const SupportCenter = () => {
  const navigate = useNavigate();
  return (
    <>
      <div
        style={{
          backgroundImage: `url(${supportcenterImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "41vh",
        }}
      >
        <div className="flex flex-col md:items-center justify-center h-full">
          <div className="text-center text-white text-2xl mb-4">
            Hello, how can we help?
          </div>
          <div className="px-4 md:px-0">
            <Search />
          </div>
        </div>
      </div>
      <div className="mt-8 flex flex-wrap gap-5 justify-center   mb-[200px]">
        {supportCenterCard.map((item, index) => (
          <div
            key={index}
            className="border-2 border-pacific-blue rounded-lg p-5 w-full md:w-1/3 lg:w-1/4"
          >
            <div className="flex items-center space-x-4 rtl:space-x-reverse text-pacific-blue">
              <div className="flex-shrink-0 rounded-full w-8 h-8 flex items-center ">
                <span className="">{item.icon}</span>
              </div>
              <div className="flex-1 max-w-52">
                <p className="text-[24px] font-bold">{item.title}</p>
              </div>

            </div>
            <div className="text-[#B6B6B6] my-4 leading-5">{item.des}</div>
            <div
              className="text-pacific-blue flex items-center "
            >

{item?.title === "Chat with Us!" ? (
        <>
          <span
            onClick={() => {
              const url = "https://wa.me/03054600001";
              window.open(url, '_blank');
            }}
            className="cursor-pointer"
          >
            {item.moreRead}
          </span>
          <span
            onClick={() => {
              const url = "https://wa.me/03054600001";
              window.open(url, '_blank');
            }}
            className="ml-2 cursor-pointer"
          >
            {arrowIcon}
          </span>
        </>
      ) : (
        <>
          <span
            onClick={() => navigate(item.route)}
            className="cursor-pointer"
          >
            {item.moreRead}
          </span>
          <span
            onClick={() => navigate(item.route)}
            className="ml-2 cursor-pointer"
          >
            {arrowIcon}
          </span>
        </>
      )}

            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default SupportCenter;
