import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import useFetchData from "../hooks/useFetchData";
import HeaderSection from "../components/HeaderSection";
import DataTable from "../components/Table/DataTable";
import { api_keys, order_types } from "../config/constants";
import { getOrder, refundOrder } from "../services/orders";
import AddCattle from "../features/cattle/components/AddCattle";
import ReusableInput from "../components/ResuableInputField";
import Search from "../components/Search";
import Button from "../components/Button/Button";
import Modal from "../components/Modal";
import { notify } from "../utils/toasts";

const Refund = () => {
  let [isOpen, setIsOpen] = useState(false);
  let [option, setOption] = useState("CANCELLED");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [refundAmount, setRefundAmount] = useState("");

  const handleRefundClick = (row) => {
    setModalData(row);
    setIsModalOpen(true);
    setRefundAmount("");
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setModalData(null);
  };

  const handleRefund = async () => {
    const orderAmount = Number(modalData?.paid_amount) || 0;
    const refundValue = Number(refundAmount);
 
     // Calculate the expected refund amount
  const expectedRefundAmount = (orderAmount * 0.90).toFixed(2);

  // Convert the refund value to a fixed string for comparison
  const formattedRefundValue = refundValue.toFixed(2);

  if (formattedRefundValue !== expectedRefundAmount) {
      notify(
        "error",
        `Refund amount must be exactly RM ${(orderAmount * 0.90).toFixed(2)}`
      );
      return;
    }

    try {
      const response = await refundOrder(modalData.id, refundAmount);
      console.log("response");
      console.log(response);
      notify("success", response);
      console.log("Refunding amount:", refundAmount);
      console.log("For order ID:", modalData?.id);
      console.log("API Response:", response);
    } catch (error) {
      console.error("Refund failed", error);
      notify("error", "Refund failed. Please try again.");
    }
    // Close modal after handling refund
    handleCloseModal();
  };

  const handleRefundAmountChange = (e) => {
    const value = e.target.value;

    if (value >= 0 && value <= (Number(modalData?.paid_amount) || 0)) {
      setRefundAmount(value);
    }
  };

  const handleMouseWheel = (e) => {
    e.target.blur();
  };

  const navigate = useNavigate();
  const {
    data: orders,
    isError,
    isLoading,
  } = useFetchData([api_keys.GET_ORDERS, option], () => getOrder(option));

  const onclick_handler = (item) => {
    navigate(`/orders/${item.id}`);
  };

  if (isError) {
    return <div className="">There was some Error, Please try again</div>;
  }
  if (isLoading) {
    return <div className="">Please wait, your data is loading</div>;
  }

  return (
    <div>
      <div className="flex flex-col md:flex-row justify-between md:items-center md:mb-3">
        <HeaderSection title={"Refund"} setIsOpen={setIsOpen} />
        {/* <div className=" md:w-[300px] mb-4 md:mb-0">
          <ReusableInput
            type={"select"}
            options={order_types}
            value={option}
            onChange={(e) => setOption(e.target.value)}
          />
        </div> */}
        <div className="md:max-w-[480px] w-full mb-4 md:mb-0">
          <Search />
        </div>
      </div>

      <table className="w-full text-sm overflow-x-auto text-left rtl:text-right text-gray-500 ">
        <thead className="text-xs text-pacific-blue uppercase bg-[#CFF0F2] ">
          <tr>
            <th scope="col" className="px-6 py-3 text-base font-medium ">
              <div className="flex gap-1 items-center">Id</div>
            </th>
            <th scope="col" className="px-6 py-3 text-base font-medium">
              <div className="flex gap-1 items-center">Order Id</div>
            </th>
            <th scope="col" className="px-6 py-3 text-base font-medium">
              <div className="flex gap-1 items-center">Date</div>
            </th>
            <th scope="col" className="px-6 py-3 text-base font-medium">
              <div className="flex gap-1 items-center">Package Type</div>
            </th>
            <th scope="col" className="px-6 py-3 text-base font-medium">
              <div className="flex gap-1 items-center">Pprtions</div>
            </th>
            <th scope="col" className="px-6 py-3 text-base font-medium">
              <div className="flex gap-1 items-center">Total Portions</div>
            </th>
            <th scope="col" className="px-6 py-3 text-base font-medium">
              <div className="flex gap-1 items-center">Price</div>
            </th>
            <th scope="col" className="px-6 py-3 text-base font-medium">
              <div className="flex gap-1 items-center">Payment Status</div>
            </th>
            <th scope="col" className="px-6 py-3 text-base font-medium">
              <div className="flex gap-1 items-center">Order Status</div>
            </th>
            <th scope="col" className="px-6 py-3 text-base font-medium">
              <div className="flex gap-1 items-center">sadakah Order</div>
            </th>
            <th scope="col" className="px-6 py-3 text-base font-medium">
              <div className="flex gap-1 items-center">Animals Assigned</div>
            </th>
            <th scope="col" className="px-6 py-3 text-base font-medium">
              <div className="flex gap-1 items-center">Installment Option</div>
            </th>
            <th scope="col" className="px-6 py-3 text-base font-medium">
              <div className="flex gap-1 items-center">Customer</div>
            </th>
            <th scope="col" className="px-6 py-3 text-base font-medium">
              <div className="flex gap-1 items-center">Address</div>
            </th>{" "}
            <th scope="col" className="px-6 py-3 text-base font-medium">
              <div className="flex gap-1 items-center">Principal Portions</div>
            </th>
            <th scope="col" className="px-2 py-3 text-base font-medium">
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          {orders?.map((e) => {
            return (
              <tr className="bg-white border-b " key={e?.id}>
                <th scope="row" className="px-6 py-4 text-sm font-medium">
                  {e?.id}
                </th>

                <th scope="row" className="px-6 py-4 text-sm font-medium">
                  {e?.ref_id}
                </th>

                <th scope="row" className="px-6 py-4 text-sm font-medium">
                  {e?.created_at?.slice(0, 10)}
                </th>

                <th scope="row" className="px-6 py-4 text-sm font-medium">
                  {e?.packageType}
                </th>

                <th scope="row" className="px-6 py-4 text-sm font-medium">
                  {e?.portions}
                </th>

                <th scope="row" className="px-6 py-4 text-sm font-medium">
                  {e?.total_portions}
                </th>

                <th scope="row" className="px-6 py-4 text-sm font-medium">
                  {(Number(e?.price) || 0).toFixed(2)}
                </th>

                <th
                  scope="row"
                  className={`px-6 py-4 text-sm font-medium ${
                    e?.payment_status == "PAYMENT_SUCCESS"
                      ? "text-green-400"
                      : "text-red-500"
                  }`}
                >
                  {e?.payment_status}
                </th>

                <th scope="row" className="px-6 py-4 text-sm font-medium">
                  {e?.order_status}
                </th>

                <th
                  scope="row"
                  className={`px-6 py-4 text-sm font-medium ${
                    e?.sadakah_order ? "text-green-400" : "text-red-500"
                  }`}
                >
                  {e?.sadakah_order ? "Yes" : "No"}
                </th>

                <th
                  scope="row"
                  className={`px-6 py-4 text-sm font-medium ${
                    e?.animals_assigned ? "text-green-400" : "text-red-500"
                  }`}
                >
                  {e?.animals_assigned ? "Yes" : "No"}
                </th>

                <th
                  scope="row"
                  className={`px-6 py-4 text-sm font-medium ${
                    e?.installment_option ? "text-green-400" : "text-red-500"
                  }`}
                >
                  {e?.installment_option ? "Yes" : "No"}
                </th>

                <th scope="row" className="px-6 py-4 text-sm font-medium">
                  {e?.customer?.fullname}
                </th>

                <th scope="row" className="px-6 py-4 text-sm font-medium">
                  {e?.address}
                </th>

                <th scope="row" className="px-6 py-4 text-sm font-medium">
                  {e?.principal_portions?.length}
                </th>

                <td className="px-6 py-4 text-sm font-medium text-pacific-blue ">
                  <div className="flex justify-center items-center space-x-4 min-h-[40px]">
                    {e?.payment_status === "PAYMENT_REFUND_PENDING" && (
                      <Button
                        className="px-4 py-2 border border-red-500 !text-red-500 hover:bg-red-500 hover:!text-white transition duration-300 rounded bg-transparent"
                        onClick={() => handleRefundClick(e)}
                      >
                        Refund
                      </Button>
                    )}
                    <Button
                      className="px-8"
                      onClick={() => {
                        navigate(`/orders/${e?.id}`);

                        console.log(e);
                      }}
                    >
                      Detail
                    </Button>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      {isModalOpen && modalData && (
        <Modal onClose={handleCloseModal}>
         <div className="p-6 bg-white rounded-lg shadow-lg">
            <h2 className="text-2xl font-bold mb-6 text-gray-800">Refund Information</h2>
            <div className="space-y-4">

            <div className="flex justify-between text-lg text-gray-700">
                <span className="font-semibold">Name of Customer:</span>
                <span>{modalData.customer.fullname}</span>
              </div>
              <div className="flex justify-between text-lg text-gray-700">
                <span className="font-semibold">Customer Bank Name:</span>
                <span>{modalData.customer.bank_name}</span>
              </div>
              <div className="flex justify-between text-lg text-gray-700">
                <span className="font-semibold">Customer Bank Account:</span>
                <span>{modalData.customer.account_no}</span>
              </div>
              {/* <div className="flex justify-between text-lg text-gray-700">
                <span className="font-semibold">Order Amount:</span>
                <span>RM {(Number(modalData.price) || 0).toFixed(2)}</span>
              </div> */}
              <div className="flex justify-between text-lg text-gray-700">
                <span className="font-semibold">Paid Amount:</span>
                <span>RM {(Number(modalData.paid_amount) || 0).toFixed(2)}</span>
              </div>
              {/* <div className="flex justify-between text-lg text-gray-700">
                <span className="font-semibold">Formula :</span>
                <span>Refund Amt = Paid Amt - 10%</span>
              </div> */}
              <div className="flex justify-between text-lg text-gray-700">
                <span className="font-semibold">Deducted Amount (10%):</span>
                <span>RM {((Number(modalData.paid_amount) || 0) * 0.1).toFixed(2)}</span>
              </div>
              <div className="flex justify-between text-lg text-gray-700">
                <span className="font-semibold">Refund Amount:</span>
                <span>RM {((Number(modalData.paid_amount) || 0) * 0.9).toFixed(2)}</span>
              </div>
              <div className="flex justify-between text-lg text-gray-700">
                <span className="font-semibold">Payment Status:</span>
                <span>{modalData?.payment_status}</span>
              </div>
              <input
                type="number"
                placeholder="Enter refund amount"
                value={refundAmount}
                onChange={handleRefundAmountChange}
                onWheel={handleMouseWheel}
                className="w-full p-2 border border-gray-300 rounded mt-4"
              />
            </div>
            <div className="flex justify-between mt-4">
              <Button
                onClick={handleCloseModal}
                className="bg-gray-500 text-white"
              >
                Close
              </Button>
              <Button
                onClick={handleRefund}
                className="bg-red-500 text-white  hover:bg-red-500 hover:!text-white"
              >
                Refund
              </Button>
            </div>
          </div>
        </Modal>
      )}

      <AddCattle isOpen={isOpen} setIsOpen={setIsOpen} />
    </div>
  );
};

export default Refund;
