import React from "react";
import { Navigate, Outlet } from "react-router-dom";

const RoleCheck = ({ allowedRoles }) => {
	const user = JSON.parse(localStorage.getItem("user"));
	debugger;
	return allowedRoles.includes(user?.role) ? (
		<Outlet />
	) : (
		<Navigate to="/login" />
	);
};

export default RoleCheck;
