// ProgressBar.jsx
import React from 'react';

const ProgressBar = ({ step }) => {
  const progress = step * 33.33; // Each step is 33.33% of the progress

  return (
    <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700 relative">
      <div
        className="bg-blue-600 h-2.5 rounded-full"
        style={{ width: `${progress}%` }}
      >
        
      </div>

    </div>
  );
};

export default ProgressBar;
