import { useQuery } from "@tanstack/react-query";
import { getProfile } from "../../../services/profile";
import { api_keys } from "../../../config/constants";

export const useGetProfile = () => {
	const {
		data: profile,
		isError,
		isLoading,
	} = useQuery({
		queryKey: [api_keys.GET_USER_PROFILE],
		queryFn: () => getProfile(),
	});

	return {
		profile,
		isError,
		isLoading,
	};
};
