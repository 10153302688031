import { axios } from "../../config/axios";
import { config } from "../../config/config";
import { endpoint } from "../../config/constants";
import { formatDate } from "../../utils/formatDate";

export const getAllPackages = async () => {
  const token = JSON.parse(localStorage.getItem("token"));

  const response = await axios.get(endpoint.GET_PACKAGES, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  if (!response.data) {
    throw new Error("No data found");
  }
  const transformedData = response.data.data.map((data) => {
    const tobeDeleted = [""];
    tobeDeleted.forEach((key) => delete data[key]);
    return {
      ...data,
      extraData:data,
      country: data?.country?.name || "N/A",
      // created_at: formatDate(data.created_at),
    };
  });
  return transformedData;
};

export const createPackage = async (data) => {
  console.log("API Create Call")
  console.log(data)
  
  const token = JSON.parse(localStorage.getItem("token"));
  const response = await axios.post(endpoint.GET_PACKAGES, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  if (!response.data) {
    throw new Error("No data found");
  }
  return response.data;
};

export const updatePackage = async (id, data) => {
  console.log("API update Call")
  console.log(data)
  const token = JSON.parse(localStorage.getItem("token"));
  const response = await axios.put(endpoint.GET_PACKAGES + `/${id}`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  if (!response.data) {
    throw new Error("No data found");
  }
  return response.data;
};

export const getAllAnimals = async () => {
  const token = JSON.parse(localStorage.getItem("token"));

  const response = await axios.get(endpoint.GET_ANIMALS, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  if (!response.data) {
    throw new Error("No data found");
  }
  const transformedData = response.data.data.map((data) => {
    const tobeDeleted = ["updated_at"];
    tobeDeleted.forEach((key) => delete data[key]);
    return {
      ...data,
      created_at: formatDate(data.created_at),
    };
  });
  return transformedData;
};

export const createAnimal = async (data) => {
  const token = JSON.parse(localStorage.getItem("token"));
  const response = await axios.post(endpoint.GET_ANIMALS, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  if (!response.data) {
    throw new Error("No data found");
  }
  return response.data;
};
export const updateAnimal = async (id, data) => {
  const token = JSON.parse(localStorage.getItem("token"));
  const response = await axios.put(endpoint.GET_ANIMALS + `/${id}`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  if (!response.data) {
    throw new Error("No data found");
  }
  return response.data;
};

export const getAllPackagesDetails = async (page = 1, limit = 1000) => {
  const token = JSON.parse(localStorage.getItem("token"));
  const response = await axios.get(
    endpoint.GET_PKG_DETAILS + `?pageNumber=${page}&pageSize=${limit}&userRole=ADMIN`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  debugger
  console.log("xyz", response)
  console.log(endpoint?.GET_PKG_DETAILS)
  console.log(endpoint.GET_PKG_DETAILS + `?pageNumber=${page}&pageSize=${limit}&userRole=ADMIN`)

  if (!response.data) {
    throw new Error("No data found");
  }
  const transformedData = response?.data?.data?.data.map((data) => {
    data.image = config.backendBaseURL + "uploads/" + data?.sub_pkg_image || "N/A";
    data.ruminant_name = data?.animal?.name || "N/A";
    data.ruminant_price = data?.animal?.price || "N/A";
    data.portions = data?.animal?.portions || "N/A";
    data.package_name = data?.package?.name || "";
    data.package_price = data?.package?.pkg_price || "N/A";
    data.package_info = data?.package?.pkg_info || "N/A";
    data.pkg_quota = data.package?.pkg_quota || "N/A";
    data.org_type = data.org_type?.org_type || "N/A";
    data.calendar_event=data?.calendar_event || "N/A";
    data.created_at=data?.created_at || "N/A";
    data.calendar_event=data?.calendar_event || "N/A";
    data.package_id=data.package?.id|| {};
    data.ruminant=data?.animal|| {};
    data.packageData=data?.package;
    
    
    // data.created_at = formatDate(data.created_at);
    const tobeDeleted = [
      
    ];
    tobeDeleted.forEach((key) => delete data[key]);
    return {
      extraData:data,
      ...data,
    };
  });
  
  return transformedData;
};


export const GetAllsadakahDetails = async (pkg_type="WAQF", page = 1, limit = 1000) => {
  const token = JSON.parse(localStorage.getItem("token"));
  const response = await axios.get(
    endpoint.GET_PKG_DETAILS + `?pageNumber=${page}&package_type=${pkg_type}&pageSize=${limit}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  console.log("responsepkgDetail", response)

  if (!response.data) {
    throw new Error("No data found");
  }
 
  
  return response;
};


export const getCalenderDetail = async () => {
  const token = JSON.parse(localStorage.getItem("token"));
  const response = await axios.get("packages/calendar-event" ,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  console.log("calender", response)

  if (!response.data) {
    throw new Error("No data found");
  }
 
  
  return response;
};


export const createCalenderDetail = async (data) => {
  const token = JSON.parse(localStorage.getItem("token"));
  const response = await axios.post("packages/calendar-event", data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  if (!response.data) {
  return response.data;

  }
  return response.data;
};



export const updateCalenderDetail = async (id, data) => {
  const token = JSON.parse(localStorage.getItem("token"));
  const response = await axios.patch(`packages/calendar-event/${id}`, data, {

    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  if (!response.data) {
  return response.data;

  }
  return response.data;
};

export const createPkgDetails = async (data) => {
  const token = JSON.parse(localStorage.getItem("token"));
  const response = await axios.post(endpoint.GET_PKG_DETAILS, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  if (!response.data) {
    throw new Error("No data found");
  }
  return response.data;
};

export const updatePkgDetails = async (id, data) => {
  const token = JSON.parse(localStorage.getItem("token"));
  const response = await axios.put(endpoint.GET_PKG_DETAILS + `/${id}`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  if (!response.data) {
    throw new Error("No data found");
  }
  return response.data;
};
