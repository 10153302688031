import { useQuery } from "@tanstack/react-query";
import { api_keys } from "../../../config/constants";
import { getSlaughtertaskList} from "../../../services/slaughter";

export const useGetSlaughterDataHook = (pageNumber, limit) => {
	const {
		data,
		isError,
		isLoading,
		refetch
	} = useQuery({
		queryKey: [api_keys.GET_SLAUGHTER_TASK_LIST, pageNumber, limit],
		queryFn: () => getSlaughtertaskList(pageNumber, limit),
	});
	return {
		data,
		isError,
		isLoading,
		refetch
	};
};

// export const useGetSlaughterListHook = () => {
// 	const {
// 		data:slaughterList,
// 		isError,
// 		isLoading,
// 	} = useQuery({
// 		queryKey: [api_keys.GET_SLAUGHTER, 1, 100],
// 		queryFn: () => getSlaughter(1, 100),
// 	});
// 	return {
// 		slaughterList,
// 		isError,
// 		isLoading,
// 	};
// };