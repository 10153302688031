
import React, { useEffect, useState } from "react";
import { Loader } from "../../components/Loader";
import { axios } from "../../config/axios";
import Button from "../../components/Button/Button";
import { Dialog } from '@headlessui/react';
import { toast, ToastContainer } from "react-toastify";
import PaymentHistory from "../analytics/components/PaymentHistory";


const ClaimList = () => {
  const [data, setData] = useState([]);
  let [editModal, setEditModal] = useState(false);
  let [edititem, setEditItem] = useState({});
  let [paidAmount, setpaidAmount] = useState({});
  const [loading, setLoading] = useState(true);
  const [step, setstep]=useState(1)
  const [historyProp,setHistoryProp ]=useState({
  })

  const changeStep=(e)=>{
    setstep(e)
  }

  useEffect(() => {
    fetchData();
  }, []);

  

  const fetchData = async () => {
    try {
      const updatedData = await getClaimListDetail();
      setData(updatedData);
    } catch (error) {
      console.error("Error fetching data: ", error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <Loader />;
  }

  return (<>
    {step==1?
    <div className="relative overflow-x-auto mb-[200px]">
      <div className="flex justify-between mb-2">
        <h1 className="font-bold" onClick={()=>console.log(data)}>Claim List</h1>
      </div>
      <table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
        <thead className="text-xs text-pacific-blue uppercase bg-[#CFF0F2] ">
          <tr>
          <th scope="col" className="px-6 py-3 text-base font-medium">
              <div className="flex gap-1 items-center">ID</div>
            </th>
            <th scope="col" className="px-6 py-3 text-base font-medium">
              <div className="flex gap-1 items-center">Name</div>
            </th>
            <th scope="col" className="px-6 py-3 text-base font-medium">
              <div className="flex gap-1 items-center">Email</div>
            </th>
            <th scope="col" className="px-6 py-3 text-base font-medium">
              <div className="flex gap-1 items-center">Phone No</div>
            </th>
            <th scope="col" className="px-6 py-3 text-base font-medium">
              <div className="flex gap-1 items-center">Role</div>
            </th>
            <th scope="col" className="px-6 py-3 text-base font-medium">
              <div className="flex gap-1 items-center">Paid Amount</div>
            </th>
           
            <th scope="col" className="px-6 py-3 text-base font-medium">
              <div className="flex gap-1 items-center"
              >Action</div>
            </th>
            <th scope="col" className="px-6 py-3 text-base font-medium">
              <div className="flex gap-1 items-center"
              >Hist0ry</div>
            </th>
          
            
          </tr>
        </thead>
        <tbody>
          {data?.map((e) => (
            <tr className="bg-white border-b " key={e.id}>
              <th scope="row" className="px-6 py-4 text-sm font-medium">
                {e?.id}
              </th>
              <td className="px-6 py-4 text-sm font-medium">{e?.executor?.fullname}</td>
              <td className="px-6 py-4 text-sm font-medium">{e?.executor?.email}</td>
              <td className="px-6 py-4 text-sm font-medium">{e?.executor?.phone_no}</td>
              <td className="px-6 py-4 text-sm font-medium">{e?.executor?.role}</td>
              <td className="px-6 py-4 text-sm font-medium"> {e?.paid_amount? "RM" :""}  {(Number(e?.paid_amount) || 0).toFixed(2)}
              </td>
              <td className="px-6 py-4 text-sm font-medium">
                <Button onClick={()=>
                  {
                    setEditItem(e)
                    setpaidAmount(e?.paid_amount)
                    setEditModal(true)}
                  }>Payment</Button>
              </td>
              <td className="px-6 py-4 text-sm font-medium">
                  <Button className="w-24 " onClick={()=>{
                 setHistoryProp({id:e?.executor?.id, role:e?.executor?.role})
                    changeStep(2)
                  
                  }}>History </Button>
                  </td>
            </tr>
          ))}
        </tbody>
      </table>


      {editModal &&
       <Dialog
       open={editModal}
       onClose={() => setEditModal(true)}
       className="fixed inset-0 z-50 overflow-y-auto p-4 duration-500 "
     >
       <div className="flex items-center justify-center min-h-screen">
         <Dialog.Overlay className="fixed inset-0 bg-black/30" />

         <Dialog.Panel className="relative max-w-[600px] w-full mx-auto bg-white p-4 rounded">
           <Dialog.Title className="text-lg font-medium mb-6 border-b-2 flex items-center justify-between">
             <span className="">
               Payment
             </span>
             <div
               className="flex items-center justify-center "
               onClick={() => 
                
                {
                  setEditModal(false)
                  // console.log(edititem)
                }}
             >
               <h1 className="bg-gray-200 px-2 rounded-md mb-1 hover:text-red-600 ">
                 x
               </h1>
             </div>
           </Dialog.Title>
           <form
    onSubmit={async (e) => {
      e.preventDefault();
      console.log(paidAmount)
      console.log(edititem?.executor_payable?.amount_payable*edititem?.slaughter_task?.reporter_order?.order?.total_portions)
      console.log(edititem?.executor_payable?.amount_payable*edititem?.slaughter_task?.reporter_order?.order?.total_portions!=paidAmount)
      if((edititem?.executor_payable?.amount_payable*edititem?.slaughter_task?.reporter_order?.order?.total_portions)!=paidAmount){
      
        toast.error("Actual Amount not equal to Paid Amound")
      }
     else{
      try {
        const token = JSON.parse(localStorage.getItem("token"));
        let data = {
          paid_amount: Number(paidAmount),
        };
 console.log(data)
        const response = await axios.patch(
          `executor/admin-pay-executor/${edititem?.id}`,
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        console.log(response);
        fetchData();
        setEditModal(false);
        toast.success("Paid Succesfully")
      } catch (error) {
        console.error(error);
       
        if (error?.response) {
       
          toast.error(`Error: ${error?.response?.data?.error || 'Something went wrong'}`);
        } else if (error.request) {
        
          toast.error('Error: No response received from the server');
        } else {
        
          toast.error(`Error: ${error.message}`);
        }
      }
     }
    }}
  >
             <div className="grid grid-cols-1  sm:grid-cols-2 gap-4">
              

             <div className="">
                 <label
                   htmlFor="animal_id"
                   className="block text-sm font-medium text-gray-700"
                   onClick={()=>console.log(edititem)}
                 >
                  Actual Amount
                 </label>
                 <input
                   className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                   defaultValue={(edititem?.executor_payable?.amount_payable*edititem?.slaughter_task?.reporter_order?.order?.total_portions)
                   }
                  
                  disabled
                 />
             
               </div>


              
               <div className="">
                 <label
                   htmlFor="animal_id"
                   className="block text-sm font-medium text-gray-700"
                 >
                  Paid Amount
                 </label>
                 <input
                   className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                   defaultValue={edititem?.paid_amount}
                   required
                   name="paid_amount"
                   onChange={(e)=>{setpaidAmount(e.target.value)}}
                  type="number" 
                 />
             
               </div>


              
             </div>

             <div className="flex justify-end mt-10">
               <Button type="submit">
                 Pay
               </Button>
             </div>
           </form>
         </Dialog.Panel>
       </div>
     </Dialog>}
    </div>:
    <PaymentHistory   changeStep={changeStep} historyProp={historyProp} />
    }
    <ToastContainer/>
  </>);
};

export default ClaimList;

export const getClaimListDetail = async () => {
  const token = JSON.parse(localStorage.getItem("token"));
  try {
    const response = await axios.get("/executor/admin-pay-executor", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log("ClaimList Response", response); // Added log
    if (!response.data) {
      throw new Error("No data found");
    }
    return response.data.data; // Ensure returning the correct part of response
  } catch (error) {
    console.error("Error in getClaimListDetail: ", error);
    throw error;
  }
};

