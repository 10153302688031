import { useQuery } from "@tanstack/react-query";
import { api_keys } from "../../../../config/constants";
import { getAllPackages } from "../../../../services/settings";

export const useGetPackagesHook = () => {
	const {
		data: packages,
		isError,
		isLoading,
		refetch,
	} = useQuery({
		queryKey: [api_keys.GET_PACKAGES],
		queryFn: () => getAllPackages(),
	});

	return {
		packages,
		isError,
		isLoading,
		refetch,
	};
};
