import { z } from "zod";

const supplierSignupSchema = z
	.object({
		fullname: z.string().trim().min(3, "Full name is required"),
		email: z.string().trim().min(5, "Email is required").email("Invalid email format"),
		phone_no: z.string().trim().min(10, "Phone number must be at least 10 characters"),
		ic_number: z.string().trim().min(9, "IC number must be at least 9 characters"),
		country_id: z.string().min(1, "Country is required"),
		state_id: z.string().min(1, "State is required"),
		region_id: z.string().min(1, "Region is required"),
		password: z
			.string()
			.min(8, "Password must be at least 8 characters")
			.refine(
				(password) =>
					/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])(?!.*\s).{8,}$/.test(
						password
					),
				{
					message:
						"Password must contain at least one capital letter, one small letter, one number, one special character (!@#$%^&*), and be at least 8 characters long without spaces",
				}
			),
		confirmPassword: z
			.string()
			.min(8, "Password must be at least 8 characters"),
	})
	.refine((data) => data.confirmPassword === data.password, {
		message: "Passwords do not match",
		path: ["confirmPassword"],
	});

export default supplierSignupSchema;
