import React from "react";
import { useParams } from "react-router-dom";
import { useFetchCountryStateRegions } from "../hooks/useFetchCountries";
import HeaderSection from "../../../components/HeaderSection";
import DataTable from "../../../components/Table/DataTable";
import { updateRegionStatus } from "../../../services/countries";
import { notifySuccess } from "../../../utils/toasts";

const DisplayRegion = () => {
  const { state } = useParams();
  const { regions, isError, isLoading, refetch } =
    useFetchCountryStateRegions(state);
  const updateStatus = (id, status) => {
    updateRegionStatus(id, status)
    .then((data) => {
        notifySuccess("Status updated successfully");
        refetch();
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  if (isError) {
    return <div className="">There was some Error, Please try again</div>;
  }

  if (isLoading) {
    return (
      <div className="flex space-x-2">
        <div className="">Loading...</div>
      </div>
    );
  }
  return (
    <div>
      <HeaderSection title={"Regions"} />
      <DataTable
        data={regions.data || []}
        canUpdateStatus={true}
        updatefn={updateStatus}
      />
    </div>
  );
};

export default DisplayRegion;
