import { axios } from "../../config/axios";
import { endpoint } from "../../config/constants";
import { formatDate } from "../../utils/formatDate";

export const getExecutor = async () => {
	

	const token = JSON.parse(localStorage.getItem("token"));
	const response = await axios.get(endpoint.Get_EXECUTOR_TASK, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
 
	if (!response.data) {
		throw new Error("No data found");
	}

	return response.data.data;
};



export const getSlaughter = async () => {
	const token = JSON.parse(localStorage.getItem("token"));
	const response = await axios.get(endpoint.GET_SLAUGHTER, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});

	if (!response.data) {
		throw new Error("No data found");
	}

	return response.data;
};








// updateOrder

// export const updateOrder = async (id, status) => {
// 	const token = JSON.parse(localStorage.getItem("token"));
// 	const response = await axios.put(
// 		endpoint.UPDATE_ORDER_STATUS + `${id}`,
// 		{
// 			order_status: status,
// 		},
// 		{
// 			headers: {
// 				Authorization: `Bearer ${token}`,
// 			},
// 		}
// 	);
// 	if (!response.data) {
// 		throw new Error("No data found");
// 	}
// 	return response.data;
// };
