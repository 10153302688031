import React, { useEffect, useState } from "react";
import { Loader } from "../../components/Loader";
import { Dialog } from "@headlessui/react";
import Button from "../../components/Button/Button";
import { useGetAnimal } from "../settings/AnimalTypes/hooks/useGetAnimal";
import { toast } from "react-toastify";
import { axios } from "../../config/axios";
import {
  createOrganizationType,
  getOrganizationTypeList,
} from "../../services/organization";
import { formatDate, formatDateWithoutTime } from "../../utils/formatDate";

function OrganizationTypePage() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  let [isOpen, setIsOpen] = useState(false);
  let [editModal, setEditModal] = useState(false);
  let [edititem, setEditItem] = useState({ org_type: "" });

  const [event, setEvent] = useState({
    type: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEvent((prevEvent) => ({
      ...prevEvent,
      [name]: value,
    }));
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditItem((prevEvent) => ({
      ...prevEvent,
      [name]: value,
    }));
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    let updatedData = await getOrganizationTypeList();
    setLoading(true);
    setData(updatedData);
  };
  const handleDelete = async (id) => {
    const token = JSON.parse(localStorage.getItem("token"));

    try {
      const response = await axios.delete(`organization/org-types/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        toast.success("Organization Type deleted successfully!");
        fetchData();
      } else {
        toast.error(
          response?.data?.message ||
            "An error occurred while deleting the organization"
        );
      }
    } catch (error) {
      console.error(error);
      toast.error(
        error.response?.data?.message || "An unexpected error occurred"
      );
    }
  };

  if (!loading) {
    return <Loader />;
  }

  return (
    <>
      <div className="relative overflow-x-auto mb-[200px]">
        <div className="flex justify-between mb-2">
          <button className="font-bold" onClick={() => console.log(data)}>
            Organization Type List
          </button>

          <Button type="button"  onClick={() => {
    setEvent({
      type: "",
    });
    setIsOpen(true);
  }}>
            Add Organization Type
          </Button>
        </div>
        <table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
          <thead className="text-xs text-pacific-blue uppercase bg-[#CFF0F2] ">
            <tr>
              <th scope="col" className="px-6 py-3 text-base font-medium">
                <div className="flex gap-1 items-center">Id</div>
              </th>
              <th scope="col" className="px-6 py-3 text-base font-medium">
                <div className="flex gap-1 items-center">Organization Type</div>
              </th>

              <th scope="col" className="px-6 py-3 text-base font-medium">
                <div className="flex gap-1 items-center">Created Date</div>
              </th>

              <th scope="col" className="px-6 py-3 text-base font-medium">
                <div className="flex gap-1 items-center">Updated Date</div>
              </th>

              <th scope="col" className="px-6 py-3 text-base font-medium">
                <div className="flex gap-1 items-center">Status</div>
              </th>

              <th scope="col" className="px-6 py-3 text-base font-medium ">
                <div className="flex gap-1 items-center text-center">Action</div>
              </th>

              <th scope="col" className="px-2 py-3 text-base font-medium"></th>
            </tr>
          </thead>
          <tbody>
            {data?.data?.map((e) => {
              return (
                <tr className="bg-white border-b ">
                  <th scope="row" className="px-6 py-4 text-sm font-medium">
                    {e?.id}
                  </th>
                  <td className="px-6 py-4 text-sm font-medium">{e?.type}</td>

                  <td className="px-6 py-4 text-sm font-medium">
                    {formatDate(e?.created_at)}
                  </td>

                  <td className="px-6 py-4 text-sm font-medium">
                    {formatDate(e?.updated_at)}
                  </td>

                  <td className="px-6 py-4 text-sm font-medium">
                    {e?.is_active ? (
                      <Button className="w-24 ">Active </Button>
                    ) : (
                      <Button
                        className="bg-red-500 hover:bg-red-500 w-24"
                        disabled
                      >
                        In-Active{" "}
                      </Button>
                    )}
                  </td>

                  <td className="px-6 py-4 text-sm font-medium text-pacific-blue">
                    <Button
                      onClick={() => {
                        setEditItem(e);
                        setEditModal(true);
                      }}
                    >
                      Edit
                    </Button>

                    <Button
                      className="px-1 py-1 text-sm font-medium bg-red-500 text-white hover:bg-red-500 ml-2"
                      onClick={() => handleDelete(e?.id)}
                    >
                      Delete
                    </Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>

        {isOpen && (
          <Dialog
            open={isOpen}
            onClose={() => setIsOpen(true)}
            className="fixed inset-0 z-50 overflow-y-auto p-4 duration-500 "
          >
            <div className="flex items-center justify-center min-h-screen">
              <Dialog.Overlay className="fixed inset-0 bg-black/30" />

              <Dialog.Panel className="relative max-w-[600px] w-full mx-auto bg-white p-4 rounded">
                <Dialog.Title className="text-lg font-medium mb-6 border-b-2 flex items-center justify-between">
                  <span className="">Create Organization Type</span>
                  <div
                    className="flex items-center justify-center "
                    onClick={() => setIsOpen(false)}
                  >
                    <h1 className="bg-gray-200 px-2 rounded-md mb-1 hover:text-red-600 ">
                      x
                    </h1>
                  </div>
                </Dialog.Title>

                <form
                  onSubmit={async (e) => {
                    e.preventDefault();
                    console.log(event);
                    try {
                      const result = await createOrganizationType(event);
                      console.log(result);

                      if (result?.status === 200) {
                        // Assuming 200 is the status for success
                        toast.success(
                          "Organization Type created successfully!"
                        );
                      } else {
                        toast.error(
                          result?.data?.message || "An error occurred"
                        );
                      }

                      fetchData();
                      setIsOpen(false);
                    } catch (error) {
                      console.error(error);
                      toast.error(error?.response?.data?.error);
                    }
                  }}
                >
                  <div className="grid grid-cols-1  sm:grid-cols-2 gap-4">
                    <div className="">
                      <label
                        htmlFor="org_type"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Organization Type Name
                      </label>
                      <input
                        type="text"
                        id="org_type"
                        name="type"
                        value={event.type}
                        className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>

                  <div className="flex justify-end mt-10">
                    <Button type="submit">Submit</Button>
                  </div>
                </form>
              </Dialog.Panel>
            </div>
          </Dialog>
        )}

        {editModal && (
          <Dialog
            open={editModal}
            onClose={() => setEditModal(true)}
            className="fixed inset-0 z-50 overflow-y-auto p-4 duration-500 "
          >
            <div className="flex items-center justify-center min-h-screen">
              <Dialog.Overlay className="fixed inset-0 bg-black/30" />

              <Dialog.Panel className="relative max-w-[600px] w-full mx-auto bg-white p-4 rounded">
                <Dialog.Title className="text-lg font-medium mb-6 border-b-2 flex items-center justify-between">
                  <span className="">Update Organization Type</span>
                  <div
                    className="flex items-center justify-center "
                    onClick={() => {
                      setEditModal(false);
                    }}
                  >
                    <h1 className="bg-gray-200 px-2 rounded-md mb-1 hover:text-red-600 ">
                      x
                    </h1>
                  </div>
                </Dialog.Title>

                <form
                  onSubmit={async (e) => {
                    e.preventDefault();

                    const token = JSON.parse(localStorage.getItem("token"));

                    let data = {
                      type: edititem?.type,
                      is_active: edititem?.status === "true" ? true : false,
                    };
                    console.log(data);
                    try {
                      const response = await axios.put(
                        `organization/org-types/${edititem?.id}`,
                        data,
                        {
                          headers: {
                            Authorization: `Bearer ${token}`,
                          },
                        }
                      );

                      console.log(response);

                      if (response.status === 200) {
                        toast.success(
                          "Organization Type updated successfully!"
                        );
                        fetchData();
                        setEditModal(false);
                      } else {
                        toast.error(
                          response?.data?.message ||
                            "An error occurred while updating the organization"
                        );
                      }
                    } catch (error) {
                      console.error(error);
                      toast.error(
                        error.response?.data?.message ||
                          "An unexpected error occurred"
                      );
                    }
                  }}
                >
                  <div className="grid grid-cols-1  sm:grid-cols-2 gap-4">
                    <div className="">
                      <label
                        htmlFor="org_type"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Organization Type Name
                      </label>
                      <input
                        type="text"
                        id="org_type"
                        name="type"
                        value={edititem.type}
                        className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                        onChange={handleEditChange}
                        required
                      />
                    </div>

                    <div className="">
                      <label
                        htmlFor="org_type"
                        className="block text-sm font-medium text-gray-700"
                      >
                      Status
                      </label>

                      <select
                        id="status"
                        name="status"
                        value={edititem.is_active}
                        className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                        onChange={handleEditChange}
                      >
                        <option value="">Select the Status</option>
                        <option value={true}>Active</option>
                        <option value={false}>In-Active</option>
                      </select>
                    </div>
                  </div>

                  <div className="flex justify-end mt-10">
                    <Button type="submit">Update Organization Type</Button>
                  </div>
                </form>
              </Dialog.Panel>
            </div>
          </Dialog>
        )}
      </div>
    </>
  );
}

export default OrganizationTypePage;
