import { useForm } from "react-hook-form";
import { QueryClient, useMutation } from "@tanstack/react-query";
import { api_keys } from "../../../../config/constants";
import { createPackage, updatePackage } from "../../../../services/settings";
import { notify, notifySuccess } from "../../../../utils/toasts";

export const useCreatePackage = (setIsOpen, updateId = null,formData = {}) => {

  const queryClient = new QueryClient();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue
  } = useForm({ formData });


  const onSubmit = (data) => {
   
    if (updateId) {
      mutationUpdate.mutate(data);
    } else {
      mutation.mutate(data);
    }
  };


  const mutation = useMutation({
    mutationKey: [api_keys.CREATE_PACKAGE],
    mutationFn: createPackage,
    onSuccess: () => {
          notifySuccess("Package added successfully");
      queryClient.invalidateQueries({ queryKey: api_keys.GET_PACKAGES });
      setIsOpen(false);
    },
    onError: (err) => {
      console.log(err);
      notify("error", err?.response?.data?.error || "Unknow error occur" );
    },
  });





  const mutationUpdate = useMutation({
    mutationKey: ["UpdatePkg", updateId?.id],
    mutationFn: (data) => updatePackage(updateId?.id, data),
    onSuccess: () => {
      notifySuccess("Package updated successfully");
      queryClient.invalidateQueries({ queryKey: api_keys.GET_PACKAGES });
      setIsOpen(false);
    },
    onError: (err) => {
      console.log(err);
      notify("error", err?.response?.data?.error || "Unknow error occur" );
    },
  });

  return {
    register,
    handleSubmit,
    errors,
    onSubmit,
    mutation,
    setValue
  };
};
