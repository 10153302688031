import React, { useEffect, useState } from "react";
import {
  createCalenderDetail,
  getAllsadakahDetails,
  getCalenderDetail,
} from "../../services/settings";
import { Loader } from "../../components/Loader";
import HeaderSection from "../../components/HeaderSection";
import { Dialog } from "@headlessui/react";
import Button from "../../components/Button/Button";
import { useGetAnimal } from "../settings/AnimalTypes/hooks/useGetAnimal";
import {
  createRuminantPrice,
  getAllRuminantPrice,
} from "../../services/executerPrice";
import { toast } from "react-toastify";
import { endpoint } from "../../config/constants";
import { axios } from "../../config/axios";
import { getAllFeedlotList } from "../../services/feedlotTest";
import { useForm } from "react-hook-form";
import {
  useFetchCountires,
  useFetchCountryStateRegions,
  useFetchCountryStates,
} from "../countries/hooks/useFetchCountries";
import { config } from "../../config/config";
import { useGetSupplier } from "../supplier/hooks/useGetSupplier";
import { useNavigate, useParams } from "react-router-dom";
import { FaUser } from "react-icons/fa";
import { formatDate } from "../../utils/formatDate";


function FeedlotPage() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [createloading, setCreateLoading] = useState(false);
  const [editloading, setEditLoading] = useState(false);
  let [isOpen, setIsOpen] = useState(false);
  let [editModal, setEditModal] = useState(false);
  let [edititem, setEditItem] = useState({});
  const { countries } = useFetchCountires();
  const [mode, setMode] = useState(null);
  const [limit, setLimit] = useState(10);

  const [selectedCountryId, setSelectedCountryId] = useState("");
  const [suppliers, setSuppliers] = useState([]);
  // const { suppliers, isError, isLoading } = useGetSupplier("1", "1000",selectedCountryId,true);
  console.log("suppliers");
  // console.log(suppliers);
  // const [suppliers, setSuppliers] = useState([]);
  const user = JSON.parse(localStorage.getItem("user"));
  console.log("tokencehck");
  console.log("USER");
  console.log(user);
  const {
    register: registerAddFeedlot,
    handleSubmit: handleAddFeedlot,
    formState: { errors: errorsAddFeedlot },
    setValue: setValueAddFeedlot,
    watch: watchAddFeedlot,
  } = useForm();

  const handleCreateFeedlot = async (data) => {
    setCreateLoading(true);

    data.capacity = parseInt(data?.capacity) || 0;
    // data.supplier_id = parseInt(data?.supplier_id) || 0;
    data.country_id = parseInt(data?.country_id) || 0;
    data.region_id = parseInt(data?.region_id) || 0;
    data.state_id = parseInt(data?.state_id) || 0;

    if (user?.role !== "SUPPLIER") {
      data.supplier_id = parseInt(data?.supplier_id) || 0;
    }
    else{
      data.supplier_id = parseInt(user?.id) || 0;
    }

    const formData = new FormData();
    console.log("datasend", data);

    for (const key in data) {
      if (data.hasOwnProperty(key) && key !== 'email') {
        if (key === "image" && data[key] && data[key].length > 0) {
          // If image is an object, assume it's a File object
          formData.append(key, data[key][0]);
        } else if (key !== "image") {
          formData.append(key, data[key]);
        }
      }
    }

    try {
      const token = JSON.parse(localStorage.getItem("token"));
      if (!token) {
        console.error("Token not found in localStorage");
        return;
      }

      for (let pair of formData.entries()) {
        console.log(pair[0] + ": " + pair[1]);
      }

      const response = await axios.post("/users/invite/feedlot", formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setCreateLoading(false);
      setIsOpen(false);

      toast.success("Add Feedlot");

      console.log("response");
      console.log(response);
      fetchData();
    } catch (error) {
      toast.error(error?.response?.data?.error);
      console.error("Error:", error);
      setCreateLoading(false);
      setIsOpen(false);
    } finally {
      setIsOpen(false);
      setCreateLoading(false);
    }

    console.log("send data", data);
  };

  const {
    register: registerEditFeedlot,
    handleSubmit: handleEditFeedlots,
    formState: { errors: errorsEditFeedlot },
    setValue: setValueEditFeedlot,
    reset: resetEditFeedlot,
    watch: watchEditFeedlot,
  } = useForm();

  const handleEditFeedlot = async (data) => {
     
    setEditLoading(true);

    data.capacity = parseInt(data?.capacity) || 0;
    // data.country_id = data?.country_id;
    // data.state_id = data?.state_id;
    // data.region_id = data?.region?.id;
    // data.supplier_id = parseInt(data?.supplier_id) || 0;
    // data.country_id = edititem?.country?.id;
    // data.state_id = edititem?.state?.id;
    // data.region_id = edititem?.region?.id;
    // data.region_id = edititem?.region?.id;
    if (user?.role !== "SUPPLIER") {
      data.supplier_id = parseInt(data?.supplier_id) || 0;
    }
     

    const token = JSON.parse(localStorage.getItem("token"));
    const formData = new FormData();

    for (const key in data) {
      if (data.hasOwnProperty(key)  && key !== 'email') {
        if (key === "image" && data[key] && data[key].length > 0) {
          // If image is an object, assume it's a File object
          if (data.image.length === 1) {
            formData.append(key, data[key][0]);
          }
        } else if (key !== "image") {
          formData.append(key, data[key]);
        }
      }
    }

    try {
      const response = await axios.put(
        `/users/update/feedlot/${edititem.id}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response);
      fetchData();
      setEditModal(false);
      toast.success("Feedlot Updated");
      setEditLoading(false);
    } catch (error) {
      toast.error(error?.response?.data?.error);
      console.error("Error:", error);
      setEditLoading(false);
    }
    console.log("Edit item", data);
  };

  useEffect(() => {
    if (editModal) {
      //       resetEditFeedlot({
      //         fullname: edititem?.fullname || null,
      //         // ic_number: edititem?.ic_number || null,
      //         feedlot_type: edititem?.feedlot_type || null,
      //         // supplier_id: edititem?.supplier_id || null,
      //         image: edititem?.image || null,
      //         capacity: edititem?.capacity || null,
      //         country_id: edititem?.country?.id || "",
      //         region_id: edititem?.region?.id || "",
      //         state_id: edititem?.state?.id || "",
      //         // pic: edititem?.pic || null,
      //         // contact: edititem?.contact || null,
      //         is_active: edititem?.is_active || false,
      //       });
      //  Select;
      //       if (user?.role !== "SUPPLIER") {
      //         resetEditFeedlot.supplier_id = edititem?.supplier_id || null;
      //       }

      const initialValues = {
        fullname: edititem?.fullname || null,
        email: edititem?.email || null,
        feedlot_type: edititem?.feedlot_type || null,
        image: edititem?.image || null,
        capacity: edititem?.capacity || null,
        country_id: edititem?.country?.id || "",
        region_id: edititem?.region?.id || "",
        state_id: edititem?.state?.id || "",
        is_active: edititem?.is_active || false,
      };
      setValueEditFeedlot("country_id", edititem?.country?.id);
      fetchsupplierbycountry(edititem?.country?.id);
      // Add supplier_id conditionally
      if (user?.role !== "SUPPLIER") {
    setValueEditFeedlot("supplier_id", edititem?.supplier_id);
        initialValues.supplier_id = edititem?.supplier_id || null;
        const selectedSupplier = suppliers?.data?.data?.find(supplier => supplier.id ===  parseInt(edititem?.supplier_id ));
    if (selectedSupplier) {
      setValueAddFeedlot("email", selectedSupplier.email);
      setValueEditFeedlot("email", selectedSupplier.email);
      
    } else {
      setValueAddFeedlot("email", "");
      setValueEditFeedlot("email", "");
    }
      }

      resetEditFeedlot(initialValues);

     

      const timeoutIdState = setTimeout(() => {
        setValueEditFeedlot("state_id", edititem?.state?.id);
        resetEditFeedlot({
          state_id: edititem?.state?.id || "",
        });
      }, 1000); // 5000 milliseconds = 5 seconds

      const timeoutIdRegion = setTimeout(() => {
        setValueEditFeedlot("region_id", edititem?.region?.Arrayid);

        resetEditFeedlot({
          region_id: edititem?.region?.id || "",
        });
      }, 4000); // 5000 milliseconds = 5 seconds

      // Cleanup function to clear all timeouts if necessary
      return () => {
        clearTimeout(timeoutIdState);
        clearTimeout(timeoutIdRegion);
      };
    }
  }, [editModal, resetEditFeedlot, edititem]);

  // console.log(edititem)
  const { states } = useFetchCountryStates(watchEditFeedlot("country_id") || -1);

  // const { states } = useFetchCountryStates(watchEditFeedlot("country_id"));
  const { regions } = useFetchCountryStateRegions(watchEditFeedlot("state_id") || -1);

  let { page = 1 } = useParams();
  const navigate = useNavigate();

  page = Number(page);
  if (page < 1) {
    page = 1;
  }

  const [pageNumber, setPageNumber] = useState(page);
  useEffect(() => {
    fetchData();
  }, [pageNumber, limit]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const updatedData = await getAllFeedlotList(pageNumber, limit);
      setData(updatedData);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  const handlePageChange = (newPage) => {
    setPageNumber(newPage);
    navigate(`/feedlot/${newPage}`); // Update the route accordingly
  };

  console.log("cehckeing");
  console.log(data);
  const totalPages = data?.data?.totalCount
    ? Math.ceil(data?.data?.totalCount / limit)
    : 0;
  if (loading) {
    return <Loader />;
  }

  const handleCountryChange = async (e) => {
    setSuppliers([]);
    const countryId = e.target.value;
    setSelectedCountryId(countryId);
    setValueAddFeedlot("country_id", countryId);
    setValueAddFeedlot("state_id", ""); // Reset state_id
    setValueAddFeedlot("region_id", ""); // Reset region_id
    setValueEditFeedlot("country_id", countryId);
    setValueEditFeedlot("state_id", ""); // Reset state_id
    setValueEditFeedlot("region_id", ""); // Reset region_id
    setValueAddFeedlot("email", "");
      setValueEditFeedlot("email", "");
    console.log(countryId);
    fetchsupplierbycountry(countryId);
  };

  const fetchsupplierbycountry = async (countryId) => {
    const token = JSON.parse(localStorage.getItem("token"));
    const response = await axios.get(
      endpoint.GET_SUPPLIERS +
        `&pageNumber=${pageNumber}&pageSize=${limit}&country_id=${countryId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setSuppliers(response?.data);
    if (!response.data) {
      throw new Error("No data found");
    }

    return {
      data: response?.data?.data,
    };
  }

  const handleSupplierChange = (e) => {
    const supplierId = e.target.value;
    setValueAddFeedlot("supplier_id", supplierId);
    setValueEditFeedlot("supplier_id", supplierId);
   
    // Find the selected supplier's email and set it in the email field
    const selectedSupplier = suppliers?.data?.data?.find(supplier => supplier.id ===  parseInt(supplierId));
    console.log("selectedSupplier");
    console.log(selectedSupplier);
    if (selectedSupplier) {
      setValueAddFeedlot("email", selectedSupplier.email);
      setValueEditFeedlot("email", selectedSupplier.email);
      
    } else {
      setValueAddFeedlot("email", "");
      setValueEditFeedlot("email", "");
    }
  };

  return (
    <>
      <div className="relative  mb-[200px]">
        <div className="flex justify-between mb-2">
          <button className="font-bold" onClick={() => console.log(data)}>
            Feedlot List
          </button>

          <Button type="button" onClick={() => setIsOpen(true)}>
            Add Feedlot
          </Button>
        </div>
        <table className="w-full text-sm overflow-x-auto text-left rtl:text-right text-gray-500 ">
          <thead className="text-xs text-pacific-blue uppercase bg-[#CFF0F2] ">
            <tr>
              <th scope="col" className="px-6 py-3 text-base font-medium ">
                <div className="flex gap-1 items-center">Id</div>
              </th>
              <th scope="col" className="px-6 py-3 text-base font-medium">
                <div className="flex gap-1 items-center">image</div>
              </th>
              
              <th scope="col" className="px-6 py-3 text-base font-medium ">
                <div className="flex gap-1 items-center">Feedlot ID</div>
              </th>
              <th scope="col" className="px-6 py-3 text-base font-medium">
                <div className="flex gap-1 items-center">Feedlot Code</div>
              </th>
              <th scope="col" className="px-6 py-3 text-base font-medium">
                <div className="flex gap-1 items-center">Feedlot Type</div>
              </th>

              <th scope="col" className="px-6 py-3 text-base font-medium">
                <div className="flex gap-1 items-center">Capacity</div>
              </th>
              <th scope="col" className="px-6 py-3 text-base font-medium">
                <div className="flex gap-1 items-center">Supplier Name</div>
              </th>

              <th scope="col" className="px-6 py-3 text-base font-medium">
                <div className="flex gap-1 items-center">Country</div>
              </th>

              <th scope="col" className="px-6 py-3 text-base font-medium">
                <div className="flex gap-1 items-center">State</div>
              </th>

              <th scope="col" className="px-6 py-3 text-base font-medium">
                <div className="flex gap-1 items-center">Region</div>
              </th>

              <th scope="col" className="px-6 py-3 text-base font-medium">
                <div className="flex gap-1 items-center">Created At</div>
              </th>
              <th scope="col" className="px-6 py-3 text-base font-medium">
                <div className="flex gap-1 items-center">Updated At</div>
              </th>

              <th scope="col" className="px-2 py-3 text-base font-medium">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {data?.data?.data?.map((e) => {
              return (
                <tr className="bg-white border-b " key={e?.id}>
                  <th scope="row" className="px-6 py-4 text-sm font-medium">
                    {e?.id}
                  </th>
                  <td className="px-6 py-4 text-sm font-medium">
                  {(e?.image !== undefined && e?.image !== null) ? (
                      <img src={`${config?.backendBaseURL}uploads/${e?.image}`} className="w-14" />
                    ) : (
                      <FaUser className="w-8 h-10" />
                    )}
                  </td>
             

                  <td className="px-6 py-4 text-sm font-medium">{e?.ref_id}</td>

                  <td className="px-6 py-4 text-sm font-medium">
                    {e?.fullname}
                  </td>
                  <td className="px-6 py-4 text-sm font-medium">
                    {e?.feedlot_type}
                  </td>
                  {/* <td className="px-6 py-4 text-sm font-medium">{e?.ic_number}</td> */}
                  <td className="px-6 py-4 text-sm font-medium">
                    {e?.capacity}
                  </td>
                  <td className="px-6 py-4 text-sm font-medium">
                    {e?.supplier?.fullname}
                  </td>

                  <td className="px-6 py-4 text-sm font-medium">
                    {e?.country?.name}
                  </td>
                  <td className="px-6 py-4 text-sm font-medium">
                    {e?.state?.name}
                  </td>
                  <td className="px-6 py-4 text-sm font-medium">
                    {e?.region?.name}
                  </td>

                  {/* <td className="px-6 py-4 text-sm font-medium">
                    {e?.is_active ? (
                      <Button className="w-24 ">Active </Button>
                    ) : (
                      <Button
                        className="bg-red-500 hover:bg-red-500 w-24"
                        disabled
                      >
                        In-Active{" "}
                      </Button>
                    )}
                  </td> */}
                   <td className="px-6 py-4 text-sm font-medium">
                    {formatDate(e?.created_at)}
                  </td>
                  <td className="px-6 py-4 text-sm font-medium">
                    {formatDate(e?.updated_at)}
                  </td>

                  <td className=" px-6 py-4 text-sm font-medium text-pacific-blue">
                    <div className="flex">
                    <Button
                      className="px-8"
                      onClick={() => {
                        setEditItem(e);
                        setEditModal(true);
                        setMode("edit");
                      }}
                    >
                      Edit
                    </Button>
                    <Button
                      className="px-8 ml-3"
                      onClick={() => {
                        setEditItem(e);
                        setEditModal(true);
                        setMode("view");
                      }}
                    >
                      View
                    </Button>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        {/* pagination start */}
        {data?.data?.data && (
          <div className="flex justify-center items-center mt-4">
            <button
              onClick={() => handlePageChange(pageNumber - 1)}
              disabled={pageNumber === 1}
              className="px-4 py-2 bg-gray-300 rounded-md mr-2"
            >
              Previous
            </button>
            <div className="flex gap-2">
              {[...Array(totalPages > 0 ? totalPages : 0)].map((_, index) => (
                <button
                  key={index + 1}
                  onClick={() => handlePageChange(index + 1)}
                  className={`px-4 py-2 rounded-md ${
                    pageNumber === index + 1
                      ? "bg-blue-500 text-white"
                      : "bg-gray-300"
                  }`}
                >
                  {index + 1}
                </button>
              ))}
            </div>
            <button
              onClick={() => handlePageChange(pageNumber + 1)}
              disabled={pageNumber === totalPages}
              className="px-4 py-2 bg-gray-300 rounded-md ml-2"
            >
              Next
            </button>
          </div>
        )}
        {/* pagination end */}

        {/* Add Feedloat */}
        {isOpen && (
          <Dialog
            open={isOpen}
            onClose={() => setIsOpen(true)}
            className="fixed inset-0 z-50 overflow-y-auto p-4 duration-500 "
          >
            <div className="flex items-center justify-center min-h-screen">
              <Dialog.Overlay className="fixed inset-0 bg-black/30" />

              <Dialog.Panel className="relative max-w-[600px] w-full mx-auto bg-white p-4 rounded">
                <Dialog.Title className="text-lg font-medium mb-6 border-b-2 flex items-center justify-between">
                  <span className="">Create Feedlot</span>
                  <div
                    className="flex items-center justify-center "
                    onClick={() => setIsOpen(false)}
                  >
                    <h1
                      onClick={() => setCreateLoading(false)}
                      className="bg-gray-200 px-2 rounded-md mb-1 hover:text-red-600 cursor-pointer"
                    >
                      x
                    </h1>
                  </div>
                </Dialog.Title>

                <form onSubmit={handleAddFeedlot(handleCreateFeedlot)}>
                  <div className="grid grid-cols-1  sm:grid-cols-2 gap-4">
                    <div className="">
                      <label
                        htmlFor="fullname"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Feedlot Code
                      </label>
                      <input
                        name="fullname"
                        id="fullname"
                        {...registerAddFeedlot("fullname", {
                          required: "Feedlot Code is required",
                        })}
                        className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                      />
                      <p className="text-red-500 text-sm">
                        {errorsAddFeedlot?.fullname?.message}
                      </p>
                    </div>

                    {/* <div className="">
                      <label
                        htmlFor="phone_no"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Phone No
                      </label>
                      <input
                        name="phone_no"
                        id="phone_no"
                        {...registerAddFeedlot("phone_no", {
                          required: "Phone No is required",
                        })}
                        className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                      />
                      <p className="text-red-500 text-sm">
                        {errorsAddFeedlot?.phone_no?.message}
                      </p>
                    </div> */}

                    {/* <div className="">
                      <label
                        htmlFor="ic_number"
                        className="block text-sm font-medium text-gray-700"
                      >
                        IC Number
                      </label>
                      <input
                        name="ic_number"
                        id="ic_number"
                        {...registerAddFeedlot("ic_number", {
                          required: " IC Number is required",
                        })}
                        className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                      />
                      <p className="text-red-500 text-sm">
                        {errorsAddFeedlot?.ic_number?.message}
                      </p>
                    </div> */}

                    {/* dropDown */}

                    <div className="">
                      <label
                        htmlFor="country_id"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Country
                      </label>
                      <select
                        name="country_id"
                        className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                        id="country_id"
                        {...registerAddFeedlot("country_id", {
                          required: "Country is required",
                        })}
                        // onChange={(e) =>
                        //   setValueEditFeedlot("country_id", e.target.value)

                        // }
                        onChange={handleCountryChange}
                      >
                        <option value="">  Select the Country</option>
                        {countries?.data.map((country) => {
                          return (
                            <option value={country.id}> {country.name}</option>
                          );
                        })}
                      </select>
                      <p className="text-red-500 text-sm">
                        {errorsAddFeedlot?.country_id?.message}
                      </p>
                    </div>

                    {user?.role !== "SUPPLIER" && (
                      <div className="">
                        <label
                          htmlFor="supplier_id"
                          className="block text-sm font-medium text-gray-700"
                          onClick={() => {
                            console.log(suppliers);
                          }}
                        >
                          Supplier
                        </label>
                        <select
                          name="supplier_id"
                          className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                          id="supplier_id"
                          {...registerAddFeedlot("supplier_id", {
                            required: "Supplier is required",
                          })}
                          // onChange={(e) =>
                          //   setValueAddFeedlot("supplier_id", e?.target?.value)
                          // }
                          onChange={handleSupplierChange}
                        >
                          <option value=""> Select the supplier</option>
                          {(Array.isArray(suppliers?.data?.data)
                            ? suppliers?.data?.data
                            : []
                          ).map((supplier) => (
                            <option key={supplier?.id} value={supplier?.id}>
                              {supplier?.fullname}
                            </option>
                          ))}
                        </select>

                        <p className="text-red-500 text-sm">
                          {errorsAddFeedlot?.supplier_id?.message}
                        </p>
                      </div>
                    )}

{user?.role !== "SUPPLIER" && (
                    <div className="">
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Email
                      </label>
                      <input
                        type="email"
                        name="email"
                        id="email"
                        {...registerAddFeedlot("email", {
                          required: "Email is required",
                        })}
                        className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                        disabled
                      />
                      <p className="text-red-500 text-sm">
                        {errorsAddFeedlot?.email?.message}
                      </p>
                    </div>
)}
                    <div className="">
                      <label
                        htmlFor="state_id"
                        className="block text-sm font-medium text-gray-700"
                      >
                        State
                      </label>
                      <select
                        name="state_id"
                        className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                        id="state_id"
                        {...registerAddFeedlot("state_id", {
                          required: "State is required",
                        })}
                        onChange={(e) =>{
                          setValueAddFeedlot("state_id", e.target.value)
                          setValueAddFeedlot("region_id", "");
                          setValueEditFeedlot("state_id", e.target.value); // Reset state_id
                          setValueEditFeedlot("region_id", ""); // Reset region_id
                         }
                        }
                      >
                        <option value="">  Select the State</option>
                        {states?.data.map((states) => {
                          return (
                            <option value={states.id}> {states.name}</option>
                          );
                        })}
                      </select>
                      {!watchAddFeedlot("country_id") && (
                        <p className="text-red-500 text-sm">
                          {errorsAddFeedlot?.state_id?.message}
                        </p>
                      )}
                      <p className="text-red-500 text-sm"></p>
                    </div>

                    <div className="">
                      <label
                        htmlFor="region_id"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Region
                      </label>
                      <select
                        name="region_id"
                        className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                        id="region_id"
                        {...registerAddFeedlot("region_id", {
                          required: "Region is required",
                        })}
                        onChange={(e) =>
                          setValueAddFeedlot("region_id", e.target.value)
                          
                        }
                      >
                        <option value="">  Select the Region</option>
                        {regions?.data.map((regions) => {
                          return (
                            <option value={regions.id}> {regions.name}</option>
                          );
                        })}
                      </select>
                      {!watchAddFeedlot("state_id") && (
                        <p className="text-red-500 text-sm">
                          {errorsAddFeedlot?.region_id?.message}
                        </p>
                      )}
                    </div>

                    <div className="">
                      <label
                        htmlFor="feedlot_type"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Feedlot Type
                      </label>
                      <select
                        name="feedlot_type"
                        className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                        id="feedlot_type"
                        {...registerAddFeedlot("feedlot_type", {
                          required: "Feedlot Typer is required",
                        })}
                      >
                        <option value=" selected">
                          {" "}
                           Select the Feedlot Type
                        </option>
                        <option value="Kurang Dari < 100">{`Kurang dari  < 100`}</option>
                        <option value="Lebihdari > 100">
                          {`Lebih dari  > 100`}
                        </option>
                      </select>

                      <p className="text-red-500 text-sm">
                        {errorsAddFeedlot?.feedlot_type?.message}
                      </p>
                    </div>

                    {/*dropDown end */}

                    <div className="">
                      <label
                        htmlFor="image"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Image
                      </label>
                      <input
                        type="file"
                        name="image"
                        id="image"
                        {...registerAddFeedlot(
                          "image",  {required: "Image is required", }
                        )}
                        
                        className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                      />
                      <p className="text-red-500 text-sm">
                        {errorsAddFeedlot?.image?.message}
                      </p>
                    </div>

                    <div className="">
                      <label
                        htmlFor="capacity"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Capacity
                      </label>
                      <input
                        type="number"
                        name="capacity"
                        id="capacity"
                        min="0"
                        {...registerAddFeedlot("capacity")}
                        className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                      />
                    </div>

                    {/* <div className="">
                      <label
                        htmlFor="pic"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Pic
                      </label>
                      <input
                        name="pic"
                        id="pic"
                        {...registerAddFeedlot("pic", {
                          required: "Pic No is required",
                        })}
                        className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                      />
                      <p className="text-red-500 text-sm">
                        {errorsAddFeedlot?.pic?.message}
                      </p>
                    </div>

                    <div className="">
                      <label
                        htmlFor="contact"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Contact
                      </label>
                      <input
                        name="contact"
                        id="contact"
                        {...registerAddFeedlot("contact", {
                          required: "Contact is required",
                        })}
                        className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                      />
                      <p className="text-red-500 text-sm">
                        {errorsAddFeedlot?.contact?.message}
                      </p>
                    </div> */}
                  </div>

                  <div className="flex justify-end mt-10">
                    {createloading ? (
                      <Button type="button">
                        <Loader />
                      </Button>
                    ) : (
                      <Button type="submit">Create Feedlot</Button>
                    )}
                  </div>
                </form>
              </Dialog.Panel>
            </div>
          </Dialog>
        )}

        {/* editModal */}
        {editModal && (
          <Dialog
            open={editModal}
            onClose={() => {
              setEditModal(true);
            }}
            className="fixed inset-0 z-50 overflow-y-auto p-4 duration-500 "
          >
            <div className="flex items-center justify-center min-h-screen">
              <Dialog.Overlay className="fixed inset-0 bg-black/30" />

              <Dialog.Panel className="relative max-w-[600px] w-full mx-auto bg-white p-4 rounded">
                <Dialog.Title className="text-lg font-medium mb-6 border-b-2 flex items-center justify-between">
                  {mode === "edit" ? (
                    <span className="">Update Feedlot Profile</span>
                  ) : (
                    <span className="">View Feedlot Profile</span>
                  )}
                  <div
                    className="flex items-center justify-center "
                    onClick={() => {
                      setEditModal(false);
                    }}
                  >
                    <h1
                      onClick={() => setEditLoading(false)}
                      className="bg-gray-200 px-2 rounded-md mb-1 hover:text-red-600 cursor-pointer"
                    >
                      x
                    </h1>
                  </div>
                </Dialog.Title>

                <form onSubmit={handleEditFeedlots(handleEditFeedlot)}>
                  <div className="grid grid-cols-1  sm:grid-cols-2 gap-4">
                    <div>
                      <label className="block text-sm font-medium text-gray-700">
                        Image
                      </label>
                      <img
                        src={`${config.backendBaseURL}uploads/${edititem?.image}`}
                        className="h-20"
                        alt="feedlot"
                      />
                    </div>

                    <div className="">
                      <label
                        htmlFor="fullname"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Feedlot Code
                      </label>
                      <input
                        name="fullname"
                        id="fullname"
                        {...registerEditFeedlot("fullname", {
                          required: "Feedlot Code is required",
                        })}
                        className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                        defaultValue={edititem?.fullname}
                        disabled={mode === "view"}
                      />
                      <p className="text-red-500 text-sm">
                        {errorsEditFeedlot?.fullname?.message}
                      </p>
                    </div>

                    <div className="">
                      <label
                        htmlFor="country_id"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Country
                      </label>
                      <select
                        name="country_id"
                        className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                        id="country_id"
                        {...registerEditFeedlot("country_id", {
                          required: "Country is required",
                        })}
                        disabled={mode === "view"}
                        // onChange={(e) =>
                        //   setValueEditFeedlot("country_id", e.target.value)
                        // }
                        onChange={handleCountryChange}
                      >
                        <option value=""> Select the Country</option>
                        {countries?.data.map((country) => {
                          return (
                            <option value={country.id}> {country.name}</option>
                          );
                        })}
                      </select>
                      <p className="text-red-500 text-sm">
                        {errorsEditFeedlot?.country_id?.message}
                      </p>
                    </div>

                    {user?.role !== "SUPPLIER" && (
                      <div className="">
                        <label
                          htmlFor="supplier_id"
                          className="block text-sm font-medium text-gray-700"
                          onClick={() => {
                            console.log(suppliers);
                          }}
                        >
                          Supplier
                        </label>
                        <select
                          name="supplier_id"
                          className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                          id="supplier_id"
                          {...registerEditFeedlot("supplier_id", {
                            required: "Supplier is required",
                          })}
                          disabled={mode === "view"}
                          onChange={handleSupplierChange}
                          // defaultValue={edititem?.supplier_id}
                          // onChange={(e) => setValueAddFeedlot("supplier_id", e?.target?.value)}

                        >
                          <option value=""> Select the supplier</option>
                          {(Array.isArray(suppliers?.data?.data)
                            ? suppliers?.data?.data
                            : []
                          ).map((supplier) => (
                            <option key={supplier?.id} value={supplier?.id}>
                              {supplier?.fullname}
                            </option>
                          ))}
                        </select>

                        <p className="text-red-500 text-sm">
                          {errorsEditFeedlot?.supplier_id?.message}
                        </p>
                      </div>
                    )}
                     {/* {user?.role !== "SUPPLIER" && (
                    <div className="">
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Email
                      </label>
                      <input
                        type="email"
                        name="email"
                        id="email"
                        value={edititem?.email}
                        className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                        disabled
                      />
                      <p className="text-red-500 text-sm">
                        {errorsEditFeedlot?.email?.message}
                      </p>
                    </div>
                     )} */}

                    <div className="">
                      <label
                        htmlFor="state_id"
                        className="block text-sm font-medium text-gray-700"
                      >
                        State
                      </label>
                      <select
                        name="state_id"
                        className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                        id="state_id"
                        {...registerEditFeedlot("state_id", {
                          required: "State is required",
                        })}
                        disabled={mode === "view"}
                        onChange={(e) =>{
                          setValueAddFeedlot("state_id", e.target.value)
                          setValueAddFeedlot("region_id", "");
                          setValueEditFeedlot("state_id", e.target.value)
                          setValueEditFeedlot("region_id", "");  }
                        }
                      >
                        <option value=""> Select the State</option>
                        {states?.data.map((states) => {
                          return (
                            <option value={states.id}> {states.name}</option>
                          );
                        })}
                      </select>
                      {!watchEditFeedlot("country_id") && (
                        <p className="text-red-500 text-sm">
                          {errorsEditFeedlot?.state_id?.message}
                        </p>
                      )}
                      <p className="text-red-500 text-sm"></p>
                    </div>

                    <div className="">
                      <label
                        htmlFor="region_id"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Region
                      </label>
                      <select
                        name="region_id"
                        className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                        id="region_id"
                        {...registerEditFeedlot("region_id", {
                          required: "Region is required",
                        })}
                        disabled={mode === "view"}
                        onChange={(e) =>
                          setValueEditFeedlot("region_id", e.target.value)
                        }
                      >
                        <option value=""> Select the Region</option>
                        {regions?.data.map((regions) => {
                          return (
                            <option value={regions.id}> {regions.name}</option>
                          );
                        })}
                      </select>
                      {!watchEditFeedlot("state_id") && (
                        <p className="text-red-500 text-sm">
                          {errorsEditFeedlot?.region_id?.message}
                        </p>
                      )}
                    </div>

                    {/* <div className="">
                      <label
                        htmlFor="phone_no"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Phone No
                      </label>
                      <input
                        name="phone_no"
                        id="phone_no"
                        {...registerEditFeedlot("phone_no", {
                          required: "Phone No is required",
                        })}
                        disabled={mode === 'view'}
                        className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                      />
                      <p className="text-red-500 text-sm">
                        {errorsEditFeedlot?.phone_no?.message}
                      </p>
                    </div> */}

                    {/* <div className="">
                      <label
                        htmlFor="ic_number"
                        className="block text-sm font-medium text-gray-700"
                      >
                        IC Number
                      </label>
                      <input
                        name="ic_number"
                        id="ic_number"
                        {...registerEditFeedlot("ic_number", {
                          required: " IC Number is required",
                        })}
                        disabled={mode === 'view'}
                        className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                      />
                      <p className="text-red-500 text-sm">
                        {errorsEditFeedlot?.ic_number?.message}
                      </p>
                    </div> */}

                    {/* dropDown */}

                    <div className="">
                      <label
                        htmlFor="feedlot_type"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Feedlot Type
                      </label>
                      <select
                        name="feedlot_type"
                        className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                        id="feedlot_type"
                        {...registerEditFeedlot("feedlot_type", {
                          required: "Feedlot Typer is required",
                        })}
                        disabled={mode === "view"}
                      >
                        <option value="">  Select the Feedlot Type</option>
                        <option value="Kurangdari < 100">{`Kurang dari  < 100`}</option>
                        <option value="Lebihdari > 100">
                          {`Lebih dari  > 100`}
                        </option>
                      </select>

                      <p className="text-red-500 text-sm">
                        {errorsEditFeedlot?.feedlot_type?.message}
                      </p>
                    </div>

                    {/* <div className="mt-2">
                    <label className="block text-sm font-medium text-gray-700">
                      Password
                    </label>
                    <input
                      type="password"
                      {...registerEditFeedlot("password", { required: true })}
                      className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                    />
                    {errorsEditFeedlot.password && (
                      <span className="text-red-clr">This field is required</span>
                    )}
                  </div> */}

                    {/*dropDown end */}

                    <div className="">
                      <label
                        htmlFor="image"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Image
                      </label>
                      <input
                        type="file"
                        name="image"
                        id="image"
                        {...registerEditFeedlot(
                          "image"
                          // , {required: "Image is required", }
                        )}
                        disabled={mode === "view"}
                        className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                      />
                      {/* <p className="text-red-500 text-sm">
                        {errorsEditFeedlot?.image?.message}
                      </p> */}
                    </div>

                    {/* <div className="">
                      <label className="block text-sm font-medium text-gray-700">
                        STATUS
                      </label>
                      <select
                        {...registerEditFeedlot("is_active")}
                        disabled={mode === "view"}
                        className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                      >
                        <option value="">Select Status</option>
                        <option value="true">Active</option>
                        <option value="false">Inactive</option>
                      </select>
                    </div> */}

                    <div className="">
                      <label
                        htmlFor="capacity"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Capacity
                      </label>
                      <input
                        type="number"
                        name="capacity"
                        id="capacity"
                        min="0"
                        {...registerEditFeedlot("capacity")}
                        disabled={mode === "view"}
                        className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                      />
                    </div>

                    {/* <div className="">
                      <label
                        htmlFor="pic"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Pic
                      </label>
                      <input
                        name="pic"
                        id="pic"
                        {...registerEditFeedlot("pic")}
                        disabled={mode === 'view'}
                        className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                      />
                    </div>

                    <div className="">
                      <label
                        htmlFor="contact"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Contact
                      </label>
                      <input
                        name="contact"
                        id="contact"
                        {...registerEditFeedlot("contact")}
                        disabled={mode === 'view'}
                        className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                      />
                    </div> */}
                  </div>

                  {mode === "edit" && (
                    <div className="flex justify-end mt-10">
                      {editloading ? (
                        <Button type="button">
                          <Loader />
                        </Button>
                      ) : (
                        <Button type="submit">Update Feedlot</Button>
                      )}
                    </div>
                  )}
                </form>
              </Dialog.Panel>
            </div>
          </Dialog>
        )}
      </div>
    </>
  );
}

export default FeedlotPage;
