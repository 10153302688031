import React from 'react';
import Button from '../../../components/Button/Button';

function TransactionTable({ transactions }) {
  return (
    <div>
      <table className="w-full text-sm overflow-x-auto text-left rtl:text-right text-gray-500">
        <thead className="text-xs text-pacific-blue uppercase bg-[#CFF0F2]">
          <tr>
            <th scope="col" className="px-6 py-3 text-base font-medium">
              <div className="flex gap-1 items-center">Id</div>
            </th>
            <th scope="col" className="px-6 py-3 text-base font-medium">
              <div className="flex gap-1 items-center">Payment Id</div>
            </th>
            <th scope="col" className="px-6 py-3 text-base font-medium">
              <div className="flex gap-1 items-center">Order Id</div>
            </th>
            <th scope="col" className="px-6 py-3 text-base font-medium">
              <div className="flex gap-1 items-center">Package Name</div>
            </th>
            <th scope="col" className="px-6 py-3 text-base font-medium">
              <div className="flex gap-1 items-center">Animal Name</div>
            </th>
            <th scope="col" className="px-6 py-3 text-base font-medium">
              <div className="flex gap-1 items-center">Package Type</div>
            </th>
            <th scope="col" className="px-6 py-3 text-base font-medium">
              <div className="flex gap-1 items-center">Package Method</div>
            </th>
            <th scope="col" className="px-6 py-3 text-base font-medium">
              <div className="flex gap-1 items-center">Amount</div>
            </th>
            <th scope="col" className="px-6 py-3 text-base font-medium">
              <div className="flex gap-1 items-center">Payment Status</div>
            </th>
            <th scope="col" className="px-6 py-3 text-base font-medium">
              <div className="flex gap-1 items-center">Date</div>
            </th>
          </tr>
        </thead>
        <tbody>
          {transactions?.data?.map((transaction) => (
            <tr className="bg-white border-b" key={transaction?.id}>
              <th scope="row" className="px-6 py-4 text-sm font-medium">
                {transaction?.id}
              </th>
              <td className="px-6 py-4 text-sm font-medium">
                {transaction?.paymentId}
              </td>
              <td className="px-6 py-4 text-sm font-medium">
                {transaction?.order_id}
              </td>
              <td className="px-6 py-4 text-sm font-medium">
                {transaction?.packageName}
              </td>
              <td className="px-6 py-4 text-sm font-medium">
                {transaction?.packageDetails?.animalName}
              </td>
              <td className="px-6 py-4 text-sm font-medium">
                {transaction?.packageDetails?.packageType=="WAQF"?"SADAKAH":transaction?.packageDetails?.packageType}
              </td>
              <td className="px-6 py-4 text-sm font-medium">
                {transaction?.payment_method}
              </td>
              <td className="px-6 py-4 text-sm font-medium">
                {transaction?.amount}
              </td>
              <td className="px-6 py-4 text-sm font-medium">
                <Button
                  className={`w-24 ${
                    transaction?.payment_status === 'Telah Bayar'
                      ? 'bg-green-500 hover:bg-green-600'
                      : 'bg-red-500 hover:bg-red-600'
                  }`}
                  disabled={transaction?.payment_status !== 'Telah Bayar'}
                >
                  {transaction?.payment_status}
                </Button>
              </td>
              <td className="px-6 py-4 text-sm font-medium">
                {transaction?.date}
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr className="bg-gray-200">
            <td colSpan="5" className="text-right px-6 py-4 text-base font-medium">
              Total Transactions:
            </td>
            <td colSpan="3" className="px-6 py-4 text-base font-medium">
              RM{' '}
              {transactions?.data
                ?.reduce((sum, transaction) => sum + parseFloat(transaction?.amount.replace('RM ', '')) || 0, 0)
                .toFixed(2)}
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
}

export default TransactionTable;
