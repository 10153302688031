import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useGetCattleById } from "../hooks/useGetCattles";
import ResuableInputField from "../../../components/ResuableInputField";
import AddCattle from "./AddCattle";
const CattleDetail = () => {
  const { page } = useParams();
  const {
    cattleTransformedData: cattle,
    isError,
    error,
    isLoading,
    refetch,
  } = useGetCattleById(page);
  const [editable, setEditable] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  if (isError) {
    return (
      <div className="">
        There was some Error, Please try again
        {error && error.message}
      </div>
    );
  }

  if (isLoading || !cattle) {
    return <div className="">Loading, Please wait!</div>;
  }
  return (
    <div className="w-full  sm:w-[600px] md:w-[800px] lg:w-[900px] ">
      <h1 className="text-lg font-bold ">Ruminat Details</h1>
        {editable ? <AddCattle isOpen={isOpen} setIsOpen={setIsOpen} updateId={page} refetch={refetch} /> : null}
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3  gap-4  border-2 p-3">
        <img
          src={cattle?.image}
          alt="cattle"
          className="w-full h-60 object-cover"
        />
        <ResuableInputField type={"text"} label={"Id"} value={cattle?.id} />
        <ResuableInputField
          type={"text"}
          label={"Available Stock"}
          value={cattle?.available_stock}
          disable={true}
        />
        <ResuableInputField
          type={"text"}
          label={"Ruminant Id"}
          value={cattle?.ruminent_id}
          disable={true}
        />
        <ResuableInputField
          type={"text"}
          label={"Supplier Id"}
          value={cattle?.supplier_id}
          disable={true}
        />
        <ResuableInputField
          type={"text"}
          label={"Package Name"}
          value={cattle?.package_name}
          disable={true}
        />
        <ResuableInputField
          type={"text"}
          label={"Ruminant Type"}
          value={cattle?.ruminant_type}
          disable={true}
        />
        <ResuableInputField
          type={"text"}
          label={"Package Price"}
          value={cattle?.package_price}
          disable={true}
        />
        <ResuableInputField
          type={"text"}
          label={"Portions"}
          value={cattle?.portions}
          disable={true}
        />
        <ResuableInputField
          type={"text"}
          label={"Is Available"}
          value={cattle?.is_available ? "Yes" : "No"}
          disable={true}
        />
        <ResuableInputField
          type={"text"}
          label={"Age"}
          value={cattle?.age}
          disable={true}
        />
        <ResuableInputField
          type={"text"}
          label={"Weight"}
          value={cattle?.weight}
          disable={true}
        />
        <ResuableInputField
          type={"text"}
          label={"Country"}
          value={cattle?.country}
          disable={true}
        />
        <ResuableInputField
          type={"text"}
          label={"Region"}
          value={cattle?.region}
          disable={true}
        />
        <ResuableInputField
          type={"text"}
          label={"State"}
          value={cattle?.state}
          disable={true}
        />
      </div>
      {/* edit */}
      {/* <div className="flex justify-end mt-4">
        <button
          className="bg-blue-500 text-white px-4 py-2 rounded-md"
          onClick={() => {
            setEditable(true);
            setIsOpen(true);
          }}
        >
          Edit
        </button>
      </div> */}
    </div>
  );
};

export default CattleDetail;
