import React, { useState, useEffect } from "react";

const ReusableInput = ({
  type,
  label,
  name,
  value: propValue,
  disable,
  className = "",
  placeholder = "",
  onChange = () => {},
  ...rest
}) => {
  const [value, setValue] = useState(propValue || "");

  useEffect(() => {
    setValue(propValue);
  }, [propValue]);

  const handleChange = (e) => {
    setValue(e.target.value);
    onChange(e);
  };

  return (
    
    <div className={`mt-4 ${type === "checkbox" ? "flex items-center" : ""}`}>
      <label htmlFor={name} className={`block text-sm font-medium text-gray-700 ${type === "checkbox" ? "mr-5" : ""}`}>
        {label}
      </label>
      {type === "select" ? (
        <select
          id={name}
          name={name}
          value={value}
          onChange={handleChange}
          className={`mt-1 p-2 w-full border border-gray-300 rounded-md ${className}`}
          {...rest}
        >
          <option value={""}>Please Select</option>
          {rest.options?.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      ) : type === "checkbox" ? (
        // Handle checkbox type input
        <input
          type="checkbox"
          id={name}
          name={name}
          checked={value} // for checkboxes, use `checked` instead of `value`
          onChange={handleChange}
          disabled={disable}
          className={`inline text-sm font-medium text-gray-700 ${className}`}
          {...rest}
        />
      ) : (
        <input
          type={type}
          id={name}
          name={name}
          disabled={disable}
          placeholder={placeholder}
          onChange={handleChange}
          value={value}
          className={`mt-1 p-1 w-full border border-gray-300 rounded-md ${className}`}
          {...rest}
        />
      )}
    </div>
  );
};

export default ReusableInput;
