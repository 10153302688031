import React from 'react'
import Chart from "react-apexcharts"; //Bar Chart
import HeaderSection from '../../components/HeaderSection';


const Analytics = () => {
    const SUMMARYBar = {
        series: [
          {
            data: [500, 40, 900, 320, 500, 350],
          },
          {
            data: [80, 90, 150, 500, 300, 250],
          },
        ],
        chart: {
          type: "bar",
          height: 240,
          toolbar: {
            show: false,
          },
        },
        title: {
          show: false,
        },
        dataLabels: {
          enabled: false,
        },
        colors: ["#00ADB9", "#9747FF"],
        plotOptions: {
          bar: {
            columnWidth: "40%",
            borderRadius: 2,
          },
        },
        xaxis: {
          axisTicks: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          labels: {
            style: {
              colors: "#616161",
              fontSize: "12px",
              fontFamily: "inherit",
              fontWeight: 400,
            },
          },
          categories: [
            2018,
            2019,
            2020,
            2021,
            2022,
            2023,
          ],
        },
        yaxis: {
          min: 0, // Ensure the y-axis starts at 0
          max: 1000, // Ensure the y-axis ends at 1000
          tickAmount: 10, // Ensures proper spacing of ticks
          labels: {
            formatter: function (value) {
              if (value === 0 || value === 100 || value === 500 || value === 1000) {
                return value;
              }
              return "";
            },
            style: {
              colors: "#616161",
              fontSize: "12px",
              fontFamily: "inherit",
              fontWeight: 400,
            },
          },
        },
        grid: {
          show: true,
          borderColor: "#dddddd",
          strokeDashArray: 1,
          xaxis: {
            lines: {
              show: false,
            },
          },
          padding: {
            top: 0,
            right: 20,
          },
        },
        fill: {
          opacity: 0.8,
        },
        tooltip: {
          enabled: false,
        },
        legend: {
          show: false,
        },
      };


      const OVERALL = {
        series: [
          {
            data: [500, 40, 900, 320, 500, 350, 200, 100, 500],
          },
          {
            data: [80, 90, 150, 500, 300, 250, 900, 280, 450],
          },
        ],
        chart: {
          type: "bar",
          height: 240,
          toolbar: {
            show: false,
          },
        },
        title: {
          show: false,
        },
        dataLabels: {
          enabled: false,
        },
        colors: ["#00ADB9", "#9747FF"],
        plotOptions: {
          bar: {
            columnWidth: "40%",
            borderRadius: 2,
          },
        },
        xaxis: {
          axisTicks: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          labels: {
            style: {
              colors: "#616161",
              fontSize: "12px",
              fontFamily: "inherit",
              fontWeight: 400,
            },
          },
          categories: [
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ],
        },
        yaxis: {
          min: 0, // Ensure the y-axis starts at 0
          max: 1000, // Ensure the y-axis ends at 1000
          tickAmount: 10, // Ensures proper spacing of ticks
          labels: {
            formatter: function (value) {
              if (value === 0 || value === 100 || value === 500 || value === 1000) {
                return value;
              }
              return "";
            },
            style: {
              colors: "#616161",
              fontSize: "12px",
              fontFamily: "inherit",
              fontWeight: 400,
            },
          },
        },
        grid: {
          show: true,
          borderColor: "#dddddd",
          strokeDashArray: 1,
          xaxis: {
            lines: {
              show: false,
            },
          },
          padding: {
            top: 0,
            right: 20,
          },
        },
        fill: {
          opacity: 0.8,
        },
        tooltip: {
          enabled: false,
        },
        legend: {
          show: false,
        },
      };

      const QURBAN = {
        series: [
          {
            data: [500, 40, 900, 320, 500, 350, 200, 100, 500],
          },
          {
            data: [80, 90, 150, 500, 300, 250, 900, 280, 450],
          },
        ],
        chart: {
          type: "bar",
          height: 240,
          toolbar: {
            show: false,
          },
        },
        title: {
          show: false,
        },
        dataLabels: {
          enabled: false,
        },
        colors: ["#00ADB9", "#9747FF"],
        plotOptions: {
          bar: {
            columnWidth: "40%",
            borderRadius: 2,
          },
        },
        xaxis: {
          axisTicks: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          labels: {
            style: {
              colors: "#616161",
              fontSize: "12px",
              fontFamily: "inherit",
              fontWeight: 400,
            },
          },
          categories: [
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ],
        },
        yaxis: {
          min: 0, // Ensure the y-axis starts at 0
          max: 1000, // Ensure the y-axis ends at 1000
          tickAmount: 10, // Ensures proper spacing of ticks
          labels: {
            formatter: function (value) {
              if (value === 0 || value === 100 || value === 500 || value === 1000) {
                return value;
              }
              return "";
            },
            style: {
              colors: "#616161",
              fontSize: "12px",
              fontFamily: "inherit",
              fontWeight: 400,
            },
          },
        },
        grid: {
          show: true,
          borderColor: "#dddddd",
          strokeDashArray: 1,
          xaxis: {
            lines: {
              show: false,
            },
          },
          padding: {
            top: 0,
            right: 20,
          },
        },
        fill: {
          opacity: 0.8,
        },
        tooltip: {
          enabled: false,
        },
        legend: {
          show: false,
        },
      };

      const AQIQAH = {
        series: [
          {
            data: [500, 40, 900, 320, 500, 350, 200, 100, 500],
          },
          {
            data: [80, 90, 150, 500, 300, 250, 900, 280, 450],
          },
        ],
        chart: {
          type: "bar",
          height: 240,
          toolbar: {
            show: false,
          },
        },
        title: {
          show: false,
        },
        dataLabels: {
          enabled: false,
        },
        colors: ["#00ADB9", "#9747FF"],
        plotOptions: {
          bar: {
            columnWidth: "40%",
            borderRadius: 2,
          },
        },
        xaxis: {
          axisTicks: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          labels: {
            style: {
              colors: "#616161",
              fontSize: "12px",
              fontFamily: "inherit",
              fontWeight: 400,
            },
          },
          categories: [
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ],
        },
        yaxis: {
          min: 0, // Ensure the y-axis starts at 0
          max: 1000, // Ensure the y-axis ends at 1000
          tickAmount: 10, // Ensures proper spacing of ticks
          labels: {
            formatter: function (value) {
              if (value === 0 || value === 100 || value === 500 || value === 1000) {
                return value;
              }
              return "";
            },
            style: {
              colors: "#616161",
              fontSize: "12px",
              fontFamily: "inherit",
              fontWeight: 400,
            },
          },
        },
        grid: {
          show: true,
          borderColor: "#dddddd",
          strokeDashArray: 1,
          xaxis: {
            lines: {
              show: false,
            },
          },
          padding: {
            top: 0,
            right: 20,
          },
        },
        fill: {
          opacity: 0.8,
        },
        tooltip: {
          enabled: false,
        },
        legend: {
          show: false,
        },
      };

  return (

    <>
    <div className='flex w-full flex-row justify-between mt-6'>
    <HeaderSection title={"Analytics"} />

    <select className="bg-white border w-36 flex outline-none border-electric-purple text-electric-purple text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500   p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
    <option selected>2023</option>
    <option >2024</option>
  </select>
    </div>
    <div className="w-full  md:mb-[100px] mb-[150px] items-center flex justify-between md:flex-col flex-col-reverse">
    {/* SUMMARYBar */}
        <div className="w-full py-5 md:px-7 md:gap-20 gap-10 md:mr-5 bg-white hover:border-2 hover:border-pacific-blue mt-4 rounded-3xl h-fit ">
            
            <h1 className='text-pacific-blue text-base'> SUMMARY</h1>

            <div className="flex flex-col items-center">

              <div className="flex mt-2 mb-10 gap-2 items-end" >
                <p className="text-base md:text-2xl font-semibold text-[#084059]">RM </p>
                <p className="text-2xl md:text-5xl font-semibold text-[#084059]">2,400.00</p>
              </div>

              <div className="flex mt-2 gap-3">
                <div className="flex items-center w-[50%]">
                  <div className="bg-pacific-blue h-4 w-4 m rounded-[50%] mr-2"></div>
                  <h1 className="text-xs">Qurban</h1>
                </div>
                <div className="flex items-center w-[50%]">
                  <div className="bg-electric-purple h-4 w-4 m rounded-[50%] mr-2"></div>
                  <h1 className="text-xs">Aqiqah</h1>
                </div>
              </div>
            </div>

        <div className="relative flex flex-col rounded-xl bg-white bg-clip-border text-gray-700 shadow-md ">
              <div className="pt-6 px-2 pb-0">
                <div id="bar-chart">
                  <Chart
                    options={SUMMARYBar}
                    series={SUMMARYBar.series}
                    type="bar"
                    height={240}
                  />
                </div>
              </div>
            </div>
            
            
            
            </div>


              {/* OVERALL 2023 */}
        <div className="w-full py-5 md:px-7 md:gap-20 gap-10 md:mr-5 bg-white hover:border-2 hover:border-pacific-blue mt-4 rounded-3xl h-fit ">
            
            <h1 className='text-pacific-blue text-base'> OVERALL 2023</h1>

            <div className="flex flex-col items-center">

              <div className="flex mt-2 mb-10 gap-2 items-end" >
                <p className="text-base md:text-2xl font-semibold text-[#084059]">RM </p>
                <p className="text-2xl md:text-5xl font-semibold text-[#084059]">2,400.00</p>
              </div>

              <div className="flex mt-2 gap-3">
                <div className="flex items-center w-[50%]">
                  <div className="bg-pacific-blue h-4 w-4 m rounded-[50%] mr-2"></div>
                  <h1 className="text-xs">Qurban</h1>
                </div>
                <div className="flex items-center w-[50%]">
                  <div className="bg-electric-purple h-4 w-4 m rounded-[50%] mr-2"></div>
                  <h1 className="text-xs">Aqiqah</h1>
                </div>
              </div>
            </div>

        <div className="relative flex flex-col rounded-xl bg-white bg-clip-border text-gray-700 shadow-md ">
              <div className="pt-6 px-2 pb-0">
                <div id="bar-chart">
                  <Chart
                    options={OVERALL}
                    series={OVERALL.series}
                    type="bar"
                    height={240}
                  />
                </div>
              </div>
            </div>
            
            
            
            </div>


              {/* QURBAN 2023 */}
        <div className="w-full py-5 md:px-7 md:gap-20 gap-10 md:mr-5 bg-white hover:border-2 hover:border-pacific-blue mt-4 rounded-3xl h-fit ">
            
            <h1 className='text-pacific-blue text-base'> QURBAN 2023</h1>

            <div className="flex flex-col items-center">

              <div className="flex mt-2 mb-10 gap-2 items-end" >
                <p className="text-base md:text-2xl font-semibold text-[#084059]">RM </p>
                <p className="text-2xl md:text-5xl font-semibold text-[#084059]">2,400.00</p>
              </div>

              <div className="flex mt-2 gap-3">
                <div className="flex items-center w-[50%]">
                  <div className="bg-pacific-blue h-4 w-4 m rounded-[50%] mr-2"></div>
                  <h1 className="text-xs">Qurban</h1>
                </div>
                <div className="flex items-center w-[50%]">
                  <div className="bg-electric-purple h-4 w-4 m rounded-[50%] mr-2"></div>
                  <h1 className="text-xs">Aqiqah</h1>
                </div>
              </div>
            </div>

        <div className="relative flex flex-col rounded-xl bg-white bg-clip-border text-gray-700 shadow-md ">
              <div className="pt-6 px-2 pb-0">
                <div id="bar-chart">
                  <Chart
                    options={QURBAN}
                    series={QURBAN.series}
                    type="bar"
                    height={240}
                  />
                </div>
              </div>
            </div>
            
            
            
            </div>


              {/* AQIQAH 2023 */}
        <div className="w-full py-5 md:px-7 md:gap-20 gap-10 md:mr-5 bg-white hover:border-2 hover:border-pacific-blue mt-4 rounded-3xl h-fit ">
            
            <h1 className='text-pacific-blue text-base'> AQIQAH 2023</h1>

            <div className="flex flex-col items-center">

              <div className="flex mt-2 mb-10 gap-2 items-end" >
                <p className="text-base md:text-2xl font-semibold text-[#084059]">RM </p>
                <p className="text-2xl md:text-5xl font-semibold text-[#084059]">2,400.00</p>
              </div>

              <div className="flex mt-2 gap-3">
                <div className="flex items-center w-[50%]">
                  <div className="bg-pacific-blue h-4 w-4 m rounded-[50%] mr-2"></div>
                  <h1 className="text-xs">Qurban</h1>
                </div>
                <div className="flex items-center w-[50%]">
                  <div className="bg-electric-purple h-4 w-4 m rounded-[50%] mr-2"></div>
                  <h1 className="text-xs">Aqiqah</h1>
                </div>
              </div>
            </div>

        <div className="relative flex flex-col rounded-xl bg-white bg-clip-border text-gray-700 shadow-md ">
              <div className="pt-6 px-2 pb-0">
                <div id="bar-chart">
                  <Chart
                    options={AQIQAH}
                    series={AQIQAH.series}
                    type="bar"
                    height={240}
                  />
                </div>
              </div>
            </div>
            
            
            
            </div>


            
            </div>
    </>
  )
}

export default Analytics