import React, { useEffect, useState } from "react";
import { GetAllsadakahDetails } from "../../../services/settings";
import { Loader } from "../../../components/Loader";
import Button from "../../../components/Button/Button";
import { Dialog } from "@headlessui/react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { formatDate, formatDateWithoutTime } from "../../../utils/formatDate";
import { useGetPackagesHook } from "../Packages/hooks/useGetPackagesHook";
import { axios } from "../../../config/axios";
import { config } from "../../../config/config";
import {formatPrice} from "../../../utils/utils";
import { FaUser } from "react-icons/fa";

function SadakahDetail() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [createLoading, setCreateLoading] = useState(false);
  let [isOpen, setIsOpen] = useState(false);
  let [isEdit, setIsEdit] = useState(false);
  let [editList, setEditList] = useState({});
  const [sadakahImageData, setSadakahImageData] = useState({
    images: [],
  });

  const [sadakahData, setsadakahData] = useState(null);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const { packages } = useGetPackagesHook();
  console.log("packages");
  console.log(packages);
  console.log("editList");
  console.log(editList);
  const formattedStartDate = editList.project_start_date
  ? new Date(editList.project_start_date).toISOString().split("T")[0]
  : null;

const formattedClosingDate = editList.project_closing_date
  ? new Date(editList.project_closing_date).toISOString().split("T")[0]
  : null;
  useEffect(() => {
    reset({
      package_id: editList?.package?.id,
      name: editList?.name,
      description: editList?.description,
      project_value: editList?.project_value,
      project_start_date: formattedStartDate,
      project_closing_date: formattedClosingDate,
      long_description: editList?.long_description,
      status: editList?.enabled,
      price_per_unit: editList?.price_per_unit,
      sub_pkg_link: editList?.sub_pkg_link
    });
  }, [isEdit]);

  // create Waqf
  const onSubmit = async (data) => {
     
    setCreateLoading(true);
    console.log(data);

    const formData = new FormData();

    // Append form fields to formData
    formData.append("package_id", parseInt(data.package_id));
    formData.append("name", data.name);
    formData.append("description", data.description);
    // formData.append("actual_price", parseInt(data.actual_price));
    formData.append("price_per_unit", data.price_per_unit);
    // formData.append("project_name", data.project_name);
    formData.append("project_start_date", data.project_start_date);
    formData.append("project_closing_date", data.project_closing_date);
    formData.append("long_description", data.long_description);
    formData.append("sub_pkg_link", data.sub_pkg_link);
    formData.append("project_value", data.project_value);


    // Append single image (sub_pkg_image) to formData
    if (data.sub_pkg_image && data.sub_pkg_image.length > 0) {
      formData.append("sub_pkg_image", data.sub_pkg_image[0]);
    }
    if (data.images && data.images.length > 0) {
      formData.append("images", data.images[0]);
    }

    // Append multiple images with the key "images[]"
    // for (let i = 0; i < data?.images.length; i++) {
    //   formData.append(`images[${i}]`, data?.images[i]);
    // }

     
    const token = JSON.parse(localStorage.getItem("token"));

    try {
      const response = await axios.post(`packages/package-details`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });

      // Handle successful response
      console.log(response.data);
      setCreateLoading(false);
      fetchData();
      setIsOpen(false)
      toast.success("Form submitted successfully!");
    } catch (error) {
      // Handle error response
      setCreateLoading(false);
      console.error(error);
      console.log("error");
      toast.error(error?.response?.data?.error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    if (files && files.length > 0) {
      const file = files[0];
      if (file.size > 1048576) {
        // 1MB = 1048576 bytes
        toast.error("Image size should not exceed 1MB");
        return;
      }
    }

    if (name === "sub_pkg_image") {
      // Handle file input separately
      setSadakahImageData((prevFormData) => ({
        ...prevFormData,
        sub_pkg_image: files[0], // Assuming single file selection
      }));
    } else {
      // Handle other inputs
      setSadakahImageData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }

    // Check if more than 4 files are uploaded
    if (files.length > 4) {
      toast.error("You can upload a maximum of 4 images.");
      return;
    }

    // Check if any file exceeds 1MB
    for (let i = 0; i < files.length; i++) {
      if (files[i].size > 1048576) {
        toast.error("Each image file size should not exceed 1MB");
        return;
      }
    }

    // Update state with valid files
    if (name === "images") {
      setSadakahImageData((prevFormData) => ({
        ...prevFormData,
        images: Array.from(files), // Store all files
      }));
    }
  };

  // Edit Waqf
  const onEditSubmit = async (data) => {
     
    setCreateLoading(true);
    console.log(data);

    const formData = new FormData();

    // Append form fields to formData
    formData.append("package_id", parseInt(data.package_id));
    formData.append("name", data.name);
    formData.append("status", JSON.parse(data.status)); // Convert status to boolean
    formData.append("description", data.description);
    // formData.append("actual_price", parseInt(data.actual_price));
    formData.append("price_per_unit", data.price_per_unit);
    // formData.append("project_name", data.project_name);
    formData.append("project_start_date", data.project_start_date);
    formData.append("project_closing_date", data.project_closing_date);
    formData.append("long_description", data.long_description);
    formData.append("project_value", data.project_value);
    formData.append("sub_pkg_link", data.sub_pkg_link);


    // Append single image (sub_pkg_image) to formData
    if (data.sub_pkg_image && data.sub_pkg_image.length > 0) {
      formData.append("sub_pkg_image", data.sub_pkg_image[0]);
    }

    // for (let i = 0; i < data?.images.length; i++) {
    //   formData.append(`images[${i}]`, data?.images[i]);
    // }

    if (data.images && data.images.length > 0) {
      formData.append("images", data.images[0]);
    }

    console.log(formData)
    const token = JSON.parse(localStorage.getItem("token"));

    try {
      const response = await axios.put(
        `packages/package-details/${editList?.id}`, // Correct URL
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      // Handle successful response
      console.log(response.data);
      setCreateLoading(false);
      fetchData();
      setIsEdit(false);
      toast.success("Form submitted successfully!");
    } catch (error) {
      // Handle error response
      setCreateLoading(false);
      console.error(error);
      toast.error("Error submitting form!");
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      let updatedData = await GetAllsadakahDetails();
      setLoading(true);
      setData(updatedData);
      console.log("updatedData");
      console.log(updatedData);
    };
    fetchData();
  }, []);

  const fetchData = async () => {
    let updatedData = await GetAllsadakahDetails();
    setLoading(true);
    setData(updatedData);
  };

  if (!loading) {
    return <Loader />;
  }
  return (
    <div className="relative overflow-x-auto mb-[200px]">
      <div className="flex justify-between mb-2">
        <button className="font-bold" onClick={() => console.log(data)}>sadakah List</button>

        <Button type="button" onClick={() => setIsOpen(true)}>
        Add Sadaqah Project
        </Button>
      </div>

      <table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
        <thead className="text-xs text-pacific-blue uppercase bg-[#CFF0F2] ">
          <tr>
            <th scope="col" className="px-6 py-3 text-base font-medium">
              <div className="flex gap-1 items-center">Id</div>
            </th>
            <th scope="col" className="px-6 py-3 text-base font-medium">
              <div className="flex gap-1 items-center">Image</div>
            </th>
            <th scope="col" className="px-6 py-3 text-base font-medium">
              <div className="flex gap-1 items-center">project Name</div>
            </th>
            <th scope="col" className="px-6 py-3 text-base font-medium">
              <div className="flex gap-1 items-center">Master Package Type</div>
            </th>
            <th scope="col" className="px-6 py-3 text-base font-medium">
              <div className="flex gap-1 items-center">description</div>
            </th>
          
            {/* <th scope="col" className="px-6 py-3 text-base font-medium">
               Sub Pkg Type
            </th> */}

            <th scope="col" className="px-6 py-3 text-base font-medium">
              Country
            </th>

            <th scope="col" className="px-6 py-3 text-base font-medium">
              Package Type
            </th>

            <th scope="col" className="px-6 py-3 text-base font-medium">
              Sadaqah Price per unit
            </th>
            <th scope="col" className="px-6 py-3 text-base font-medium">
            Project Value
            </th>

            <th scope="col" className="px-6 py-3 text-base font-medium">
            Pkg Link
            </th>
            <th scope="col" className="px-6 py-3 text-base font-medium">
              Project Start Date
            </th>
            <th scope="col" className="px-6 py-3 text-base font-medium">
              Project Closing Date
            </th>
            {/* <th scope="col" className="px-6 py-3 text-base font-medium">
              Package Info
            </th> */}
           <th scope="col" className="px-6 py-3 text-base font-medium">
              <div className="flex gap-1 items-center">Created Date</div>
            </th>
            <th scope="col" className="px-6 py-3 text-base font-medium">
              <div className="flex gap-1 items-center">Updated Date</div>
            </th>
            <th scope="col" className="px-6 py-3 text-base font-medium">
              <div className="flex gap-1 items-center">Status</div>
            </th>

            <th scope="col" className="px-2 py-3 text-base font-medium">
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          {data?.data?.data?.data?.map((e) => {
            return (
              <tr className="bg-white border-b ">
                <th scope="row" className="px-6 py-4 text-sm font-medium">
                  {e?.id}
                </th>
                <th scope="row" className="px-6 py-4 text-sm font-medium">
                {(e?.sub_pkg_image !== undefined && e?.sub_pkg_image !== "" && e?.sub_pkg_image !== "undefined" && e?.sub_pkg_image !== null && e?.sub_pkg_image !== "null") ? (
                      <img src={`${config?.backendBaseURL}uploads/${e?.sub_pkg_image}`} className="w-14" />
                    ) : (
                      <FaUser className="w-8 h-10" />
                    )}
                 
                </th>

                <td className="px-6 py-4 text-sm font-medium">{e?.name}</td>
                <td className="px-6 py-4 text-sm font-medium">
                  {e?.package?.name}
                </td>
                <td className="px-6 py-4 text-sm font-medium">
                  {e?.description}
                </td>
                

                {/* <td className="px-6 py-4">
                  <div className="flex items-end" >
                    {e?.sub_pkg_type}
                  </div>

                </td> */}

                <td className="px-6 py-4 text-sm font-medium">
                  {e?.package?.country?.name}
                </td>

                <td className="px-6 py-4  text-sm font-medium">
                  {/* {e?.package.pkg_type} */}
                  Sadaqah
                </td>

                <td className="px-6 py-4  text-sm font-medium">
                {formatPrice(e?.price_per_unit)}
                </td>
                <td className="px-6 py-4  text-sm font-medium">
                {formatPrice(e?.project_value)}

                </td>

                <td className="px-6 py-4 text-sm font-medium">
                  {e?.sub_pkg_link}
                </td>
                <td className="px-6 py-4  text-sm font-medium">
                {formatDateWithoutTime(e?.project_start_date)}
                </td>
                <td className="px-6 py-4  text-sm font-medium">
                {formatDateWithoutTime(e?.project_closing_date)}
                </td>

                {/* <td className="px-6 py-4  text-sm font-medium">
                  {e?.package.pkg_info}
                </td> */}
            <td className="px-6 py-4 text-sm font-medium">
                  {formatDate(e?.created_at)}
                </td>
                <td className="px-6 py-4 text-sm font-medium">
                  {formatDate(e?.updated_at)}
                </td>

                <td className="px-6 py-4 text-sm font-medium">
                  {e?.enabled? <Button>Active</Button>:<Button>In-Active</Button>}
                </td>
                <td className="px-6 py-4  text-sm font-medium">
                  <Button
                    onClick={() => {
                      setEditList(e);
                      setIsEdit(true);
                    }}
                  >
                    {" "}
                    Edit
                  </Button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      {isOpen && (
        <Dialog
          open={isOpen}
          onClose={() => setIsOpen(true)}
          className="fixed inset-0 z-50 overflow-y-auto p-4 duration-500 "
        >
          <div className="flex items-center justify-center min-h-screen">
            <Dialog.Overlay className="fixed inset-0 bg-black/30" />

            <Dialog.Panel className="relative max-w-[600px] w-full mx-auto bg-white p-4 rounded">
              <Dialog.Title className="text-lg font-medium mb-6 border-b-2 flex items-center justify-between">
                <span className="">Add Sadaqah Project</span>
                <div
                  className="flex items-center justify-center "
                  onClick={() => setIsOpen(false)}
                >
                  <h1 className="bg-gray-200 px-2 rounded-md mb-1 cursor-pointer hover:text-red-600 ">
                    x
                  </h1>
                </div>
              </Dialog.Title>

              <form onSubmit={handleSubmit(onSubmit)} noValidate>
                <div className="grid grid-cols-1  sm:grid-cols-2 gap-4">
                  <div className="">
                    <label
                      htmlFor="name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Project Name
                    </label>
                    <input
                      {...register("name")}
                      type="text"
                      id="name"
                      name="name"
                      className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                    />
                  </div>

                  <div className="">
                    <label
                      htmlFor="package_id"
                      className="block text-sm font-medium text-gray-700"
                      onClick={()=>console.log(packages)}
                    >
                      Master package type{" "}
                      <span className="text-red-500">*</span>
                    </label>

                    <select
                      name="package_id"
                      id="package_id"
                      {...register("package_id", {
                        required: "Package ID is required",
                        pattern: {
                          value: /^[0-9]*$/,
                          message: "Package ID must be an integer",
                        },
                      })}
                      className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                    >
                      <option value="">Select Package</option>
                      {packages?.map((e, index) => {
                        if (e?.pkg_type !== "WAQF" || !e?.is_active) return null;
                        return (
                          <>
                            <option value={e.id} key={index}>
                              {e?.name}{" "}
                            </option>
                          </>
                        );
                      })}
                    </select>

                    <p className="hidden">
                      {toast.error(errors.package_id?.message)}
                    </p>
                  </div>

                  <div className="">
                    <label
                      htmlFor="description"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Description
                    </label>
                    <input
                      {...register("description")}
                      type="text"
                      id="description"
                      name="description"
                      className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                    />
                  </div>

                  <div className="">
                    <label
                      htmlFor="sub_pkg_image"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Sub Package Image <span className="text-red-500">*</span>
                    </label>
                    <input
                      {...register("sub_pkg_image", {
                        // required: " Sub Package Image is required",
                      })}
                      type="file"
                      id="sub_pkg_image"
                      onChange={handleInputChange}
                      name="sub_pkg_image"
                      className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                    />
                    <p className="hidden">
                      {!errors.package_id &&
                        toast.error(errors.sub_pkg_image?.message)}
                    </p>
                  </div>

                  {/* <div className="">
                 <label
                   htmlFor="actual_price"
                   className="block text-sm font-medium text-gray-700"
                 >
                  Actual Price <span className='text-red-500'>*</span>
                 </label>
                 <input
                 { ...register("actual_price",{
                  required: "Actual Price is required",
                  pattern: {
                   value: /^[0-9]*$/,
                   message: 'Actual Price must be an integer'  
               },
                 }) }
                   type="number"
                   id="actual_price"
                   name="actual_price"
                   className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                  
                  
                 />
                 <p className='hidden'>
             {!errors.sub_pkg_image &&
             toast.error(errors.actual_price?.message)}
                 </p>
             
               </div> */}

                  <div className="">
                    <label
                      htmlFor="price_per_unit"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Sadaqah Price per unit{" "}
                      <span className="text-red-500">*</span>
                    </label>
                    <input
                      {...register("price_per_unit", {
                        required: "Sadaqah Price per unit is required",
                        pattern: {
                          value: /^[0-9]*$/,
                          message: "Sadaqah Price per unit must be an integer",
                        },
                      })}
                      type="number"
                      id="price_per_unit"
                      name="price_per_unit"
                      className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                    />
                    <p className="hidden">
                      {!errors.price_per_unit &&
                        toast.error(errors.price_per_unit?.message)}
                    </p>
                  </div>

                  {/* <div className="">
                 <label
                   htmlFor="project_name"
                   className="block text-sm font-medium text-gray-700"
                 >
                   Project Name
                 </label>
                 <input
                 { ...register("project_name") }
                   type="text"
                   id="project_name"
                   name="project_name"
                   className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                 />
             </div> */}

                  <div className="">
                    <label
                      htmlFor="project_value"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Project Value
                    </label>
                    <input
                      {...register("project_value")}
                      type="number"
                      id="project_value"
                      name="project_value"
                      className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                    />
                  </div>

                  <div className="">
                    <label
                      htmlFor="project_start_date"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Project Start Date
                    </label>
                    <input
                      {...register("project_start_date")}
                      type="date"
                      id="project_start_date"
                      name="project_start_date"
                      className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                    />
                  </div>
                  <div className="">
                    <label
                      htmlFor="project_closing_date"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Project Closing Date
                    </label>
                    <input
                      {...register("project_closing_date")}
                      type="date"
                      id="project_closing_date"
                      name="project_closing_date"
                      className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                    />
                  </div>

                  <div className="">
                    <label
                      htmlFor="images"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Project Images (max 4) (size 1MB)
                    </label>
                    <input
                      {...register("images", {
                        // required: "Images is required",
                      })}
                      type="file"
                      id="images"
                      name="images"
                      onChange={handleInputChange}
                      className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                      multiple
                    />
                  </div>

                  <div className="">
                    <label
                      htmlFor="sub_pkg_link"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Package Info Link
                    </label>
                    <input
                      {...register("sub_pkg_link")}
                      type="text"
                      id="sub_pkg_link"
                      name="sub_pkg_link"
                      className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                    />
                  </div>

                  <div className="">
                    <label
                      htmlFor="long_description"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Long Description
                    </label>
                    <textarea
                      {...register("long_description")}
                      type="text"
                      id="long_description"
                      name="long_description"
                      className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                    />
                  </div>
                <div className="flex justify-end mt-10">
                  {!createLoading ? (
                    <Button type="submit">Add Project</Button>
                  ) : (
                    <Button type="button">
                      <Loader />
                    </Button>
                  )}
                </div>
                </div>
              </form>
            </Dialog.Panel>
          </div>
        </Dialog>
      )}

      {isEdit && (
        <Dialog
          open={isEdit}
          onClose={() => setIsEdit(true)}
          className="fixed inset-0 z-50 overflow-y-auto p-4 duration-500 "
        >
          <div className="flex items-center justify-center min-h-screen">
            <Dialog.Overlay className="fixed inset-0 bg-black/30" />

            <Dialog.Panel className="relative max-w-[600px] w-full mx-auto bg-white p-4 rounded">
              <Dialog.Title className="text-lg font-medium mb-6 border-b-2 flex items-center justify-between">
                <span className="" onClick={() => console.log(editList)}>
                  Update Sadaqah
                </span>
                <div
                  className="flex items-center justify-center "
                  onClick={() => setIsEdit(false)}
                >
                  <h1 className="bg-gray-200 px-2 rounded-md mb-1 cursor-pointer hover:text-red-600 ">
                    x
                  </h1>
                </div>
              </Dialog.Title>

              <form onSubmit={handleSubmit(onEditSubmit)} noValidate>
                <div className="grid grid-cols-1  sm:grid-cols-2 gap-4">
                  <div className="">
                    <label
                      htmlFor="name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Project Name
                    </label>
                    <input
                      {...register("name")}
                      type="text"
                      id="name"
                      name="name"
                      className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                    />
                  </div>

                  <div className="">
                    <label
                      htmlFor="package_id"
                      className="block text-sm font-medium text-gray-700"
                      onClick={()=>console.log(packages)}
                    >
                      Master package type{" "}
                      <span className="text-red-500">*</span>
                    </label>

                    <select
                      name="package_id"
                      id="package_id"
                      {...register("package_id", {
                        required: "Package ID is required",
                        pattern: {
                          value: /^[0-9]*$/,
                          message: "Package ID must be an integer",
                        },
                      })}
                      className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                    >
                      <option value="">Select Package</option>
                      {packages?.map((e, index) => {
                        if (e.pkg_type !== "WAQF" || !e?.is_active ) return null;
                        return (
                          <>
                            <option value={e.id} key={index}>
                              {e?.name}{" "}
                            </option>
                          </>
                        );
                      })}
                    </select>

                    <p className="hidden">
                      {toast.error(errors.package_id?.message)}
                    </p>
                  </div>

                  <div className="">
                    <label
                      htmlFor="description"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Description
                    </label>
                    <input
                      {...register("description")}
                      type="text"
                      id="description"
                      name="description"
                      className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                    />
                  </div>

                  <div className="">
                    <label
                      htmlFor="sub_pkg_image"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Sub Package Image <span className="text-red-500">*</span>
                    </label>
                    <input
                      {...register("sub_pkg_image", {
                        // required: " Sub Package Image is required",
                      })}
                      type="file"
                      id="sub_pkg_image"
                      name="sub_pkg_image"
                      className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                    />
                    <p className="hidden">
                      {!errors.package_id &&
                        toast.error(errors.sub_pkg_image?.message)}
                    </p>
                  </div>

                  {/* <div className="">
                 <label
                   htmlFor="actual_price"
                   className="block text-sm font-medium text-gray-700"
                 >
                  Actual Price <span className='text-red-500'>*</span>
                 </label>
                 <input
                 { ...register("actual_price",{
                  required: "Actual Price is required",
                  pattern: {
                   value: /^[0-9]*$/,
                   message: 'Actual Price must be an integer'  
               },
                 }) }
                   type="number"
                   id="actual_price"
                   name="actual_price"
                   className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                  
                  
                 />
                 <p className='hidden'>
             {!errors.sub_pkg_image &&
             toast.error(errors.actual_price?.message)}
                 </p>
             
               </div> */}

                  <div className="">
                    <label
                      htmlFor="price_per_unit"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Sadaqah Price per unit{" "}
                      <span className="text-red-500">*</span>
                    </label>
                    <input
                      {...register("price_per_unit", {
                        required: "Sadaqah Price per unit is required",
                        pattern: {
                          value: /^[0-9]*$/,
                          message: "Sadaqah Price per unit must be an integer",
                        },
                      })}
                      type="number"
                      id="price_per_unit"
                      name="price_per_unit"
                      className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                    />
                    <p className="hidden">
                      {!errors.price_per_unit &&
                        toast.error(errors.price_per_unit?.message)}
                    </p>
                  </div>

                  {/* <div className="">
                 <label
                   htmlFor="project_name"
                   className="block text-sm font-medium text-gray-700"
                 >
                   Project Name
                 </label>
                 <input
                 { ...register("project_name") }
                   type="text"
                   id="project_name"
                   name="project_name"
                   className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                 />
             </div> */}

                  <div className="">
                    <label
                      htmlFor="project_start_date"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Project Value
                    </label>
                    <input
                      {...register("project_value")}
                      type="number"
                      id="project_start_date"
                      name="project_value"
                      className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                    />
                  </div>

                  <div className="">
                    <label
                      htmlFor="project_start_date"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Project Start Date
                    </label>
                    <input
                      {...register("project_start_date")}
                      type="date"
                      id="project_start_date"
                      name="project_start_date"
                      className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                    />
                  </div>

                  <div className="">
                    <label
                      htmlFor="project_closing_date"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Project Closing Date
                    </label>
                    <input
                      {...register("project_closing_date")}
                      type="date"
                      id="project_closing_date"
                      name="project_closing_date"
                      className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                    />
                  </div>

                  <div className="">
                    <label
                      htmlFor="status"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Status <span className="text-red-500">*</span>
                    </label>

                    <select
                      name="status"
                      id="status"
                      {...register("status", {
                        required: "Status is required",
                      })}
                      className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                    >
                      <option value="">Select Status</option>
                      <option value="true">Active</option>
                      <option value="false">In-Active</option>
                    </select>

                    <p className="hidden">
                      {toast.error(errors.package_id?.message)}
                    </p>
                  </div>

                  <div className="">
                    <label
                      htmlFor="images"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Project Image (max 4) (size 1MB){" "}
                    </label>
                    <input
                      {...register("images", {
                        // required: " Sub Package Image is required",
                      })}
                      type="file"
                      id="images"
                      name="images"
                      className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                      multiple
                    />
                  </div>

                  <div className="">
                    <label
                      htmlFor="sub_pkg_link"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Package Info Link
                    </label>
                    <input
                      {...register("sub_pkg_link")}
                      type="text"
                      id="sub_pkg_link"
                      name="sub_pkg_link"
                      className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                    />
                  </div>

                  <div className="">
                    <label
                      htmlFor="long_description"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Long Description
                    </label>
                    <textarea
                      {...register("long_description")}
                      type="text"
                      id="long_description"
                      name="long_description"
                      className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                    />
                  </div>

                  <div className="flex justify-end mt-10">
                    {!createLoading ? (
                      <Button type="submit">Submit</Button>
                    ) : (
                      <Button type="button">
                        <Loader />
                      </Button>
                    )}
                  </div>
                </div>
              </form>
            </Dialog.Panel>
          </div>
        </Dialog>
      )}
    </div>
  );
}

export default SadakahDetail;
