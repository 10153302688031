export default function useToken() {
	const getToken = () => {
		const tokenString = localStorage.getItem("token");
		return tokenString;
	};

	const token = getToken();

	const saveToken = (userToken) => {
		localStorage.setItem("token", JSON.stringify(userToken));
	};

	return {
		setToken: saveToken,
		token,
	};
}
