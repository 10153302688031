import { axios } from "../../config/axios";
import { endpoint } from "../../config/constants";
import { formatDate } from "../../utils/formatDate";

export const getCattlesData = async (
  pageNumber = 1,
  limit = 10,
  queryOption
) => {
  let query = "";
  if (queryOption) {
    query = Object.keys(queryOption)
      .filter((key) => queryOption[key])
      .map((key) => `${key}=${queryOption[key]}`)
      .join("&");
  }
  const response = await axios.get(
    endpoint.GET_CATTLE + `?pageNumber=${pageNumber}&pageSize=${limit}&${query}`
  );
console.log("chec",response)
  if (!response.data) {
    throw new Error("No data found");
  }
  const transformedData = response.data.data.data.map((data) => {
    data.updated_at && delete data.updated_at;
    return {
      ...data,
      created_at: formatDate(data.created_at),
    };
  });
  const { data, ...rest } = response.data.data;
  return {
    data: transformedData,
    ...rest,
  };
};

export const addCattle = async (data) => {
  const token = JSON.parse(localStorage.getItem("token"));

  const response = await axios.post(endpoint.CREATE_CATTLE, data, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
  });
  if (!response.data) {
    throw new Error("No data found");
  }
  return response.data;
};

// updateCattle
export const updateCattle = async (id, data) => {
  const token = JSON.parse(localStorage.getItem("token"));
  const response = await axios.put(
    endpoint.CREATE_CATTLE + `/animals/${id}`,
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    }
  );
  if (!response.data) {
    throw new Error("No data found");
  }
  return response.data;
};

export const getSupliersList = async () => {
  const token = JSON.parse(localStorage.getItem("token"));
  const user = JSON.parse(localStorage.getItem("user"));

  const response = await axios.get(endpoint.GET_SUPPLIERS, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (user.role !== "SUPPLIER") {
  if (!response.data) {
    throw new Error("No data found");
  }
  }
  return response.data;
};

export const getFeedlotList = async () => {
  const token = JSON.parse(localStorage.getItem("token"));
  const response = await axios.get(endpoint.GET_FEEDLOTS, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  if (!response.data) {
    throw new Error("No data found");
  }

  return response.data;
};

// cattle by id
export const getCattleById = async (id) => {
  const token = JSON.parse(localStorage.getItem("token"));
  const response = await axios.get(endpoint.GET_CATTLE + `/animals/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
 
  if (!response.data) {
    throw new Error("No data found");
  }
  return response.data;
};
