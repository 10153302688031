import { useQuery } from "@tanstack/react-query";
import { api_keys } from "../../../config/constants";
import { GetTransaction } from "../../../services/transaction";

export const useTransactionDetails = () => {
    const {
        data,
        isError,
        isLoading,
    } = useQuery({
        queryKey: [api_keys.GET_TRANSCTION],
        queryFn: GetTransaction,
    });

    console.log("I am here");

    return {
        data,
        isError,
        isLoading,
    };
};
