import React, { useState } from 'react';
import TransTab from './TransTab';
import Search from '../../../components/Search';
import NewOrder from './newOrder';
import OnGoing from './onGoing';
import Rejected from './Rejected';
import Completed from './completed';
const Slaughter = ({ data, refetch }) => {
  const [activeTab, setActiveTab] = useState('New'); // Initial active tab

  const tabsName = ['New', 'Ongoing', "PARTIAL_COMPLETED","COMPLETED", "Rejected"]; // Tabs to display
  const tabNumberCounting = [
    { name: 'New', countValue: "" }, // Counts for each tab
    { name: 'Ongoing', countValue: "" },
    { name: 'PARTIAL_COMPLETED', countValue: "" },
    { name: 'COMPLETED', countValue: "" },
    { name: 'Rejected', countValue: "" },
  ];

  // Function to handle tab change
  const handleTabChange = (tabName) => {
    setActiveTab(tabName);
  };

  console.log("Just Checking123", data)
  // Render conditionally based on activeTab
  const renderTabContent = () => {
    switch (activeTab) {
      case 'New':
        return <NewOrder data={data?.data?.filter((e) => e.status === "NEW")} refetch={refetch} />;
      case 'Ongoing':
        return <OnGoing data={data?.data?.filter((e) => e.status === "ONGOING")} refetch={refetch} />;
      case 'PARTIAL_COMPLETED':
        return <Completed data={data?.data?.filter((e) => e.status === "PARTIAL_COMPLETED")} refetch={refetch} />;
        case 'COMPLETED':
        return <Completed data={data?.data?.filter((e) => e.status === "COMPLETED")} refetch={refetch} />;
      case 'Rejected':
        return <Rejected data={data?.data?.filter((e) => e.status === "REJECTED")} refetch={refetch} />;
      default:
        return null;
    }
  };

  return (
    <>
      {/* Upper Tab */}
      <TransTab
        tabsName={tabsName}
        tabNumberCounting={tabNumberCounting}
        onTabChange={handleTabChange}
        searchbar={<Search className="md:w-[300px] my-2 md:my-0" />}
      />

      {renderTabContent()}
    </>
  );
};

export default Slaughter;
