import React from "react";
import { useParams } from "react-router-dom";
import { api_keys } from "../config/constants";
import { getOrderDetails } from "../services/orders";
import { useQuery } from "@tanstack/react-query";
import UpdateOrder from "../components/UpdateOrder";
import HeaderSection from "../components/HeaderSection";

const DisplayOrder = () => {
	const { id } = useParams();
	const { data, isError, error, isLoading ,refetch} = useQuery({
		queryKey: [api_keys.GET_ORDER_DETAIL],
		queryFn: () => getOrderDetails(id),
	});
	if (isError) {
		return (
			<div className="">
				There was some Error, Please try again
				{error}
			</div>
		);
	}
	if (isLoading) {
		return <div className="">Please wait, your data is loading</div>;
	}

	return (
		<div>
			<HeaderSection title={"Order Details"} />
			<UpdateOrder data={data?.data} refetch={refetch}/>
		</div>
	);
};

export default DisplayOrder;
