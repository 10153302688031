import { QueryClient, useMutation } from "@tanstack/react-query";
import { api_keys } from "../../../config/constants";
import { updateSupplierStatus } from "../../../services/supplier";
import { notify } from "../../../utils/toasts";
import { useState } from "react";

export const useUpdateSupplierStatus = () => {
	const queryClient = new QueryClient();
	const [is_loading, setIs_loading] = useState(false);

	const onSubmit = (id) => {
		setIs_loading(true);
		mutation.mutate(id);
	};
	const mutation = useMutation({
		mutationKey: [api_keys.APPROVE_USER_PROFILE],
		mutationFn: updateSupplierStatus,
		onSuccess: () => {
			setIs_loading(false);
			notify("success", "Supplier status updated successfully");
			queryClient.invalidateQueries({ queryKey: api_keys.GET_SUPPLIERS });
		},
		onError: (err) => {
			// notify("error", err.message);
			notify("error", err?.response?.data?.error || "Unknow error occur" );
		},
	});
	return {
		is_loading,
		onSubmit,
	};
};
