/* eslint-disable no-unused-vars */
import { useState } from "react";
import DataTable from "../../../components/Table/DataTable";
import { useFetchAnalyticsStock } from "../hooks/useGetAnalytics";
import { Loader } from "../../../components/Loader";
import { useNavigate, useParams } from "react-router-dom";
import HeaderSection from "../../../components/HeaderSection";
import { notifyNoDataFound } from "../../../utils/toasts"; 
import Search from "../../../components/Search"; 
import Stock from '../../dashboard/Admin/componenet/Stock'
const AnalyticsStock = () => {
  // const navigate = useNavigate();
  // let { page = 1 } = useParams();
  // page = Number(page);
  // if (page < 1) {
  //   page = 1;
  // }
  // const [pageNumber, setPageNumber] = useState(page);
  // const [limit, setLimit] = useState(10);

  // const { stock, isError, isLoading } = useFetchAnalyticsStock(); 
  // if (isError) {
  //   return <div className="">There was some Error, Please try again</div>;
  // }
  // if (stock?.data.length === 0) {
  //   notifyNoDataFound();
  //   setPageNumber(1);
  //   navigate(`/stock/1`);
  // }
  // if (isLoading) {
  //   return (
  //     <div className="flex space-x-2">
  //       <Loader />
  //       <div className="">Loading...</div>
  //     </div>
  //   );
  // } 

  return (
    // <div>
    //   <HeaderSection title={"Stock"} /> 
    //     <div className="col-span-12 sm:col-span-5 flex justify-end mb-3">
    //       <Search /> 
    //   </div> 

    //   <DataTable
    //     data={stock?.data ? [stock.data] : []}
    //     // page={pageNumber}
    //     // setPageNumber={setPageNumber}
    //     // paginationEnabled={true}
    //   />
    // </div>
    <>
    <Stock />
    </>
  );
};

export default AnalyticsStock;