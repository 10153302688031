import React from "react";
import DisplayCountries from "./components/DisplayCountries";

const Countires = () => {
	return (
		<div>
			<DisplayCountries />
		</div>
	);
};

export default Countires;
