

export const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
    return `${day < 10 ? `0${day}` : day}-${month < 10 ? `0${month}` : month}-${year} ${
      hours < 10 ? `0${hours}` : hours}:${minutes < 10 ? `0${minutes}` : minutes}:${
      seconds < 10 ? `0${seconds}` : seconds
    }`;
  }
    

    export const formatDateWithoutTime = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based in JS
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
      };


      export const calculateAge = (dob) => {
        const birthDate = new Date(dob);
        const now = new Date();
      
        let years = now.getFullYear() - birthDate.getFullYear();
        let months = now.getMonth() - birthDate.getMonth();
      
        // Adjust for negative month difference
        if (months < 0) {
          years -= 1;
          months += 12;
        }
      
        return `${years} years, ${months} months`;
      };