import React from "react";
import DisplayPackageDetails from "./components/DisplayPackageDetails";

const PackagesDetailWraaper = () => {
	return (
		<div>
			<DisplayPackageDetails />
		</div>
	);
};

export default PackagesDetailWraaper;
