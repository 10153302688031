import React from 'react'

export const Loader = () => {
  return (
    <div className="">
        <div className="flex ">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-[#405189]"></div>
        </div>
    </div>
  )
}
