import React, { useState, useEffect } from 'react';
import { Dialog } from "@headlessui/react";
import Button from "../../../components/Button/Button";
import ReusableInput from "../../../components/ResuableInputField";
import { useForm } from "react-hook-form";
import { notifySuccess, notify } from "../../..//utils/toasts";
import { createRegion, updateRegion } from "../../../services/countries/index";
import { useFetchCountires, useFetchCountryStates } from '../hooks/useFetchCountries';

function CreateOrUpdateRegion({ isOpen, setIsOpen, regionDetails, refetch }) {
    const { register, handleSubmit, setValue, watch, reset, formState: { errors } } = useForm({
        defaultValues: {
            country_id: regionDetails?.state?.country_id || "",
            name: regionDetails?.name || "",
            state_id: regionDetails?.state?.id || "",
            status: regionDetails?.enabled || false,
        }
    });
   
   

    const [selectedCountry, setSelectedCountry] = useState(regionDetails?.country_id || "");
    const [selectedState, setSelectedState] = useState(regionDetails?.state_id || "");
    const { countries } = useFetchCountires();
    const { states, isLoading: isLoadingStates } = useFetchCountryStates(selectedCountry);

    useEffect(() => {
        if (regionDetails) {
            
            setSelectedCountry(regionDetails?.state?.country_id);
            setValue("name", regionDetails.name);
            setValue("status", regionDetails.enabled);
            setValue("state_id", regionDetails?.state?.id);
            setValue("country_id", regionDetails?.state?.country_id );
        } else {
            reset(); // Reset form if there are no details (creating new)
        }
    }, [regionDetails, setValue, reset]);

    const onSubmit = async (data) => {
        
        try {
            let payload = { };
            if (regionDetails) {
                payload = {
                    state_id: data.state_id,
                    name: data.name,
                    status:data.status
                }
                await updateRegion(regionDetails.id, payload);
                notifySuccess("Region updated successfully");
            } else {
                payload = {
                    state_id: data.state_id,
                    name: data.name
                }
                await createRegion(payload);
                notifySuccess("Region created successfully");
            }
            setIsOpen(false);
         
        } catch (error) {
            notify("error","Failed to submit region data");
        }
    };

    const handleCountryChange = (e) => {
        
        const newCountryId = e.target.value;
        setSelectedCountry(newCountryId);
        setValue("country_id", newCountryId);
    };

    const handleStateChange = (e) => {
        
        const newStateId = e.target.value;
        setSelectedState(newStateId);
        setValue("state_id", newStateId);
    };
    const handleInputChange = (e) => {
        
        const { name, type, checked, value } = e.target;
        setValue(name, type === 'checkbox' ? checked : value);
    };

    return (
        <Dialog open={isOpen} onClose={() => setIsOpen(false)} className="fixed inset-0 z-50 overflow-y-auto p-4 duration-500 top-40">
            <Dialog.Overlay className="fixed inset-0 bg-black/30" />

<Dialog.Panel className="relative max-w-[600px] w-full mx-auto bg-white p-4 rounded">
                <Dialog.Title className="text-lg font-medium mb-6 border-b-2 flex items-center justify-between">{regionDetails ? "Update Region" : "Create Region"}</Dialog.Title>
                   <form onSubmit={handleSubmit(onSubmit)}>
                    <ReusableInput
                        type="select"
                        label="Country"
                        name="country_id"
                        // value={selectedCountry}
                        value={watch("country_id")}
                        options={countries?.data?.map((country) => ({ value: country.id, label: country.name }))}
                        {...register("country_id", { required: true })}
                        onChange={handleCountryChange}
                    />
                    <ReusableInput
                        type="select"
                        label="State"
                        name="state_id"
                        // value={selectedState}
                        value={watch("state_id")}
                        options={states?.data?.map((state) => ({ value: state.id, label: state.name }))}
                        disable={!selectedCountry || isLoadingStates}
                        {...register("state_id", { required: true })}
                        onChange={handleStateChange}
                    />
                    <ReusableInput
                        {...register("name", { required: true })}
                        label="Region Name"
                        placeholder="Enter Region Name"
                        onChange={handleInputChange}
                        value={watch("name")}
                    />
                    {regionDetails && (
                    <ReusableInput
                        type="checkbox"
                        {...register("status")}
                        label="Active Status"
                        onChange={handleInputChange}
                        checked={watch("status")}
                    />
                    )}
                    <Button type="submit">{regionDetails ? "Update" : "Create"}</Button>
                </form>
            </Dialog.Panel>
        </Dialog>
    );
}

export default CreateOrUpdateRegion;
