/* eslint-disable no-unused-vars */
import DataTable from "../../../components/Table/DataTable";
import { useEffect, useState } from "react";
import { useGetSuppliers } from "../hooks/useGetSupplier";
import { Loader } from "../../../components/Loader";
import { Link, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { notifyNoDataFound } from "../../../utils/toasts";
import HeaderSection from "../../../components/HeaderSection";
import Search from "../../../components/Search";
import Button from "../../../components/Button/Button";
import { Dialog } from "@headlessui/react";
import {
  useFetchCountires,
  useFetchCountryStateRegions,
  useFetchCountryStates,
} from "../../countries/hooks/useFetchCountries";
import { useForm } from "react-hook-form";
import { config } from "../../../config/config";
import { axios } from "../../../config/axios";
import { toast } from "react-toastify";
import { FaUser } from "react-icons/fa";
import { formatDate } from "../../../utils/formatDate";




export const SupplierWrapper = () => {
  let { page = 1 } = useParams();
  page = Number(page);
  if (page < 1) {
    page = 1;
  }
  const [pageNumber, setPageNumber] = useState(page);
  const [limit, setLimit] = useState(10);
  const navigate = useNavigate();
  let [edititem, setEditItem] = useState({});
  let [editModal, setEditModal] = useState(false);
  const [editLoading, setEditLoading] = useState(false)
  let [createModal, setCreateModal] = useState(false);
  const [createLoading, setCreateLoading] = useState(false)

  const { countries } = useFetchCountires();



  const {
    register: EditSupplier,
    handleSubmit: handleEditSuppliers,
    formState: { errors: errorsEditSupplier },
    reset: resetEditSupplier,

  } = useForm();

  const handleEditSupplier = async (data) => {
    setEditLoading(true);

    // Ensure country_id, region_id, and state_id are positive integers
    data.country_id = parseInt(data.country_id) || 0;
    data.region_id = parseInt(data.region_id) || 0;
    data.state_id = parseInt(data.state_id) || 0;
    data.phone_no = edititem?.phone_no;

    const token = JSON.parse(localStorage.getItem("token"));
    const formData = new FormData();

    // Iterate through data object keys
    for (const key in data) {
      // Check if key is country_id, region_id, or state_id and the value is not null or undefined
      if (['country_id', 'region_id', 'state_id'].includes(key) && data[key] !== null && data[key] !== undefined) {
        // Append to formData
        formData.append(key, data[key]);
      } else if (data[key] !== null && typeof data[key] === "object" && !(data[key] instanceof File)) {
        // Handle nested objects
        // for (const innerKey in data[key]) {
        //   formData.append(`${key}[${innerKey}]`, data[key][innerKey]);
        // }
        formData.append(`image`, data.image[0]);
      } else {
        // Append other fields
        formData.append(key, data[key]);
      }
    }
    for (var p of formData) {
      let name = p[0];
      let value = p[1];

      console.log(name, value)
    }

    try {
      const response = await axios.put(
        `/users/update/supplier/${edititem.id}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response);
      // fetchData();
      setEditModal(false);
      toast.success("Supplier Updated");
      setEditLoading(false);
      refetch();
    } catch (error) {
      console.error("Error:", error);
      toast.error(error?.response?.data?.error || "Something went wrong");
      setEditLoading(false);
    }
    console.log("Edit item", data);
  };

  useEffect(() => {
    if (editModal) {

      console.log("Resetting edit supplier form with item:", edititem);
      resetEditSupplier({
        fullname: edititem?.fullname || "",
        email: edititem?.email || "",
        ic_number: edititem?.ic_number || "",
        country_id: edititem?.country?.id || "",
        region_id: edititem?.region?.id || "",
        state_id: edititem?.state_id || "",
        image: edititem?.image || "",
        phone_no: edititem?.phone_no || "",
        // password: edititem?.password || "",
        is_active: edititem?.is_active || false,
      });

      setValueEditSupplier("country_id", edititem?.country_id);

      const timeoutIdState = setTimeout(() => {
        setValueEditSupplier("state_id", edititem?.state_id);
        resetEditSupplier({

          state_id: edititem?.state_id || "",
        });
      }, 1000); // 5000 milliseconds = 5 seconds

      const timeoutIdRegion = setTimeout(() => {
        setValueEditSupplier("region_id", edititem?.region_id);

        resetEditSupplier({

          region_id: edititem?.region_id || "",
        });
      }, 4000); // 5000 milliseconds = 5 seconds

      // Cleanup function to clear all timeouts if necessary
      return () => {
        clearTimeout(timeoutIdState);
        clearTimeout(timeoutIdRegion);
      };

    }

  }, [editModal, resetEditSupplier, edititem]);




  const {
    register: CreateSupplier,
    handleSubmit: handleCreateSupplier,
    formState: { errors: errorsCreateSupplier },
    setValue: setValueEditSupplier,
    watch: watchEditSupplier,

  } = useForm();


  const { suppliers, isError, isLoading, refetch } = useGetSuppliers(pageNumber, limit);
  const totalPages = Math.ceil(suppliers?.data?.totalCount / limit);

  const handlePageChange = (newPage) => {
    setPageNumber(newPage);
    navigate(`/suppliers/${newPage}`); // Update the route accordingly
  };



  const handleCreateSuppliers = async (data) => {
    // setCreateLoading(true)
    data.country_id = parseInt(data.country_id);
    data.region_id = parseInt(data.region_id);
    data.state_id = parseInt(data.state_id);


    console.log(data)


    try {
      const token = JSON.parse(localStorage.getItem("token"));
      if (!token) {
        console.error('Token not found in localStorage');
        return;
      }


      const response = await axios.post(
        `${config.backendURL}auth/signup/seller`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      setCreateLoading(false);
      setCreateModal(false);
      toast.success("Add Supplier");

      console.log(response);

    } catch (error) {
      toast.error(error?.response?.data?.error);
      console.error('Error:', error);
      setCreateLoading(false);
      setCreateModal(false);
    } finally {
      setCreateLoading(false);
      setCreateModal(false);
    }

  };


  let { states } = useFetchCountryStates(watchEditSupplier("country_id") || -1 );
  let { regions } = useFetchCountryStateRegions(watchEditSupplier("state_id") || -1);


  if (isError) {
    return <div className="">There was some Error, Please try again</div>;
  }


  if (suppliers?.data?.data?.length === 0) {
    // notifyNoDataFound();
    // setPageNumber(1);
    // navigate(`/suppliers/1`);
    return (<>No Data Found</>)

  }
  if (isLoading) {
    return (
      <div className="flex space-x-2">
        <Loader />
        <div className="">Loading...</div>
      </div>
    );
  }
  return (

    <>
      <div>
        <div className='flex justify-between mb-2'>
          <button className='font-bold' onClick={() => console.log(edititem)}>
            Supplier List
          </button>

          <Button type="button" onClick={() => setCreateModal(true)}>
            Add Supplier
          </Button>


        </div>


        <table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
          <thead className="text-xs text-pacific-blue uppercase bg-[#CFF0F2] ">
            <tr >

              <th scope="col" className="px-6 py-3 text-base font-medium ">
                <div className='flex gap-1 items-center'>
                  Id
                </div>
              </th>
              <th scope="col" className="px-6 py-3 text-base font-medium ">
                <div className='flex gap-1 items-center'>
                  Supplier Id
                </div>
              </th>
              <th scope="col" className="px-6 py-3 text-base font-medium">
                <div className='flex gap-1 items-center'>

                  image
                </div>

              </th>
              <th scope="col" className="px-6 py-3 text-base font-medium">
                <div className='flex gap-1 items-center'>

                  Full Name
                </div>

              </th>
              <th scope="col" className="px-6 py-3 text-base font-medium">
                <div className='flex gap-1 items-center'>
                  Email

                </div>
              </th>
              <th scope="col" className="px-6 py-3 text-base font-medium">
                <div className='flex gap-1 items-center'>

                  Phone No
                </div>

              </th>


              <th scope="col" className="px-6 py-3 text-base font-medium">
                <div className='flex gap-1 items-center'>

                  IC Number
                </div>

              </th>

              <th scope="col" className="px-6 py-3 text-base font-medium">
                <div className='flex gap-1 items-center'>

                  Ic Type
                </div>

              </th>



              <th scope="col" className="px-6 py-3 text-base font-medium">
                <div className='flex gap-1 items-center'>

                  Country
                </div>

              </th>

              <th scope="col" className="px-6 py-3 text-base font-medium">
                <div className='flex gap-1 items-center'>

                  State
                </div>

              </th>

              <th scope="col" className="px-6 py-3 text-base font-medium">
                <div className='flex gap-1 items-center'>

                  Region
                </div>

              </th>


              <th scope="col" className="px-6 py-3 text-base font-medium">
                <div className="flex gap-1 items-center">Created At</div>
              </th>
              <th scope="col" className="px-6 py-3 text-base font-medium">
                <div className="flex gap-1 items-center">Update At</div>
              </th>


              <th scope="col" className="px-6 py-3 text-base font-medium">
                <div className='flex gap-1 items-center'>

                  Detail Page
                </div>

              </th>

              <th scope="col" className="px-6 py-3 text-base font-medium">
                <div className='flex gap-1 items-center'>

                  Status
                </div>

              </th>




              <th scope="col" className="px-2 py-3 text-base font-medium">
                Action
              </th>
            </tr>
          </thead>
          <tbody>


            {suppliers?.data?.data?.map((e) => {
              return (
                <tr className="bg-white border-b " key={e?.id}>

                  <th scope="row" className="px-6 py-4 text-sm font-medium">
                    {e?.id}
                  </th>

                  <td className="px-6 py-4 text-sm font-medium">{e?.ref_id}</td>

                  <td className="px-6 py-4 text-sm font-medium">

                    {(e?.image !== "undefined" && e?.image !== null) ? (
                      <img src={`${config?.backendBaseURL}uploads/${e?.image}`} className="w-14" />
                    ) : (
                      <FaUser className="w-8 h-10" />
                    )}
                  </td>
                  <td className="px-6 py-4 text-sm font-medium">
                    {e?.fullname}
                  </td>
                  <td className="px-6 py-4 text-sm font-medium">
                    {e?.email}
                  </td>


                  <td className="px-6 py-4 text-sm font-medium">
                    {e?.phone_no}
                  </td>


                  <td className="px-6 py-4 text-sm font-medium">
                    {e?.ic_number}
                  </td>
                  <td className="px-6 py-4 text-sm font-medium">
                    {e?.ic_type}
                  </td>

                  



                  <td className="px-6 py-4 text-sm font-medium">
                    {e?.country?.name}
                  </td>

                  <td className="px-6 py-4 text-sm font-medium">
                    {e?.state?.name}
                  </td>

                  <td className="px-6 py-4 text-sm font-medium">
                    {e?.region?.name}
                  </td>
                  <td className="px-6 py-4 text-sm font-medium">
                    {formatDate(e?.created_at)}
                  </td>
                  <td className="px-6 py-4 text-sm font-medium">
                    {formatDate(e?.updated_at)}
                  </td>
                  <td className="px-6 py-4 text-sm font-medium">
                    <Link to={`/supplier/detail/${e.id}`}>
                      <Button> Detail </Button>
                    </Link>
                  </td>
                  <td className="px-6 py-4 text-sm font-medium">
                    {e?.is_active ? <Button className="w-24">Active </Button> : <Button className="bg-red-500 hover:bg-red-500 w-24" disabled>In-Active </Button>}
                  </td>







                  <td className="px-6 py-4 text-sm font-medium">
                    <Button onClick={() => {
                      setEditItem(e)
                      setEditModal(true)
                      // console.log(edititem)
                    }}>
                      Edit
                    </Button>
                  </td>


                </tr>
              )
            })}


          </tbody>
        </table>


        {/* pagination */}
        <div className="flex justify-center items-center mt-4 mb-40">
          <button
            onClick={() => handlePageChange(pageNumber - 1)}
            disabled={pageNumber === 1}
            className="px-4 py-2 bg-gray-300 rounded-md mr-2"
          >
            Previous
          </button>
          <div className="flex gap-2">
            {[...Array(totalPages > 0 ? totalPages : 0)].map((_, index) => (
              <button
                key={index + 1}
                onClick={() => handlePageChange(index + 1)}
                className={`px-4 py-2 rounded-md ${pageNumber === index + 1 ? 'bg-blue-500 text-white' : 'bg-gray-300'
                  }`}
              >
                {index + 1}
              </button>
            ))}
          </div>
          <button
            onClick={() => handlePageChange(pageNumber + 1)}
            disabled={pageNumber === totalPages}
            className="px-4 py-2 bg-gray-300 rounded-md ml-2"
          >
            Next
          </button>
        </div>
        {/* pagination end */}


        {/* Create Modal */}
        {createModal &&
          <Dialog
            open={createModal}
            onClose={() => setEditItem(true)}
            className="fixed inset-0 z-50 overflow-y-auto p-4 duration-500 "
          >
            <div className="flex items-center justify-center min-h-screen">
              <Dialog.Overlay className="fixed inset-0 bg-black/30" />

              <Dialog.Panel className="relative max-w-[600px] w-full mx-auto bg-white p-4 rounded">
                <Dialog.Title className="text-lg font-medium mb-6 border-b-2 flex items-center justify-between">
                  <span className="">
                    Create Supplier
                  </span>
                  <div
                    className="flex items-center justify-center "
                    onClick={() => {
                      setCreateModal(false)
                    }}
                  >
                    <h1 className="bg-gray-200 px-2 rounded-md mb-1 hover:text-red-600 cursor-pointer"
                    >
                      x
                    </h1>
                  </div>
                </Dialog.Title>

                <form onSubmit={handleCreateSupplier(handleCreateSuppliers)}>
                  <div className="grid grid-cols-1  sm:grid-cols-2 gap-4">




                    <div className="">
                      <label
                        htmlFor="fullname"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Full Name
                      </label>
                      <input
                        name='fullname'
                        id='fullname'
                        {...CreateSupplier("fullname", {
                          required: "Full Name is required",
                        })}
                        className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                      />
                      <p className="text-red-500 text-sm">
                        {errorsCreateSupplier?.fullname?.message}
                      </p>
                    </div>


                    <div className="">
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Email
                      </label>
                      <input
                        name='email'
                        id='email'
                        type="email"
                        {...CreateSupplier("email", {
                          required: "Email is required",
                        })}
                        className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                      />
                      <p className="text-red-500 text-sm">
                        {errorsCreateSupplier?.email?.message}
                      </p>
                    </div>

                    <div className="">
                      <label
                        htmlFor="role"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Role
                      </label>
                      <input
                        name='role'
                        id='role'
                        type="role"
                        value="SUPPLIER"

                        {...CreateSupplier("role")}
                        className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                      />

                    </div>

                    <div className="">
                      <label
                        htmlFor="password"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Password
                      </label>
                      <input
                        name='password'
                        id='password'
                        type="password"
                        {...CreateSupplier("password", {
                          required: "password is required",
                        })}
                        className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                      />
                      <p className="text-red-500 text-sm">
                        {errorsCreateSupplier?.password?.message}
                      </p>
                    </div>


                    <div className="">
                      <label
                        htmlFor="phone_no"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Phone No
                      </label>
                      <input
                        name='phone_no'
                        id='phone_no'
                        {...CreateSupplier("phone_no", {
                          required: "Phone No is required",
                        })}
                        className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                      />
                      <p className="text-red-500 text-sm">
                        {errorsCreateSupplier?.phone_no?.message}
                      </p>
                    </div>

                    <div className="">
                      <label
                        htmlFor="ic_number"
                        className="block text-sm font-medium text-gray-700"
                      >
                        IC Number
                      </label>
                      <input
                        name='ic_number'
                        id='ic_number'
                        {...CreateSupplier("ic_number", {
                          required: " IC Number is required",
                        })}
                        className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                      />
                      <p className="text-red-500 text-sm">
                        {errorsCreateSupplier?.ic_number?.message}
                      </p>
                    </div>


                    {/* dropDown */}
                    <div className="">
                      <label
                        htmlFor="country_id"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Country
                      </label>
                      <select
                        name='country_id'
                        className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                        id='country_id'
                        {...CreateSupplier("country_id", {
                          required: "Country is required",
                        })}
                        onChange={(e) => setValueEditSupplier("country_id", e.target.value)}
                      >
                        <option value=""> Select the Country</option>
                        {countries?.data?.map((country) => {
                          return (
                            <option value={country.id}> {country.name}</option>

                          )
                        })}

                      </select>
                      <p className="text-red-500 text-sm">
                        {errorsCreateSupplier?.country_id?.message}
                      </p>
                    </div>

                    <div className="">
                      <label
                        htmlFor="state_id"
                        className="block text-sm font-medium text-gray-700"
                      >
                        State
                      </label>
                      <select
                        name='state_id'
                        className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                        id='state_id'
                        {...CreateSupplier("state_id", {
                          required: "State is required",
                        })}
                        onChange={(e) => setValueEditSupplier("state_id", e.target.value)}

                      >
                        <option value=""> Select the State</option>
                        {states?.data?.map((states) => {
                          return (
                            <option value={states.id}> {states.name}</option>

                          )
                        })}

                      </select>
                      {!watchEditSupplier("country_id") && (
                        <p className="text-red-500 text-sm">
                          {errorsCreateSupplier?.state_id?.message}

                        </p>
                      )}
                      <p className="text-red-500 text-sm">
                      </p>
                    </div>

                    <div className="">
                      <label
                        htmlFor="region_id"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Region
                      </label>
                      <select
                        name='region_id'
                        className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                        id='region_id'
                        {...CreateSupplier("region_id", {
                          required: "Region is required",
                        })}
                        onChange={(e) => setValueEditSupplier("region_id", e.target.value)}

                      >
                        <option value=""> Select the Region</option>
                        {regions?.data.map((regions) => {
                          return (
                            <option value={regions.id}> {regions.name}</option>

                          )
                        })}

                      </select>
                      {!watchEditSupplier("state_id") && (
                        <p className="text-red-500 text-sm">
                          {errorsCreateSupplier?.region_id?.message}
                        </p>
                      )}

                    </div>









                  </div>

                  <div className="flex justify-end mt-10">
                    {
                      editLoading ?
                        <Button type="button">
                          <Loader />
                        </Button> :
                        <Button type="submit">
                          Create
                        </Button>

                    }

                  </div>
                </form>
              </Dialog.Panel>
            </div>
          </Dialog>}









        {/* Edit Modal */}
        {editModal &&
          <Dialog
            open={editModal}
            onClose={() => setEditItem(true)}
            className="fixed inset-0 z-50 overflow-y-auto p-4 duration-500 "
          >
            <div className="flex items-center justify-center min-h-screen">
              <Dialog.Overlay className="fixed inset-0 bg-black/30" />

              <Dialog.Panel className="relative max-w-[600px] w-full mx-auto bg-white p-4 rounded">
                <Dialog.Title className="text-lg font-medium mb-6 border-b-2 flex items-center justify-between">
                  <span className="">
                    Update Supplier
                  </span>
                  <div
                    className="flex items-center justify-center "
                    onClick={() => setEditModal(false)}
                  >
                    <h1 className="bg-gray-200 px-2 rounded-md mb-1 hover:text-red-600 cursor-pointer">
                      x
                    </h1>
                  </div>
                </Dialog.Title>

                <form onSubmit={handleEditSuppliers(handleEditSupplier)}>
                  <div className="grid grid-cols-1  sm:grid-cols-2 gap-4">

                    <div>
                      <label className="block text-sm font-medium text-gray-700">
                        Image


                      </label>

                      {(edititem?.image !== "undefined" && edititem?.image !== null) ? (
                        <img src={`${config?.backendBaseURL}uploads/${edititem?.image}`} className="w-40" />
                      ) : (
                        <FaUser className="w-8 h-10" />
                      )}

                    </div>



                    <div className="">
                      <label
                        htmlFor="fullname"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Full Name
                      </label>
                      <input
                        name='fullname'
                        id='fullname'
                        {...EditSupplier("fullname", {
                          required: "Full Name is required",
                        })}
                        className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                      />
                      <p className="text-red-500 text-sm">
                        {errorsEditSupplier?.fullname?.message}
                      </p>
                    </div>

                    <div className="">
                      <label className="block text-sm font-medium text-gray-700">
                        Email
                      </label>
                      <input
                        type="email"
                        value={edititem?.email}
                        disabled
                        className="mt-1 p-1 w-full border border-gray-300 rounded-md bg-slate-300"
                      />

                    </div>



                    <div className="">
                      <label
                        htmlFor="phone_no"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Phone No
                      </label>
                      <input
                        name='phone_no'
                        id='phone_no'
                        {...EditSupplier("phone_no", {
                          required: "Phone No is required",
                        })}
                        className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                      />
                      <p className="text-red-500 text-sm">
                        {errorsEditSupplier?.phone_no?.message}
                      </p>
                    </div>

                    <div className="">
                      <label
                        htmlFor="ic_number"
                        className="block text-sm font-medium text-gray-700"
                      >
                        IC Number
                      </label>
                      <input
                        name='ic_number'
                        id='ic_number'
                        {...EditSupplier("ic_number", {
                          required: " IC Number is required",
                        })}
                        className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                      />
                      <p className="text-red-500 text-sm">
                        {errorsEditSupplier?.ic_number?.message}
                      </p>
                    </div>


                    {/* dropDown */}
                    <div className="">
                      <label
                        htmlFor="country_id"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Country
                      </label>
                      <select
                        name='country_id'
                        className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                        id='country_id'
                        {...EditSupplier("country_id", {
                          required: "Country is required",
                        })}
                        onChange={(e) => setValueEditSupplier("country_id", e.target.value)}
                      >
                        <option value=""> Select the Country</option>
                        {countries?.data.map((country) => {
                          return (
                            <option value={country.id}> {country.name}</option>

                          )
                        })}

                      </select>
                      <p className="text-red-500 text-sm">
                        {errorsEditSupplier?.country_id?.message}
                      </p>
                    </div>

                    <div className="">
                      <label
                        htmlFor="state_id"
                        className="block text-sm font-medium text-gray-700"
                      >
                        State
                      </label>
                      <select
                        name='state_id'
                        className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                        id='state_id'
                        {...EditSupplier("state_id", {
                          required: "State is required",
                        })}
                        onChange={(e) => setValueEditSupplier("state_id", e.target.value)}

                      >
                        <option value=""> Select the State</option>
                        {states?.data.map((states) => {
                          return (
                            <option value={states.id}> {states.name}</option>

                          )
                        })}

                      </select>
                      {!watchEditSupplier("country_id") && (
                        <p className="text-red-500 text-sm">
                          {errorsEditSupplier?.state_id?.message}

                        </p>
                      )}
                      <p className="text-red-500 text-sm">
                      </p>
                    </div>

                    <div className="">
                      <label
                        htmlFor="region_id"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Region
                      </label>
                      <select
                        name='region_id'
                        className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                        id='region_id'
                        {...EditSupplier("region_id", {
                          required: "Region is required",
                        })}
                        onChange={(e) => setValueEditSupplier("region_id", e.target.value)}

                      >
                        <option value=""> Select the Region</option>
                        {regions?.data.map((regions) => {
                          return (
                            <option value={regions.id}> {regions.name}</option>

                          )
                        })}

                      </select>
                      {!watchEditSupplier("state_id") && (
                        <p className="text-red-500 text-sm">
                          {errorsEditSupplier?.region_id?.message}
                        </p>
                      )}

                    </div>






                    <div className="">
                      <label
                        htmlFor="is_active"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Status
                      </label>
                      <select
                        name='is_active'
                        className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                        id='is_active'
                        {...EditSupplier("is_active", {
                          // required: "Status is Required",
                        })}
                      >
                        <option value=""> Select the Status</option>
                        <option value={true}> Active</option>
                        <option value={false}> In-Active</option>

                      </select>

                      <p className="text-red-500 text-sm">
                        {errorsEditSupplier?.is_active?.message}
                      </p>

                    </div>




                    {/*dropDown end */}

                    <div className="">
                      <label
                        htmlFor="image"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Image
                      </label>
                      <input
                        type="file"
                        name='image'
                        id='image'
                        {...EditSupplier("image",
                          // {required: "Image is required", }
                        )}
                        className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                      />
                      {/* <p className="text-red-500 text-sm">
                        {errorsEditSupplier?.image?.message}
                      </p> */}
                    </div>


                    {/* <div className="">
                      <label
                        htmlFor="password"
                        className="block text-sm font-medium text-gray-700"
                      >
                       Password
                      </label>
                      <input
                        name='password'
                        id='password'
                        type="password"
                        {...EditSupplier("password", {
                          required: "password is required",
                        })}
                        className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                      />
                      <p className="text-red-500 text-sm">
                        {errorsEditSupplier?.password?.message}
                      </p>
                    </div> */}



                  </div>

                  <div className="flex justify-end mt-10">
                    {
                      editLoading ?
                        <Button type="button">
                          <Loader />
                        </Button> :
                        <Button type="submit">
                          Update
                        </Button>

                    }

                  </div>
                </form>
              </Dialog.Panel>
            </div>
          </Dialog>}









      </div>
    </>);
};
