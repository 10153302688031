import { useForm } from "react-hook-form";
import { formatDate, formatDateWithoutTime } from "../utils/formatDate";
import Button from "../components/Button/Button";
import { Disclosure } from "@headlessui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateOrder } from "../services/orders";
import { order_status } from "../config/constants";
import { useState } from "react";
import { notify } from "../utils/toasts";
import FeedlotList from "./FeedlotList";
import AsignOrderToReporder from "./AsignOrderToReporder";
import HeaderSection from "./HeaderSection";
import { config } from "../config/config";
import OrderToReporter from "./OrderToReporter";
import { FaUser } from "react-icons/fa";

const UpdateOrder = ({ data, refetch }) => {
	const [is_loading, setIs_loading] = useState(false);
	const { register, handleSubmit } = useForm({
		defaultValues: async () => ({ ...data, ...data.animal }),
	});
	const queryClient = useQueryClient();
	const { mutate } = useMutation({
		mutationFn: (data) => updateOrder(data.id, data.order_status),
		onSuccess: async () => {
			await queryClient.invalidateQueries({ queryKey: ["get-orders"] });
			setIs_loading(false);
			refetch()
			notify("success", "Order updated successfully");
		},
		onError: (err) => {
			notify("error", err?.response?.data?.error || "Unknow error occur");
		},
	});

	const onSubmit = async (data) => {
		setIs_loading(true);
		await mutate(data);
	};


	

	const familyMembersPortions = data?.familyMembersPortions || [];
	const OrderHistoryOrganization = data?.OrderHistoryOrganization || [];
	const installments = data?.installments || [];
	const Participants = data?.principal_portions || [];
	const AnimalStocks = data?.animalStocks || [];
	const waqf_order=data?.waqf_order;

	return (<>
		<form onSubmit={handleSubmit(onSubmit)} className="bg-white border-2  p-4">
			<div className="grid grid-cols-1  sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
				<div className="">
					{/* <Button onClick={()=>console.log(data)}> Check API Result</Button> */}
					<label

						className="block text-sm font-medium text-gray-700"
					>
						Order id
					</label>
					<input
						type="text"
						{...register("id")}
						disabled
						className="mt-1 p-1 w-full border border-gray-300 rounded-md"
					/>
				</div>

				<div className="">
					<label
						htmlFor="animal_type"
						className="block text-sm font-medium text-gray-700"
					>
						Ruminant Name
					</label>
					<input
						type="text"
						{...register("package.animal.name")}
						disabled
						id="package.animal.pkg_type"
						name="package.animal.pkg_type"
						className="mt-1 p-1 w-full border border-gray-300 rounded-md"
					/>
				</div>
				<div className="">

					<label
						htmlFor="package.animal.pkg_type"
						className="block text-sm font-medium text-gray-700"
						onClick={() => console.log}
					>
						Customer Name
					</label>
					<input
						type="text"
						{...register("customer.fullname")}
						disabled
						id="package.animal.pkg_type"
						name="package.animal.pkg_type"
						className="mt-1 p-1 w-full border border-gray-300 rounded-md"
					/>
				</div>
				<div className="">

					<label
						htmlFor="payment_status"
						className="block text-sm font-medium text-gray-700"
						onClick={() => console.log}
					>
						Payment Status
					</label>
					<input
						type="text"
						{...register("payment_status")}
						disabled
						id="payment_statuse"
						name="payment_status"
						className="mt-1 p-1 w-full border border-gray-300 rounded-md"
					/>
				</div>
				<div className="">
					<label
						htmlFor="packageType"
						className="block text-sm font-medium text-gray-700">
						Package Type
					</label>
					<input
						type="text"
						{...register("packageType")}
						disabled
						id="packageType"
						name="package_type"
						className="mt-1 p-1 w-full border border-gray-300 rounded-md"
					/>
				</div>

				<div className="">
					<label
						htmlFor="address"
						className="block text-sm font-medium text-gray-700">
						Address
					</label>
					<input
						type="text"
						{...register("address")}
						disabled
						id="address"
						name="address"
						className="mt-1 p-1 w-full border border-gray-300 rounded-md"
					/>
				</div>
				<div className="">
					<label
						htmlFor="price"
						className="block text-sm font-medium text-gray-700">
						Price
					</label>
					<input
						type="text"
						{...register("price")}
						disabled
						id="price"
						name="price"
						className="mt-1 p-1 w-full border border-gray-300 rounded-md"
					/>
				</div>
				<div className="">
					<label
						htmlFor="customer_name"
						className="block text-sm font-medium text-gray-700">
						Customer Name
					</label>
					<input
						type="text"
						{...register("customer.fullname")}
						disabled
						id="customer_name"
						name="customer_name"
						className="mt-1 p-1 w-full border border-gray-300 rounded-md"
					/>
				</div>

				{/* <div className="">
					<label
						htmlFor="payment_id"
						className="block text-sm font-medium text-gray-700">
						Payment ID
					</label>
					<input
						type="text"
						{...register("payment_id")}
						disabled
						id="payment_id"
						name="payment_id"
						className="mt-1 p-1 w-full border border-gray-300 rounded-md"
					/>
				</div>{" "} */}

				<div className="">
					<label
						htmlFor="total_portions"
						className="block text-sm font-medium text-gray-700">
						Total Portions
					</label>
					<input
						type="text"
						{...register("total_portions")}
						disabled
						id="total_portions"
						name="total_portions"
						className="mt-1 p-1 w-full border border-gray-300 rounded-md"
					/>
				</div>

				{/* created_at */}
				<div className="">
					<label
						htmlFor="created_at"
						className="block text-sm font-medium text-gray-700">
						Order Date
					</label>
					<input
						type="text"
						defaultValue={formatDate(data.created_at)}
						disabled
						id="created_at"
						name="created_at"
						className="mt-1 p-1 w-full border border-gray-300 rounded-md"
					/>
				</div>
			</div>

			{installments.length > 0 && ( <>
			{/* Installments Table */}
			<div className="mt-10">
				<HeaderSection title={"Installment List"} />
			</div>

			<div className="overflow-auto max-h-80">
				<table className="min-w-full divide-y divide-gray-200">
					<thead className="text-xs text-pacific-blue uppercase bg-[#CFF0F2] ">
						<tr>
							<th scope="col" className="px-6 py-3 text-left text-base font-medium text-pacific-blue uppercase tracking-wider">ID</th>
							<th scope="col" className="px-6 py-3 text-left text-base font-medium text-pacific-blue uppercase tracking-wider">Admin Fee</th>
							<th scope="col" className="px-6 py-3 text-left text-base font-medium text-pacific-blue uppercase tracking-wider">Amount</th>
							<th scope="col" className="px-6 py-3 text-left text-base font-medium text-pacific-blue uppercase tracking-wider">Created Date</th>
							<th scope="col" className="px-6 py-3 text-left text-base font-medium text-pacific-blue uppercase tracking-wider">Due Date</th>
							<th scope="col" className="px-6 py-3 text-left text-base font-medium text-pacific-blue uppercase tracking-wider">Status</th>
							<th scope="col" className="px-6 py-3 text-left text-base font-medium text-pacific-blue uppercase tracking-wider">Total Payable</th>
							<th scope="col" className="px-6 py-3 text-left text-base font-medium text-pacific-blue uppercase tracking-wider">Updated Date</th>

						</tr>
					</thead>
					<tbody className="bg-white divide-y divide-gray-200">
						{installments.map((installment, index) => (
							<tr key={index} className="bg-white border-b">
								<td className="px-6 py-4 whitespace-nowrap">{installment.id}</td>
								<td className="px-6 py-4 whitespace-nowrap">{Number(installment.admin_fee).toFixed(2)}</td>
								<td className="px-6 py-4 whitespace-nowrap">{Number(installment.amount).toFixed(2)}</td>
								<td className="px-6 py-4 whitespace-nowrap">{formatDateWithoutTime(installment.created_at)}</td>
								<td className="px-6 py-4 whitespace-nowrap">{formatDateWithoutTime(installment.due_date)}</td>
								<td className={`px-6 py-4 whitespace-nowrap ${installment.status == "PAID" ? "text-green-400" : "text-red-500"}`}>{installment.status} </td>
								<td className="px-6 py-4 whitespace-nowrap">{Number(installment.total_payable).toFixed(2)}</td>
								<td className="px-6 py-4 whitespace-nowrap">{formatDateWithoutTime(installment.updated_at)}</td>

							</tr>
						))}
					</tbody>
				</table>
			</div>
			</>
)}

{Participants.length > 0 && ( <>

			{/* participants list */}
			<div className="mt-10">
				<HeaderSection title={"Participants  list"} />
			</div>


			<div className="overflow-auto max-h-80">
				<table className="min-w-full divide-y divide-gray-200">
					<thead className="text-xs text-pacific-blue uppercase bg-[#CFF0F2] ">
						<tr>
							<th scope="col" className="px-6 py-3 text-left text-base font-medium text-pacific-blue uppercase tracking-wider">ID</th>
							<th scope="col" className="px-6 py-3 text-left text-base font-medium text-pacific-blue uppercase tracking-wider">Full Name</th>
							<th scope="col" className="px-6 py-3 text-left text-base font-medium text-pacific-blue uppercase tracking-wider">IC Number</th>
							<th scope="col" className="px-6 py-3 text-left text-base font-medium text-pacific-blue uppercase tracking-wider">Portions</th>
							<th scope="col" className="px-6 py-3 text-left text-base font-medium text-pacific-blue uppercase tracking-wider">Address</th>

						</tr>
					</thead>
					<tbody className="bg-white divide-y divide-gray-200">
						{Participants.map((principal_portions, index) => (
							<tr key={index} className="bg-white border-b">
								<td className="px-6 py-4 whitespace-nowrap">{principal_portions.id}</td>
								<td className="px-6 py-4 whitespace-nowrap">{principal_portions.fullname}</td>
								<td className="px-6 py-4 whitespace-nowrap">{principal_portions.ic_number}</td>
								<td className="px-6 py-4 whitespace-nowrap">{principal_portions.principle_portions}</td>
								<td className="px-6 py-4 whitespace-nowrap">{principal_portions.address}</td>

							</tr>
						))}
						
					</tbody>
				</table>


		
			</div>

</>)}



{familyMembersPortions.length > 0 && ( <>

{/* Family member list */}
<div className="mt-10">
	<HeaderSection title={"Family Member list"} />
</div>


<div className="overflow-auto max-h-80">
	<table className="min-w-full divide-y divide-gray-200">
		<thead className="text-xs text-pacific-blue uppercase bg-[#CFF0F2] ">
			<tr>
				<th scope="col" className="px-6 py-3 text-left text-base font-medium text-pacific-blue uppercase tracking-wider">ID</th>
				<th scope="col" className="px-6 py-3 text-left text-base font-medium text-pacific-blue uppercase tracking-wider">Full Name</th>
				<th scope="col" className="px-6 py-3 text-left text-base font-medium text-pacific-blue uppercase tracking-wider">IC Number</th>
				<th scope="col" className="px-6 py-3 text-left text-base font-medium text-pacific-blue uppercase tracking-wider">Portions</th>
			</tr>
		</thead>
		<tbody className="bg-white divide-y divide-gray-200">
			{familyMembersPortions.map((familyMember, index) => (
				<tr key={index} className="bg-white border-b">
					<td className="px-6 py-4 whitespace-nowrap">{familyMember?.familyMemberId}</td>
					<td className="px-6 py-4 whitespace-nowrap">{familyMember?.familyMember?.fullname}</td>
					<td className="px-6 py-4 whitespace-nowrap">{familyMember?.familyMember?.ic_number}</td>
					<td className="px-6 py-4 whitespace-nowrap">{familyMember?.portions}</td>

				</tr>
			))}
		</tbody>
	</table>
</div>

</>)}

{OrderHistoryOrganization
.length > 0 && ( <>

{/* Organization member list */}
<div className="mt-10">
	<HeaderSection title={"Organization list"} />
</div>


<div className="overflow-auto max-h-80">
	<table className="min-w-full divide-y divide-gray-200">
		<thead className="text-xs text-pacific-blue uppercase bg-[#CFF0F2] ">
			<tr>
				<th scope="col" className="px-6 py-3 text-left text-base font-medium text-pacific-blue uppercase tracking-wider">ID</th>
				<th scope="col" className="px-6 py-3 text-left text-base font-medium text-pacific-blue uppercase tracking-wider">Full Name</th>
				<th scope="col" className="px-6 py-3 text-left text-base font-medium text-pacific-blue uppercase tracking-wider">Organization Type</th>
				<th scope="col" className="px-6 py-3 text-left text-base font-medium text-pacific-blue uppercase tracking-wider">Portions</th>
			</tr>
		</thead>
		<tbody className="bg-white divide-y divide-gray-200">
			{OrderHistoryOrganization
.map((organization, index) => (
				<tr key={index} className="bg-white border-b">
					<td className="px-6 py-4 whitespace-nowrap">{organization?.UserOrganization?.id}</td>
					<td className="px-6 py-4 whitespace-nowrap">{organization?.UserOrganization?.name}</td>
					<td className="px-6 py-4 whitespace-nowrap">{organization?.UserOrganization?.organization?.org_type}</td>
					<td className="px-6 py-4 whitespace-nowrap">{organization?.portions}</td>

				</tr>
			))}
		</tbody>
	</table>
</div>

</>)}


{!waqf_order &&  <>


			{/* Ruminant */}
			<div className="mt-10">
				<HeaderSection title={"Rumiaant list"} />
			</div>


			<div className="overflow-auto max-h-80">
				<table className="min-w-full divide-y divide-gray-200">
					<thead className="text-xs text-pacific-blue uppercase bg-[#CFF0F2] ">
						<tr>
							<th scope="col" className="px-6 py-3 text-left text-base font-medium text-pacific-blue uppercase tracking-wider">ID</th>
							<th scope="col" className="px-6 py-3 text-left text-base font-medium text-pacific-blue uppercase tracking-wider">Image</th>

							<th scope="col" className="px-6 py-3 text-left text-base font-medium text-pacific-blue uppercase tracking-wider">Allocated Portion</th>
							<th scope="col" className="px-6 py-3 text-left text-base font-medium text-pacific-blue uppercase tracking-wider">Gender</th>
							<th scope="col" className="px-6 py-3 text-left text-base font-medium text-pacific-blue uppercase tracking-wider">Package Type</th>
							<th scope="col" className="px-6 py-3 text-left text-base font-medium text-pacific-blue uppercase tracking-wider">Feedlot Name</th>
							<th scope="col" className="px-6 py-3 text-left text-base font-medium text-pacific-blue uppercase tracking-wider">Feedlot Id</th>
							<th scope="col" className="px-6 py-3 text-left text-base font-medium text-pacific-blue uppercase tracking-wider">Supplier Name</th>
							<th scope="col" className="px-6 py-3 text-left text-base font-medium text-pacific-blue uppercase tracking-wider">Supplier Id</th>
						</tr>
					</thead>
					<tbody className="bg-white divide-y divide-gray-200">
						{AnimalStocks?.map((ruminant, index) => (
							<tr key={index} className="bg-white border-b">
								<td className="px-6 py-4 whitespace-nowrap">{ruminant?.id}</td>


								{(ruminant?.animal?.animal_image !== undefined && ruminant?.animal?.animal_image !== "" && ruminant?.animal?.animal_image !== "undefined" && ruminant?.animal?.animal_image !== null && ruminant?.animal?.animal_image !== "null") ? (
                      <img src={`${config?.backendBaseURL}uploads/${ruminant?.animal?.animal_image}`} className="w-14" />
                    ) : (
                      <FaUser className="w-8 h-10" />
                    )}
								<td className="px-6 py-4 whitespace-nowrap">{ruminant?.portions}</td>
								<td className="px-6 py-4 whitespace-nowrap">{ruminant?.animal?.animal_gender}</td>
								<td className="px-6 py-4 whitespace-nowrap">{ruminant?.animal?.package_type}</td>
								<td className="px-6 py-4 whitespace-nowrap">{ruminant?.animal?.feedlot?.fullname}</td>
								<td className="px-6 py-4 whitespace-nowrap">{ruminant?.animal?.feedlot?.id}</td>
								<td className="px-6 py-4 whitespace-nowrap">{ruminant?.animal?.supplier?.fullname}</td>
								<td className="px-6 py-4 whitespace-nowrap">{ruminant?.animal?.supplier?.id}</td>

							</tr>
						))}
					</tbody>
				</table>
			</div>

			
			<div className="w-full h-1 bg-gray-400 mt-10 mb-6"></div>
			{/* <div>
				{familyMembersPortions.map((member, index) => (
					<Disclosure key={index}>
						{({ open }) => (
							<>
								<Disclosure.Button className="flex justify-between items-center w-full py-2 px-4 text-sm font-medium text-gray-900 bg-gray-100 rounded-md focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75">
									<span>{member.familyMember.fullname}</span>
									<svg
										className={`${open ? "transform rotate-180" : ""} w-4 h-4`}
										xmlns="http://www.w3.org/2000/svg"
										viewBox="0 0 20 20"
										fill="currentColor"
										aria-hidden="true">
										<path
											fillRule="evenodd"
											d="M10 18a1 1 0 0 1-.707-.293l-8-8a1 1 0 0 1 1.414-1.414L10 15.586l6.293-6.293a1 1 0 1 1 1.414 1.414l-7.999 7.999A1 1 0 0 1 10 18z"
											clipRule="evenodd"
										/>
									</svg>
								</Disclosure.Button>
								<Disclosure.Panel className="p-4">
									<div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
										<div className="">
											<label
												htmlFor="fullname"
												className="block text-sm font-medium text-gray-700">
												Full Name
											</label>
											<input
												type="text"
												disabled
												value={member.familyMember.fullname}
												className="mt-1 p-1 w-full border border-gray-300 rounded-md"
											/>
										</div>{" "}
										<div className="">
											<label
												htmlFor="fullname"
												className="block text-sm font-medium text-gray-700">
												IC Number
											</label>
											<input
												type="text"
												disabled
												value={member.familyMember.ic_number}
												className="mt-1 p-1 w-full border border-gray-300 rounded-md"
											/>
										</div>
										<div className="">
											<label
												htmlFor="memberid"
												className="block text-sm font-medium text-gray-700">
												ID
											</label>
											<input
												type="text"
												disabled
												value={member.id}
												className="mt-1 p-1 w-full border border-gray-300 rounded-md"
											/>
										</div>
										<div className="">
											<label
												htmlFor="orderHistory"
												className="block text-sm font-medium text-gray-700">
												Order History
											</label>
											<input
												type="text"
												disabled
												value={member.orderHistoryId}
												className="mt-1 p-1 w-full border border-gray-300 rounded-md"
											/>
										</div>{" "}
										<div className="">
											<label
												htmlFor="portions"
												className="block text-sm font-medium text-gray-700">
												Portion
											</label>
											<input
												type="text"
												disabled
												value={member.portions}
												className="mt-1 p-1 w-full border border-gray-300 rounded-md"
											/>
										</div>{" "}
										<div className="">
											<label
												htmlFor="portions"
												className="block text-sm font-medium text-gray-700">
												Family Member ID
											</label>
											<input
												type="text"
												disabled
												value={member.familyMemberId}
												className="mt-1 p-1 w-full border border-gray-300 rounded-md"
											/>
										</div>
									</div>
								</Disclosure.Panel>
							</>
						)}
					</Disclosure>
				))}
			</div> */}


			<div className="text-green-700 font-lg font-bold mb-2 mt-6">
				Update Order Status
			</div>

			<div className="flex flex-col w-full sm:w-[300px] space-y-2">
				<div className="">
					<label
						htmlFor="order_status"
						className="block text-sm font-medium text-gray-700">
						Select Status Type
					</label>
					<select
						id="order_status"
						name="order_status"
						{...register("order_status")}
						defaultValue={data.order_status}
						className="mt-1 p-2 w-full border border-gray-300 rounded-md">
						<option value="">Select Package Type</option>
						{Object.keys(order_status).map((status) => (
							<option key={status} value={status}>
								{order_status[status]}
							</option>
						))}
					</select>
				</div>
				<Button type="submit">
					{is_loading ? "Please wait..." : "Update Order"}{" "}
				</Button>





			</div>

			</>}
		</form>
		{!waqf_order &&  <>
		{data?.order_status == "ORDER_COMPLETED" && <OrderToReporter id={data?.id} />}
</>}


		{/* <AsignOrderToReporder/> */}

	</>
	);
};

export default UpdateOrder;
