import React, { useEffect, useState } from 'react'
import { createCalenderDetail, getAllsadakahDetails, getCalenderDetail } from '../../services/settings';
import { Loader } from '../../components/Loader';
import HeaderSection from '../../components/HeaderSection';
import { Dialog } from '@headlessui/react';
import Button from '../../components/Button/Button';
import { useGetAnimal } from '../settings/AnimalTypes/hooks/useGetAnimal';
import { createRuminantPrice, getAllRuminantPrice } from '../../services/executerPrice';
import { toast } from 'react-toastify';
import { endpoint } from '../../config/constants';
import { axios } from '../../config/axios';

function ExecuterPage () {

  const [data,setData]=useState([])
  const [loading,setLoading]=useState(false)
  let [isOpen, setIsOpen] = useState(false);
  let [editModal, setEditModal] = useState(false);
  let [edititem, setEditItem] = useState({});
  const { animals} = useGetAnimal();

  const [event, setEvent] = useState({
    animal_id: 0,
    executor_role: "",
    amount_payable:0
  });

  const changeStatus = async (e) => {
    const token = JSON.parse(localStorage.getItem("token"));
    let data = {
      "is_active": !e?.is_active
    };
  
    try {
      const response = await axios.patch(`executor/admin-change-status-executor-payable/${e?.id}`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      toast.success("Status Updated");
      console.log(response);
      fetchData();
    } catch (error) {
      console.error("Error updating status:", error);
      
      if (error.response) {
        // The request was made, and the server responded with a status code
        // that falls out of the range of 2xx
        toast.error(`Error: ${error.response.data.message || "Failed to update status."}`);
      } else if (error.request) {
        // The request was made but no response was received
        toast.error("No response from the server. Please try again later.");
      } else {
        // Something happened in setting up the request that triggered an Error
        toast.error("Error in setting up the request.");
      }
    }
  };
  

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEvent((prevEvent) => ({
      ...prevEvent,
      [name]: name === "animal_id" || name === "amount_payable" ? Number(value) : value
    }));
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditItem((prevEvent) => ({
      ...prevEvent,
      [name]: value
    }));
  };
 
  useEffect(() => {
    
    fetchData();
  }, []);

  const fetchData = async () => {
    let updatedData = await getAllRuminantPrice();
    setLoading(true)
    setData(updatedData);
  };


  if(!loading){
    return(
      <Loader/>
    )
  }


  return (
    <>
      
    
    <div className="relative overflow-x-auto mb-[200px]">

        <div className='flex justify-between mb-2'>
        <button className='font-bold' onClick={()=>console.log(data)}>
           Executer Price
        </button>
      
        <Button type="button" onClick={() => setIsOpen(true)}>
							Add Price
						</Button>

        
        </div>
      <table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
        <thead className="text-xs text-pacific-blue uppercase bg-[#CFF0F2] ">
          <tr>
            
            <th scope="col" className="px-6 py-3 text-base font-medium">
              <div className='flex gap-1 items-center'>
                Id
              </div>
            </th>
            <th scope="col" className="px-6 py-3 text-base font-medium">
              <div className='flex gap-1 items-center'>
              Executor Role

              </div>
            </th>
            <th scope="col" className="px-6 py-3 text-base font-medium">
              <div className='flex gap-1 items-center'>

             Amount
              </div>

            </th>
            <th scope="col" className="px-6 py-3 text-base font-medium">
              <div className='flex gap-1 items-center'>

             Ruminant Type
              </div>

            </th>
            <th scope="col" className="px-6 py-3 text-base font-medium">
              <div className='flex gap-1 items-center'>

             Status
              </div>

            </th>

            <th scope="col" className="px-6 py-3 text-base font-medium">
              <div className='flex gap-1 items-center'>

            Action
              </div>

            </th>
          

            <th scope="col" className="px-2 py-3 text-base font-medium">

            </th>
          </tr>
        </thead>
        <tbody>


{data?.data?.map((e)=>{
  return(
    <tr className="bg-white border-b ">
           
    <th scope="row" className="px-6 py-4 text-sm font-medium">
      {e?.id}
    </th>
    <td className="px-6 py-4 text-sm font-medium">
      {e?.executor_role=="SLAUGHTER"?"Pelapor":"Penyembelih"}
    </td>
    <td className="px-6 py-4 text-sm font-medium">
    RM { Number(e?.amount_payable).toFixed(2)}
                  
    </td>
    <td className="px-6 py-4 text-sm font-medium">
      {e?.animal?.name}
    </td>
    <td className={`px-6 py-4 text-sm font-medium ${e?.is_active?"text-green-500":"text-red-500"}`}>
      {/* {e?.is_active?"Active":"InActive"} */}
      <input type="checkbox" value="" class="sr-only peer" checked={e?.is_active}
       onClick={()=>changeStatus(e)}/>
      <div 
      onClick={()=>changeStatus(e)}
      class="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
    </td>
    <td className="px-6 py-4 text-sm font-medium text-pacific-blue">
      <Button onClick={()=>{
        setEditItem(e)
        setEditModal(true)
      }}>
           Edit
        </Button>
    </td>

    
   
  </tr>
  )
})}

         
        </tbody>
      </table>


      {isOpen &&
       <Dialog
       open={isOpen}
       onClose={() => setIsOpen(true)}
       className="fixed inset-0 z-50 overflow-y-auto p-4 duration-500 "
     >
       <div className="flex items-center justify-center min-h-screen">
         <Dialog.Overlay className="fixed inset-0 bg-black/30" />

         <Dialog.Panel className="relative max-w-[600px] w-full mx-auto bg-white p-4 rounded">
           <Dialog.Title className="text-lg font-medium mb-6 border-b-2 flex items-center justify-between">
             <span className="">
               Set Price
             </span>
             <div
               className="flex items-center justify-center "
               onClick={() => setIsOpen(false)}
             >
               <h1 className="bg-gray-200 px-2 rounded-md mb-1 hover:text-red-600 ">
                 x
               </h1>
             </div>
           </Dialog.Title>

           <form onSubmit={async(e)=>{
            e.preventDefault()
            setIsOpen(false)
            
           const result=await createRuminantPrice(event)
           console.log(result)
           toast.error(result?.data?.data)
            fetchData()
           setIsOpen(false)
            // console.log(event)
           }}>
             <div className="grid grid-cols-1  sm:grid-cols-2 gap-4">
              

              

              
               <div className="">
                 <label
                   htmlFor="animal_id"
                   className="block text-sm font-medium text-gray-700"
                 >
                   Ruminant Type
                 </label>
                <select
                name='animal_id'
                id='animal_id'
                value={event.animal_id}
                onChange={handleChange}
                  className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                  required
                >
                    <option value=""> Select the animal type</option>

                 {animals?.map((e)=>{
                  return(
                    <option key={e.id} value={e.id}> {e.name}</option>
                  )
                 })}
                </select>
             
               </div>


               <div className="">
                 <label
                   htmlFor="executor_role"
                   className="block text-sm font-medium text-gray-700"
                 >
                   Executer Role
                 </label>
                <select
                name='executor_role'
                  className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                id='executor_role'
                value={event?.executor_role}
                onChange={handleChange}
                required
                >
                    <option value="">  Select the executer role</option>
                    <option value="SLAUGHTER"> Penyembelih</option>
                    <option value="REPORTER"> Pelapor</option>
                 
                </select>
             
               </div>

               <div className="">
                 <label
                   htmlFor="amount_payable"
                   className="block text-sm font-medium text-gray-700"
                 >
                   Amount per Portion
                 </label>
                 <input
                   type="number"
                   id="amount_payable"
                   name="amount_payable"
                   className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                   onChange={handleChange}
                   required
                 />
             
               </div>

               

              
             </div>

             <div className="flex justify-end mt-10">
               <Button type="submit">
                 Set Price
               </Button>
             </div>
           </form>
         </Dialog.Panel>
       </div>
     </Dialog>}



     {editModal &&
       <Dialog
       open={editModal}
       onClose={() => setEditModal(true)}
       className="fixed inset-0 z-50 overflow-y-auto p-4 duration-500 "
     >
       <div className="flex items-center justify-center min-h-screen">
         <Dialog.Overlay className="fixed inset-0 bg-black/30" />

         <Dialog.Panel className="relative max-w-[600px] w-full mx-auto bg-white p-4 rounded">
           <Dialog.Title className="text-lg font-medium mb-6 border-b-2 flex items-center justify-between">
             <span className="">
               Set Price
             </span>
             <div
               className="flex items-center justify-center "
               onClick={() => 
                
                {
                  setEditModal(false)
                }}
             >
               <h1 className="bg-gray-200 px-2 rounded-md mb-1 hover:text-red-600 ">
                 x
               </h1>
             </div>
           </Dialog.Title>

           <form onSubmit={async(e)=>{
            e.preventDefault()
           
            const token = JSON.parse(localStorage.getItem("token"));
            let data = {
              "amount_payable":JSON.parse(edititem?.amount_payable),
              "executor_role":edititem?.executor_role,
              "animal_id":JSON.parse(edititem?.animal_id),

            };
            
            const response = await axios.patch(`executor/admin-set-executor-payable/${edititem?.id}`, data, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            });
            

          
           console.log(response)
            fetchData()
            setEditModal(false)
          
            // console.log(event)
           }}>
             <div className="grid grid-cols-1  sm:grid-cols-2 gap-4">
              

              

              
               <div className="">
                 <label
                   htmlFor="animal_id"
                   className="block text-sm font-medium text-gray-700"
                 >
                   Ruminant Type
                 </label>
               
                  <select
                name='animal_id'
                id='animal_id'
                value={edititem?.animal_id}
                onChange={handleEditChange}
                  className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                  required
                >
                    <option value=""> Select the animal type</option>

                 {animals?.map((e)=>{
                  return(
                    <option key={e.id} value={e.id}> {e.name}</option>
                  )
                 })}
                </select>
             
               </div>


               <div className="">
                 <label
                   htmlFor="executor_role"
                   className="block text-sm font-medium text-gray-700"
                 >
                   Executer Role
                 </label>
                 <select
                name='executor_role'
                  className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                id='executor_role'
                value={edititem?.executor_role}
                onChange={handleEditChange}
                required
                >
                    <option value="">  Select the executer role</option>
                    <option value="SLAUGHTER"> Pelapor</option>
                    <option value="REPORTER"> Penyembelih</option>
                 
                </select>
              
               </div>

               <div className="">
                 <label
                   htmlFor="amount_payable"
                   className="block text-sm font-medium text-gray-700"
                 >
                   Amount Per Portion 
                 </label>
                 <input
                   type="number"
                   id="amount_payable"
                   name="amount_payable"
                   className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                   value={edititem?.amount_payable}
                   onChange={handleEditChange}
                   required
                 />
             
               </div>

               

              
             </div>

             <div className="flex justify-end mt-10">
               <Button type="submit">
                 Set Price
               </Button>
             </div>
           </form>
         </Dialog.Panel>
       </div>
     </Dialog>}



    </div>

    </>
  )
}

export default ExecuterPage


