import { api_keys } from "../../../config/constants";
import { useQuery } from "@tanstack/react-query";
import {
	getAllSuppliers,
	getSupplierDetails,
} from "../../../services/supplier";

export const useGetSupplier = (pageNumber, limit,country_id ="",feedlot=false) => {
	const {
		data: suppliers,
		isError,
		isLoading,
		refetch
	} = useQuery({
		queryKey: [api_keys.GET_SUPPLIERS, pageNumber, limit],
		queryFn: () => getAllSuppliers(pageNumber, limit,country_id),
		enabled: feedlot && !!country_id 
	});
	return {
		suppliers,
		isError,
		isLoading,
		refetch
	};
};
export const useGetSuppliers = (pageNumber, limit) => {
	
	const {
		data: suppliers,
		isError,
		isLoading,
		refetch
	} = useQuery({
		queryKey: [api_keys.GET_SUPPLIERS, pageNumber, limit],
		queryFn: () => getAllSuppliers(pageNumber, limit,"")
	});
	return {
		suppliers,
		isError,
		isLoading,
		refetch
	};
};

export const useGetSupplierDetails = (id) => {
	const { data, isError, error, isLoading } = useQuery({
		queryKey: [api_keys.GET_USER_DETAILS],
		queryFn: () => getSupplierDetails(id),
	});
	return {
		data,
		isError,
		error,
		isLoading,
	};
};
