import { SupplierWrapper } from "../features/supplier/components/SupplierWrapper";

const Suppliers = () => {
  return (
    <div className="">
      <SupplierWrapper />
    </div>
  );
};

export default Suppliers;
