import React, { useEffect, useState } from 'react'
import { IoIosArrowDropright } from 'react-icons/io'
import { useForm } from 'react-hook-form';
import { config } from '../../../config/config';
import Button from '../../../components/Button/Button';
import { axios } from '../../../config/axios';
import { toast } from 'react-toastify';
import { json } from 'react-router-dom';

function ProfilePage() {
  const [editStep, setEditStep] = useState(1)
  const user = JSON.parse(localStorage.getItem("user"));
  const password = localStorage.getItem("password");
  const token = JSON.parse(localStorage.getItem("token"));



  // Initialize react-hook-form
  const { register, handleSubmit, setValue, reset } = useForm({});



  const bankList = [
    {
      "name": "Maybank",
      "code": "27"
    },
    {
      "name": "CIMB Bank",
      "code": "35"
    },
    {
      "name": "RHB Bank",
      "code": "18"
    },
    {
      "name": "Bank Islam",
      "code": "45"
    },
    {
      "name": "Bank Muamalat",
      "code": "41"
    },
    {
      "name": "Bank Rakyat",
      "code": "02"
    },
    {
      "name": "Bank Simpanan Nasional",
      "code": "10"
    },
    {
      "name": "Citibank",
      "code": "17"
    },
    {
      "name": "Hong Leong Bank",
      "code": "24"
    },
    {
      "name": "HSBC Bank",
      "code": "22"
    },
    {
      "name": "OCBC Bank",
      "code": "29"
    },
    {
      "name": "Public Bank",
      "code": "33"
    },
    {
      "name": "Affin Bank",
      "code": "32"
    },
    {
      "name": "AmBank",
      "code": "08"
    },
    {
      "name": "Agro Bank",
      "code": "49"
    },
    {
      "name": "Alliance Bank",
      "code": "12"
    },
    {
      "name": "Al-Rajhi Bank",
      "code": "53"
    },
    {
      "name": "Bank of China",
      "code": "42"
    },
    {
      "name": "Bank of America",
      "code": "07"
    },
    {
      "name": "Bank of Tokyo-Mitsubishi UFJ",
      "code": "52"
    },
    {
      "name": "BNP Paribas",
      "code": "60"
    },
    {
      "name": "Deutsche Bank",
      "code": "19"
    },
    {
      "name": "Industrial & Commercial Bank of China",
      "code": "59"
    },
    {
      "name": "JP Morgan Chase Bank",
      "code": "48"
    },
    {
      "name": "Kuwait Finance House",
      "code": "47"
    },
    {
      "name": "Mizuho Bank",
      "code": "73"
    },
    {
      "name": "Standard Chartered Bank",
      "code": "14"
    },
    {
      "name": "Sumitomo Mitsui Banking Corporation",
      "code": "51"
    },
    {
      "name": "The Royal Bank of Scotland",
      "code": "46"
    },
    {
      "name": "United Overseas Bank",
      "code": "26"
    }
  ]




  // Handle form submission
  const onSubmit = async (data) => {
    try {
      // Create a new FormData object
      const formData = new FormData();
  
      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          // Check if the key is for an image
          if (key === 'image') {
            // Check if the image file exists and is not empty
            if (data[key] && data[key].length > 0) {
              formData.append('image', data.image[0]); // Append image file if present
            }
          } else {
            // Append other data
            formData.append(key, data[key]);
          }
        }
      }
  
      // Define the URL of your API endpoint
      let url = "";
  
      if (user?.role === "SLAUGHTER") {
        url = `users/update/slaughter/${user?.id}`; // Replace with your actual URL
      } else if (user?.role === "FEEDLOT") {
        url = `users/update/feedlot/${user?.id}`; // Replace with your actual URL
      } else if (user?.role === "REPORTER") {
        url = `users/update/reporter/${user?.id}`; // Replace with your actual URL
      } else if (user?.role === "SUPPLIER") {
        url = `users/update/supplier/${user?.id}`; // Replace with your actual URL
      } else if (user?.role === "CUSTOMER") {
        url = `users/update/customer/${user?.id}`; // Replace with your actual URL
      }
  
      // Make a PUT request with axios
      const response = await axios.put(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${token}`, // Include the token here
        },
      });
  
      toast.success("Profile updated successfully");
      // Log the response from the server
      localStorage.setItem("user",JSON.stringify(response?.data?.data))
      console.log(response?.data)
    } catch (error) {
      toast.error(error?.response?.data?.message || "An error occurred");
      console.error('Error making PUT request:', error);
    }
  };
  
  useEffect(() => {

    reset({
      fullname: user?.fullname || '',
      ic_number: user?.ic_number || '',
      phone_no: user?.phone_no || '',
      country_id: user?.country?.id || '',
      address: user?.address || '',
      state_id: user?.state?.id || '',
      region_id: user?.region?.id || '',
      bank_name: user?.bank_name || '',
      account_no: user?.account_no || '',
      password: password || '',
    });
  }, []);


  return (
    <div className='mb-[200px] md:mb-[100px]  overflow-hidden'>
      <div className=' bg-gradient-to-r from-[#00ADB9] from-70% to-[#9747FF]  flex items-center justify-between px-6 py-12 rounded-md'>
        <div className='text-white '>
          <h1 className='font-bold text-[22px]'>Verify your account</h1>
          <p className='mt-2'>Upload a picture of your identification card and a selfie to verify.</p>
        </div>
        <IoIosArrowDropright className=' text-white text-[40px] font-bold' />
      </div>



      {/* 1st Step */}
      <div className={`p-6 border  ${editStep != 1 ? "border-[#D5D5D5]" : "border-pacific-blue"}  rounded-lg mt-5`}>
        <div className='flex justify-between'>
          <h1 className='text-[#084059] text-[22px]'>Personal Information</h1>

        </div>

        <div className='flex items-center'>
          <img src={`${config?.backendBaseURL}uploads/${user?.image}`} className='w-28 h-28 bg-[#D9D9D9] rounded-full mr-4' />
          <div className='flex flex-col '>
            <h1 className='text-pacific-blue text-[24px] font-semibold'>{user?.fullname}</h1>
          </div>
        </div>


        <form onSubmit={handleSubmit(onSubmit)} className='w-[100%] mt-4 flex flex-wrap'>
          <div className='w-[50%] flex flex-col pr-4 my-2' >
            <label className='text-[#084059] text-[14px] mb-2'>Full name</label>
            <input
              className={`px-3 py-2 rounded-md text-[#666666] ${editStep !== 1 ? 'border-0' : 'border border-pacific-blue'}`}
              type='text'
              placeholder='Enter your Name'
              {...register('fullname')}
            />
          </div>




          <div className='w-[50%] flex flex-col  my-2' >
            <label className='text-[#084059] text-[14px] mb-2'>IC Number</label>
            <input
              className={`px-3 py-2 rounded-md text-[#666666] ${editStep !== 1 ? 'border-0' : 'border border-pacific-blue'}`}
              type='text'
              {...register('ic_number')}
            />
          </div>


          <div className='w-[50%] flex flex-col pr-4 my-2' >
            <label className='text-[#084059] text-[14px] mb-2'>Email</label>
            <input
              className={`px-3 py-2 rounded-md bg-gray-200 text-[#666666] ${editStep !== 1 ? 'border-0' : 'border border-pacific-blue'}`}
              type='text'
              value={user?.email}
              disabled
            />
          </div>


          <div className='w-[50%] flex flex-col  my-2' >
            <label className='text-[#084059] text-[14px] mb-2'>Phone Number</label>
            <input
              className={`px-3 py-2 rounded-md text-[#666666] ${editStep !== 1 ? 'border-0' : 'border border-pacific-blue'}`}
              type='text'
              {...register('phone_no')}
            />
          </div>

          {/* s2..... */}





          <div className='w-[50%] flex flex-col pr-4  my-2' >


            <label className='text-[#084059] text-[14px] mb-2'>Country</label>
            <input
              className={`px-3 py-2 bg-gray-200  rounded-md text-[#666666] ${editStep !== 1 ? 'border-0' : 'border border-pacific-blue'}`}
              type='text'
              disabled
              // {...register('country_id')}
              value={user?.country?.name}


            />
          </div>

          <div className='w-[50%] flex flex-col my-2' >

            <label className='text-[#084059] text-[14px] mb-2'>Home address</label>
            <input
              className={`px-3 py-2 rounded-md text-[#666666] ${editStep !== 1 ? 'border-0' : 'border border-pacific-blue'}`}
              type='text'
              disabled={editStep !== 1}
              {...register('address')}
            />
          </div>








          <div className='w-[50%] flex flex-col pr-4  my-2' >

            <label className='text-[#084059] text-[14px] mb-2'>State</label>
            <input
              className={`px-3 py-2 bg-gray-200 rounded-md text-[#666666] ${editStep !== 1 ? 'border-0' : 'border border-pacific-blue'}`}
              // {...register('state_id')}
              value={user?.region?.name}
              disabled
              type='text'
            />
          </div>

          <div className='w-[50%] flex flex-col  my-2' >

            <label className='text-[#084059] text-[14px] mb-2'>Region</label>
            <input
              className={`px-3 py-2 bg-gray-200 rounded-md text-[#666666] ${editStep !== 1 ? 'border-0' : 'border border-pacific-blue'}`}
              // {...register('region_id')}
              value={user?.region?.name}

              disabled
              type='text'
            />
          </div>



          {/*S 3 */}


          <div className='w-[50%] flex flex-col pr-4 my-2' >


            <label className='text-[#084059] text-[14px] mb-2'>Bank Name</label>


            <select
              className={`px-3 py-2 rounded-md text-[#666666] ${editStep !== 1 ? 'border-0' : 'border border-pacific-blue'}`}
              {...register('bank_name')}

            >

              <option value="">Select the Bank</option>
              {bankList?.map((e) => {
                return (
                  <option value={e?.code}>{e?.name}</option>
                )
              })}
            </select>


            {/* <input
                  className={`px-3 py-2 rounded-md text-[#666666] ${editStep !== 1 ? 'border-0' : 'border border-pacific-blue'}`}

  type='text'
  {...register('bank_name')}

/> */}
          </div>


          <div className='w-[50%] flex flex-col  my-2' >

            <label className='text-[#084059] text-[14px] mb-2'>Account Number</label>
            <input
              className={`px-3 py-2 rounded-md text-[#666666] ${editStep !== 1 ? 'border-0' : 'border border-pacific-blue'}`}

              type='text'
              {...register('account_no')}

            />
          </div>



          {/* S4 */}





          <div className='w-[50%] flex flex-col pr-4 my-2 ' >

            <label className='text-[#084059] text-[14px] mb-2'>Password</label>
            <input
              className={`px-3 py-2 rounded-md text-[#666666] ${editStep !== 1 ? 'border-0' : 'border border-pacific-blue'}`}
              {...register('password')}

              type='password'
            />
          </div>


          <div className='w-[50%] flex flex-col  my-2 ' >

<label className='text-[#084059] text-[14px] mb-2'>Image</label>
<input
  className={`px-3 py-2 rounded-md text-[#666666] ${editStep !== 1 ? 'border-0' : 'border border-pacific-blue'}`}
  {...register('image')}

  type='file'
/>
</div>



          <Button className='h-full mt-4 ml-auto mb-3 border border-[#D5D5D5] px-6 py-2 rounded-md' type='submit'
          //     onClick={() =>{ setEditStep(editStep === 1 ? 0 : 1)} 
          // }
          >
            {/* {editStep==1? "Done": "Edit"}  */}
            Update
          </Button>



        </form>
      </div>


    

    </div>
  )
}

export default ProfilePage
