import React from "react";
import Button from "./Button/Button";
const HeaderSection = ({ title, btnTitle, setIsOpen }) => {
	return (
		<div>
			<div className="flex flex-col md:flex-row md:items-center justify-between mb-2">
				<h1 className="text-lg font-bold md:self-end mb-2 md:mb-0">{title} </h1>
				{btnTitle && (
					<div className="mb-3 md:mb-0">
						<Button type="button" onClick={() => setIsOpen(true)}>
							{btnTitle}
						</Button>
					</div>
				)}
			</div>
		</div>
	);
};

export default HeaderSection;
