import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { sidebarMenuIcon } from "../assets/icons";
import Button from "./Button/Button";
import { roleRoutes } from "../config/roles";
import logo from "../assets/DQ Digital Qurban.png";
import Footer from "./Footer";
import { IoMailSharp } from "react-icons/io5";
import { TbBellRingingFilled } from "react-icons/tb";
import { PiLineVerticalThin } from "react-icons/pi";
import { BiSolidNotepad } from "react-icons/bi";

const Sidebar = ({ children, PageName }) => {
  const [open, setopen] = useState(false);
  const [openSubmenu, setSubmenu] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const path = location.pathname;
  const user = JSON.parse(localStorage.getItem("user"));
  const handleLogOut = () => {
    navigate("/login");
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    localStorage.removeItem("tokenExp");
  };
  
  const routes = roleRoutes[user.role];
  const handleLinkClick = () => {
    if (window.innerWidth < 1024) {
      setopen(false);
      setSubmenu(false);
    }
  };
  const handleLinkMenuClick = () => {
    if (window.innerWidth < 1024) {
      setopen(false);
    }
  };
  return (
    <div className="bg-white">
      <nav className="duration-500 fixed top-0 z-50 w-full border-b border-b-gray-200  bg-white text-black">
        <div className="px-3 py-3 lg:px-5 lg:pl-3">
          <div className="flex items-center justify-between">
            <div className="flex items-center justify-start">
              <button
                onClick={() => setopen(!open)}
                className="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg  hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
              >
                {sidebarMenuIcon}
              </button>
              <Link to="" className="flex h-[40px] ml-2 lg:ml-0">
                <span className="self-center text-xl font-semibold sm:text-2xl whitespace-nowrap">
                  <img
                    src={logo}
                    alt=""
                    className="h-[40px] max-w-[200px] ml-3 mt-2"
                  />
                </span>
              </Link>

              {/* <div className=" hidden text-xl font-bold ml-5 md:flex ">{PageName}</div> */}
            </div>

            <div className="flex">
              <div className="flex items-center md:gap-2">
                <div
                  className="relative"
                  data-modal-target="default-modal"
                  data-modal-toggle="email-toggle"
                  onClick={() =>
                    document
                      .getElementById("email-toggle")
                      .classList.toggle("hidden")
                  }
                >
                  {/* Email */}
                  <IoMailSharp className="md:text-3xl text-pacific-blue mr-2" />
                  <div className="bg-[#ED313D] w-3 h-3 rounded-full absolute top-0 right-1 border border-white"></div>

                  <div
                    id="email-toggle"
                    tabIndex="-1"
                    aria-hidden="true"
                    className="hidden absolute top-16 md:-left-40 -left-48 px-4 py-3 bg-white shadow shadow-gray-400 md:w-96 w-72 rounded-xl"
                  >
                    <h1 className="text-base font-bold">Notification</h1>

                    <hr className="my-4" />
                    <div className="flex  gap-4 flex-col">
                      <div className="text-star-dust text-xs text-right">
                        Clear all
                      </div>

                      {/* Notification */}
                      <div className="flex flex-col gap-2 h-56 md:h-72 overflow-y-auto">
                        <div className="flex items-center gap-2 cursor-pointer bg-gradient-to-r from-pale-sky-blue from-100% border-2 border-pacific-blue rounded-md py-4 px-3">
                          <div className="w-12 h-12 rounded-full bg-white flex items-center justify-center">
                            <IoMailSharp className="text-3xl text-pacific-blue " />
                          </div>

                          <div className="flex-col md:flex ">
                            <h5 className="mb-1 text-sm font-medium text-pacific-blue dark:text-white">
                              You have received a message
                            </h5>
                            <span className="text-xs text-gray-500">
                              2 hours ago
                            </span>
                          </div>
                        </div>

                        <div className="flex items-center gap-2 cursor-pointer bg-gradient-to-r from-pale-sky-blue from-100% border-2 border-pacific-blue rounded-md py-4 px-3">
                          <div className="w-12 h-12 rounded-full bg-white flex items-center justify-center">
                            <IoMailSharp className="text-3xl text-pacific-blue " />
                          </div>

                          <div className="flex-col md:flex ">
                            <h5 className="mb-1 text-sm font-medium text-pacific-blue dark:text-white">
                              You have received a message
                            </h5>
                            <span className="text-xs text-gray-500">
                              2 hours ago
                            </span>
                          </div>
                        </div>

                        <div className="flex items-center gap-2 cursor-pointer bg-gradient-to-r from-[#F1F1F1ED] from-100% border-2 border-[#F1F1F1ED] rounded-md py-4 px-3">
                          <div className="w-12 h-12 rounded-full bg-white flex items-center justify-center">
                            <BiSolidNotepad className="text-3xl text-gray-500 " />
                          </div>

                          <div className="flex-col md:flex ">
                            <h5 className="mb-1 text-sm font-medium text-[#BBBBBB] dark:text-white">
                              You have received a message
                            </h5>
                            <span className="text-xs text-[#BBBBBB]">
                              2 hours ago
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <TbBellRingingFilled className="md:text-3xl text-pacific-blue" />
                <PiLineVerticalThin className="text-3xl text-[#D5D5D5]" />
              </div>

              {/* profile */}

              <div className="relative">
                <div
                  data-modal-target="default-modal"
                  data-modal-toggle="profile-toggle"
                  onClick={() =>
                    document
                      .getElementById("profile-toggle")
                      .classList.toggle("hidden")
                  }
                  className="flex items-center gap-2 justify-center cursor-pointer"
                >
                  {/* <img
                    className="md:w-11 md:h-11 w-10 h-10 rounded-full shadow-lg"
                    src="/docs/images/people/profile-picture-3.jpg"
                    alt=""
                  /> */}
                  <div className="hidden flex-col md:flex">
                    <h5 className="mb-1 text-xl font-medium text-gray-900 dark:text-white">
                    {user?.fullname}
                    </h5>
                    <span className="text-sm text-gray-500">
                      {user?.role}
                    </span>
                  </div>
                </div>

                <div
                  id="profile-toggle"
                  tabIndex="-1"
                  aria-hidden="true"
                  className="hidden absolute top-16  left-[-260px] px-4 py-3 bg-white shadow shadow-gray-400 md:w-80 w-48 rounded-xl"
                >
                  <div className="flex justify-center">
                    <h1 className="text-2xl text-pacific-blue">{user?.fullname}</h1>
                  </div>
                  <hr className="my-4" />
                  <div className="flex md:gap-10 gap-4 md:flex-row flex-col">
                    <Link to="/profilepage" className="md:w-[50%]">
                      <Button
                        type="button"
                        className="w-[100%]"
                        onClick={() =>
                          document
                            .getElementById("profile-toggle")
                            .classList.toggle("hidden")
                        }
                      >
                        Profil
                      </Button>
                    </Link>

                    <Button
                      type="button"
                      className="md:w-[50%] bg-[#E42726] hover:bg-[#E42726]"
                      onClick={handleLogOut}
                    >
                      Log Keluar
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>

      <div
        className={`fixed top-0 left-0 z-40 w-64 h-[90vh] overflow-y-auto pt-20 duration-500 -translate-x-full bg-white text-santa-grey
                   border-r border-gray-200 
        				${open && "translate-x-0"}`}
      >
        <div
          className="h-full overflow-y-auto pb-16"
          style={{ scrollbarWidth: "thin", scrollbarColor: "#FFFFFF #FFFFFF" }}
        >
          <ul className="pl-2 pb-4 space-y-2">
            {routes.map((route) => (
              <li className="" key={route.id}>
                {route.childMenu ? (
                  <>
                    <div
                      onClick={() => setSubmenu(route.pathname)}
                      className={`flex items-center p-2 text-base font-semibold hover:bg-pale-sky-blue hover:text-pacific-blue duration-500 rounded-lg 
            ${
              path === route.path
                ? "bg-pale-sky-blue text-pacific-blue"
                : "text-santa-grey"
            }
            `}
                    >
                      <div className="w-[10%] h-full">{route.icon}</div>
                      <div className="w-[90%] h-full flex items-center justify-between">
                        <span className="ml-3 whitespace-pre">
                          {route.pathname}
                        </span>
                      </div>
                      <div
                        className={` ${
                          openSubmenu === route.pathname ? "rotate-90" : ""
                        }`}
                      >
                        {route.arrowIcon}
                      </div>
                    </div>
                    {openSubmenu === route.pathname && (
                      <ul className="pl-4 mt-1">
                        {route.childMenu.map((childRoute) => (
                          <li key={childRoute.id}>
                            <Link
                              to={childRoute.path}
                              onClick={handleLinkMenuClick}
                              className={`flex items-center p-2 text-base font-semibold hover:bg-pale-sky-blue hover:text-pacific-blue duration-500 rounded-lg 
														${
                              path === childRoute.path
                                ? "bg-pale-sky-blue text-pacific-blue"
                                : "text-santa-grey"
                            }`}
                            >
                              <div className="w-[10%] h-full">
                                {childRoute.icon}
                              </div>
                              <div className="w-[80%] h-full">
                                <span className="ml-3">
                                  {childRoute.pathname}
                                </span>
                              </div>
                            </Link>
                          </li>
                        ))}
                      </ul>
                    )}
                  </>
                ) : (
                  <Link
                    to={route.path}
                    onClick={handleLinkClick}
                    className={`flex items-center p-2 text-base font-semibold hover:bg-pale-sky-blue hover:text-pacific-blue duration-500 rounded-lg 
          ${
            path === route.path
              ? "bg-pale-sky-blue text-pacific-blue"
              : "text-santa-grey"
          }
          `}
                  >
                    <div className="w-[10%] h-full">{route.icon}</div>
                    <div className="w-[80%] h-full">
                      <span className="ml-3">{route.pathname}</span>
                    </div>
                  </Link>
                )}
              </li>
            ))}
          </ul>
        </div>
        {user && (
          <div className="absolute bottom-0 w-full p-4 bg-white border-t border-gray-200">
            <Button
              type="button"
              onClick={() => handleLogOut()}
              className="w-full"
            >
              Log Keluar
            </Button>
          </div>
        )}
      </div>

      <div className={`p-4 ${open && `lg:ml-64`} mt-16 mb-32 overflow-auto`}>
        {children}
        
      </div>
      <div
          className={`${
            open && `lg:ml-64`
          } duration-500 fixed bottom-0 right-0 z-[100] w-full`}
        >
          <Footer />
        </div>
    </div>
  );
};

export default Sidebar;
