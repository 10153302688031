import { CustomerWrapper } from "../features/customers/components/CustomerWrapper";

const Customers = () => {
  return (
    <div className="">
      <CustomerWrapper />
    </div>
  );
};

export default Customers;
