import { useQuery } from "@tanstack/react-query";
import { api_keys } from "../../../config/constants";
import { getExecutor,getSlaughter } from "../../../services/executor";

export const useGetExecutorDataHook = (pageNumber, limit) => {
	const {
		data,
		isError,
		isLoading,
		refetch
	} = useQuery({
		queryKey: [api_keys.GET_EXECUTOR, pageNumber, limit],
		queryFn: () => getExecutor(pageNumber, limit),
	});
	return {
		data,
		isError,
		isLoading,
		refetch
	};
};

export const useGetSlaughterDataHook = () => {
	const {
		data:slaughterList,
		isError,
		isLoading,
	} = useQuery({
		queryKey: [api_keys.GET_SLAUGHTER, 1, 100],
		queryFn: () => getSlaughter(1, 100),
	});
	return {
		slaughterList,
		isError,
		isLoading,
	};
};
// export const useGetFeedlotDetails = (id) => {
// 	const { data, isError, error, isLoading } = useQuery({
// 		queryKey: [api_keys.GET_USER_DETAILS],
// 		queryFn: () => getFeedlotDetails(id),
// 	});
// 	return {
// 		data,
// 		isError,
// 		error,
// 		isLoading,
// 	};
// };
