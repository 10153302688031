import React from "react";
import { Outlet, useNavigate } from "react-router-dom";

const ProtectedRoute = () => {
	const user = JSON.parse(localStorage.getItem("user"));
	const tokenString = localStorage.getItem("token");
	const navigate = useNavigate();

	if (!user || !tokenString) {
		navigate("/login");
		return null;
	}

	return <Outlet />;
};

export default ProtectedRoute;
