import React, { Fragment } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Button from "../Button/Button";
const DataTable = ({
	data,
	onClick,
	page = 1,
	setPageNumber,
	paginationEnabled = false,
	canUpdateStatus = false,
	canEdit = false,
	updatefn = null,
}) => {
	const navigate = useNavigate();
	const location = useLocation();
	const currentPath = location.pathname.split("/")[1];
	const handlePageChange = (page) => {
		if (page < 1) {
			page = 1;
		}
		navigate(`/${currentPath}/${page}`);
		setPageNumber(page);
	};
	if (data.length === 0) {
		return <p className="text-gray-500">No data available.</p>;
	}
	const headers = Object.keys(data[0]);
	const headerLabels = {
		'country_id': 'Country Name', 
		'state_id': 'State Name', 
		'region_id': 'Region Name', 
		
	};
	const isPriceOrAmountField = (header) => {
		// Check if the header contains 'price' or 'amount'
		const lowerCaseHeader = header.toLowerCase();
		return lowerCaseHeader.includes('price') || lowerCaseHeader.includes('amount');
	};
	const getValue = (item, header) => {
		const keys = header.split('.');
		let value = item;
		for (let key of keys) {
			value = value[key];
			if (value === undefined) {
				return '';
			}
		}
		return value;
	};

	const flattenData = (data) => {
		return data.map(item => {
			const flattened = {};
			headers.forEach(header => {
				if (typeof getValue(item, header) !== 'object') {
					flattened[header] = getValue(item, header);
				}
			});
			return flattened;
		});
	};

	const flattenedDatavalue = flattenData(data);
	const filteredHeaders = headers.filter(
		(header) => typeof flattenedDatavalue[0][header] !== 'object' && !Array.isArray(flattenedDatavalue[0][header])
	);
	
	return (
		<div className="w-full bg-[#FFFFFF] ">
			{/* <button className="btn bg-cyan-400" onClick={() => console.log(data)}>Check</button> */}
			<div className="overflow-x-auto w-full">
				<table className="w-full divide-y divide-gray-200 text-[#404040]">
					<thead className="bg-pale-sky-blue ">
						<tr>
							{filteredHeaders?.map((header) =>
								// typeof data[0][header] !== 'object' && !Array.isArray(data[0][header]) ? (
									<th
										key={header}
										scope="col"
										className="px-6 py-3 text-left text-xs font-medium text-pacific-blue uppercase tracking-wider border-b border-quill-grey">
										  {headerLabels[header] || header.replace("_", " ")}
									</th>
								// ) : null
							)}
							{canUpdateStatus && (
								<th
									key={"status"}
									scope="col"
									className="px-6 py-3 text-left text-xs font-medium text-pacific-blue uppercase tracking-wider border-b border-quill-grey">
									Status
								</th>
							)}
							{canEdit && (
									<th
									key={"edit"}
									scope="col"
									className="px-6 py-3 text-left text-xs font-medium text-pacific-blue uppercase tracking-wider border-b border-quill-grey">
									Action
								</th>
								)}
						</tr>
					</thead>
					<tbody className="bg-white divide-y divide-gray-200">
						{flattenedDatavalue?.map((item) => (
							<tr
								key={item.id}
								onClick={() => onClick && onClick(item)}
								className="cursor-pointer">
								{headers.map((header) =>
									// typeof item[header] !== 'object' && !Array.isArray(item[header]) ? (
										<td
											key={header}
											className="px-6 py-4 whitespace-nowrap border-b border-gray-300">
											{header === "image" ? (
												<img
													src={item[header]}
													alt="animal"
													className="w-10 h-10 rounded-full"
												/>
											) : header === "enabled" ? (
												item[header] === true ? "Active" : "Inactive"
											) : header === "country_id" ? (
													item.country ? item?.country?.name : ''
											)  :  header === "state_id" ? (
												item.state ? item?.state?.name : ''
											)  : header === "region_id" ? (
												item.region ? item?.region?.name : ''
											) : isPriceOrAmountField(header) ? (
												`RM ${item[header]}`
											) : (
												item[header] && item[header].toString()
											)}
										</td>
									// ) : ""
								)}

								{canUpdateStatus && (
									<td className="px-6 py-4 whitespace-nowrap text-left text-sm font-medium border-b border-gray-300">
										<button
											className={`${item.enabled === true
												? "text-green-600 hover:text-green-900"
												: "text-red-600 hover:text-red-900"
												}`}
											onClick={(e) => {
												e.preventDefault();
												updatefn(item, !item.enabled);
											}}>
											{item.enabled === true ? "Activate" : "Deactivate"}
										</button>
									</td>
								)}
								{/* edit */}
								{canEdit && (
									<td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-pacific-blue">
										<Button
											className=" "
											onClick={(e) => {
												e.preventDefault();
												updatefn(item);
											}}>
											Edit
										</Button>
									</td>
								)}
							</tr>
						))}
					</tbody>
				</table>
			</div>
			{paginationEnabled && (
				<div className="flex justify-end p-4">
					<Button
						onClick={() => handlePageChange(page - 1)}
						className="">
						Previous
					</Button>
					<Button
						onClick={() => handlePageChange(page + 1)}
						className=" ml-2">
						Next
					</Button>
				</div>
			)}
		</div>
	);
};

export default DataTable;