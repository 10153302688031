import { useQuery } from "@tanstack/react-query";
import { api_keys } from "../../../config/constants";
import {
  getCattleById,
  getCattlesData,
  getFeedlotList,
  getSupliersList,
} from "../../../services/cattles";
import { config } from "../../../config/config";

export const useGetCattles = (pageNumber, limit, queryOption) => {
  const {
    data: cattlesData,
    isError,
    isLoading,
  } = useQuery({
    queryKey: [api_keys.GET_CATTLE, pageNumber, limit, queryOption],
    queryFn: () => getCattlesData(pageNumber, limit, queryOption),
    keepPreviousData: true,
  });
  const cattleTransformedData = cattlesData?.data.map((item) => {
    return {
      id: item?.id,
      image: config.resourceUrl +"uploads/"+ item?.animal_image,
      available_stock: item?.available_stock,
      ruminent_id: item?.ruminent_id,
      supplier_id: item?.supplier?.id,
      supplier_fullname: item?.supplier?.fullname,
      package_name: item?.package?.package?.name || "N/A",
      ruminant_type: item?.package?.animal?.name || "N/A",
      package_price: item?.package?.price || "N/A",
      portions: item?.package?.animal?.portions || "N/A",
      is_available: item?.is_available,
      age: item?.age,
      weight: item?.weight,
      country: item?.supplier?.country?.name,
      region: item?.supplier?.region?.name,
      state: item?.supplier?.state?.name,
      extraData: item,
    };
  });
  const cattleDataMain = {
    data: cattleTransformedData,
    totlaPages: cattlesData?.totalPages,
  };
  return { cattleDataMain, isError, isLoading };
};


export const useFetchSuppliersList = () => {
  const {
    data: suppliresList,
    isError,
    isLoading,
    
  } = useQuery({
    queryKey: [api_keys.GET_SUPPLIERS],
    queryFn: () => getSupliersList(),
    keepPreviousData: true,
  });
  return { suppliresList, isError, isLoading };
};
export const useFetchFeddlotList = () => {
  const {
    data: feedlotList,
    isError,
    isLoading,
    refetch
  } = useQuery({
    queryKey: [api_keys.GET_FEEDLOTS],
    queryFn: () => getFeedlotList(),
    keepPreviousData: true,
  });
  return { feedlotList, isError, isLoading ,refetch};
};

export const useGetCattleById = (id = null) => {
  const {
    data: item,
    isError,
    error,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: [api_keys.GET_USER_DETAILS],
    queryFn: () => id !== null && getCattleById(id),
    retry: 1,
  });
  const cattleTransformedData = {
    id: item?.data?.id,
    image: config.resourceUrl + item?.data?.animal_image,
    available_stock: item?.data?.available_stock,
    ruminent_id: item?.data?.ruminent_id,
    supplier_id: item?.data?.supplier?.id,
    package_name: item?.data?.package?.package?.name || "N/A",
    ruminant_type: item?.data?.package?.animal?.name || "N/A",
    package_price: item?.data?.package?.price || "N/A",
    portions: item?.data?.package?.animal?.portions || "N/A",
    is_available: item?.data?.is_available,
    age: item?.data?.age,
    weight: item?.data?.weight,
    country: item?.data?.supplier?.country?.name,
    region: item?.data?.supplier?.region?.name,
    state: item?.data?.supplier?.state?.name,
  };
  return {
    cattleTransformedData,
    isError,
    error,
    isLoading,
    refetch,
  };
};
