import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { config } from "../../../../config/config";
import { Dialog } from "@headlessui/react";
import { axios } from "../../../../config/axios";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useFetchCountires } from "../../../countries/hooks/useFetchCountries";
import Button from "../../../../components/Button/Button";
import HeaderSection from "../../../../components/HeaderSection";
import Search from "../../../../components/Search";
import { useGetAnimal } from "../hooks/useGetAnimal";
import { Loader } from "../../../../components/Loader";
import { FaUser } from "react-icons/fa";

const AnimalTypesTest = () => {
  const { animals, isError, isLoading, refetch } = useGetAnimal();
  const { countries } = useFetchCountires();
  const navigate = useNavigate();
  let [isOpen, setIsOpen] = useState(false);
  let [editModal, isEditModal] = useState(false);
  let [loading, setLoading] = useState(false);
  let [edititem, setEditItem] = useState({});
  const [selectedMonths, setSelectedMonths] = useState([]);
  const [selectedEditMonths, setSelectedEditMonths] = useState([]);
  const months = Array.from({ length: 11 }, (_, i) => i + 2);

  const handleCheckboxChange = (e) => {
    const value = parseInt(e.target.value);
    setSelectedMonths((prevSelected) => {
      if (prevSelected.includes(value)) {
        return prevSelected.filter((month) => month !== value);
      } else {
        return [...prevSelected, value];
      }
    });
  };

  console.log(animals);
  const handleEditCheckboxChange = (e) => {
    const value = parseInt(e.target.value);
    setSelectedEditMonths((prevSelected) => {
      if (prevSelected.includes(value)) {
        return prevSelected.filter((month) => month !== value);
      } else {
        return [...prevSelected, value];
      }
    });
  };

  const selectedMonthsString = selectedMonths.sort((a, b) => a - b).join(',');
  let selectedEditMonthsString = selectedEditMonths.sort((a, b) => a - b).join(',');

 


  const {
    register: registerAddAnimals,
    handleSubmit: handleAddAnimals,
    formState: { errors: errorsAddAnimals },
  } = useForm();

  const {
    register: registerUpdateAnimals,
    handleSubmit: handleUpdateAnimals,
    formState: { errors: errorsUpdateAnimals },
    reset: resetUpdateAnimals,
  } = useForm();

  useEffect(() => {
    if (editModal) {
      resetUpdateAnimals({
        name: edititem?.name || "",
        portions: edititem?.portions || "",
        price: edititem?.price || "",
        admin_fee: edititem?.admin_fee || "",
        has_portions: edititem?.has_portions || false,
        has_installment  :edititem?.has_installments || false,
      });
      const editMonthsArray = edititem?.allowed_months.split(',').map(Number);
      setSelectedEditMonths(editMonthsArray)
    }
  }, [editModal, resetUpdateAnimals, edititem]);



  const handleCreateAnimalFunction = async (data) => {
    setLoading(true);

if(selectedMonthsString==""){
  toast.error("Please Select the Allowed Month")
}

else{

    const token = JSON.parse(localStorage.getItem("token"));
    const formData = new FormData();

    formData.append('name', data?.name || '');
    formData.append('portions', parseInt(data?.portions) || 0);
    formData.append('price', parseInt(data?.price) || 0);
    formData.append('admin_fee', parseInt(data?.admin_fee) || 0);
    formData.append('has_portions', data?.has_portions || false);
    formData.append('has_installments', data?.has_installments || false);
    formData.append('allowed_months', selectedMonthsString || '');




    // Iterate through data object keys
    if (data.animal_image && data.animal_image[0]) {
      formData.append('animal_image', data.animal_image[0]);
    }


    // Log formData for debugging
    for (let [key, value] of formData.entries()) {
      console.log(`${key}: ${value}`);
    }

    try {
      const response = await axios.post(
        `/packages/animal-types`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response);
      refetch()
      setIsOpen(false);
      setLoading(false)
      toast.success("Ruminant Type Created");

    } catch (error) {
      toast.error(error?.response?.data?.error || "Something went wrong");
      console.error("Error:", error);
      setLoading(false)
    }
    console.log("add item", data);

  }
  };


  const handleUpdateAnimalsFunction = async (data) => {
    setLoading(true);


    if(selectedEditMonthsString==""){
      toast.error("Please Select the Allowed Month")
    }
    
    else{
    
    console.log(data);

    const token = JSON.parse(localStorage.getItem("token"));
    const formData = new FormData();

    formData.append('name', data?.name || '');
    formData.append('portions', parseInt(data?.portions) || 0);
    formData.append('price', parseInt(data?.price) || 0);
    formData.append('admin_fee', parseInt(data?.admin_fee) || 0);
    formData.append('has_portions', data?.has_portions || false);
    formData.append('has_installments', data?.has_installments || false);
    formData.append('allowed_months', selectedEditMonthsString || '');
    
    if (data.animal_image && data.animal_image[0]) {
      formData.append('animal_image', data.animal_image[0]);
    }
for (let [key, value] of formData.entries()) {
      console.log(`${key}: ${value}`);
    }

    try {
      const response = await axios.put(
        `/packages/animal-types/${edititem?.id}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response);
      refetch()
      setIsOpen(false);
      setLoading(false)
      toast.success("Ruminant Type Updated");

    } catch (error) {
      toast.error(error?.response?.data?.error || "Something went wrong");
      console.error("Error:", error);
      setLoading(false)
    }

  }
    console.log("Edit item", data);
  };




  if (isError) {
    return <h1 className="">An error occured</h1>;
  }

  if (isLoading) {
    return <h1 className="">Please wait, fetching data</h1>;
  }





  return (
    <div>





      <HeaderSection
        title={"Ruminant Types"}
        btnTitle={"Create Ruminant Type"}
        setIsOpen={setIsOpen}
      />
      <div className="flex items-center justify-end mb-3">
        <Search />
      </div>
      <table className="w-full text-sm overflow-x-auto text-left rtl:text-right text-gray-500 ">

        <thead className="text-xs text-pacific-blue uppercase bg-[#CFF0F2] ">
          <tr >

            <th scope="col" className="px-6 py-3 text-base font-medium ">
              <div className='flex gap-1 items-center'>
                Id
              </div>
            </th>
            <th scope="col" className="px-6 py-3 text-base font-medium">
              <div className='flex gap-1 items-center'>

                image
              </div>

            </th>

            <th scope="col" className="px-6 py-3 text-base font-medium">
              <div className='flex gap-1 items-center'>
                Name

              </div>
            </th>
            <th scope="col" className="px-6 py-3 text-base font-medium">
              <div className='flex gap-1 items-center'>

                portions
              </div>

            </th>
            <th scope="col" className="px-6 py-3 text-base font-medium">
              <div className='flex gap-1 items-center'>

                Has Portions
              </div>

            </th>
            <th scope="col" className="px-6 py-3 text-base font-medium">
              <div className='flex gap-1 items-center'>

                price
              </div>

            </th>
            <th scope="col" className="px-6 py-3 text-base font-medium">
              <div className='flex gap-1 items-center'>

                Allowed Months
              </div>

            </th>
            <th scope="col" className="px-6 py-3 text-base font-medium">
              <div className='flex gap-1 items-center'>

                Admin Fee
              </div>

            </th>

            <th scope="col" className="px-6 py-3 text-base font-medium">
              <div className='flex gap-1 items-center'>

                HAS INSTALLMENTS
              </div>

            </th>

            <th scope="col" className="px-6 py-3 text-base font-medium">
              <div className='flex gap-1 items-center'>

                CREATED AT
              </div>

            </th>





            <th scope="col" className="px-2 py-3 text-base font-medium">
              Action
            </th>
          </tr>
        </thead>
        <tbody>


          {animals?.map((e) => {
            return (
              <tr className="bg-white border-b " key={e?.id}>

                <th scope="row" className="px-6 py-4 text-sm font-medium">
                  {e?.id}
                </th>
                <td className="px-6 py-4 text-sm font-medium">
                  
                  {(e?.image !== undefined && e?.image !== "" && e?.image !== "undefined" && e?.image !== null && e?.image !== "null") ? (
                      <img src={`${config?.backendBaseURL}uploads/${e?.image}`} className="w-14" />
                    ) : (
                      <FaUser className="w-8 h-10" />
                    )}
                </td>
                <td className="px-6 py-4 text-sm font-medium">
                  {e?.name}
                </td>

                <td className="px-6 py-4 text-sm font-medium">
                  {e?.portions}
                </td>


                <td className="px-6 py-4 text-sm font-medium">
                  {e?.has_portions === true ? "true" : "false"}
                </td>

                <td className="px-6 py-4 text-sm font-medium">
                 RM {e?.price}
                </td>



                <td className="px-6 py-4 text-sm font-medium">
                  {e?.allowed_months}
                </td>


                <td className="px-6 py-4 text-sm font-medium">
                 RM {e?.admin_fee}
                </td>

                <td className="px-6 py-4 text-sm font-medium">
                  {e?.has_installments === true ? "true" : "false"}
                </td>

                <td className="px-6 py-4 text-sm font-medium">
                  {e?.created_at}
                </td>






                <td className="px-6 py-4 text-sm font-medium text-pacific-blue">
                  <Button className="px-8"
                    onClick={() => {

                      setEditItem(e)
                      isEditModal(true)
                      console.log(e)
                    }}>
                    Edit
                  </Button>
                </td>



              </tr>
            )
          })}


        </tbody>
      </table>



      {/* Add Ruminant Type */}
      {isOpen &&
        <Dialog
          open={isOpen}
          onClose={() => setIsOpen(true)}
          className="fixed inset-0 z-50 overflow-y-auto p-4 duration-500 "
        >
          <div className="flex items-center justify-center min-h-screen">
            <Dialog.Overlay className="fixed inset-0 bg-black/30" />

            <Dialog.Panel className="relative max-w-[600px] w-full mx-auto bg-white p-4 rounded">
              <Dialog.Title className="text-lg font-medium mb-6 border-b-2 flex items-center justify-between">
                <span className="">
                  Create Ruminant Type
                </span>
                <div
                  className="flex items-center justify-center "
                  onClick={() => setIsOpen(false)}
                >
                  <h1 onClick={() => setIsOpen(false)} className="bg-gray-200 px-2 rounded-md mb-1 hover:text-red-600 cursor-pointer">
                    x
                  </h1>
                </div>
              </Dialog.Title>

              <form onSubmit={handleAddAnimals(handleCreateAnimalFunction)}
                className="max-h-[600px] overflow-auto px-3">
                <div className="grid grid-cols-1  sm:grid-cols-2 gap-4">





                  <div className="">
                    <label
                      htmlFor="name"
                      className="block mt-1   font-medium text-gray-700"
                    >
                      Name
                    </label>

                    <input
                      name='name'
                      id='name'
                      {...registerAddAnimals("name", {
                        required: "Name is required",
                      })}
                      className="mt-2 p-1 w-full border border-gray-300 rounded-md"
                    />
                    <p className="text-red-500 text-sm">
                      {errorsAddAnimals?.name?.message}
                    </p>
                  </div>


                  <div className="">
                    <label htmlFor="animal_image" className="block mb-2 font-medium text-gray-700">
                      Image
                    </label>
                    <input
                      id="animal_image"
                      type="file"
                      {...registerAddAnimals("animal_image")}
                      className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                    />
                    {errorsAddAnimals.animal_image && (
                      <span className="text-red-clr">
                        This field is required
                      </span>
                    )}
                  </div>


                  <div className="flex items-center">
                    <label
                      htmlFor="has_portions"
                      className="block mt-1 font-medium text-gray-700 mr-3"
                    >
                      Has Portions
                    </label>
                  <input
                      type="checkbox"
                      name="has_portions"
                      id="has_portions"
                      {...registerAddAnimals("has_portions")}
                      className="mt-2 p-1 border border-gray-300 rounded-md"
                    />
                 

                  
                    <p className="text-red-500 text-sm">
                      {errorsAddAnimals?.has_portions?.message}
                    </p>
                  </div>


                  <div className="">
                    <label
                      htmlFor="portions"
                      className="block mt-1 font-medium text-gray-700"
                    >
                      Portions
                    </label>

                    <input
                      type="number"
                      name="portions"
                      id="portions"
                      {...registerAddAnimals("portions")}
                      className="mt-2 p-1 w-full border border-gray-300 rounded-md"
                    />
                    <p className="text-red-500 text-sm">
                      {errorsAddAnimals?.portions?.message}
                    </p>
                  </div>

                  <div className="">
                    <label
                      htmlFor="price"
                      className="block mt-1   font-medium text-gray-700"
                    >
                      Price
                    </label>

                    <input
                      type="number"
                      name='price'
                      id='price'
                      {...registerAddAnimals("price", {
                        required: "Price is required",
                      })}
                      className="mt-2 p-1 w-full border border-gray-300 rounded-md"
                    />
                    <p className="text-red-500 text-sm">
                      {errorsAddAnimals?.price?.message}
                    </p>
                  </div>

                 

                  <div className="">
                    <label
                      htmlFor="admin_fee"
                      className="block mt-1   font-medium text-gray-700"
                    >
                      Admin Fee
                    </label>

                    <input
                      type="number"
                      name='admin_fee'
                      id='admin_fee'
                      {...registerAddAnimals("admin_fee", {
                        required: "Admin Fee is required",
                      })}
                      className="mt-2 p-1 w-full border border-gray-300 rounded-md"
                    />
                    <p className="text-red-500 text-sm">
                      {errorsAddAnimals?.admin_fee?.message}
                    </p>
                  </div>


                  <div className="flex items-center">
                    <label
                      htmlFor="has_portions"
                      className="block mt-1 font-medium text-gray-700 mr-3"
                    >
                      Has hnstallments 
                    </label>
                  <input
                      type="checkbox"
                      name="has_installments"
                      id="has_installments"
                      {...registerAddAnimals("has_installments")}
                      className="mt-2 p-1 border border-gray-300 rounded-md"
                    />
                 

                  
                    <p className="text-red-500 text-sm">
                      {errorsAddAnimals?.has_installments?.message}
                    </p>
                  </div>
                  
                </div>

                <div className="border rounded-lg p-6 border-pacific-blue my-6">


                <div className="">
                    <label
                      htmlFor="allowed_months"
                      className="block mt-1 font-medium text-gray-700"
                    onClick={()=>console.log(selectedMonthsString)}
                    >
                      Allowed Months
                    </label>

                    <input
                      name="allowed_months"
                      id="allowed_months"
                      value={selectedMonthsString}
                      disabled
                      className="mt-2 p-1 w-full border border-gray-300 rounded-md"
                    />
                  </div>

               
                  <div className="grid grid-cols-1 md:grid-cols-3">
                    {months.map((month) => (
                      <div key={month} className="flex mx-4">
                        <input
                        id={`month${month}`}
                          type="checkbox"
                          value={month}
                          checked={selectedMonths.includes(month)}
                          onChange={handleCheckboxChange}
                          className="mt-2 p-1 border cursor-pointer border-gray-300 rounded-md"
                        />
                        <label 
                        htmlFor={`month${month}`}
                        className="flex mt-2 ml-2 cursor-pointer font-medium text-gray-700">
                          {month} Months
                        </label>
                      </div>
                    ))}
                  </div>

                </div>

                <div className="flex justify-end mt-10">
                   
                   {loading?
                   <Button>
                    <Loader/>
                   </Button>:
                  <Button type="submit">Create Ruminant Type</Button>
                   }
                </div>
              </form>
            </Dialog.Panel>
          </div>
        </Dialog>}


      {/* edit Ruminant Type */}
      {editModal &&
        <Dialog
          open={editModal}
          onClose={() => isEditModal(true)}
          className="fixed inset-0 z-50 overflow-y-auto p-4 duration-500 "
        >
          <div className="flex items-center justify-center min-h-screen">
            <Dialog.Overlay className="fixed inset-0 bg-black/30" />

            <Dialog.Panel className="relative max-w-[600px] w-full mx-auto bg-white p-4 rounded">
              <Dialog.Title className="text-lg font-medium mb-6 border-b-2 flex items-center justify-between">
                <span className="">
                  Update Ruminant Type
                </span>
                <div
                  className="flex items-center justify-center "
                  onClick={() => setIsOpen(false)}
                >
                  <h1 onClick={() => isEditModal(false)} className="bg-gray-200 px-2 rounded-md mb-1 hover:text-red-600 cursor-pointer">
                    x
                  </h1>
                </div>
              </Dialog.Title>

              <form onSubmit={handleUpdateAnimals(handleUpdateAnimalsFunction)}>
                <div className="grid grid-cols-1  sm:grid-cols-2 gap-4">


                <div>
                  <label className="block text-sm font-medium text-gray-700">
                      Image
                    </label>
                     {(edititem?.image !== undefined && edititem?.image !== "" && edititem?.image !== "undefined" && edititem?.image !== null && edititem?.image !== "null") ? (
                      <img src={`${config?.backendBaseURL}uploads/${edititem?.image}`} className="w-14" />
                    ) : (
                      <FaUser className="w-8 h-10" />
                    )}
                    </div>


                  <div className="">
                    <label
                      htmlFor="name"
                      className="block mt-1   font-medium text-gray-700"
                    >
                      Name
                    </label>

                    <input
                      name='name'
                      id='name'
                      {...registerUpdateAnimals("name", {
                        required: "Name is required",
                      })}
                      className="mt-2 p-1 w-full border border-gray-300 rounded-md"
                    />
                    <p className="text-red-500 text-sm">
                      {errorsUpdateAnimals?.name?.message}
                    </p>
                  </div>


                  <div className="">
                  <label htmlFor="animal_image" className="block mb-2 font-medium text-gray-700">
                    Image
                  </label>
                  <input
                    id="animal_image"
                    type="file"
                    {...registerUpdateAnimals("animal_image")}
                    className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                  />
                  {errorsUpdateAnimals.animal_image && (
                    <span className="text-red-clr">
                      This field is required
                    </span>
                  )}
                </div>


                  <div className="flex items-center">
                    <label
                      htmlFor="has_portions"
                      className="block mt-1  mr-3 font-medium text-gray-700"
                    >
                      Has Portions
                    </label>

                    <input
                      type="checkbox"
                      name="has_portions"
                      id="has_portions"
                      {...registerUpdateAnimals("has_portions")}
                      className="mt-2 p-1 border border-gray-300 rounded-md"

                    />
                    <p className="text-red-500 text-sm">
                      {errorsUpdateAnimals?.has_portions?.message}
                    </p>
                  </div>


                  <div className="">
                    <label
                      htmlFor="portions"
                      className="block mt-1 font-medium text-gray-700"
                    >
                      Portions
                    </label>

                    <input
                      type="number"
                      name="portions"
                      id="portions"
                      {...registerUpdateAnimals("portions")}
                      className="mt-2 p-1 w-full border border-gray-300 rounded-md"
                    />
                    <p className="text-red-500 text-sm">
                      {errorsUpdateAnimals?.portions?.message}
                    </p>
                  </div>

                  <div className="">
                    <label
                      htmlFor="price"
                      className="block mt-1   font-medium text-gray-700"
                    >
                      Price
                    </label>

                    <input
                      type="number"
                      name='price'
                      id='price'
                      {...registerUpdateAnimals("price", {
                        required: "Price is required",
                      })}
                      className="mt-2 p-1 w-full border border-gray-300 rounded-md"
                    />
                    <p className="text-red-500 text-sm">
                      {errorsUpdateAnimals?.price?.message}
                    </p>
                  </div>

                 

                  <div className="">
                    <label
                      htmlFor="admin_fee"
                      className="block mt-1   font-medium text-gray-700"
                    >
                      Admin Fee
                    </label>

                    <input
                      type="number"
                      name='admin_fee'
                      id='admin_fee'
                      {...registerUpdateAnimals("admin_fee", {
                        required: "Admin Fee is required",
                      })}
                      className="mt-2 p-1 w-full border border-gray-300 rounded-md"
                    />
                    <p className="text-red-500 text-sm">
                      {errorsUpdateAnimals?.admin_fee?.message}
                    </p>
                  </div>


                  <div className="flex items-center">
                    <label
                      htmlFor="has_portions"
                      className="block mt-1  mr-3 font-medium text-gray-700"
                    >
                      Has Installments
                    </label>

                    <input
                      type="checkbox"
                      name="has_installments"
                      id="has_installments"
                      {...registerUpdateAnimals("has_installments")}
                      className="mt-2 p-1 border border-gray-300 rounded-md"

                    />
                    <p className="text-red-500 text-sm">
                      {errorsUpdateAnimals?.has_installments?.message}
                    </p>
                  </div>
             


                </div>
                <div className="border rounded-lg p-6 border-pacific-blue my-6">


<div className="">
    <label
      htmlFor="allowed_months"
      className="block mt-1 font-medium text-gray-700"
    onClick={()=>{console.log(selectedEditMonthsString)}}
    >
      Allowed Months
    </label>

    <input
      name="allowed_months"
      id="allowed_months"
      value={selectedEditMonthsString}
      disabled
      className="mt-2 p-1 w-full border border-gray-300 rounded-md"
      onClick={()=>{console.log(edititem)}}
    />
  </div>


  <div className="grid grid-cols-1 md:grid-cols-3">
    {months.map((month) => (
      <div key={month} className="flex mx-4">
        <input
        id={`month${month}`}
          type="checkbox"
          value={month}
          checked={selectedEditMonths.includes(month)}
          onChange={handleEditCheckboxChange}
          className="mt-2 p-1 border cursor-pointer border-gray-300 rounded-md"
        />
        <label 
        htmlFor={`month${month}`}
        className="flex mt-2 ml-2 cursor-pointer font-medium text-gray-700">
          {month} Months
        </label>
      </div>
    ))}
  </div>

</div>

                <div className="flex justify-end mt-10">

                {loading?
                   <Button>
                    <Loader/>
                   </Button>:
                  <Button type="submit">Update Ruminant Type</Button>
                }
                </div>
              </form>
            </Dialog.Panel>
          </div>
        </Dialog>}

    </div>
  )
}

export default AnimalTypesTest