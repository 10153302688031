import React, { useState, useEffect } from "react";
import { IoIosArrowDropright } from "react-icons/io";
import moneverse from "../../../assets/images/Moneyverse.png";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { IoLocation } from "react-icons/io5";
import { FaCheckCircle } from "react-icons/fa";
import { Link } from "react-router-dom";
import Chart from "react-apexcharts"; //Bar Chart
import { axios } from "../../../config/axios";
import { toast } from "react-toastify";


const Countdown = ({ targetDate }) => {
  const calculateTimeLeft = () => {
    const now = new Date();
    const difference = targetDate - now;
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    } else {
      // If the target date has been reached or passed
      timeLeft = { days: 0, hours: 0, minutes: 0, seconds: 0 };
    }

    return timeLeft;
  };

  const [time, setTime] = useState(calculateTimeLeft());

  useEffect(() => {
    const timerId = setInterval(() => {
      setTime(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timerId); // Cleanup on unmount
  }, [targetDate]);

  const formatTime = (value) => {
    return value.toString().padStart(2, "0");
  };

  return (
    <h1 className="text-pacific-blue text-[46px] font-semibold text-center">
      {time.days} days {formatTime(time.hours)}:{formatTime(time.minutes)}:{formatTime(time.seconds)}
    </h1>
  );
};

export default function DashboardMenu() {

  const [data,setData]=useState({})
  const [loading,setLoading]=useState(true)
	const token = JSON.parse(localStorage.getItem("token"));
	const user = JSON.parse(localStorage.getItem("user"));


  useEffect(() => {

  
    const fetchData = async (endpoint) => {
      try {
        const response = await axios.get(endpoint, {
       
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        setData(response?.data?.data)
        console.log(response.data);
        setLoading(false)
      } catch (error) {
        console.error('Error fetching data:', error);
        toast.error("Something wrong")
        setLoading(false)
        // Handle the error accordingly
      }
    };

    if(user?.role=="SLAUGHTER")
      {
        fetchData("reports/slaughter-dashboard?view_all=true");
      }

      if(user?.role=="REPORTER")
        {
          fetchData("reports/reporter-dashboard?view_all=true");
        }

    
  }, [token]);


  return (
    <div className="mb-[100px]">
      <div className="flex flex-col md:flex-row ">
        {/* Left side */}
<div className='md:w-[70%] md:pr-5 flex flex-col'>
          {/* <Link to="/ProfileVerification">          
        <div className='bg-gradient-to-r from-[#00ADB9] from-70% to-[#9747FF]  flex items-center justify-between px-6 py-12 rounded-lg'>
           <div className='text-white '>
              <h1 className='font-bold text-[22px]'>Verify your account</h1>
              <p className='mt-2'>Upload a picture of your identification card and a selfie to verify.</p>
           </div>
           <IoIosArrowDropright className=' text-white text-[40px] font-bold'/>
        </div>
          </Link> */}


        {/* <div className='py-6 px-10 border border-gray-200 mt-4 rounded-md flex flex-col justify-center  items-center'> 
          <img  src={moneverse} className='w-[300px]'/>
          <p className='text-[#00ADB9] py-4 text-[18px] font-semibold'>You have yet to complete any tasks. Participate in qurban or aqiqah tasks now!</p>
          <button className='bg-[#00ADB9] text-white w-full p-2 rounded-md'> Earn Now</button>
        </div> */}

    <h1 className="my-4 font-bold text-[18px]">ALL Task</h1>
<table className=" text-sm text-left rtl:text-right text-gray-500 overflow-x-auto">
          <thead className="text-xs text-pacific-blue uppercase bg-[#CFF0F2] overflow-x-scroll">
            <tr>
              <th scope="col" className="px-6 py-3 text-base font-medium ">
              
               
                <div className="flex gap-1 items-center">Slaughter Id</div>

                
              </th>
              <th scope="col" className="px-6 py-3 text-base font-medium ">
                <div className="flex gap-1 items-center">Task Id</div>
              </th>
              <th scope="col" className="px-6 py-3 text-base font-medium">
                <div className='flex gap-1 items-center'>

                  Task Status
                </div>

              </th>
              <th scope="col" className="px-6 py-3 text-base font-medium">
                <div className="flex gap-1 items-center">Payment Status</div>
              </th>
             
            </tr>
          </thead>
          <tbody>




            {data && Array.isArray(data?.taskList) && data?.taskList?.length > 0 && data?.taskList?.map((e,index) => {

              return (
                <tr className="bg-white border-b" key={e?.id}>
                 <th scope="row" className="px-6 py-4 text-sm font-medium">
  {user?.role === "REPORTER" ? e?.SlaughterTasks[0]?.slaughter_id : e?.slaughter_id}
</th>

                  <th scope="row" className="px-6 py-4 text-sm font-medium">
                    {e?.id}
                  </th>

                  <th scope="row" className="px-6 py-4 text-sm font-medium">
                  {user?.role === "REPORTER" ? e?.SlaughterTasks[0]?.status : e?.status}

                  </th>

                  <th scope="row" className="px-6 py-4 text-sm font-medium">
                    {e?.payment_status}
                  </th>
                 
                </tr>
              );
            })}
          </tbody>
        </table>

        <div className='py-6 px-10 border border-gray-200 mt-4 rounded-md flex flex-col justify-center  items-center'> 
             
          <div className='flex justify-between w-full mb-4'>
          <button><IoIosArrowBack className='text-[20px] font-bold text-gray-500'/></button>
            <p className='text-[20px] font-semibold'>August <span className='text-[16px]'>2024</span></p>
            <button><IoIosArrowForward className='text-[20px] font-bold text-gray-500'/></button>


          </div>

          <div className='border border-pacific-blue rounded-md p-2 w-full'>
            <div className='flex justify-end'>
            <button className='text-white bg-red-clr  rounded-full px-4 py-1 text-[12px]'>Upcoming</button>
            </div>
            <div>
                <h1 className='text-dark-teal font-semibold'>Aqiqah</h1>
                <p className='text-dark-teal opacity-30 text-end'>9am -2pm</p>

                <p className='text-dark-teal opacity-30'>02/08/2024</p>
                <div className='flex items-center mt-2'>
                <IoLocation className='text-dark-teal opacity-30 '/>
                <p className='text-dark-teal opacity-30'>Masjid Kampung Klang Gate - Setapak</p>
                </div>
            </div>
          </div>
 

        </div>
        

        </div>     

        {/* <div className="md:w-[70%] md:pr-5 mb-[100px]  flex justify-between flex-col">
          <div className="md:w-[100%] p-3 md:mr-5 border border-gray-200 mt-4 rounded-md flex flex-col h-fit ">
            <div>
              <h1 className="text-base font-semibold text-pacific-blue">
                Congratulations Asiah!{" "}
              </h1>
              <p className="text-xs text-[#0F405A]">
                You have successfully completed 38 tasks on 07/07/2024!{" "}
              </p>
            </div>

            <div className="mt-12">
              <p className="text-xs text-star-dust mb-5">
                Current total rewards gathered
              </p>
              <div className="flex md:flex-row flex-col items-center gap-7 ">
                <div className="flex">
                  <p className="md:text-2xl text-base font-semibold text-dark-teal">
                    RM
                  </p>{" "}
                  <p className="md:text-5xl text-4xl font-semibold text-dark-teal">
                    {" "}
                    3,139.60
                  </p>
                </div>
                <div className="w-full">
                  <div className="flex items-center justify-center md:mt-4">
                    <button className="text-gray-300 border max-sm:w-[50%] border-[#bdbbbb] bg-white rounded-md px-4 py-1 text-[12px]">
                      View graph
                    </button>
                    <button className="text-white ml-4 max-sm:w-[50%] bg-red-clr border border-red-clr rounded-md px-4 py-1 text-[12px]">
                      Earn More
                    </button>
                  </div>
                </div>
              </div>
            </div>

            
            <div className="relative flex flex-col rounded-xl bg-white bg-clip-border text-gray-700 shadow-md mt-32">
              <div className="pt-6 px-2 pb-0">
                <div id="bar-chart">
                  <Chart
                    options={chartConfig}
                    series={chartConfig.series}
                    type="bar"
                    height={240}
                  />
                </div>
              </div>
            </div>
          </div>


          <div className="pt-8 pb-8 md:px-10 px-2 border border-gray-200 mt-3 rounded-md flex flex-col justify-center  items-center">
            <div className="flex justify-between w-full mb-4">
              <button>
                <IoIosArrowBack className="text-[20px] font-bold text-gray-500" />
              </button>
              <p className="text-[20px] font-semibold">
                August <span className="text-[16px]">2024</span>
              </p>

              <button>
                <IoIosArrowForward className="text-[20px] font-bold text-gray-500" />
              </button>
            </div>
            <div className="flex w-full md:gap-4 gap-1">
              <div className="border border-pacific-blue rounded-md p-2 w-[50%]">
                <div className="flex justify-end">
                  <button className="text-white bg-red-clr  rounded-full px-4 py-1 text-[12px]">
                    Upcoming
                  </button>
                </div>
                <div>
                  <h1 className="text-dark-teal font-semibold">Aqiqah</h1>
                  <div className="flex justify-between mt-3"> 

                  <p className="text-dark-teal opacity-30">02/08/2024</p>
                  <p className="text-dark-teal opacity-30 text-end">9am -2pm</p>

                  </div>
                  <div className="flex items-center mt-3 w-full">
                    <IoLocation className="text-pacific-blue  w-[30%] md:w-[9%] text-2xl" />
                    <p className="text-dark-teal opacity-30 w-[70%] md:w-full">
                      Masjid Kampung Klang Gate - Setapak
                    </p>
                  </div>
                </div>
              </div>

              <div className="border border-star-dust rounded-md p-2 w-[50%] ">
                <div className="flex justify-end">
                  <button className="text-white bg-pacific-blue rounded-full px-4 py-1 text-[12px]">
                  Completed
                  </button>
                </div>
                <div>
                  <h1 className="text-dark-teal font-semibold">Aqiqah</h1>
                  <div className="flex justify-between mt-3"> 

                  <p className="text-dark-teal opacity-30">02/08/2024</p>
                  <p className="text-dark-teal opacity-30 text-end">9am -2pm</p>

                  </div>
                  <div className="flex items-center mt-3 w-full">
                    <IoLocation className="text-pacific-blue  w-[30%] md:w-[9%] text-2xl" />
                    <p className="text-dark-teal opacity-30 w-[70%] md:w-full">
                      Masjid Kampung Klang Gate - Setapak
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        {/* right-side */}

        <div className="md:w-[30%] mt-3 mb-[100px] md:mb-0 md:mt-0">
          <div className="border border-[#CDCDCD] rounded-md p-3">
            <p className="text-[#CDCDCD] text-center">
              {" "}
              Kiraan Detik ke Qurban
            </p>
            <Countdown targetDate={new Date('2025-06-24T00:00:00')} />
          </div>

          <div className="bg-gradient-to-r mt-3 text-white from-pacific-blue from-60% to-[#004d53b4] rounded-md p-3">
            <p className="text-[22px]">Anda mempunyai</p>

            <div className="flex font-semibold items-center">
              <h1 className="text-[75px] mr-2">{data?.qurbanTasks}</h1>
              <div className="">
                <h1 className="text-end text-[30px]">Qurban</h1>
                <p className="text-[22px] font-normal">tugas menunggu anda</p>
              </div>
            </div>
            {user?.role=="REPORTER"?
            <Link to="/tasksreporter" >
            <button className=" text-pacific-blue py-1  text-[20px] bg-white rounded-full w-full">
              {" "}
              Lihat
            </button>
            </Link>
            :
            <Link to="/tasks" >
            <button className=" text-pacific-blue py-1  text-[20px] bg-white rounded-full w-full">
              {" "}
              Lihat
            </button>
            </Link>
            }
          </div>

          <div className="bg-gradient-to-r mt-3 text-white from-electric-purple from-60% to-[#5B2B99] rounded-md p-3">
            <p className="text-[22px]">Anda mempunyai</p>

            <div className="flex font-semibold items-center">
              <h1 className="text-[75px] mr-2">{data?.aqiqahTasks}</h1>
              <div className="">
                <h1 className="text-end text-[30px]">Aqiqah</h1>
                <p className="text-[22px] font-normal">tugas menunggu anda</p>
              </div>
            </div>

            {user?.role=="REPORTER"?
            <Link to="/tasksreporter" >
            <button className=" text-pacific-blue py-1  text-[20px] bg-white rounded-full w-full">
              {" "}
              Lihat
            </button>
            </Link>
            :
            <Link to="/tasks" >
            <button className=" text-pacific-blue py-1  text-[20px] bg-white rounded-full w-full">
              {" "}
              Lihat
            </button>
            </Link>
            }
          </div>

          <div className="p-3 rounded-md bg-white border border-[#CDCDCD] mt-3">
            <div className="flex justify-between">
              <h1 className="text-[#0F405A] font-semibold">
                Jumlah tugas dilaksanakan
              </h1>
              <FaCheckCircle className="text-[#CFF0F2] text-[48px]" />
            </div>

            

            <h1 className="text-star-dust"> Tugas sudah selesai</h1>
            {/* Progress bar*/}
            <div className="relative pt-1">
              <div className="overflow-hidden h-10 mb-4 text-xs flex bg-amber-200 rounded-full">
               
                <div
                  style={{ width: "100%" }}
                  className="relative shadow-none py-2 flex flex-col text-center whitespace-nowrap text-white justify-center bg-electric-purple"
                >
                  <span className="absolute inset-0 flex justify-center items-center">
                    {data?.completedAqiqahTasks}
                  </span>
                </div>
              </div>
            </div>

            <h1 className="text-star-dust">Tugas sudah selesai</h1>
            {/* Progress bar*/}
            <div className="relative pt-1">
              <div className="overflow-hidden h-10 mb-4 text-xs flex bg-amber-200 rounded-full">
                <div
                  style={{ width: "100%" }}
                  className="relative shadow-none py-2 flex flex-col text-center whitespace-nowrap text-white justify-center bg-pacific-blue"
                >
                  <span className="absolute inset-0 flex justify-center items-center">
                   {data?.completedQurbanTasks}
                  </span>
                </div>
               
              </div>
            </div>

            {/* <div className="flex mt-2">
              <div className="flex items-center w-[50%]">
                <div className="bg-pacific-blue h-4 w-4 m rounded-[50%] mr-2"></div>
                <h1 className="font-semibold">Qurban</h1>
              </div>
              <div className="flex items-center w-[50%]">
                <div className="bg-electric-purple h-4 w-4 m rounded-[50%] mr-2"></div>
                <h1 className="font-semibold">Aqiqah</h1>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}
